import { cloneDeep, isBoolean, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import {
    IAdditionalCostsBackend,
    IAdditionalCostsWithValue,
} from '../../AdditionalCost/models/AdditionalCost.model';
import {
    DELIVERABLE_LENGTH_LIMIT,
    validatePaymentTerms,
} from '../../Global/Helpers/paymentTermsHelpers';
import { IItemDetails } from '../../Organizations/Admin/Interfaces/ItemDirectoryInterface';
import {
    IItemValuePayload,
    updateItemValue,
} from '../../Organizations/Admin/Services/ItemDirectoryService';
import {
    IItemCartTemplate,
    ItemCartTemplateStandardFieldsEnum,
} from '../Interfaces/ItemCartTemplateInterfaces';
import {
    CartItemDetails,
    ICartItemFieldValidation,
    ICartItemValidation,
    updateCartItemDetailsActionType,
} from '../Interfaces/itemCartItemInterfaces';

export const convertIAdditionalCostBackendToIAdditionalCostWithValue = (
    additional_costs: IAdditionalCostsBackend[]
) => {
    return additional_costs.map((ac) => ({
        additional_cost_id: ac.additional_cost_id,
        allocationType: ac.allocation_type,
        cost_source: ac.cost_source,
        costName: ac.cost_name,
        costType: ac.cost_type,
        value: ac.cost_value,
    }));
};

export const convertIAdditionalCostWithValueToIAdditionalCostBackend = (
    additional_costs: IAdditionalCostsWithValue[]
): IAdditionalCostsBackend[] => {
    return additional_costs.map((ac) => ({
        additional_cost_id: ac.additional_cost_id,
        allocation_type: ac.allocationType,
        cost_name: ac.costName,
        cost_source: ac.cost_source,
        cost_type: ac.costType,
        cost_value: ac.value,
    }));
};

export const getCartItemValidationKey = (
    updatedFieldName: updateCartItemDetailsActionType
): keyof ICartItemValidation | 'ALL' => {
    switch (updatedFieldName) {
        case 'quantity':
            return 'quantity';
        case 'delivery_information':
            return 'deliveryScheduleOverall';
        case 'deliveryScheduleOverall':
            return 'deliveryScheduleOverall';
        case 'price':
            return 'price';
        case 'shipping_address':
            return 'shipping_address';
        case 'incoterm':
            return 'incoterm';
        case 'item_additional_information':
            return 'item_additional_information';
        case 'lead_time':
            return 'lead_time';
        case 'lead_time_period':
            return 'lead_time_period';

        case 'custom_fields':
            return 'custom_fields';
        case 'paymentTerms_prepaymentPercentage':
            return 'paymentTerms_prepayment';
        case 'paymentTerms_invoiceItem_terms':
            return 'paymentTerms_invoiceItem_terms';
        case 'paymentTerms_invoiceItem_period':
            return 'paymentTerms_invoiceItem_period';
        case 'paymentTerms_invoiceItem_appliedFrom':
            return 'paymentTerms_invoiceItem_appliedFrom';
        case 'paymentTerms_poItem_allocationRows':
            return 'paymentTerms_poItem_allocationRows';
        case 'quantity_tolerance_percentage':
            return 'quantity_tolerance_percentage';
        case 'additional_costs':
            return 'additional_costs';
        case 'taxes':
            return 'taxes';
        case 'discounts':
            return 'discounts';
        default:
            return 'ALL';
    }
};

export interface IValidateCartItemDetailsProps {
    field: keyof ICartItemValidation | 'ALL';
    itemDetails: CartItemDetails;
    currentErrors: ICartItemValidation;
    templateDetails: IItemCartTemplate;
    preferredVendorCount: number | null;
    nonpreferredVendorCount: number | null;
}

export const validateCartItemDetails = ({
    currentErrors,
    field,
    itemDetails,
    templateDetails,
    nonpreferredVendorCount,
    preferredVendorCount,
}: IValidateCartItemDetailsProps) => {
    let newErrors = cloneDeep(currentErrors);

    if (field === 'ALL' || field === 'quantity') {
        if (!Boolean(itemDetails.quantity?.toString())) {
            newErrors.quantity = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Quantity input required',
                suppress: false,
            };
        } else if (+itemDetails.quantity <= 0) {
            newErrors.quantity = {
                type: 'ERROR',
                fieldMessageText: 'Must be greater than 0',
                popupMessageText: 'Invalid quantity',
                suppress: false,
            };
        } else {
            delete newErrors['quantity'];
        }
    }

    if (
        (field === 'ALL' || field === 'vendor_count') &&
        preferredVendorCount !== null &&
        nonpreferredVendorCount !== null
    ) {
        if (preferredVendorCount + nonpreferredVendorCount === 0) {
            //No of vendors is 0
            newErrors.vendor_count = {
                type: 'ERROR',
                fieldMessageText: '',
                popupMessageText: 'No vendors added',
                suppress: false,
            };
        } else if (nonpreferredVendorCount) {
            //No of vendors is 0
            newErrors.vendor_count = {
                type: 'WARNING',
                fieldMessageText: '',
                popupMessageText: 'Non-preferred vendors added',
                suppress: false,
            };
        } else {
            delete newErrors['vendor_count'];
        }
    }

    if (field === 'ALL' || field === 'custom_fields') {
        itemDetails.custom_fields.section_list.forEach((section) => {
            if (!Boolean(newErrors.custom_fields[section.name])) {
                newErrors.custom_fields[section.name] = {};
            }
            section.fields.forEach((field) => {
                const templateSettingsForField =
                    templateDetails.fieldSettings.customFields[field.name];
                console.log('validating fields', field);
                if (
                    (field.value === '' ||
                        isEqual(field.value, []) ||
                        field.value === null ||
                        (isBoolean(field.value) && field.value === null) ||
                        (!isBoolean(field.value) && +field.value === 0)) &&
                    templateSettingsForField?.buyerSettings?.required
                ) {
                    if (newErrors.custom_fields[section.name]) {
                        newErrors.custom_fields[section.name][field.name] = {
                            type: 'ERROR',
                            fieldMessageText: 'Field is required',
                            popupMessageText: 'Field is required',
                            suppress: false,
                        };
                    } else {
                        newErrors.custom_fields[section.name] = {
                            [field.name]: {
                                type: 'ERROR',
                                fieldMessageText: 'Field is required',
                                popupMessageText: 'Field is required',
                                suppress: false,
                            },
                        };
                    }
                } else {
                    if (newErrors.custom_fields[section.name]) {
                        delete newErrors.custom_fields[section.name][
                            field.name
                        ];
                    }
                }

                if (isEqual(newErrors.custom_fields[section.name], {})) {
                    delete newErrors.custom_fields[section.name];
                }
            });
        });
    }

    if (
        field === 'ALL' ||
        field === 'lead_time' ||
        field === 'lead_time_period'
    ) {
        if (
            templateDetails.fieldSettings.standardFields[
                ItemCartTemplateStandardFieldsEnum.LEAD_TIME
            ].buyerSettings.showField &&
            templateDetails.fieldSettings.standardFields[
                ItemCartTemplateStandardFieldsEnum.LEAD_TIME
            ].buyerSettings.required
        ) {
            if (!Boolean(itemDetails.procurement_information.lead_time)) {
                newErrors.lead_time = {
                    type: 'ERROR',
                    fieldMessageText: 'Must be greater than 0',
                    popupMessageText: 'Invalid leadtime',
                    suppress: false,
                };
            } else {
                delete newErrors['lead_time'];
            }
        }
    }

    if (
        field === 'ALL' ||
        field === 'delivery_information' ||
        field === 'deliveryScheduleOverall'
    ) {
        let totalDeliveryQuantity = 0;
        newErrors.delivery_information = [];

        if (itemDetails.quantity > 0) {
            for (
                let index = 0;
                index < itemDetails.delivery_information?.length;
                index++
            ) {
                const delivery = cloneDeep(
                    itemDetails.delivery_information[index]
                );
                let errorRow: {
                    quantity?: ICartItemFieldValidation;
                    date?: ICartItemFieldValidation;
                    cost_centre_id?: ICartItemFieldValidation;
                    project_id?: ICartItemFieldValidation;
                    general_ledger_id?: ICartItemFieldValidation;
                } = {};
                if (
                    typeof itemDetails.quantity !== 'number' &&
                    !Boolean(itemDetails.delivery_information[index].quantity)
                ) {
                    //Delivery quantity is blank
                    errorRow.quantity = {
                        type: 'ERROR',
                        fieldMessageText: 'Input required',
                        popupMessageText: '',
                        suppress: false,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: false,
                    };
                } else if (
                    +itemDetails.delivery_information[index].quantity <= 0
                ) {
                    console.log('what am i getting here', itemDetails);
                    errorRow.quantity = {
                        type: 'ERROR',
                        fieldMessageText: 'Must be greater than 0',
                        popupMessageText: '',
                        suppress: false,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: false,
                    };
                }

                if (
                    delivery.delivery_date !== null &&
                    !moment(
                        delivery.delivery_date,
                        'yyyy-MM-DD',
                        true
                    ).isValid()
                ) {
                    //Delivery date is blank or invalid (cannot differentiate these two cases in code)
                    errorRow.date = {
                        type: 'ERROR',
                        fieldMessageText: 'Invalid date',
                        popupMessageText: '',
                        suppress: false,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: false,
                    };
                } else if (
                    moment(delivery.delivery_date).isBefore(
                        moment().format('YYYY-MM-DD')
                    )
                ) {
                    errorRow.date = {
                        type: 'ERROR',
                        fieldMessageText: 'Past date cannot be entered',
                        popupMessageText: '',
                        suppress: false,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: false,
                    };
                }
                if (
                    templateDetails.fieldSettings.standardFields.COST_CENTER
                        .buyerSettings.required &&
                    templateDetails.fieldSettings.standardFields.COST_CENTER
                        .buyerSettings.showField &&
                    templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
                        (field) =>
                            field.field ===
                            ItemCartTemplateStandardFieldsEnum.COST_CENTER
                    )
                ) {
                    if (
                        delivery.cost_centre_id === null ||
                        delivery.cost_centre_id === ''
                    ) {
                        errorRow.cost_centre_id = {
                            type: 'ERROR',
                            fieldMessageText: 'Input required',
                            popupMessageText: 'Cost centre is required',
                            suppress: false,
                        };
                        newErrors.deliveryScheduleOverall = {
                            type: 'ERROR',
                            fieldMessageText: '',
                            popupMessageText: 'Invalid delivery schedule',
                            suppress: false,
                        };
                    }
                }
                if (
                    templateDetails.fieldSettings.standardFields.GL
                        .buyerSettings.required &&
                    templateDetails.fieldSettings.standardFields.GL
                        .buyerSettings.showField &&
                    templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
                        (field) =>
                            field.field ===
                            ItemCartTemplateStandardFieldsEnum.GL
                    )
                ) {
                    if (
                        delivery.general_ledger_id === null ||
                        delivery.general_ledger_id === ''
                    ) {
                        errorRow.general_ledger_id = {
                            type: 'ERROR',
                            fieldMessageText: 'Input required',
                            popupMessageText: '',
                            suppress: false,
                        };
                        newErrors.deliveryScheduleOverall = {
                            type: 'ERROR',
                            fieldMessageText: '',
                            popupMessageText: 'Invalid delivery schedule',
                            suppress: false,
                        };
                    }
                }
                if (
                    templateDetails.fieldSettings.standardFields.PROJECT
                        .buyerSettings.required &&
                    templateDetails.fieldSettings.standardFields.PROJECT
                        .buyerSettings.showField &&
                    templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
                        (field) =>
                            field.field ===
                            ItemCartTemplateStandardFieldsEnum.PROJECT
                    )
                ) {
                    if (
                        delivery.project_id === null ||
                        delivery.project_id === ''
                    ) {
                        errorRow.project_id = {
                            type: 'ERROR',
                            fieldMessageText: 'Input required',
                            popupMessageText: 'Project code is required',
                            suppress: false,
                        };
                        newErrors.deliveryScheduleOverall = {
                            type: 'ERROR',
                            fieldMessageText: '',
                            popupMessageText: 'Invalid delivery schedule',
                            suppress: false,
                        };
                    }
                }
                totalDeliveryQuantity += isNaN(+delivery.quantity)
                    ? 0
                    : +delivery.quantity;

                if (!isEqual(errorRow, {})) {
                    newErrors.delivery_information.push(errorRow);
                }
            }
        }

        if (
            templateDetails.fieldSettings.standardFields.DELIVERY_DATE
                .buyerSettings.showField &&
            templateDetails.fieldSettings.standardFields.DELIVERY_DATE
                .buyerSettings.required
        ) {
            if (field === 'ALL' || field === 'delivery_information') {
                if (
                    itemDetails.delivery_information.length === 0 ||
                    itemDetails.delivery_information.some(
                        (delivery) => delivery.delivery_date === null
                    )
                ) {
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: 'Input required',
                        popupMessageText: 'Delivery date input required',
                        suppress: false,
                    };
                }
            }
        }
        if (
            // Only perform this validation if delivery schedule is valid but quantity mismatches.
            !newErrors.deliveryScheduleOverall &&
            !isEmpty(itemDetails.quantity) &&
            totalDeliveryQuantity !== +itemDetails.quantity
        ) {
            //Total quantity does not match sum of delivery quantities
            newErrors.deliveryScheduleOverall = {
                type: 'ERROR',
                fieldMessageText: '',
                popupMessageText:
                    'Delivery schedule quantity does not match total desired quantity',
                suppress: false,
            };
        }
    }

    if (
        (field === 'ALL' || field === 'price') &&
        templateDetails.fieldSettings.standardFields[
            ItemCartTemplateStandardFieldsEnum.TARGET_RATE
        ].buyerSettings.showField &&
        templateDetails.fieldSettings.standardFields[
            ItemCartTemplateStandardFieldsEnum.TARGET_RATE
        ].buyerSettings.required
    ) {
        if (
            typeof itemDetails.pricing_information.desired_price !== 'number' &&
            isEmpty(itemDetails.pricing_information.desired_price)
        ) {
            //Price is blank
            newErrors.price = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Target rate input required',
                suppress: false,
            };
        } else if (itemDetails.pricing_information.desired_price <= 0) {
            //Price is 0
            newErrors.price = {
                type: 'ERROR',
                fieldMessageText: 'Must be greater than 0',
                popupMessageText: 'Invalid target rate',
                suppress: false,
            };
        } else {
            delete newErrors['price'];
        }
    }

    if (
        templateDetails.fieldSettings.standardFields.GR_TOLERANCE.buyerSettings
            .showField &&
        templateDetails.fieldSettings.standardFields.GR_TOLERANCE.buyerSettings
            .required &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) =>
                field.field === ItemCartTemplateStandardFieldsEnum.GR_TOLERANCE
        ) &&
        (field === 'ALL' || field === 'quantity_tolerance_percentage')
    ) {
        if (+itemDetails.quantity_tolerance_percentage > 10) {
            newErrors.quantity_tolerance_percentage = {
                type: 'ERROR',
                fieldMessageText: 'Cannot exceed 10%',
                popupMessageText: 'GR tolerance cannot exceed 10%',
                suppress: false,
            };
        } else if (
            templateDetails.fieldSettings.standardFields['GR_TOLERANCE']
                .buyerSettings.required &&
            templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
                (field) =>
                    field.field ===
                    ItemCartTemplateStandardFieldsEnum.GR_TOLERANCE
            )
        ) {
            newErrors['quantity_tolerance_percentage'] = {
                type: 'ERROR',
                fieldMessageText: 'GR tolerance is required',
                popupMessageText: 'GR tolerance is required',
                suppress: false,
            };
        } else {
            delete newErrors['quantity_tolerance_percentage'];
        }
    } else {
        delete newErrors['quantity_tolerance_percentage'];
    }

    if (
        templateDetails.fieldSettings.standardFields['SHIPPING_ADDRESS']
            .buyerSettings.showField &&
        templateDetails.fieldSettings.standardFields['SHIPPING_ADDRESS']
            .buyerSettings.required &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) =>
                field.field ===
                ItemCartTemplateStandardFieldsEnum.SHIPPING_ADDRESS
        ) &&
        (field === 'ALL' || field === 'shipping_address')
    ) {
        if (!Boolean(itemDetails.shipping_address.shipping_address_id)) {
            newErrors.shipping_address = {
                type: 'ERROR',
                fieldMessageText: 'Shipping address is required',
                popupMessageText: 'Shipping address is required',
                suppress: false,
            };
        } else {
            delete newErrors['shipping_address'];
        }
    } else {
        delete newErrors['shipping_address'];
    }

    const templatePaymentTermsOptions =
        templateDetails.fieldSettings.standardFields.PAYMENT_TERMS;
    const ptValidation = itemDetails?.paymentTerms
        ? validatePaymentTerms({
              paymentTerms: itemDetails?.paymentTerms!,
              prepaymentAllowed:
                  templatePaymentTermsOptions.paymentTermOptions
                      .prepaymentAllowed,
          })
        : {
              overallErrors: {
                  prepaymentBlank: false,
                  prepaymentAbove100: false,
                  allocationNot100: false,
                  termsBlank: false,
                  periodBlank: false,
                  appliedFromBlank: false,
                  appliedFromInvalid: false,
                  appliedFromTooLong: false,
              },
              rowErrors: [],
              errorExists: false,
          };

    const paymentTermsErrorKeys: (keyof ICartItemValidation)[] = [
        'paymentTerms_prepayment',
        'paymentTerms_invoiceItem_terms',
        'paymentTerms_invoiceItem_period',
        'paymentTerms_invoiceItem_appliedFrom',
        'paymentTerms_poItem_overallPercentage',
        'paymentTerms_poItem_allocationRows',
    ];

    if (
        templateDetails.fieldSettings.standardFields[
            ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS
        ].buyerSettings.showField &&
        templateDetails.fieldSettings.standardFields[
            ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS
        ].buyerSettings.required &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) =>
                field.field === ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS
        ) &&
        (field === 'ALL' || paymentTermsErrorKeys.includes(field))
    ) {
        paymentTermsErrorKeys.forEach((key) => {
            delete newErrors[key];
        });

        newErrors.paymentTerms_poItem_allocationRows = [];

        if (ptValidation.overallErrors.prepaymentBlank) {
            newErrors.paymentTerms_prepayment = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Prepayment input required',
                suppress: false,
            };
        } else if (ptValidation.overallErrors.prepaymentAbove100) {
            newErrors.paymentTerms_prepayment = {
                type: 'ERROR',
                fieldMessageText: 'Cannot exceed 100%',
                popupMessageText: 'Invalid prepayment percentage',
                suppress: false,
            };
        }
        if (ptValidation.overallErrors.termsBlank) {
            newErrors.paymentTerms_invoiceItem_terms = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms input required',
                suppress: false,
            };
        }
        if (ptValidation.overallErrors.periodBlank) {
            newErrors.paymentTerms_invoiceItem_period = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms period input required',
                suppress: false,
            };
        }
        if (ptValidation.overallErrors.appliedFromBlank) {
            newErrors.paymentTerms_invoiceItem_appliedFrom = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms reference date required',
                suppress: false,
            };
        }
        if (ptValidation.overallErrors.allocationNot100) {
            const msg =
                +(itemDetails.paymentTerms?.prepaymentPercentage ?? '0') > 0
                    ? 'Allocations and prepayment must add up to 100%'
                    : 'Allocations must add up to 100%';
            newErrors.paymentTerms_poItem_overallPercentage = {
                type: 'ERROR',
                fieldMessageText: msg,
                popupMessageText: msg,
                suppress: false,
            };
        }
        for (let rowIdx = 0; rowIdx < ptValidation.rowErrors.length; rowIdx++) {
            const curRowErrors: {
                rowAllocationPercentage?: ICartItemFieldValidation;
                rowTerms?: ICartItemFieldValidation;
                rowPeriod?: ICartItemFieldValidation;
                rowDeliverableName?: ICartItemFieldValidation;
                rowDate?: ICartItemFieldValidation;
            } = {};
            if (ptValidation.rowErrors[rowIdx].allocationBlank) {
                curRowErrors.rowAllocationPercentage = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: false,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].allocationOver100) {
                curRowErrors.rowAllocationPercentage = {
                    fieldMessageText: 'Cannot exceed 100',
                    popupMessageText: 'Payment terms invalid',
                    suppress: false,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].dateBlank) {
                curRowErrors.rowDate = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: false,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].datePast) {
                curRowErrors.rowDate = {
                    fieldMessageText: 'Input invalid',
                    popupMessageText: 'Payment terms invalid',
                    suppress: false,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].termsBlank) {
                curRowErrors.rowTerms = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: false,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].periodBlank) {
                curRowErrors.rowPeriod = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: false,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].deliverableBlank) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: false,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].deliverableTooLong) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: `Cannot exceed ${DELIVERABLE_LENGTH_LIMIT} characters`,
                    popupMessageText: 'Payment terms invalid',
                    suppress: false,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].deliverableInvalid) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: 'Input invalid',
                    popupMessageText: 'Payment terms invalid',
                    suppress: false,
                    type: 'ERROR',
                };
            }

            newErrors.paymentTerms_poItem_allocationRows.push(curRowErrors);
        }
    }
    if (
        templateDetails.fieldSettings.standardFields.INCOTERMS.buyerSettings
            .showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) =>
                field.field === ItemCartTemplateStandardFieldsEnum.INCOTERMS
        ) &&
        (field === 'ALL' || field === 'incoterm')
    ) {
        if (
            itemDetails.incoterm === '' &&
            templateDetails.fieldSettings.standardFields.INCOTERMS.buyerSettings
                .required
        ) {
            //Incoterm is blank
            newErrors.incoterm = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Incoterms input required',
                suppress: false,
            };
        } else {
            delete newErrors['incoterm'];
        }
    } else {
        delete newErrors['incoterm'];
    }

    if (
        templateDetails.fieldSettings.standardFields.ADDITIONAL_INFORMATION
            .buyerSettings.showField &&
        templateDetails.fieldSettings.standardFields.ADDITIONAL_INFORMATION
            .buyerSettings.required &&
        templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
            (field) =>
                field.field ===
                ItemCartTemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
        ) &&
        !Boolean(itemDetails.item_information.item_additional_details)
    ) {
        newErrors.item_additional_information = {
            type: 'ERROR',
            fieldMessageText: 'Input required',
            popupMessageText: 'Input required',
            suppress: false,
        };
    } else {
        delete newErrors['item_additional_information'];
    }

    if (
        templateDetails.fieldSettings.standardFields.ADDITIONAL_COSTS
            .buyerSettings.showField &&
        templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
            (field) =>
                field.field ===
                ItemCartTemplateStandardFieldsEnum.ADDITIONAL_COSTS
        ) &&
        (field === 'ALL' || field === 'additional_costs')
    ) {
        const requiredAdditionalCost =
            templateDetails.fieldSettings.standardFields.ADDITIONAL_COSTS.additionalCostOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredAdditionalCost.length > 0) {
            const isThereError = requiredAdditionalCost.reduce(
                (acc, additionalCostopt) => {
                    const foundItemAdditionalCost =
                        itemDetails.additional_costs.find(
                            (costAdditional) =>
                                costAdditional.cost_name.trim() ===
                                additionalCostopt.costName.trim()
                        );

                    if (foundItemAdditionalCost) {
                        if (
                            typeof foundItemAdditionalCost.cost_value ===
                                'string' &&
                            foundItemAdditionalCost.cost_value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );
            if (isThereError) {
                newErrors.additional_costs = {
                    type: 'ERROR',
                    fieldMessageText: 'Additional cost is required',
                    popupMessageText: 'Additional cost is required',
                    suppress: false,
                };
            } else {
                delete newErrors['additional_costs'];
            }
        } else {
            delete newErrors['additional_costs'];
        }
    }
    if (
        templateDetails.fieldSettings.standardFields.TAX.buyerSettings
            .showField &&
        templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
            (field) =>
                field.field === ItemCartTemplateStandardFieldsEnum.DISCOUNT
        ) &&
        (field === 'ALL' || field === 'taxes')
    ) {
        const requiredAdditionalCost =
            templateDetails.fieldSettings.standardFields.TAX.taxesOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredAdditionalCost.length > 0) {
            const isThereError = requiredAdditionalCost.reduce(
                (acc, additionalCostTaxopt) => {
                    const foundItemTaxes = itemDetails.taxes.find(
                        (cost) =>
                            cost.cost_name.trim() ===
                            additionalCostTaxopt.costName.trim()
                    );

                    if (foundItemTaxes) {
                        if (
                            typeof foundItemTaxes.cost_value === 'string' &&
                            foundItemTaxes.cost_value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );
            if (isThereError) {
                newErrors.taxes = {
                    type: 'ERROR',
                    fieldMessageText: 'Taxes is required',
                    popupMessageText: 'Taxes is required',
                    suppress: false,
                };
            } else {
                delete newErrors['taxes'];
            }
        } else {
            delete newErrors['taxes'];
        }
    }

    return newErrors;
};

export const updateItem = async (
    itemId: string,
    itemDetails: IItemDetails,
    callbackFn?: () => void
) => {
    try {
        if (itemDetails) {
            const payload: IItemValuePayload = {
                name: itemDetails.itemName,
                code: itemDetails.itemCode,
                item_type: itemDetails.item_type,
                description: itemDetails.description,
                custom_fields: itemDetails.custom_fields,
                image_id: itemDetails.image.map((img) => img.attachment_id),
                secondary_indices: itemDetails.secondary_indices ?? [],
                rss_feed_links: itemDetails.rss_feed_links ?? [],
                primary_index: itemDetails.primary_index
                    ? itemDetails.primary_index
                    : null,
                linked_currencies: itemDetails.linked_currencies,
                attributes: {
                    attributes: itemDetails.specifications.map((spec) => {
                        return {
                            attribute_name: spec.name,
                            attribute_value: spec.value,
                            attribute_value_type: 'TEXT',
                            attribute_exclude: false,
                        };
                    }),
                },
                measurement_units: itemDetails.measurementUnits.map(
                    (m) => m.id
                ),

                is_buyer: itemDetails.isBuyer,
                is_seller: itemDetails.isSeller,
                buyer_pricing_information: {
                    price: itemDetails.buyerPricingInformation.price ?? null,
                    currency_code_id:
                        itemDetails.buyerPricingInformation.currencyCodeId ??
                        null,
                    additional_costs:
                        itemDetails.buyerPricingInformation.additionalCost ??
                        [],
                    taxes: [],
                },
                seller_pricing_information: {
                    price: itemDetails.sellerPricingInformation.price ?? null,
                    currency_code_id:
                        itemDetails.sellerPricingInformation.currencyCodeId ??
                        null,
                    additional_costs:
                        itemDetails.buyerPricingInformation.additionalCost ??
                        [],
                    taxes: [],
                },
                notes: itemDetails.notes,
                internal_notes: itemDetails.internal_notes,
                custom_ids: {
                    custom_ids: itemDetails.customIds,
                },

                status: itemDetails.status,
                tags: itemDetails.tags ?? [],
            };

            await updateItemValue(itemId, payload);

            if (callbackFn) {
                callbackFn();
            }
        }
    } catch (err) {
        throw new Error('failed to update data');
    }
};
