import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import { IVLNItemCatalogue } from '../../../Interfaces/VendorLNInterfaces';
import InfoPopOver from '../../../../BuyerTemplates/Component/InfoPopOver';
import { useSellerProfileItemCatalogue } from '../../../../SellerProfile/Hooks/useSellerProfileItemCatalogueItem';
import ItemCataloguePopup from '../../../../SellerProfile/Components/ItemCataloguePopup';
import { FWPopup } from '../../../../Common/FWPopup';
import { VendorProfileIdContext } from '../../../../SellerProfile/Containers/SellerProfileItemCatalogue';

const VLNProfileItemComponent = ({
    itemsCatalogueData,
    canEdit,
}: {
    itemsCatalogueData: IVLNItemCatalogue;
    canEdit?: boolean;
}) => {
    const { handleDelete, handleUpdate } = useSellerProfileItemCatalogue(
        itemsCatalogueData.item_catalogue_id,
        itemsCatalogueData
    );
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const onDelete = () => {
        setDeleteOpen(false);
        handleDelete();
    };

    const onUpdate = (data: any) => {
        setOpen(false);
        handleUpdate(data);
    };

    const { buyerItems } = useContext(VendorProfileIdContext);

    return (
        <Box
            sx={
                {
                    // aspectRatio: '1/1',
                }
            }
            borderRadius={1.5}
            border={'1px solid lightgrey'}
            overflow={'hidden'}
        >
            {itemsCatalogueData.icon_url ? (
                <Box
                    component={'img'}
                    height={'200px'}
                    width={'100%'}
                    sx={{
                        objectFit: 'cover',
                    }}
                    src={itemsCatalogueData.icon_url}
                    alt={''}
                />
            ) : (
                <Box
                    overflow={'hidden'}
                    height={'200px'}
                    width={'100%'}
                    bgcolor={'lightgrey'}
                    className="flex flex--aic flex--jcc"
                    sx={{
                        color: 'white',
                        opacity: 0.6,
                        position: 'relative',
                    }}
                >
                    <i className="bi bi-image" style={{ fontSize: '200px' }} />
                </Box>
            )}
            <Box padding={2}>
                <div className="flex flex--aic flex--jcsb">
                    <div className="flex flex--aic" style={{ minWidth: 0 }}>
                        <FWTypography fontWeight={500}>
                            {itemsCatalogueData.name}
                        </FWTypography>
                        &nbsp;
                        <InfoPopOver
                            button={
                                <FWTooltip title="More info">
                                    <Box>
                                        <IconButton
                                            color={'primary'}
                                            sx={{ fontSize: 15 }}
                                        >
                                            <i className="bi bi-info-circle" />
                                        </IconButton>
                                    </Box>
                                </FWTooltip>
                            }
                            onPopContent={
                                <Box width={'250px'}>
                                    {itemsCatalogueData.description && (
                                        <>
                                            <Typography
                                                component={'h6'}
                                                marginBottom={0.5}
                                                fontWeight={500}
                                                color={'text.secondary'}
                                            >
                                                Description
                                            </Typography>
                                            <Typography>
                                                {itemsCatalogueData.description}
                                            </Typography>
                                        </>
                                    )}
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                component={'h6'}
                                                marginBottom={0.5}
                                                fontWeight={500}
                                                color={'text.secondary'}
                                            >
                                                Item name
                                            </Typography>
                                            <Typography>
                                                {itemsCatalogueData.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                component={'h6'}
                                                marginBottom={0.5}
                                                fontWeight={500}
                                                color={'text.secondary'}
                                            >
                                                Item ID
                                            </Typography>
                                            <Typography>
                                                {itemsCatalogueData.code}
                                            </Typography>
                                        </Grid>
                                        {itemsCatalogueData.attributes
                                            .attributes.length > 0 &&
                                            itemsCatalogueData.attributes.attributes.map(
                                                (val) => (
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            component={'h6'}
                                                            marginBottom={0.5}
                                                            fontWeight={500}
                                                            color={
                                                                'text.secondary'
                                                            }
                                                        >
                                                            {val.attribute_name}
                                                        </Typography>
                                                        <Typography>
                                                            {val.attribute_value
                                                                .length > 0
                                                                ? val.attribute_value.join(
                                                                      '/'
                                                                  )
                                                                : '-'}
                                                        </Typography>
                                                    </Grid>
                                                )
                                            )}
                                    </Grid>
                                </Box>
                            }
                        />
                    </div>
                    {canEdit && (
                        <div className="flex flex--aic">
                            <IconButton
                                size={'small'}
                                color={'primary'}
                                onClick={() => setOpen(true)}
                            >
                                <i className="bi bi-pencil" />
                            </IconButton>
                            <FWTooltip title={'Delete item from catalog'}>
                                <div>
                                    <IconButton
                                        size={'small'}
                                        color={'error'}
                                        onClick={() => setDeleteOpen(true)}
                                    >
                                        <i className="bi bi-trash" />
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        </div>
                    )}
                </div>
                <FWTypography color={'text.secondary'}>
                    {itemsCatalogueData?.pricing_information?.[0]?.price
                        ? `${itemsCatalogueData?.pricing_information?.[0]?.currency_symbol} ${itemsCatalogueData?.pricing_information?.[0]?.price} /${itemsCatalogueData?.pricing_information?.[0]?.measurement_unit_primary_name}`
                        : `${'\u00A0'}`}
                </FWTypography>
            </Box>
            {open && (
                <ItemCataloguePopup
                    initialData={{
                        imageUrl: itemsCatalogueData.icon_url,
                        name: itemsCatalogueData.name,
                        price: itemsCatalogueData?.pricing_information[0]
                            ?.price,
                        currencyCodeUuid:
                            itemsCatalogueData?.pricing_information?.[0]
                                ?.currency_code_id,
                        measurementUnitUuid:
                            itemsCatalogueData?.pricing_information?.[0]
                                ?.measurement_unit_id,
                    }}
                    open={open}
                    onClose={() => setOpen(false)}
                    onSave={onUpdate}
                    selectedItemData={
                        buyerItems.find(
                            (val) =>
                                val.enterprise_item.enterprise_item_id ===
                                itemsCatalogueData.enterprise_item
                        ) || null
                    }
                />
            )}
            <FWPopup
                title="Delete Item"
                msg="Are you sure you want to delete this item?"
                open={deleteOpen}
                onClickConfirm={onDelete}
                setOpen={setDeleteOpen}
                btnColor="error"
            />
        </Box>
    );
};

export default VLNProfileItemComponent;
