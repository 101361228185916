import { Avatar, Box, Grid, IconButton } from '@mui/material';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWSelect from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import {
    ProjectPermissionLabelEnum,
    ProjectPermissions,
} from '../../Organizations/Admin/Interfaces/UsersInterface';
import { IUserPermissionFEStructure } from './ShareSectionPopup';

export interface IShareSectionUserListProps {
    sharedAccessUserList: IUserPermissionFEStructure[];
    // handleUserRemove: (user_id: string) => void;
    disableRemoveButton: boolean;
    updateUserAccess: (user_id: string, newPermission: string) => void;
    removeUserAccess: (user_id: string) => void;
    sharingAccessFor: 'TEMPLATE' | 'SECTION';
    userOptions: IUserPermissionFEStructure[];
    removedUsers: {
        user: {
            name: string;
            user_id: string;
        };
        permission: string;
    }[];
}

const ShareSectionUserList = ({
    sharedAccessUserList,
    userOptions,
    disableRemoveButton,
    removeUserAccess,
    updateUserAccess,
    sharingAccessFor,
    removedUsers,
}: IShareSectionUserListProps) => {
    const getInitials = (name: string) => {
        if (name.length === 0) {
            return '';
        }
        let nameArr = name.split(' ');
        return nameArr.map((namePart) => namePart[0].toUpperCase()).join('');
    };

    return (
        <>
            {sharedAccessUserList.map((userData, index) => {
                const userInfoInOptions = userOptions.find(
                    (user) => user.user_id === userData.user_id
                );

                const userInfoInRemovedUser = removedUsers.find(
                    (remUser) => remUser.user.user_id === userData.user_id
                );

                let hideSecondOption = userInfoInOptions
                    ? userInfoInOptions?.permissions?.length === 1
                    : false;

                return (
                    <FWTooltip
                        title={
                            userInfoInRemovedUser
                                ? `User no longer has ${
                                      ProjectPermissionLabelEnum[
                                          userData
                                              .permissions[0] as ProjectPermissions
                                      ] as string
                                  } permissions`
                                : ''
                        }
                    >
                        <Grid
                            item
                            key={index}
                            xs={12}
                            sx={{
                                opacity: userInfoInRemovedUser ? '75%' : '100%',
                                display: 'flex',
                                gap: '1rem',
                                alignItems: 'center',
                            }}
                        >
                            <Grid
                                item
                                xs={8}
                                sx={{
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar>
                                    <FWTypography style={{ fontSize: '15px' }}>
                                        {userInfoInRemovedUser
                                            ? getInitials(
                                                  userInfoInRemovedUser.user
                                                      .name
                                              )
                                            : getInitials(userData.name)}
                                    </FWTypography>
                                </Avatar>
                                <FWTypography>
                                    {userInfoInRemovedUser
                                        ? userInfoInRemovedUser.user.name
                                        : userData.name}
                                </FWTypography>
                            </Grid>

                            <Grid item xs={2.5}>
                                <FWSelect
                                    disabled={Boolean(userInfoInRemovedUser)}
                                    value={userData.permissions[0]}
                                    fullWidth
                                    onChange={(e, newValue) => {}}
                                >
                                    {sharingAccessFor === 'SECTION' && (
                                        <FWMenuItem
                                            value={
                                                ProjectPermissions.PROJECT_EDIT
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                updateUserAccess(
                                                    userData.user_id,
                                                    ProjectPermissions.PROJECT_EDIT
                                                );
                                            }}
                                        >
                                            Edit
                                        </FWMenuItem>
                                    )}
                                    {!hideSecondOption &&
                                        sharingAccessFor === 'SECTION' && (
                                            <FWMenuItem
                                                value={
                                                    ProjectPermissions.PROJECT_ASSIGN_USERS
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    updateUserAccess(
                                                        userData.user_id,
                                                        ProjectPermissions.PROJECT_ASSIGN_USERS
                                                    );
                                                }}
                                            >
                                                Assign
                                            </FWMenuItem>
                                        )}
                                    {hideSecondOption &&
                                        sharingAccessFor === 'SECTION' && (
                                            <FWTooltip title="User does not have permissions to assign users">
                                                <Box>
                                                    <FWMenuItem
                                                        value={
                                                            ProjectPermissions.PROJECT_ASSIGN_USERS
                                                        }
                                                        disabled
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            updateUserAccess(
                                                                userData.user_id,
                                                                ProjectPermissions.PROJECT_ASSIGN_USERS
                                                            );
                                                        }}
                                                    >
                                                        Assign
                                                    </FWMenuItem>
                                                </Box>
                                            </FWTooltip>
                                        )}

                                    {sharingAccessFor === 'TEMPLATE' && (
                                        <FWMenuItem
                                            value={
                                                ProjectPermissions.PROJECT_CREATE
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                updateUserAccess(
                                                    userData.user_id,
                                                    'PROJECT_CREATE'
                                                );
                                            }}
                                        >
                                            Create
                                        </FWMenuItem>
                                    )}
                                    {!hideSecondOption &&
                                        sharingAccessFor === 'TEMPLATE' && (
                                            <FWMenuItem
                                                value={
                                                    ProjectPermissions.PROJECT_ASSIGN_MANAGERS
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    updateUserAccess(
                                                        userData.user_id,
                                                        'PROJECT_ASSIGN_MANAGERS'
                                                    );
                                                }}
                                            >
                                                Assign
                                            </FWMenuItem>
                                        )}
                                    {hideSecondOption &&
                                        sharingAccessFor === 'TEMPLATE' && (
                                            <FWTooltip title="User does not have permissions to assign project managers">
                                                <Box>
                                                    <FWMenuItem
                                                        value={
                                                            ProjectPermissions.PROJECT_ASSIGN_MANAGERS
                                                        }
                                                        disabled
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            updateUserAccess(
                                                                userData.user_id,
                                                                'PROJECT_ASSIGN_MANAGERS'
                                                            );
                                                        }}
                                                    >
                                                        Assign
                                                    </FWMenuItem>
                                                </Box>
                                            </FWTooltip>
                                        )}
                                </FWSelect>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    disabled={disableRemoveButton}
                                    onClick={() => {
                                        removeUserAccess(userData.user_id);
                                    }}
                                    size="small"
                                    color="error"
                                    sx={{ marginLeft: '0.75rem' }}
                                >
                                    <i className="bi bi-dash-circle"></i>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </FWTooltip>
                );
            })}
        </>
    );
};

export default ShareSectionUserList;
