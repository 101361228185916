import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import {
    Box,
    Chip,
    FormControl,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { memo, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import FWAutocomplete from '../../../Common/FWAutocomplete';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import FWIcon from '../../../Common/FWIcon';
import { FWTypography } from '../../../Common/FWTypograhy';
import { CustomFieldType } from '../../../Global/Interfaces/TemplateInterface';
import { FieldTypeLabel } from '../../constants/projectDashboardConstants';
import { getSortOptions } from '../../constants/projectDashboardSortConstants';
import {
    DashboardSort,
    DashbordSortOptions,
} from '../../interface/project.model';

const ProjectDashboardSortFieldRowContent = ({
    provided,
    fieldTypeLabels,
    index,
    sortRow,
    updateSortList,
    singleSortField,
    removeSortRow,
    selectedFields,
}: {
    singleSortField: boolean;
    provided: DraggableProvided;
    index: number;
    sortRow: DashboardSort;
    removeSortRow: (index: number) => void;
    updateSortList: (
        index: any,
        updatedField:
            | {
                  type: 'FIELD';
                  value: string;
                  isBuiltIn: boolean | null;
                  fieldType: CustomFieldType;
              }
            | {
                  type: 'ORDER';
                  ascending: boolean;
              }
    ) => void;
    selectedFields: string[];
    fieldTypeLabels: FieldTypeLabel[];
}) => {
    const [inputValue, setInputValue] = useState('');
    fieldTypeLabels = fieldTypeLabels.filter(
        (f) =>
            f.column !== 'Primary contact' &&
            f.column !== 'Secondary contact(s)'
    );
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <IconButton
                size="small"
                sx={{
                    width: '45px',
                    height: '45px',
                }}
            >
                <Box {...provided.dragHandleProps}>
                    <DragIndicatorOutlinedIcon />
                </Box>
            </IconButton>
            <FormControl
                sx={{
                    m: 1,
                    backgroundColor: 'white',
                }}
                size="small"
            >
                <FWAutocomplete
                    options={fieldTypeLabels}
                    value={
                        sortRow.field
                            ? fieldTypeLabels.find(
                                  (option) => option.column === sortRow.field
                              ) ?? null
                            : null
                    }
                    sx={{
                        minWidth: '300px',
                    }}
                    popupIcon={
                        <Box fontSize={'16px'} paddingTop={0.5}>
                            <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                        </Box>
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                    )}
                    getOptionLabel={(option: FieldTypeLabel) => option.label}
                    fullWidth
                    onChange={(e, value: FieldTypeLabel) => {
                        if (!value) {
                            setInputValue('');
                        } else {
                            updateSortList(index, {
                                type: 'FIELD',
                                value: value.column as string,
                                isBuiltIn: value.is_built_in,
                                fieldType: value.fieldType as CustomFieldType,
                            });
                        }
                    }}
                    filterOptions={(option: FieldTypeLabel[]) =>
                        option.filter((option) =>
                            option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                        )
                    }
                    renderOption={(props, option: FieldTypeLabel) => {
                        const isDisabled = selectedFields.includes(
                            option.column
                        );
                        console.log(
                            `Option: ${option.column}, Disabled: ${isDisabled}`
                        );
                        return (
                            <FWTooltip
                                title={
                                    isDisabled
                                        ? 'Field already selected'
                                        : `${option.label} | ${option.fieldType}`
                                }
                            >
                                <div>
                                    <FWMenuItem
                                        {...props}
                                        key={option.label}
                                        value={option.column}
                                        disabled={isDisabled}
                                    >
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <p> {option.label}</p>
                                            {option.is_built_in && (
                                                <img
                                                    style={{
                                                        height: '1.5rem',
                                                        width: '1.5rem',
                                                        marginLeft: '0.7rem',
                                                    }}
                                                    src="/logo.png"
                                                    alt="factwise logo"
                                                />
                                            )}
                                            <Chip
                                                sx={{
                                                    backgroundColor: '#C7C8CC',
                                                    borderRadius:
                                                        '7px !important',
                                                    fontSize: '0.7rem',
                                                    marginLeft: '0.5rem',
                                                    color: 'black',
                                                }}
                                                label={option.fieldType}
                                            />
                                        </Typography>
                                    </FWMenuItem>
                                </div>
                            </FWTooltip>
                        );
                    }}
                    isOptionEqualToValue={(option, value) => {
                        return option.column === value.column;
                    }}
                />
            </FormControl>
            <FormControl
                sx={{
                    m: 1,
                    minWidth: 200,
                    backgroundColor: 'white',
                }}
                size="small"
            >
                <FWAutocomplete
                    options={getSortOptions(sortRow.type)}
                    sx={{
                        minWidth: '300px',
                    }}
                    value={
                        getSortOptions(sortRow.type).find(
                            (option) => option.ascending === sortRow.ascending
                        ) ?? null
                    }
                    popupIcon={
                        <Box fontSize={'16px'} paddingTop={0.5}>
                            <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                        </Box>
                    }
                    renderInput={(params) => <TextField {...params} />}
                    getOptionLabel={(option: DashbordSortOptions) =>
                        option.label
                    }
                    isOptionEqualToValue={(
                        option: DashbordSortOptions,
                        value: DashboardSort
                    ) => {
                        return option.ascending === value.ascending;
                    }}
                    fullWidth
                    onChange={(e, value: DashbordSortOptions) => {
                        updateSortList(index, {
                            type: 'ORDER',
                            ascending: value.ascending,
                        });
                    }}
                    renderOption={(props, option: DashbordSortOptions) => {
                        return (
                            <FWMenuItem
                                key={option.label}
                                {...props}
                                value={option.label}
                            >
                                <FWTypography>{option.label}</FWTypography>
                            </FWMenuItem>
                        );
                    }}
                />
            </FormControl>

            <IconButton
                disabled={singleSortField && index === 0}
                onClick={() => {
                    removeSortRow(index);
                }}
                sx={{
                    color: 'black',
                }}
            >
                <Box
                    sx={{
                        fontSize: '1.5rem',
                    }}
                >
                    <FWIcon name="bi bi-x" size={20} />
                </Box>
            </IconButton>
        </Box>
    );
};

export default memo(ProjectDashboardSortFieldRowContent);
