import { cloneDeep } from 'lodash';
import {
    BaseMetricDefinition,
    ChartCurrencySettings,
    ChartFilterController,
    ChartGroupFilter,
    ChartGroupSort,
    ChartGrouping,
    ChartGroupingDate,
    ChartGroupingEnum,
    ChartGroupingMetric,
    ChartGroupingString,
    ChartMetricBar,
    ChartMetricDefinition,
    ChartMetricPie,
    FilterFields,
    IHistoricalAnalyticsChart,
    PercentageMetricDefinition,
    SingleFilter,
} from '../Interfaces/IHistoricalAnalytics.model';
import {
    HARequestSingleFilterNotNull,
    IHARequestPayloadFilterList,
    IHARequestPayloadFilter,
    IHARequestPayloadGrouping,
    IHARequestPayloadMetricDict,
    IHARequestPayloadSingleFilterDetails,
    IHARequestPayloadType,
    IHARequestSort,
    HARequestSingleFilterSingleselectDropdown,
    HARequestSingleFilterMultiselectDropdown,
    HARequestSingleFilterMultiselectDropdownContains,
    IHADownloadPayloadType,
} from '../Interfaces/IHistoricalAnalyticsApi.model';

export const getHADataRequestPayload = ({
    metricSettings,
    groupingOptions,
    filterOptions,
    ignoreGroupSortAndFilter = false,
    currencySettings,
}: {
    metricSettings: ChartMetricPie | ChartMetricBar;
    groupingOptions: {
        groupBy: ChartGrouping[];
        groupSort: ChartGroupSort;
        groupFilter: ChartGroupFilter;
    };
    filterOptions: ChartFilterController;
    ignoreGroupSortAndFilter?: boolean;
    currencySettings: ChartCurrencySettings;
}): IHARequestPayloadType => {
    const { metricId, metricDefinition } = metricSettings;
    const { metrics, computation } = getMetricPayload(metricDefinition);
    const filterOptionsWithCurrency = cloneDeep(filterOptions);
    if (currencySettings.filterCurrenciesIds.length > 0) {
        filterOptionsWithCurrency.fixedFilters.push({
            filterId: 'currency',
            filterType: 'multiselectDropdown',
            filterFields: {
                metricFieldType: 'universal',
                fieldName: 'currency_code__entry_id',
            },
            filterLabel: 'Currency',
            modifierType: 'CONSOLIDATED',
            modifierValue: 'IN',
            values: currencySettings.filterCurrenciesIds,
            filterApplied: true,
        });
    }

    const filters = getFiltersPayload({
        metricId,
        filterOptions: filterOptionsWithCurrency,
    });

    const groupingList = getGroupingList(groupingOptions);
    const { sort_fields, number_of_entries } = ignoreGroupSortAndFilter
        ? { sort_fields: [], number_of_entries: null }
        : getSortByAndCount(groupingOptions);

    const grouping_filters = getGroupingFilters({
        groupingOptions,
        filterOptions,
    });
    const payload: IHARequestPayloadType = {
        metrics: metrics,
        grouping_list: groupingList,
        filters: filters,
        sort_fields,
        number_of_entries,
    };
    if (computation) {
        payload.computation = computation;
    }
    if (grouping_filters) {
        payload.grouping_filters = grouping_filters;
    }
    if (currencySettings.targetCurrency) {
        payload.currency_code_id = currencySettings.targetCurrency.currencyUuid;
    }
    return payload;
};

const getFiltersPayload = ({
    metricId,
    filterOptions,
}: {
    metricId: string;
    filterOptions: ChartFilterController;
}): IHARequestPayloadFilter => {
    /**
     * Aiming to return a payload like this:
     * and_: [1. fixed filters, 2. customizable filters]
     *
     * where customizable filters = and_: [category 1, category 2, ...]
     * And each category is a list of single filters.
     */
    const payloadFilters: IHARequestPayloadFilter = {
        and_: [],
    };

    /**
     * First process fixed filters
     */
    const fixedFilterList: IHARequestPayloadFilterList = processFilterList({
        filterList: filterOptions.fixedFilters,
        metricId: metricId,
    });
    if (fixedFilterList.length > 0) {
        //If fixed filters exist, then add them to the outermost and_ condition
        const fixedFilterPayload: IHARequestPayloadFilter = {
            and_: fixedFilterList,
        };
        payloadFilters.and_.push(fixedFilterPayload);
    }

    const { categories, categoryConnector } = filterOptions.customizableFilters;

    const allCategoriesList: IHARequestPayloadFilterList = [];

    categories.forEach((curCategory) => {
        const curCategoryFilterList: IHARequestPayloadFilterList =
            processFilterList({
                filterList: curCategory.filters,
                metricId: metricId,
            });
        //If the category has a filter applied, then add the category to the category list.
        if (curCategoryFilterList.length > 0) {
            const curCategoryPayload: IHARequestPayloadFilter =
                curCategory.filterConnector === 'AND'
                    ? {
                          and_: curCategoryFilterList,
                      }
                    : {
                          or_: curCategoryFilterList,
                      };
            allCategoriesList.push(curCategoryPayload);
        }
    });

    //If at least 1 category exists, then add the "categories" to the outermost and_ condition
    if (allCategoriesList.length > 0) {
        const allCategoriesPayload: IHARequestPayloadFilter =
            categoryConnector === 'AND'
                ? {
                      and_: allCategoriesList,
                  }
                : {
                      or_: allCategoriesList,
                  };

        payloadFilters.and_.push(allCategoriesPayload);
    }

    return payloadFilters;
};

const processFilterList = ({
    filterList,
    metricId,
}: {
    filterList: SingleFilter[];
    metricId: string;
}): IHARequestPayloadFilterList => {
    const processedList: IHARequestPayloadFilterList = [];
    filterList.forEach((curFilter) => {
        const processedFilter = getSingleFilterPayload({
            filter: curFilter,
            metricId: metricId,
        });
        if (processedFilter) {
            switch (processedFilter.type) {
                case 'object':
                    processedList.push({
                        single: processedFilter.value,
                    });
                    break;
                case 'array':
                    processedFilter.value.forEach((elem) => {
                        processedList.push({
                            single: elem,
                        });
                    });
                    break;
                case 'consolidated_list':
                    processedList.push(processedFilter.value);
                    break;
            }
        }
    });
    return processedList;
};

type getSingleFilterPayloadObj =
    | {
          /**
           * This type is for tags. If we want item_tag = 'fruits' or 'vegetables',
           * this will return
           * {_or: [item_tag CONTAINS 'fruits', item_tag CONTAINS 'vegetables']}
           */
          type: 'consolidated_list';
          value: IHARequestPayloadFilter;
      }
    | {
          /**
           * This is for 'normal' filters like dropdowns.
           * e.g. "STATUS in [ISSUED, ONGOING]"
           */
          type: 'object';
          value: IHARequestPayloadSingleFilterDetails;
      }
    | {
          /**
           * Currently used for dates. When a custom date filter is applied, we need
           * to send two separate conditions to the BE (>= start date, <= end date)
           */
          type: 'array';
          value: IHARequestPayloadSingleFilterDetails[];
      };

/**
 * If the filter is present and well-defined, then return the payload for the filter.
 * Else null.
 * Sometimes a single filter in the settings needs to be converted to an entire list
 * for the backend.
 * So the return type is getSingleFilterPayloadObj
 */
const getSingleFilterPayload = ({
    filter,
    metricId,
}: {
    filter: SingleFilter;
    metricId: string;
}): getSingleFilterPayloadObj | null => {
    const fieldName = getFieldName(filter.filterFields, metricId);
    if (!fieldName || !filter.filterApplied) {
        return null;
    }
    switch (filter.filterType) {
        case 'notNull':
            const notNullFilter: HARequestSingleFilterNotNull = {
                field: fieldName,
                condition_type: 'NOT_EQUALS',
                condition: {
                    value: '',
                },
            };
            return {
                type: 'object',
                value: notNullFilter,
            };
        case 'date':
            let startDateFormatted: string | null = null;
            let endDateFormatted: string | null = null;
            if (filter.dateRange !== 'custom') {
                const parts = filter.dateRange.split('_');
                if (parts.length === 3) {
                    const numDays = +parts[1];
                    const startDate = new Date();
                    startDate.setDate(startDate.getDate() - numDays);
                    // TODO: Discuss with Nishant. Datetime better for backend
                    startDateFormatted = startDate.toISOString().split('T')[0];
                }
            } else {
                // Why is .length condition needed?
                if (
                    filter.customRange.startDate &&
                    filter.customRange.startDate.length
                ) {
                    startDateFormatted = filter.customRange.startDate;
                }
                if (
                    filter.customRange.endDate &&
                    filter.customRange.endDate.length
                ) {
                    endDateFormatted = filter.customRange.endDate;
                }
            }

            const dateFilters: getSingleFilterPayloadObj = {
                type: 'array',
                value: [],
            };
            if (startDateFormatted) {
                dateFilters.value.push({
                    field: fieldName,
                    condition_type: 'GREATER_THAN_EQUALS',
                    condition: {
                        value: startDateFormatted,
                    },
                });
            }
            if (endDateFormatted) {
                dateFilters.value.push({
                    field: fieldName,
                    condition_type: 'LESS_THAN_EQUALS',
                    condition: {
                        value: endDateFormatted,
                    },
                });
            }
            return dateFilters;
        case 'singleselectDropdown':
            if (filter.value && filter.value.length) {
                const singleSelectDropdownFilter: HARequestSingleFilterSingleselectDropdown =
                    {
                        field: fieldName,
                        condition_type: filter.modifier,
                        condition: {
                            value: filter.value,
                        },
                    };
                return {
                    type: 'object',
                    value: singleSelectDropdownFilter,
                };
            }
            return null;
        case 'multiselectDropdown':
            if (filter.values && filter.values.length) {
                if (filter.modifierType === 'CONSOLIDATED') {
                    const multiSelectDropdownFilter: HARequestSingleFilterMultiselectDropdown =
                        {
                            field: fieldName,
                            condition_type: filter.modifierValue,
                            condition: {
                                list: filter.values,
                            },
                        };
                    return {
                        type: 'object',
                        value: multiSelectDropdownFilter,
                    };
                } else {
                    const filters: HARequestSingleFilterMultiselectDropdownContains[] =
                        filter.values.map((val) => {
                            return {
                                field: fieldName,
                                condition_type: filter.modifierValue,
                                condition: {
                                    value: val,
                                },
                            };
                        });
                    let consolidatedFilter: IHARequestPayloadFilter;
                    if (filter.modifierValue === 'CONTAINS') {
                        consolidatedFilter = {
                            or_: filters.map((f) => {
                                return {
                                    single: f,
                                };
                            }),
                        };
                    } else {
                        consolidatedFilter = {
                            and_: filters.map((f) => {
                                return {
                                    single: f,
                                };
                            }),
                        };
                    }
                    return {
                        type: 'consolidated_list',
                        value: consolidatedFilter,
                    };
                }
            }
            return null;
    }
};

/**
 * Gets the field name to use for the current filter and current metric.
 * If the filter is universal, then the field name is the same for all metrics.
 * If the filter is metric-specific, then the field name is different for each metric,
 * and can be missing for some metrics (which means 'do not use this filter')
 */
const getFieldName = (filterFields: FilterFields, metricId: string) => {
    if (filterFields.metricFieldType === 'universal') {
        return filterFields.fieldName;
    }
    if (metricId in filterFields.metricFields) {
        return filterFields.metricFields[metricId];
    }
    return null;
};

type MetricAndComputationType = {
    metrics: IHARequestPayloadMetricDict;
    computation?: string;
};

const getMetricPayload = (
    metricDefinition: ChartMetricDefinition
): MetricAndComputationType => {
    switch (metricDefinition.metricType) {
        case 'base':
            return getMetricPayload_BaseMetric(metricDefinition);
        case 'percentage':
            return getMetricPayload_PercentageMetric(metricDefinition);
    }
};

const getMetricPayload_BaseMetric = (
    metricDefinition: BaseMetricDefinition
): MetricAndComputationType => {
    return {
        metrics: {
            [metricDefinition.metricField]: {
                field: metricDefinition.metricField,
                aggregation: metricDefinition.aggregation,
            },
        },
    };
};

const getMetricPayload_PercentageMetric = (
    metricDefinition: PercentageMetricDefinition
): MetricAndComputationType => {
    const numeratorBaseMetric = metricDefinition.numerator;
    const denominatorBaseMetric = metricDefinition.denominator;

    return {
        metrics: {
            numerator: {
                field: numeratorBaseMetric.metricField,
                aggregation: numeratorBaseMetric.aggregation,
            },
            denominator: {
                field: denominatorBaseMetric.metricField,
                aggregation: denominatorBaseMetric.aggregation,
            },
        },
        computation: `100*numerator/denominator`,
    };
};

const getGroupingList = (groupingOptions: {
    groupBy: ChartGrouping[];
    groupSort: ChartGroupSort;
    groupFilter: ChartGroupFilter;
}) => {
    return groupingOptions.groupBy.map((group) => {
        return getSingleGrouping(group);
    });
};

const getSingleGrouping = (
    groupBy: ChartGrouping
): IHARequestPayloadGrouping => {
    switch (groupBy.type) {
        case 'enum':
            return getGroupingEnum(groupBy);
        case 'date':
            return getGroupingDate(groupBy);
        case 'str':
            return getGroupingStr(groupBy);
        case 'metric':
            return getGroupingMetric(groupBy);
    }
};

const getGroupingEnum = (
    groupBy: ChartGroupingEnum
): IHARequestPayloadGrouping => {
    return {
        type: 'enum',
        field: groupBy.field,
    };
};

const getGroupingStr = (
    groupBy: ChartGroupingString
): IHARequestPayloadGrouping => {
    return {
        type: 'str',
        field: groupBy.field,
    };
};

const getGroupingDate = (
    groupBy: ChartGroupingDate
): IHARequestPayloadGrouping => {
    return {
        type: 'date',
        field: groupBy.field,
        date_period: groupBy.period,
    };
};

const getGroupingMetric = (
    groupBy: ChartGroupingMetric
): IHARequestPayloadGrouping => {
    return {
        type: 'metric',
        field: groupBy.field,
        custom_groups: groupBy.groupDefinitions.map((groupDef) => {
            return {
                name: groupDef.groupingName,
                start: groupDef.start,
                start_inclusive:
                    groupDef.start === null || groupDef.start === undefined
                        ? null
                        : groupDef.startInclusive,
                end: groupDef.end,
                end_inclusive:
                    groupDef.end === null || groupDef.end === undefined
                        ? null
                        : groupDef.endInclusive,
            };
        }),
    };
};

const getSortByAndCount = (groupingOptions: {
    groupBy: ChartGrouping[];
    groupSort: ChartGroupSort;
    groupFilter: ChartGroupFilter;
}): {
    sort_fields: IHARequestSort[];
    number_of_entries: {
        count: number;
        ascending: boolean;
    } | null;
} => {
    const sort_fields: IHARequestSort[] = [];

    if (groupingOptions.groupSort.sortBy === 'name') {
        groupingOptions.groupBy.forEach((group) => {
            let formattedFieldName = group.field + '_grouped';
            switch (group.type) {
                case 'str':
                    if (group.includeInGroupName) {
                        sort_fields.push({
                            field: formattedFieldName,
                            ascending:
                                groupingOptions.groupSort.sortOrder === 'asc',
                        });
                    }
                    break;
                case 'enum':
                    sort_fields.push({
                        field: formattedFieldName,
                        ascending:
                            groupingOptions.groupSort.sortOrder === 'asc',
                    });
                    break;
                case 'date':
                    if (group.period === 'year') {
                        formattedFieldName = group.field + '__year';
                        sort_fields.push({
                            field: formattedFieldName,
                            ascending:
                                groupingOptions.groupSort.sortOrder === 'asc',
                        });
                        /*
                    TODO: refactoring required can be done in a function
                     */
                    } else if (group.period === 'quarter') {
                        sort_fields.push({
                            field: group.field + '__year',
                            ascending:
                                groupingOptions.groupSort.sortOrder === 'asc',
                        });
                        sort_fields.push({
                            field: group.field + '__quarter',
                            ascending:
                                groupingOptions.groupSort.sortOrder === 'asc',
                        });
                    } else if (group.period === 'month') {
                        sort_fields.push({
                            field: group.field + '__year',
                            ascending:
                                groupingOptions.groupSort.sortOrder === 'asc',
                        });
                        sort_fields.push({
                            field: group.field + '__month',
                            ascending:
                                groupingOptions.groupSort.sortOrder === 'asc',
                        });
                    } else if (group.period === 'date') {
                        sort_fields.push({
                            field: group.field + '__date',
                            ascending:
                                groupingOptions.groupSort.sortOrder === 'asc',
                        });
                    }

                    break;
                case 'metric':
                    sort_fields.push({
                        field: formattedFieldName,
                        ascending:
                            groupingOptions.groupSort.sortOrder === 'asc',
                    });
                    break;
            }
        });
    } else if (groupingOptions.groupSort.sortBy === 'value') {
        sort_fields.push({
            field: 'metric_value',
            ascending: groupingOptions.groupSort.sortOrder === 'asc',
        });
    }

    let number_of_entries: {
        count: number;
        ascending: boolean;
    } | null = null;

    if (
        groupingOptions.groupFilter.filterNumber &&
        (groupingOptions.groupFilter.filterBy === 'top' ||
            groupingOptions.groupFilter.filterBy === 'bottom')
    ) {
        number_of_entries = {
            count: groupingOptions.groupFilter.filterNumber,
            // Temp fix since we're not using first/last n in HA
            ascending: groupingOptions.groupFilter.filterBy === 'top',
        };
    } else if (groupingOptions.groupFilter.filterBy === 'custom') {
        //TODO: Pending.
    }

    return {
        sort_fields,
        number_of_entries,
    };
};

const getGroupingFilters = ({
    groupingOptions,
    filterOptions,
}: {
    groupingOptions: {
        groupBy: ChartGrouping[];
        groupSort: ChartGroupSort;
        groupFilter: ChartGroupFilter;
    };
    filterOptions: ChartFilterController;
}): IHARequestPayloadFilter | null => {
    /**
     * One special case is addressed here. In case we are filtering by tags and also
     * grouping by tags, then we need to filter groups by tags too.
     *
     * Some row might have 'fruits' and 'vegetables' are the item tags.
     * In this case, if we filter to only 'fruits', then the row is included
     * but then both groups 'fruits' and 'vegetables' are shown to the user.
     * Thats confusing.
     *
     * So we should automatically filter the groups to only 'fruits' in this case.
     *
     */
    let searchDropdownId:
        | 'enterprise_item__tags'
        | 'enterprise_vendor_master__tags'
        | null = null;
    for (let group of groupingOptions.groupBy) {
        if (
            group.type === 'str' &&
            (group.field === 'enterprise_item__tags' ||
                group.field === 'enterprise_vendor_master__tags')
        ) {
            searchDropdownId = group.field;
            break;
        }
    }
    if (!searchDropdownId) {
        return null;
    }

    for (let category of filterOptions.customizableFilters.categories) {
        for (let filter of category.filters) {
            if (
                filter.filterApplied &&
                filter.filterType === 'multiselectDropdown' &&
                filter.modifierType === 'SEPARATE' &&
                filter.values.length
            ) {
                if (filter.dropdownId === searchDropdownId) {
                    return getTagsGroupingFilter({
                        field: filter.dropdownId,
                        tags: filter.values,
                        type: filter.modifierValue,
                    });
                }
            }
        }
    }
    return null;
};

const getTagsGroupingFilter = ({
    field,
    tags,
    type,
}: {
    field: string;
    tags: string[];
    type: 'CONTAINS' | 'NOT_CONTAINS';
}): IHARequestPayloadFilter => {
    if (type === 'CONTAINS') {
        return {
            single: {
                field,
                condition_type: 'IN',
                condition: {
                    list: tags,
                },
            },
        };
    } else {
        return {
            single: {
                field,
                condition_type: 'NOT_IN',
                condition: {
                    list: tags,
                },
            },
        };
    }
};

/**
 * Download payload
 */
export const getHADownloadPayload = ({
    graphSettings,
}: {
    graphSettings: IHistoricalAnalyticsChart;
}): IHADownloadPayloadType | null => {
    const { downloadSettings, filterContoller, currencySettings } =
        graphSettings;
    if (!downloadSettings) {
        return null;
    }
    const columns = downloadSettings.columns;
    const group_by = downloadSettings.object;
    const filterOptionsWithCurrency = cloneDeep(filterContoller);
    if (currencySettings.filterCurrenciesIds.length > 0) {
        filterOptionsWithCurrency.fixedFilters.push({
            filterId: 'currency',
            filterType: 'multiselectDropdown',
            filterFields: {
                metricFieldType: 'universal',
                fieldName: 'currency_code__entry_id',
            },
            filterLabel: 'Currency',
            modifierType: 'CONSOLIDATED',
            modifierValue: 'IN',
            values: currencySettings.filterCurrenciesIds,
            filterApplied: true,
        });
    }

    /**
     * Filters are processed in exactly the same way as for the chart.
     * However, metric specific filters are excluded.
     */
    const filters = getFiltersPayload({
        //Passing '' so that no metricSpecific filters are applied.
        metricId: '',
        filterOptions: filterOptionsWithCurrency,
    });

    const payload: IHADownloadPayloadType = {
        columns,
        filters,
        group_by,
    };

    if (currencySettings.targetCurrency) {
        payload['currency_code_id'] =
            currencySettings.targetCurrency.currencyUuid;
    }

    return payload;
};
