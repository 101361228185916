import { FWCustomGridKeyValue } from '../../../../Common/FWCustomGridKeyValue';
import FWPopover from '../../../../Common/FWPopover';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IItemSummary } from '../../Interfaces/ItemDirectoryInterface';

interface IItemDirectoryDetailsPopoverProps {
    data: IItemSummary;
    open: boolean;
    anchor: HTMLElement | null;
    handleClose: () => void;
}

const ItemDirectoryDetailsPopover = ({
    data,
    open,
    anchor,
    handleClose,
}: IItemDirectoryDetailsPopoverProps) => {
    return (
        <FWPopover
            sx={{
                // pointerEvents: 'none',
                maxWidth: '800px',
            }}
            open={open}
            anchorEl={anchor}
            onClose={handleClose}
        >
            <div className="pl--10 pr--10 pt--10">
                <div className="mb--15">
                    <FWTypography variant="h5" sx={{ fontWeight: 500 }}>
                        {data.name}
                    </FWTypography>
                </div>
                <div className="mb--15">
                    <FWCustomGridKeyValue title="Item ID">
                        {data.itemCode}
                    </FWCustomGridKeyValue>
                </div>
                <div className="mb--15">
                    <FWCustomGridKeyValue title="Description">
                        <FWTypography>{data.description}</FWTypography>
                    </FWCustomGridKeyValue>
                </div>
                {data.specifications.length > 0 && (
                    <>
                        <FWTypography>Specifications</FWTypography>
                        <div className="flex flex--wrap pl--10">
                            {data.specifications.map((specification, index) => {
                                return (
                                    <div
                                        className="mb--15 mr--20"
                                        key={
                                            specification.name +
                                            specification.value +
                                            index
                                        }
                                    >
                                        <FWCustomGridKeyValue
                                            title={specification.name}
                                        >
                                            {specification.value.join(' / ')}
                                        </FWCustomGridKeyValue>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </FWPopover>
    );
};

export default ItemDirectoryDetailsPopover;
