import { useCallback, useEffect, useState } from 'react';
import { IBuyerItemList } from '../../Events/Interfaces/Buyer/RFQ.model';
import { getBuyerMasterItemsForEntity } from '../../Events/Services/Buyer/shared.service';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import {
    addItemCatalogueApi,
    fetchItemCatalogueListApi,
} from '../../VendorLinkedIn/Services/VendorLinkedIn.service';
import { IVLNItemCatalogue } from '../../VendorLinkedIn/Interfaces/VendorLNInterfaces';

export const useSellerProfileItemCatalogue = (
    entityId: string,
    vendorProfileId: string
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);
    const [buyerItems, setBuyerItems] = useState<IBuyerItemList[]>([]);
    const [filteredBuyerItems, setFilteredBuyerItems] = useState<
        IBuyerItemList[]
    >([]);
    const [itemCatalogueList, setItemCatalogueList] = useState<
        IVLNItemCatalogue[]
    >([]);
    const [selectedItem, setSelectedItem] = useState<IBuyerItemList | null>(
        null
    );

    useEffect(() => {
        if (entityId && vendorProfileId) {
            updateHookState(HookStateValue.LOADING);
            const itemsMasterPromise = getBuyerMasterItemsForEntity(entityId);
            const itemsCataloguePromise =
                fetchItemCatalogueListApi(vendorProfileId);
            Promise.all([itemsMasterPromise, itemsCataloguePromise])
                .then((data) => {
                    setBuyerItems(data[0]);
                    setItemCatalogueList(data[1]);
                    updateHookState(HookStateValue.READY);
                })
                .catch(() => {
                    updateHookState(HookStateValue.ERROR);
                    toast.error('Failed to fetch items/item catalogue');
                });
        }
    }, [entityId, updateHookState, vendorProfileId]);

    const addItemToCatalogue = useCallback(
        async (
            item: IBuyerItemList,
            data: {
                name: string;
                price: string;
                imageUrl: string;
                measurementUnitUuid: string;
                currencyCodeUuid: string;
            }
        ) => {
            try {
                const resp = await addItemCatalogueApi(
                    vendorProfileId,
                    item,
                    data
                );
                setItemCatalogueList((prev) => [...prev, resp]);
                setSelectedItem(null);
            } catch (error) {
                toast.error('Failed to add item to catalogue');
            }
        },
        [vendorProfileId]
    );

    const onItemSelect = (item: IBuyerItemList | null) => {
        setSelectedItem(item);
    };

    const onAddItemToCatalogue = async (data: {
        name: string;
        price: string;
        imageUrl: string;
        measurementUnitUuid: string;
        currencyCodeUuid: string;
    }) => {
        if (!selectedItem) return;
        addItemToCatalogue(selectedItem, data);
    };

    const removeItemFromList = (item_catalogue_id: string) => {
        setItemCatalogueList((prev) =>
            prev.filter((item) => item.item_catalogue_id !== item_catalogue_id)
        );
    };

    const updateItemCatalogueList = (item: IVLNItemCatalogue) => {
        setItemCatalogueList((prev) =>
            prev.map((prevItem) =>
                prevItem.item_catalogue_id === item.item_catalogue_id
                    ? item
                    : prevItem
            )
        );
    };

    useEffect(() => {
        if (buyerItems.length > 0 && itemCatalogueList.length > 0) {
            const filteredItems = buyerItems.filter(
                (item) =>
                    !itemCatalogueList.find(
                        (catalogueItem) =>
                            catalogueItem.enterprise_item ===
                            item.enterprise_item.enterprise_item_id
                    )
            );
            setFilteredBuyerItems(filteredItems);
        } else {
            setFilteredBuyerItems(buyerItems);
        }
    }, [buyerItems, itemCatalogueList]);

    return {
        buyerItems,
        filteredBuyerItems,
        hookState,
        itemCatalogueList,
        onItemSelect,
        selectedItem,
        onAddItemToCatalogue,
        removeItemFromList,
        updateItemCatalogueList,
    };
};
