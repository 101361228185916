import { IBuyerItemList } from '../../Events/Interfaces/Buyer/RFQ.model';
import { IEntityDetails } from '../../Global/Interfaces/EntityInterface';
import { IAddressCreation } from '../../Organizations/Admin/Services/AddressService';
import { del, delWpl, get, post, put } from '../../Utils/api';
import {
    ITranscations,
    IVLNAbout,
    IVLNEmployees,
    IVLNItemCatalogue,
    IVLNNote,
} from '../Interfaces/VendorLNInterfaces';

export const fetchSellerProfileDataApi = (
    vendor_profile_id: string
): Promise<IVLNAbout> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await get(
                `/organization/vendor_profile/${vendor_profile_id}/`
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const createSellerProfile = async (
    entityId: string,
    payload: IVLNAbout
): Promise<IEntityDetails> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await post(
                `/organization/vendor_profile/create/`,
                {
                    ...payload,
                    entity_id: entityId,
                }
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const updateSellerProfile = async (
    vendor_profile_id: string,
    entityId: string,
    payload: IVLNAbout
) => {
    try {
        const resp: any = await put(
            `/organization/vendor_profile/${vendor_profile_id}/update/`,
            { ...payload, entity_id: entityId }
        );
        //
        return resp.data;
    } catch (error) {}
};

export const createFactoryLocationApi = async (
    vendor_profile_id: string,
    addAddressObj: IAddressCreation
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        const payload = {
            address_nickname: addAddressObj.addressName,
            address1: addAddressObj.address1,
            address2: addAddressObj.address2,
            address3: addAddressObj.address3,
            description: addAddressObj?.address_url ?? '',
            city: addAddressObj.city,
            state_or_territory: addAddressObj.stateOrTerritory,
            postal_code: addAddressObj.postalCode,
            country: addAddressObj.country,
            notes: '',
            is_primary: false,
            latitude: '0',
            longitude: '0',
            images: [
                // {
                //     attachment_id: '',
                //     file_name: '',
                // },
            ],
        };
        try {
            const resp: any = await post(
                `/organization/vendor_profile/${vendor_profile_id}/address/create/`,
                payload
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchFactoryLocationsApi = async (
    vendor_profile_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await get(
                `/organization/vendor_profile/${vendor_profile_id}/address/`
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchEntityAddress = async (entityId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await get(
                `/organization/address/entity/${entityId}`
            );
            //

            let res = resp.data;
            // .filter(
            //     (address: IVLNAboutNewFactoryLocations) =>
            //         address.status === 'ACTIVE' && address.is_public
            // );

            resolve(res);
        } catch (error) {
            reject(error);
        }
    });
};

export const deleteFactoryLocationApi = async (
    vendor_profile_id: string,
    address_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await del(
                `/organization/vendor_profile/${vendor_profile_id}/address/${address_id}/delete/`
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchItemCatalogueListApi = async (
    vendor_profile_id: string
): Promise<IVLNItemCatalogue[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await get(
                `/organization/vendor_profile/${vendor_profile_id}/item_catalogue/`
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const addItemCatalogueApi = async (
    vendor_profile_id: string,
    payload: IBuyerItemList,
    extraInfo: {
        name: string;
        price: string;
        imageUrl: string;
        measurementUnitUuid: string;
        currencyCodeUuid: string;
    }
): Promise<IVLNItemCatalogue> => {
    //
    const payloadData = {
        ...payload.enterprise_item,
        is_visible: true,
        measurement_units:
            payload.enterprise_item.measurement_units.item_measurement_units.map(
                (val) => val.measurement_unit_id
            ),
        pricing_information: [
            ...(+extraInfo.price > 0
                ? [
                      {
                          price: extraInfo.price,
                          is_visible: true,
                          currency_name: '',
                          currency_symbol: '',
                          currency_code_id: extraInfo.currencyCodeUuid,
                          measurement_unit_id: extraInfo.measurementUnitUuid,
                          measurement_unit_category: '',
                          currency_code_abbreviation: '',
                          measurement_unit_value_type: '',
                          measurement_unit_primary_name: '',
                      },
                  ]
                : []),
        ],
        notes: payload.enterprise_item.notes || '',
        tags: payload.enterprise_item.tags || [],
        name: extraInfo.name,
        price: extraInfo.price,
        icon_url: extraInfo.imageUrl,
    };
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await post(
                `/organization/vendor_profile/${vendor_profile_id}/item_catalogue/create/`,
                payloadData
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const updateItemCatalogueApi = async (
    vendor_profile_id: string,
    item_id: string,
    payload: IVLNItemCatalogue,
    extraInfo: {
        name: string;
        price: string;
        imageUrl: string;
        measurementUnitUuid: string;
        currencyCodeUuid: string;
    }
): Promise<IVLNItemCatalogue> => {
    //
    const payloadData = {
        ...payload,
        ...extraInfo,
        icon_url: extraInfo.imageUrl,
        measurement_units: [],
        pricing_information: [
            ...(+extraInfo.price > 0
                ? [
                      {
                          price: extraInfo.price,
                          is_visible: true,
                          currency_name: '',
                          currency_symbol: '',
                          currency_code_id: extraInfo.currencyCodeUuid,
                          measurement_unit_id: extraInfo.measurementUnitUuid,
                          measurement_unit_category: '',
                          currency_code_abbreviation: '',
                          measurement_unit_value_type: '',
                          measurement_unit_primary_name: '',
                      },
                  ]
                : []),
        ],
    };
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await put(
                `/organization/vendor_profile/${vendor_profile_id}/item_catalogue/${item_id}/update/`,
                payloadData
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const deleteItemCatalogueApi = async (
    vendor_profile_id: string,
    item_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await del(
                `/organization/vendor_profile/${vendor_profile_id}/item_catalogue/${item_id}/delete/`
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchTranscationsApi = async (
    vendor_profile_id: string
): Promise<ITranscations[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await get(
                `/organization/vendor_profile/${vendor_profile_id}/events/`
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchVLNNotesApi = async (
    vendor_profile_id: string
): Promise<IVLNNote[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await get(
                `/organization/vendor_profile/${vendor_profile_id}/notes/`
            );
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

interface IAddVLNNotePayload {
    buyer_entity_id: string;
    title: string;
    notes: string;
}

export const addVLNNoteApi = async (
    vendor_profile_id: string,
    payload: IAddVLNNotePayload
): Promise<IVLNNote> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await post(
                `/organization/vendor_profile/${vendor_profile_id}/notes/add/`,
                payload
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const updateVLNNoteApi = async (
    vendor_profile_id: string,
    note_id: string,
    payload: IAddVLNNotePayload
): Promise<IVLNNote> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await put(
                `/organization/vendor_profile/${vendor_profile_id}/notes/${note_id}/update/`,
                payload
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const deleteVLNNoteApi = async (
    vendor_profile_id: string,
    note_id: string,
    buyer_entity_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await delWpl(
                `/organization/vendor_profile/${vendor_profile_id}/notes/${note_id}/delete/`,
                {
                    buyer_entity_id,
                }
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const addEmployeeApi = async (
    vendor_profile_id: string,
    employee_user_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await post(
                `/organization/vendor_profile/${vendor_profile_id}/employees/add/`,
                { employee_user_id }
            );
            //
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchEmployeeListApi = async (
    vendor_profile_id: string
): Promise<IVLNEmployees[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await get(
                `/organization/vendor_profile/${vendor_profile_id}/employees/`
            );
            //
            resp.data = resp.data.map((val: any) => ({
                ...val.user,
                isPrimary: val.is_primary,
            }));
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const setPrimaryEmployeeApi = async (
    vendor_profile_id: string,
    employee_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await put(
                `/organization/vendor_profile/${vendor_profile_id}/employees/${employee_id}/set-primary/`,
                {}
            );
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const removeEmployeeApi = async (
    vendor_profile_id: string,
    employee_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await delWpl(
                `/organization/vendor_profile/${vendor_profile_id}/employees/${employee_id}/remove/`,
                {}
            );
            resolve(resp.data);
        } catch (error) {
            reject(error);
        }
    });
};
