import { templateType } from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { IForumlaWithIfElseAndOperators } from '../Interfaces/AdditionalCostInterfaces';

export enum CostTypeEnum {
    ABSOLUTE_VALUE = 'ABSOLUTE_VALUE',
    PERCENTAGE = 'PERCENTAGE',
}

export enum CostModuleEnum {
    EVENT = 'EVENT',
    PURCHASE_ORDER = 'PURCHASE_ORDER',
    QUOTE_CALCULATOR = 'QUOTE_CALCULATOR',
    RFQ_ANALYTICS = 'RFQ_ANALYTICS',
    CART = 'CART',
    CLM = 'CLM',
    BOM = 'BOM',
    REQUISITION = 'REQUISITION',
}

export const convertCostModuleEnumToTemplateType = (
    module: CostModuleEnum
): templateType => {
    switch (module) {
        case CostModuleEnum.EVENT:
            return 'RFQ';
        case CostModuleEnum.PURCHASE_ORDER:
            return 'PO GROUP';
        case CostModuleEnum.QUOTE_CALCULATOR:
            return 'QUOTE CALCULATOR';
        case CostModuleEnum.RFQ_ANALYTICS:
            return 'RFQ';
        case CostModuleEnum.CLM:
            return 'CLM';
        case CostModuleEnum.REQUISITION:
            return 'REQUISITION';
        default:
            return 'RFQ';
    }
};

export enum CostSourceEnum {
    NONE = 'DEFAULT',
    ITEM_MASTER = 'ITEM',
    BUYER = 'BUYER',
    VENDOR_MASTER = 'VENDOR',
    FORMULA = 'FORMULA',
    RFQ_ANALYTICS = 'RFQ_ANALYTICS',
}

export const CostTypeEnumToLabelMap = {
    [CostTypeEnum.ABSOLUTE_VALUE]: 'Flat rate',
    [CostTypeEnum.PERCENTAGE]: 'Percentage',
};

export const CostSourceEnumToLabelMap = {
    [CostSourceEnum.ITEM_MASTER]: 'Item directory',
    [CostSourceEnum.VENDOR_MASTER]: 'Vendor directory',
    [CostSourceEnum.BUYER]: 'Customer directory',
    [CostSourceEnum.FORMULA]: 'Formula',
    [CostSourceEnum.NONE]: 'Default Value',
    [CostSourceEnum.RFQ_ANALYTICS]: 'Rfq Analytics',
    BID: 'Seller bid',
};

export const CostSourcePopupEnumtoLabel = {
    [CostSourceEnum.ITEM_MASTER]: 'Item directory',
    [CostSourceEnum.VENDOR_MASTER]: 'Vendor directory',
    [CostSourceEnum.BUYER]: 'Customer directory',
    [CostSourceEnum.FORMULA]: 'Formula based',
    [CostSourceEnum.NONE]: 'None',
    [CostSourceEnum.RFQ_ANALYTICS]: 'Rfq Analytics',
};

export enum AllocationTypeEnum {
    OVERALL_QUANTITY = 'OVERALL_QUANTITY',
    PER_UNIT = 'PER_UNIT',
}

export const AllocationTypeEnumToLabelMap = {
    [AllocationTypeEnum.OVERALL_QUANTITY]: 'Overall quantity',
    [AllocationTypeEnum.PER_UNIT]: 'Per unit',
};

export interface IAdditionalCostTypePercentage {
    costName: string;
    costType: CostTypeEnum.PERCENTAGE;
    additional_cost_id: string | null;
    allocationType: null;
}

export interface IAdditionalCostTypeAbsoluteValue {
    costName: string;
    costType: CostTypeEnum.ABSOLUTE_VALUE;
    allocationType: AllocationTypeEnum;
    additional_cost_id: string | null;
}

// export type IAdditionalCost =
//     | IAdditionalCostTypePercentage
//     | IAdditionalCostTypeAbsoluteValue;

export interface IAdditionalCost {
    costName: string;
    costType: CostTypeEnum;
    cost_source: CostSourceEnum | null;
    cost_currency_id?: string | null;
    allocationType: AllocationTypeEnum | null;
    additional_cost_id: string | null;
    autofill?: boolean;
    source_currency_id?: string | null;
    source_currency_currency_code_abbreviation?: string | null;
    source_currency_currency_code_symbol?: string | null;
    conversion_rate?: number | null;
    currency_id?: string | null;
    source_value?: number | null;
    is_calculated?: boolean;
    threshold?: string | null;
    threshold_applied_on?: 'TOTAL' | 'SUBTOTAL';
    formula?: IForumlaWithIfElseAndOperators | null;
}

export interface IPreSelectedAdditionalCostFromApi {
    additional_cost: {
        additional_cost_id: string | null;
        cost_name: string;
        cost_type: CostTypeEnum;
        allocation_type: AllocationTypeEnum;
        cost_value: number;
    };
    cost_value: number;
}

export interface IAdditionalCostInformation {
    additional_cost_id: string;
    created_by_user_name: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    cost_name: string;
    cost_type: CostTypeEnum;
    allocation_type: AllocationTypeEnum | null;
    cost_value: number;
    type: string;
    cost_source: CostSourceEnum;
    formula: {
        formula_id: string;
        formula_name: string;
        formula: IForumlaWithIfElseAndOperators;
    } | null;
    is_calculated: boolean;
    pincode_to: string | null;
    pincode_from: string | null;
    modules: CostModuleEnum[];
    status: 'ACTIVE' | 'INACTIVE';
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    enterprise: string;
    currency: string | null;
    country_to: any[];
    state_to: any[];
    city_to: any[];
    country_from: any[];
    state_from: any[];
    city_from: any[];
    buyer_items: any[];
    seller_items: any[];
    seller_entity: any[];
    buyer_entity: any[];
}

export type IAdditionalCostsWithValue = IAdditionalCost & {
    value: number | string;
};

export type IAdditionalCostWithCurrencyAndValue = IAdditionalCostsWithValue & {
    currency_id: string | null;
};

export type IAdditionalCostWithIsRequired = {
    is_required: boolean;
} & IAdditionalCost;

export type IDiscountWithValue = IAdditionalCostsWithValue;
export type IDiscount = IAdditionalCost;

export interface IAdditionalCostsBackend {
    cost_name: string;
    cost_type: CostTypeEnum;
    allocation_type: AllocationTypeEnum | null;
    cost_source: CostSourceEnum | null;
    cost_value: number | string;
    additional_cost_id: string | null; // mandatory for additional cost
    threshold?: string | null;
    threshold_applied_on?: 'TOTAL' | 'SUBTOTAL';
}
export interface INewAdditionalCostBackend {
    cost_name: string;
    cost_type: CostTypeEnum;
    allocation_type: AllocationTypeEnum | null;
    cost_value: number | string;
    type?: 'TAX' | 'ADDITIONAL_COST';
    modules?: string[] | null;
    country_id: [];
    state_id: [];
    city_id: [];
    pincode: [];
    status: 'ACTIVE';
    buyer_entity: string[] | null;
    seller_entity: string[] | null;
    threshold?: string | null;
    threshold_applied_on?: 'TOTAL' | 'SUBTOTAL';
}

export interface IAdditionalCostsBackendTemplate {
    template_section_item_id: string;
    cost_name: string;
    cost_type: CostTypeEnum;
    cost_source: CostSourceEnum | null;
    allocation_type: AllocationTypeEnum | null;
    is_required: boolean;
    is_visible: boolean | undefined;
    is_negotiable: boolean | undefined;
    is_mandatory: boolean;
    is_factwise_default: boolean;
    is_hidden: boolean | undefined;
    additional_cost_id: string | null; // mandatory for additional cost
    description?: string | null;
}

export enum OperatorType {
    ADD = 'ADD',
    SUBTRACT = 'SUBTRACT',
    MULTIPLY = 'MULTIPLY',
    DIVIDE = 'DIVIDE',
    POWER = 'POWER',
    OPENING_PARANTHESIS = 'OPENING_PARANTHESIS',
    CLOSING_PARANTHESIS = 'CLOSING_PARANTHESIS',
}

export enum OperandType {
    NUMBER = 'NUMBER',
    FIELD = 'FIELD',
    FORMULA = 'FORMULA',
}
