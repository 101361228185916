import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BlankCreateProject } from '../helpers/projectHelpers';
import {
    ICreateProjectPayload,
    createProjectDetailsActionType,
} from '../interface/project.model';
import { createProjectApi } from '../services/project.services';

export const useProjectCreationHook = () => {
    const [createProjectPayload, setCreateProjectPayload] =
        useState<ICreateProjectPayload>(BlankCreateProject);

    const history = useHistory();

    const updateCreateProjectPayload = useCallback(
        (field: createProjectDetailsActionType, value: string) => {
            // let newProjectCreationDetails = cloneDeep(createProjectPayload);
            setCreateProjectPayload((prev) => {
                return {
                    ...prev,
                    [field]: value,
                };
            });
            // console.log(
            //     'sklfvklsjdfvnfj',
            //     field,
            //     value,
            //     cloneDeep(newProjectCreationDetails)
            // );
            // newProjectCreationDetails = {
            //     ...newProjectCreationDetails,
            //     [field]: value,
            // };
            //

            // setCreateProjectPayload(newProjectCreationDetails);
        },
        []
    );

    const createProject = useCallback(() => {
        let detailsToSave = cloneDeep(createProjectPayload);

        let projectName = null;
        //`Project - ${getFWDateTime(
        //     moment().format('YYYY-MM-DD HH:mm:ss')
        // )}`;

        detailsToSave = {
            ...detailsToSave,
            project_name: projectName,
        };

        createProjectApi(detailsToSave)
            .then((res) => {
                history.replace(
                    `/custom/cost-tracking/projects/${res.project_id}/draft`
                );
            })
            .catch((err) => {
                toast.error('Failed to create project!');
            });
    }, [createProjectPayload, history]);

    return {
        createProject,
        updateCreateProjectPayload,
    };
};

export default useProjectCreationHook;
