import { cloneDeep } from 'lodash';
import {
    AllocationTypeEnum,
    CostSourceEnum,
    CostTypeEnum,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import { IMeasurementUnitDetails } from '../../../Models/MeasurementUnit.model';
import { cleanUpFieldName } from '../../../ProjectGlCostCenter/helpers/projectDashboardHelper';
import { get } from '../../../Utils/api';
import {
    IItemAdditionalCostBackend,
    IPreSelectedAdditionalCostFromApi,
} from '../Hooks/ItemDirectory/ItemAdditionalCostHook';
import { IItemCustomFieldsBackend } from '../Interfaces/ItemDirectoryInterface';
import {
    IConstraints,
    IITemTemplateFieldResponse,
} from '../Services/ItemDirectoryService';

export interface IItemAdditionalCostField extends IItemCustomField {
    additional_cost_information: {
        cost_type: CostTypeEnum | undefined;
        allocation_type: AllocationTypeEnum | undefined;
        additional_cost_id: string;
        cost_source: CostSourceEnum | null;
    };
}

// below interface is for template settings for custom fields
export interface IItemField {
    label: string;
    type: string;
    is_required: boolean;
    constraints: IConstraints;
    additional_information: {
        additional_cost_id: string;
        allocation_type: AllocationTypeEnum | null;
        cost_type: CostTypeEnum;
    };
}

// below interface is for custom fields in payload
export interface IItemTemplateCustomField {
    type:
        | 'DATE'
        | 'BOOLEAN'
        | 'DATETIME'
        | 'INTEGER'
        | 'FLOAT'
        | 'SHORTTEXT'
        | 'LONGTEXT'
        | 'PERCENTAGE'
        | 'LONGTEXT'
        | 'MULTI_SELECT'
        | 'MULTI_CHOICE'
        | 'CHOICE';
    is_required: boolean;
    is_locked: boolean;
    description: string;
    name: string;
    value: string | boolean | null | string[] | number;
}

export interface IItemCustomField {
    constraints: {
        field_type:
            | 'DATE'
            | 'BOOLEAN'
            | 'DATETIME'
            | 'INTEGER'
            | 'FLOAT'
            | 'SHORTTEXT'
            | 'PERCENTAGE'
            | 'LONGTEXT'
            | 'PERCENTAGE'
            | 'CHOICE';
        max_limit?: number;
        min_limit?: number;
        choices?: string[];
        decimal_places?: number;
    };
    is_locked: boolean;
    description: string;
    is_required: boolean;
    is_visible: boolean;
    is_hidden: boolean;
    name: string;
    column: string;
    value: string | boolean | null | [] | number;
    section_item_id: string;
}

export enum ItemCustomFieldSectionFieldType {
    SHORTTEXT = 'SHORTTEXT',
    LONGTEXT = 'LONGTEXT',
    FLOAT = 'FLOAT',
    DATE = 'DATE',
    DROPDOWN = 'DROPDOWN',
    MULTI_SELECT = 'MULTI_SELECT',
    BOOLEAN = 'BOOLEAN',
    PERCENTAGE = 'PERCENTAGE',
}

export interface IPreSelectedApiResponse {
    enterprise_item_id: string;
    buyer_additional_costs: IPreSelectedAdditionalCostFromApi[];
    buyer_taxes: any;
    seller_additional_costs: IPreSelectedAdditionalCostFromApi[];
    seller_taxes: any;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    code: string;
    name: string;
    item_type: string;
    description: string | null;
    notes: string | null;
    internal_notes: string | null;
    measurement_units: {
        item_measurement_units: IMeasurementUnitDetails[];
    };
    attributes: {
        attributes: Array<any>;
    };
    custom_ids: {
        custom_ids: Array<any>;
    };
    is_buyer: boolean;
    buyer_pricing_information: {
        price: number;
        taxes: any;
        currency_name: string;
        currency_symbol: string;
        additional_costs: any;
        currency_code_id: string;
        currency_code_abbreviation: string;
    };
    is_seller: boolean;
    seller_pricing_information: {
        price: number;
        taxes: any;
        currency_name: string;
        currency_symbol: string;
        additional_costs: any;
        currency_code_id: string;
        currency_code_abbreviation: string;
    };
    status: string;
    preferred_vendor_status: string;
    tags: Array<string>;

    custom_fields: IItemCustomFieldsBackend;

    additional_details: any;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    buyer_enterprise: string;
}

export const convertTemplateDetailsToItemAdditionalCosts = (
    itemTemplateDetails: IITemTemplateFieldResponse | undefined
) => {
    let additionalCostIds: string[] = [];
    let isHidden: boolean;
    itemTemplateDetails?.section_list.forEach((sectionItemList) => {
        let additionalCostInfo = sectionItemList.section_items.filter(
            (sectionItem) => sectionItem.name === 'Additional costs'
        );

        isHidden = additionalCostInfo[0].additional_information.is_hidden;

        additionalCostIds.push(additionalCostInfo[0].section_item_id);
    });

    let section_list: {
        name: string;
        fields: IItemAdditionalCostField[];
        isHidden: boolean;
    }[] =
        itemTemplateDetails?.section_list.map((sectionItemsList) => {
            let additionalCosts: IItemAdditionalCostField[] = [];

            sectionItemsList.section_items.forEach((sectionItem) => {
                if (
                    sectionItem.parent_section_item &&
                    additionalCostIds.includes(
                        sectionItem.parent_section_item
                    ) &&
                    !sectionItem.additional_information.is_hidden
                ) {
                    switch (sectionItem.constraints.field_type) {
                        case ItemCustomFieldSectionFieldType.FLOAT:
                            additionalCosts.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.FLOAT,
                                    max_limit: 10000,
                                    min_limit: 0,
                                },
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                column: sectionItem.name,
                                description: sectionItem.description,
                                section_item_id: sectionItem.section_item_id,
                                value: 0,
                                additional_cost_information: {
                                    cost_type:
                                        sectionItem.additional_information
                                            .additional_cost_information
                                            ?.cost_type,
                                    allocation_type:
                                        sectionItem.additional_information
                                            .additional_cost_information
                                            ?.allocation_type,
                                    additional_cost_id:
                                        sectionItem.additional_information
                                            .additional_cost_information
                                            ?.additional_cost_id ?? '',
                                    cost_source:
                                        sectionItem.additional_information
                                            .additional_cost_information
                                            ?.cost_source ?? null,
                                },
                            });
                            break;

                        case ItemCustomFieldSectionFieldType.PERCENTAGE:
                            additionalCosts.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.PERCENTAGE,

                                    max_limit:
                                        sectionItem.constraints.max_limit ??
                                        100,
                                    min_limit:
                                        sectionItem.constraints.min_limit ?? 0,
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: '',
                                additional_cost_information: {
                                    cost_type:
                                        sectionItem.additional_information
                                            .additional_cost_information
                                            ?.cost_type,
                                    allocation_type:
                                        sectionItem.additional_information
                                            .additional_cost_information
                                            ?.allocation_type,
                                    additional_cost_id:
                                        sectionItem.additional_information
                                            .additional_cost_information
                                            ?.additional_cost_id ?? '',
                                    cost_source:
                                        sectionItem.additional_information
                                            .additional_cost_information
                                            ?.cost_source ?? null,
                                },
                            });
                            break;
                    }
                }
            });

            return {
                name: 'item_additional_costs',
                fields: additionalCosts,
                isHidden: isHidden,
            };
        }) ?? [];

    return section_list;
};

export const convertTemplateDetailsToItemCustomFieldsForItem2 = (
    itemTemplateDetails: IITemTemplateFieldResponse
) => {
    let customFields: IItemCustomFieldsBackend = {
        section_list: [],
    };

    let customFieldsSettings: {
        [field: string]: IItemField;
    } = {};

    itemTemplateDetails.section_list.forEach((section) => {
        let customFieldForSection: {
            name: string;
            fields: IItemTemplateCustomField[];
        } = {
            name: section?.alternate_name,
            fields: [],
        };

        section.section_items.forEach((sectionItem) => {
            if (
                !sectionItem.is_builtin_field &&
                sectionItem.parent_section_item === null
            ) {
                customFieldsSettings[sectionItem.name] = {
                    label: sectionItem.name,
                    constraints: sectionItem.constraints,
                    additional_information: {
                        additional_cost_id:
                            sectionItem.additional_information
                                .additional_cost_information
                                ?.additional_cost_id ?? '',
                        allocation_type:
                            sectionItem.additional_information
                                .additional_cost_information?.allocation_type ??
                            null,
                        cost_type:
                            sectionItem.additional_information
                                .additional_cost_information?.cost_type ??
                            CostTypeEnum.PERCENTAGE,
                    },
                    is_required: sectionItem.is_required,
                    type: sectionItem.constraints.field_type,
                };

                switch (sectionItem.constraints.field_type) {
                    case ItemCustomFieldSectionFieldType.FLOAT:
                        customFieldForSection.fields.push({
                            type: ItemCustomFieldSectionFieldType.FLOAT,
                            description: sectionItem.description,
                            is_locked: true,
                            is_required: sectionItem.is_required,

                            name: cleanUpFieldName(sectionItem.alternate_name),
                            value: '',
                        });
                        break;
                    case ItemCustomFieldSectionFieldType.BOOLEAN:
                        customFieldForSection.fields.push({
                            type: ItemCustomFieldSectionFieldType.BOOLEAN,
                            description: sectionItem.description,
                            is_locked: true,
                            is_required: sectionItem.is_required,
                            name: cleanUpFieldName(sectionItem.alternate_name),
                            value: null,
                        });
                        break;
                    case ItemCustomFieldSectionFieldType.DROPDOWN:
                    case 'CHOICE':
                        customFieldForSection.fields.push({
                            type:
                                sectionItem.constraints.choice_type ===
                                'DROPDOWN'
                                    ? 'CHOICE'
                                    : 'MULTI_CHOICE',
                            description: sectionItem.description,
                            is_locked: true,
                            is_required: sectionItem.is_required,

                            name: cleanUpFieldName(sectionItem.alternate_name),
                            value: null,
                        });
                        break;
                    case ItemCustomFieldSectionFieldType.MULTI_SELECT:
                        customFieldForSection.fields.push({
                            type: 'MULTI_SELECT',
                            description: sectionItem.description,
                            is_locked: true,
                            is_required: sectionItem.is_required,

                            name: cleanUpFieldName(sectionItem.alternate_name),
                            value: [],
                        });
                        break;
                    case ItemCustomFieldSectionFieldType.DATE:
                    case 'DATETIME':
                        customFieldForSection.fields.push({
                            type: sectionItem.constraints.field_type,
                            description: sectionItem.description,
                            is_locked: true,
                            is_required: sectionItem.is_required,

                            name: cleanUpFieldName(sectionItem.alternate_name),
                            value: null,
                        });
                        break;

                    case ItemCustomFieldSectionFieldType.SHORTTEXT:
                        customFieldForSection.fields.push({
                            type: 'SHORTTEXT',
                            description: sectionItem.description,
                            is_locked: true,
                            is_required: sectionItem.is_required,

                            name: cleanUpFieldName(sectionItem.alternate_name),

                            value: '',
                        });
                        break;
                    case ItemCustomFieldSectionFieldType.LONGTEXT:
                        customFieldForSection.fields.push({
                            type: 'LONGTEXT',
                            description: sectionItem.description,
                            is_locked: true,
                            is_required: sectionItem.is_required,

                            name: cleanUpFieldName(sectionItem.alternate_name),

                            value: '',
                        });
                        break;
                }
            }
        });

        customFields.section_list.push(customFieldForSection);
    });

    return { customFields, customFieldsSettings };
};

export const convertTemplateDetailsToItemCustomFieldsForItem = (
    itemTemplateDetails: IITemTemplateFieldResponse | undefined
): {
    name: string;
    fields: IItemCustomField[];
}[] => {
    let section_list: { name: string; fields: IItemCustomField[] }[] =
        itemTemplateDetails?.section_list.map((sectionItemsList) => {
            let fields: IItemCustomField[] = [];

            sectionItemsList.section_items.forEach((sectionItem) => {
                // selected additional costs
                if (
                    !sectionItem.additional_information.is_hidden &&
                    !sectionItem.is_builtin_field &&
                    sectionItem.parent_section_item === null
                ) {
                    switch (sectionItem.constraints.field_type) {
                        case ItemCustomFieldSectionFieldType.FLOAT:
                            fields.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.FLOAT,
                                    max_limit: 10000,
                                    min_limit: 0,
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: '',
                            });
                            break;
                        case ItemCustomFieldSectionFieldType.BOOLEAN:
                            fields.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.BOOLEAN,
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: sectionItem.name,
                                section_item_id: sectionItem.section_item_id,
                                value: false,
                            });
                            break;
                        case ItemCustomFieldSectionFieldType.DROPDOWN:
                            fields.push({
                                constraints: {
                                    field_type: 'CHOICE',
                                    choices:
                                        sectionItem.constraints.choices?.map(
                                            (val) => val
                                        ) ?? [],
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: null,
                            });
                            break;
                        case ItemCustomFieldSectionFieldType.MULTI_SELECT:
                            fields.push({
                                constraints: {
                                    field_type: 'CHOICE',
                                    choices:
                                        sectionItem.constraints.choices?.map(
                                            (val) => val
                                        ) ?? [],
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: [],
                            });
                            break;
                        case ItemCustomFieldSectionFieldType.DATE:
                            fields.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.DATE,
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: null,
                            });
                            break;

                        case ItemCustomFieldSectionFieldType.SHORTTEXT:
                            fields.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.SHORTTEXT,

                                    max_limit:
                                        sectionItem.constraints.max_limit ??
                                        10000,
                                    min_limit:
                                        sectionItem.constraints.min_limit ?? 0,
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: '',
                            });
                            break;
                        case ItemCustomFieldSectionFieldType.LONGTEXT:
                            fields.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.LONGTEXT,

                                    max_limit:
                                        sectionItem.constraints.max_limit ??
                                        10000,
                                    min_limit:
                                        sectionItem.constraints.min_limit ?? 0,
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: '',
                            });
                            break;
                    }
                }
            });

            return {
                name: 'item_custom_fields',
                fields,
            };
        }) ?? [];

    return section_list;
};

export const convertTemplateDetailsToItemFields = (
    itemTemplateDetails: IITemTemplateFieldResponse | undefined
) => {
    let section_list:
        | { name: string; fields: IItemCustomField[] }[]
        | undefined =
        itemTemplateDetails?.section_list.map((sectionItemsList) => {
            let fields: IItemCustomField[] = [];
            sectionItemsList.section_items.forEach((sectionItem) => {
                // selected additional costs
                if (sectionItem.is_builtin_field) {
                    switch (sectionItem.constraints.field_type) {
                        case ItemCustomFieldSectionFieldType.FLOAT:
                            fields.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.FLOAT,
                                    max_limit: 10000,
                                    min_limit: 0,
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: '',
                            });
                            break;
                        case ItemCustomFieldSectionFieldType.BOOLEAN:
                            fields.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.BOOLEAN,
                                },
                                column: sectionItem.name,
                                is_locked: true,
                                description: sectionItem.description,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: false,
                            });
                            break;
                        case ItemCustomFieldSectionFieldType.DROPDOWN:
                            fields.push({
                                constraints: {
                                    field_type: 'CHOICE',
                                    choices:
                                        sectionItem.constraints.choices?.map(
                                            (val) => val
                                        ) ?? [],
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: null,
                            });
                            break;
                        case ItemCustomFieldSectionFieldType.MULTI_SELECT:
                            fields.push({
                                constraints: {
                                    field_type: 'CHOICE',
                                    choices:
                                        sectionItem.constraints.choices?.map(
                                            (val) => val
                                        ) ?? [],
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: [],
                            });
                            break;
                        case ItemCustomFieldSectionFieldType.DATE:
                            fields.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.DATE,
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: null,
                            });
                            break;

                        case ItemCustomFieldSectionFieldType.SHORTTEXT:
                            console.log(
                                'what is happening here',
                                cloneDeep(sectionItem),
                                cloneDeep(
                                    cleanUpFieldName(sectionItem.alternate_name)
                                )
                            );
                            fields.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.SHORTTEXT,

                                    max_limit:
                                        sectionItem.constraints.max_limit ??
                                        10000,
                                    min_limit:
                                        sectionItem.constraints.min_limit ?? 0,
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: '',
                            });
                            break;
                        case ItemCustomFieldSectionFieldType.LONGTEXT:
                            fields.push({
                                constraints: {
                                    field_type:
                                        ItemCustomFieldSectionFieldType.LONGTEXT,

                                    max_limit:
                                        sectionItem.constraints.max_limit ??
                                        10000,
                                    min_limit:
                                        sectionItem.constraints.min_limit ?? 0,
                                },
                                column: sectionItem.name,
                                description: sectionItem.description,
                                is_locked: true,
                                is_required: sectionItem.is_required,
                                is_hidden:
                                    sectionItem.additional_information
                                        .is_hidden,
                                is_visible:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                name: cleanUpFieldName(
                                    sectionItem.alternate_name
                                ),
                                section_item_id: sectionItem.section_item_id,
                                value: '',
                            });
                            break;
                    }
                }
            });
            console.log('fields', fields);
            return {
                name: 'item_fields',
                fields,
            };
        }) ?? [];

    let obj: {
        [key: string]: IItemCustomField;
    } = {
        Item_code: {} as IItemCustomField,
        Item_name: {} as IItemCustomField,
        Description: {} as IItemCustomField,
        Identifier_name: {} as IItemCustomField,
        Identifier_value: {} as IItemCustomField,
        Measurement_unit: {} as IItemCustomField,
        Notes: {} as IItemCustomField,
        Specification_name: {} as IItemCustomField,
        Specification_value: {} as IItemCustomField,
        Tag: {} as IItemCustomField,
        Internal_notes: {} as IItemCustomField,
    };

    section_list[0].fields.forEach((field) => {
        let fieldName: string = field.name.split(' ').join('_');

        obj[fieldName] = field;

        obj[field.column.split(' ').join('_')] = field;
    });

    return obj;
    // return section_list;
};

export const convertAdditionalCostInterface = (
    additionalCost: IPreSelectedAdditionalCostFromApi[]
) => {
    let formattedAdditionalCost: IItemAdditionalCostBackend[] =
        additionalCost.map((cost) => {
            let obj: IItemAdditionalCostBackend = {
                allocation_type: cost.additional_cost.allocation_type,
                additional_cost_id: cost.additional_cost.additional_cost_id,
                cost_source: cost.cost_source,
                cost_name: cost.additional_cost.cost_name,
                cost_type: cost.additional_cost.cost_type,
                cost_value: cost.cost_value,
            };
            return obj;
        });

    return formattedAdditionalCost;
};

export async function fetchPreselectedAdditionalCostFromApi(itemId: string) {
    try {
        if (itemId) {
            let res = await get<any>(`/organization/items/${itemId}/admin/`);
            let data: IPreSelectedApiResponse = res.data;

            let buyerAdditionalCosts = convertAdditionalCostInterface(
                data.buyer_additional_costs
            )?.filter((cost) => cost.cost_value !== null);
            let sellerAdditionalCosts = convertAdditionalCostInterface(
                data.seller_additional_costs
            )?.filter((cost) => cost.cost_value !== null);

            return {
                buyerAdditionalCosts,
                sellerAdditionalCosts,
                custom_fields: data?.custom_fields,
            };
        }
    } catch (e) {}
}
