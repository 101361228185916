import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { ColDef, IHeaderParams } from 'ag-grid-community';
import React, { Dispatch, SetStateAction } from 'react';
import { FWTypography } from '../../../Common/FWTypograhy';
import { CartItemDetails } from '../../../ItemAnalytics/Interfaces/itemCartItemInterfaces';
import FWPopover from '../../../Common/FWPopover';
import FWMultipleTooltipMessages from '../../../Common/FWMultipleTooltipMessages';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { getStatusLabelAndColor } from '../../../Requisitions/Helpers/requisitionHelpers';

export const OrderColumns = (
    selectedOrders: any,
    setSelectedOrders: Dispatch<SetStateAction<string[]>>,
    orderDetails: any,
    selectedReqId: string
): ColDef[] => {
    return [
        {
            field: 'requisition_id',
            headerName: '',
            width: 80,
            sortable: false,
            filter: false,
            resizable: false,
            headerComponent: (props: IHeaderParams) => {
                const noOfOrders = orderDetails?.length ?? null;

                return (
                    <>
                        {' '}
                        <Box marginLeft={'11px'}>
                            <FormControlLabel
                                label=""
                                control={
                                    <Checkbox
                                        checked={
                                            noOfOrders === selectedOrders.length
                                        }
                                        indeterminate={
                                            noOfOrders !==
                                                selectedOrders.length &&
                                            selectedOrders.length > 0
                                        }
                                        onChange={(e) => {
                                            if (selectedOrders) {
                                                if (e.target.checked) {
                                                    setSelectedOrders(
                                                        orderDetails
                                                            .map(
                                                                (v: any) =>
                                                                    v.requisition_id
                                                            )
                                                            .filter(
                                                                (v: any) =>
                                                                    v !==
                                                                    undefined
                                                            ) as string[]
                                                    );
                                                } else {
                                                    setSelectedOrders(
                                                        orderDetails
                                                            .map(
                                                                (data: any) =>
                                                                    data.requisition_id
                                                            )
                                                            .filter(
                                                                (data: any) =>
                                                                    data ===
                                                                    selectedReqId
                                                            )
                                                    );
                                                }
                                            }
                                        }}
                                        size="small"
                                        color={
                                            noOfOrders !== selectedOrders.length
                                                ? 'secondary'
                                                : undefined
                                        }
                                    />
                                }
                            />
                        </Box>
                    </>
                );
            },
            cellRenderer: (params: any) => {
                return (
                    <>
                        <Checkbox
                            disabled={params.value === selectedReqId}
                            checked={
                                params.value !== null &&
                                params.value !== undefined &&
                                params.value === selectedReqId
                                    ? true
                                    : selectedOrders.includes(params.value)
                                    ? true
                                    : false
                            }
                            onChange={() => {
                                if (
                                    params.value !== null &&
                                    params.value !== undefined &&
                                    !selectedOrders.includes(params.value)
                                ) {
                                    setSelectedOrders([
                                        ...selectedOrders,
                                        params.value,
                                    ]);
                                } else
                                    setSelectedOrders(
                                        selectedOrders.filter(
                                            (order: string) =>
                                                order !== params.value
                                        )
                                    );
                            }}
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                            size={'small'}
                        />
                    </>
                );
            },
        },
        {
            field: 'custom_requisition_id',
            headerName: 'Order No.',
            sortable: false,
            filter: false,
            width: 140,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
        },
        {
            field: 'requisition_name',
            headerName: 'Order Name',
            sortable: false,
            filter: false,
            width: 265,
            flex: 1,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            filter: false,
            resizable: false,
            flex: 1,
            cellRenderer: (params: any) => {
                return (
                    <FWTypography
                        color={getStatusLabelAndColor(params.data.status).color}
                    >
                        {getStatusLabelAndColor(params.data.status).label}
                    </FWTypography>
                );
            },
        },

        {
            field: 'item_count',
            headerName: 'Item(s)',
            sortable: false,
            filter: false,
            resizable: false,
            width: 120,
            cellRenderer: (params: any) => {
                return ItemCountCell(params);
            },
        },
        {
            field: 'created_by_user_name',
            headerName: 'Created by',
            sortable: false,
            filter: false,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
            flex: 1,
        },
        {
            field: 'buyer_entity_name',
            headerName: 'Entity',
            sortable: false,
            filter: false,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
            flex: 1,
        },
    ];
};

export const OrderColumnsForDashBoard = (
    selectedOrders: any,
    setSelectedOrders: Dispatch<SetStateAction<string[]>>,
    orderDetails: any
): ColDef[] => {
    return [
        {
            field: 'requisition_id',
            headerName: '',
            width: 80,
            sortable: false,
            filter: false,
            resizable: false,
            headerComponent: (props: IHeaderParams) => {
                const noOfOrders = orderDetails?.length ?? null;

                return (
                    <>
                        {' '}
                        <Box marginLeft={'11px'}>
                            <FormControlLabel
                                label=""
                                control={
                                    <Checkbox
                                        checked={
                                            noOfOrders === selectedOrders.length
                                        }
                                        indeterminate={
                                            noOfOrders !==
                                                selectedOrders.length &&
                                            selectedOrders.length > 0
                                        }
                                        onChange={(e) => {
                                            if (selectedOrders) {
                                                if (e.target.checked) {
                                                    setSelectedOrders(
                                                        orderDetails
                                                            .map(
                                                                (v: any) =>
                                                                    v.requisition_id
                                                            )
                                                            .filter(
                                                                (v: any) =>
                                                                    v !==
                                                                    undefined
                                                            ) as string[]
                                                    );
                                                } else {
                                                    setSelectedOrders([]);
                                                }
                                            }
                                        }}
                                        size="small"
                                        color={
                                            noOfOrders !== selectedOrders.length
                                                ? 'secondary'
                                                : undefined
                                        }
                                    />
                                }
                            />
                        </Box>
                    </>
                );
            },
            cellRenderer: (params: any) => {
                return (
                    <>
                        <Checkbox
                            checked={
                                params.value !== null &&
                                params.value !== undefined &&
                                selectedOrders.includes(params.value)
                            }
                            onChange={() => {
                                if (
                                    params.value !== null &&
                                    params.value !== undefined &&
                                    !selectedOrders.includes(params.value)
                                ) {
                                    setSelectedOrders([
                                        ...selectedOrders,
                                        params.value,
                                    ]);
                                } else
                                    setSelectedOrders(
                                        selectedOrders.filter(
                                            (order: string) =>
                                                order !== params.value
                                        )
                                    );
                            }}
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                            size={'small'}
                        />
                    </>
                );
            },
        },
        {
            field: 'custom_requisition_id',
            headerName: 'Order No.',
            sortable: false,
            filter: false,
            width: 140,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
        },
        {
            field: 'requisition_name',
            headerName: 'Order Name',
            sortable: false,
            filter: false,
            width: 265,
            flex: 1,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            filter: false,
            resizable: false,
            flex: 1,
            cellRenderer: (params: any) => {
                return (
                    <FWTypography
                        color={getStatusLabelAndColor(params.data.status).color}
                    >
                        {getStatusLabelAndColor(params.data.status).label}
                    </FWTypography>
                );
            },
        },

        {
            field: 'item_count',
            headerName: 'Item(s)',
            sortable: false,
            filter: false,
            resizable: false,
            width: 120,
            cellRenderer: (params: any) => {
                return ItemCountCell(params);
            },
        },
        {
            field: 'created_by_user_name',
            headerName: 'Created by',
            sortable: false,
            filter: false,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
            flex: 1,
        },
        {
            field: 'buyer_entity_name',
            headerName: 'Entity',
            sortable: false,
            filter: false,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
            flex: 1,
        },
    ];
};

export const RequisitionColumns = (
    selectedOrders: any,
    setSelectedOrders: Dispatch<SetStateAction<string[]>>,
    orderDetails: any,
    selectedEntity: string
): ColDef[] => {
    return [
        {
            field: 'requisition_id',
            headerName: '',
            width: 80,
            sortable: false,
            filter: false,
            resizable: false,
            headerComponent: (props: IHeaderParams) => {
                const noOfOrders = orderDetails?.length ?? null;
                return (
                    <>
                        {' '}
                        <Box marginLeft={'11px'}>
                            <FormControlLabel
                                label=""
                                control={
                                    <Checkbox
                                        disabled={getNumberOfEntities(
                                            orderDetails
                                        )}
                                        checked={
                                            noOfOrders === selectedOrders.length
                                        }
                                        indeterminate={
                                            noOfOrders !==
                                                selectedOrders.length &&
                                            selectedOrders.length > 0
                                        }
                                        onChange={(e) => {
                                            if (selectedOrders) {
                                                if (e.target.checked) {
                                                    setSelectedOrders(
                                                        orderDetails
                                                            .map(
                                                                (v: any) =>
                                                                    v.requisition_id
                                                            )
                                                            .filter(
                                                                (v: any) =>
                                                                    v !==
                                                                    undefined
                                                            ) as string[]
                                                    );
                                                } else {
                                                    setSelectedOrders([]);
                                                }
                                            }
                                        }}
                                        size="small"
                                        color={
                                            noOfOrders !== selectedOrders.length
                                                ? 'secondary'
                                                : undefined
                                        }
                                    />
                                }
                            />
                        </Box>
                    </>
                );
            },
            cellRenderer: (params: any) => {
                return (
                    <>
                        <Checkbox
                            disabled={
                                selectedEntity === ''
                                    ? false
                                    : params.data.buyer_entity !==
                                      selectedEntity
                            }
                            checked={
                                params.value !== null &&
                                params.value !== undefined &&
                                selectedOrders.includes(params.value)
                            }
                            onChange={() => {
                                if (
                                    params.value !== null &&
                                    params.value !== undefined &&
                                    !selectedOrders.includes(params.value)
                                ) {
                                    setSelectedOrders([
                                        ...selectedOrders,
                                        params.value,
                                    ]);
                                } else
                                    setSelectedOrders(
                                        selectedOrders.filter(
                                            (order: string) =>
                                                order !== params.value
                                        )
                                    );
                            }}
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                            size={'small'}
                        />
                    </>
                );
            },
        },
        {
            field: 'custom_requisition_id',
            headerName: 'Requisition ID',
            sortable: false,
            filter: false,
            width: 140,
            resizable: false,
            cellRenderer: (params: any) => {
                const warnings = getWarningsForRequisitions(
                    params.data,
                    selectedEntity
                );
                return (
                    <>
                        <Grid className="flex">
                            <FWTypography>{params.value}</FWTypography>

                            {warnings.length > 0 && (
                                <FWMultipleTooltipMessages
                                    title={'Warnings/Errors:'}
                                    messages={warnings}
                                    iconColor={''}
                                    icon={
                                        <div>
                                            <i
                                                className="bi bi-circle-fill"
                                                style={{
                                                    fontSize: '10px',
                                                    color: 'orange',
                                                }}
                                            ></i>
                                        </div>
                                    }
                                />
                            )}
                        </Grid>
                    </>
                );
            },
        },
        {
            field: 'requisition_name',
            headerName: 'Requisition name',
            sortable: false,
            filter: false,
            width: 265,
            flex: 1,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            filter: false,
            resizable: false,
            flex: 1,
            cellRenderer: (params: any) => {
                return (
                    <FWTypography
                        color={getStatusLabelAndColor(params.data.status).color}
                    >
                        {getStatusLabelAndColor(params.data.status).label}
                    </FWTypography>
                );
            },
        },

        {
            field: 'item_count',
            headerName: 'Item(s)',
            sortable: false,
            filter: false,
            resizable: false,
            width: 120,
            cellRenderer: (params: any) => {
                return ItemCountCell(params);
            },
        },
        {
            field: 'buyer_entity_name',
            headerName: 'Entity',
            sortable: false,
            filter: false,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
            flex: 1,
        },
        {
            field: 'created_by_user_name',
            headerName: 'Created by',
            sortable: false,
            filter: false,
            resizable: false,
            cellRenderer: (params: any) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
            flex: 1,
        },
    ];
};

export const itemColumnsForRequisitons = (
    selectedItems: any,
    setSelectedItems: Dispatch<SetStateAction<string[]>>,
    itemsAvailable: any,
    shippingAddressId: string,
    getCustomRequisitionId: (id: string) => string | null,
    defaultCurrencyDetails: any,
    warningForShippingAddress: Set<string | null>
): ColDef[] => {
    return [
        {
            field: 'requisition_item_id',
            headerName: '',
            width: 80,
            sortable: false,
            filter: false,
            resizable: false,
            headerComponent: (props: IHeaderParams) => {
                const noOfItems = itemsAvailable?.length ?? null;

                return (
                    <>
                        {' '}
                        <Box marginLeft={'11px'}>
                            <FormControlLabel
                                label=""
                                control={
                                    <Checkbox
                                        disabled={getNumberOfShippingAddress(
                                            itemsAvailable
                                        )}
                                        checked={
                                            noOfItems === selectedItems.length
                                        }
                                        indeterminate={
                                            noOfItems !==
                                                selectedItems.length &&
                                            selectedItems.length > 0
                                        }
                                        onChange={(e) => {
                                            if (selectedItems) {
                                                if (e.target.checked) {
                                                    setSelectedItems(
                                                        itemsAvailable
                                                            .map(
                                                                (v: any) =>
                                                                    v.requisition_item_id
                                                            )
                                                            .filter(
                                                                (v: any) =>
                                                                    v !==
                                                                    undefined
                                                            ) as string[]
                                                    );
                                                } else {
                                                    setSelectedItems([]);
                                                }
                                            }
                                        }}
                                        size="small"
                                        color={
                                            noOfItems !== selectedItems.length
                                                ? 'secondary'
                                                : undefined
                                        }
                                    />
                                }
                            />
                        </Box>
                    </>
                );
            },
            cellRenderer: (params: any) => {
                const addressValidation = () => {
                    if (
                        params.data.shipping_address.shipping_address_id ===
                            null ||
                        shippingAddressId === null ||
                        shippingAddressId === ''
                    )
                        return false;
                    else
                        return (
                            params.data.shipping_address.shipping_address_id !==
                            shippingAddressId
                        );
                };
                return (
                    <>
                        <Checkbox
                            disabled={
                                addressValidation() ||
                                !areDeliveryDatesValid(params.data)
                            }
                            checked={
                                params.value !== null &&
                                params.value !== undefined &&
                                selectedItems.includes(params.value)
                                    ? true
                                    : false
                            }
                            onChange={() => {
                                if (
                                    params.value !== null &&
                                    params.value !== undefined &&
                                    !selectedItems.includes(params.value)
                                ) {
                                    setSelectedItems([
                                        ...selectedItems,
                                        params.value,
                                    ]);
                                } else
                                    setSelectedItems(
                                        selectedItems.filter(
                                            (order: string) =>
                                                order !== params.value
                                        )
                                    );
                            }}
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                            size={'small'}
                        />
                    </>
                );
            },
        },
        {
            field: 'item_code',
            headerName: 'Item ID',
            sortable: false,
            filter: false,
            width: 140,
            flex: 1,
            resizable: false,
            cellRenderer: ({ data }: { data: CartItemDetails }) => {
                const { warnings, errors } = getWarningsForOrderItems(
                    data,
                    warningForShippingAddress,
                    areDeliveryDatesValid(data),
                    selectedItems
                );
                const addressValidation = () => {
                    if (
                        data.shipping_address.shipping_address_id === null ||
                        shippingAddressId === null ||
                        shippingAddressId === ''
                    )
                        return false;
                    else
                        return (
                            data.shipping_address.shipping_address_id !==
                            shippingAddressId
                        );
                };
                return (
                    <>
                        <Grid container className="flex flex--aic">
                            <Grid item xs={11}>
                                <FWTypography>
                                    {data.item_information.item_code}
                                </FWTypography>
                            </Grid>
                            <Grid item xs={1}>
                                {warnings.length > 0 && !addressValidation() && (
                                    <FWMultipleTooltipMessages
                                        title={'Warnings:'}
                                        messages={warnings}
                                        iconColor={''}
                                        icon={
                                            <div>
                                                <i
                                                    className="bi bi-circle-fill"
                                                    style={{
                                                        fontSize: '10px',
                                                        color: 'orange',
                                                    }}
                                                ></i>
                                            </div>
                                        }
                                    />
                                )}
                                {errors.length > 0 && (
                                    <FWTooltip title={errors[0]}>
                                        <div>
                                            <i
                                                className="bi bi-circle-fill"
                                                style={{
                                                    fontSize: '10px',
                                                    color: 'red',
                                                }}
                                            ></i>
                                        </div>
                                    </FWTooltip>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            },
        },
        {
            field: 'item_name',
            headerName: 'Item Name',
            sortable: false,
            filter: false,
            width: 265,
            flex: 1,
            resizable: false,
            cellRenderer: ({ data }: { data: CartItemDetails }) => {
                return (
                    <FWTypography>
                        {data.item_information.item_name}
                    </FWTypography>
                );
            },
        },
        {
            field: 'requisition',
            headerName: 'Requisition ID',
            sortable: false,
            filter: false,
            resizable: false,
            width: 120,
            flex: 1,
            cellRenderer: (params: any) => {
                return (
                    <FWTypography>
                        {getCustomRequisitionId(params.value) ?? ''}
                    </FWTypography>
                );
            },
        },

        {
            field: 'quantity',
            headerName: 'Pending Quantity',
            sortable: false,
            filter: false,
            resizable: false,
            flex: 1,
            cellRenderer: (params: any) => {
                // const pendingQuantity = 0;
                return (
                    <FWTypography>{`${calculatePendingQuantity(
                        params.data.delivery_schedule
                    )} ${
                        params.data.measurement_unit_details
                            .measurement_unit_name
                    }`}</FWTypography>
                );
            },
        },
        {
            field: 'desired_price',
            headerName: 'Desired rate',
            sortable: false,
            filter: false,
            resizable: false,
            flex: 1,
            cellRenderer: ({ data }: { data: CartItemDetails }) => {
                return (
                    <>
                        <FWTypography marginRight={2}>
                            {`${data.pricing_information.currency_symbol} ${data.pricing_information.desired_price}`}
                        </FWTypography>
                    </>
                );
            },
        },
        {
            field: 'shipping_full_address',
            headerName: 'Shipping address',
            sortable: false,
            filter: false,
            resizable: false,
            flex: 1,
            cellRenderer: ({ data }: { data: CartItemDetails }) => {
                return (
                    <FWTypography>
                        {data.shipping_address.shipping_full_address}
                    </FWTypography>
                );
            },
        },
    ];
};
export const itemColumns = (
    selectedItems: any,
    setSelectedItems: Dispatch<SetStateAction<string[]>>,
    itemsAvailable: any,
    shippingAddressId: string,
    getCustomRequisitionId: (id: string) => string | null,
    defaultCurrencyDetails: any,
    warningForShippingAddress: Set<string | null>
): ColDef[] => {
    return [
        {
            field: 'requisition_item_id',
            headerName: '',
            width: 80,
            sortable: false,
            filter: false,
            resizable: false,
            headerComponent: (props: IHeaderParams) => {
                const noOfItems = itemsAvailable?.length ?? null;

                return (
                    <>
                        {' '}
                        <Box marginLeft={'11px'}>
                            <FormControlLabel
                                label=""
                                control={
                                    <Checkbox
                                        disabled={getNumberOfShippingAddress(
                                            itemsAvailable
                                        )}
                                        checked={
                                            noOfItems === selectedItems.length
                                        }
                                        indeterminate={
                                            noOfItems !==
                                                selectedItems.length &&
                                            selectedItems.length > 0
                                        }
                                        onChange={(e) => {
                                            if (selectedItems) {
                                                if (e.target.checked) {
                                                    setSelectedItems(
                                                        itemsAvailable
                                                            .map(
                                                                (v: any) =>
                                                                    v.requisition_item_id
                                                            )
                                                            .filter(
                                                                (v: any) =>
                                                                    v !==
                                                                    undefined
                                                            ) as string[]
                                                    );
                                                } else {
                                                    setSelectedItems([]);
                                                }
                                            }
                                        }}
                                        size="small"
                                        color={
                                            noOfItems !== selectedItems.length
                                                ? 'secondary'
                                                : undefined
                                        }
                                    />
                                }
                            />
                        </Box>
                    </>
                );
            },
            cellRenderer: (params: any) => {
                const addressValidation = () => {
                    if (
                        params.data.shipping_address.shipping_address_id ===
                            null ||
                        shippingAddressId === null ||
                        shippingAddressId === ''
                    )
                        return false;
                    else
                        return (
                            params.data.shipping_address.shipping_address_id !==
                            shippingAddressId
                        );
                };
                return (
                    <>
                        <Checkbox
                            disabled={
                                addressValidation() ||
                                !areDeliveryDatesValid(params.data)
                            }
                            checked={
                                params.value !== null &&
                                params.value !== undefined &&
                                selectedItems.includes(params.value)
                                    ? true
                                    : false
                            }
                            onChange={() => {
                                if (
                                    params.value !== null &&
                                    params.value !== undefined &&
                                    !selectedItems.includes(params.value)
                                ) {
                                    setSelectedItems([
                                        ...selectedItems,
                                        params.value,
                                    ]);
                                } else
                                    setSelectedItems(
                                        selectedItems.filter(
                                            (order: string) =>
                                                order !== params.value
                                        )
                                    );
                            }}
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                            size={'small'}
                        />
                    </>
                );
            },
        },
        {
            field: 'item_code',
            headerName: 'Item ID',
            sortable: false,
            filter: false,
            width: 140,
            flex: 1,
            resizable: false,
            cellRenderer: ({ data }: { data: CartItemDetails }) => {
                const { warnings, errors } = getWarningsForOrderItems(
                    data,
                    warningForShippingAddress,
                    areDeliveryDatesValid(data),
                    selectedItems
                );
                return (
                    <>
                        <Grid className="flex">
                            <FWTypography>
                                {data.item_information.item_code}
                            </FWTypography>

                            {warnings.length > 0 && (
                                <FWMultipleTooltipMessages
                                    title={'Warnings/Errors:'}
                                    messages={warnings}
                                    iconColor={''}
                                    icon={
                                        <div>
                                            <i
                                                className="bi bi-circle-fill"
                                                style={{
                                                    fontSize: '10px',
                                                    color: 'orange',
                                                }}
                                            ></i>
                                        </div>
                                    }
                                />
                            )}
                            {errors.length > 0 && (
                                <FWMultipleTooltipMessages
                                    title={'Warnings/Errors:'}
                                    messages={errors}
                                    iconColor={''}
                                    icon={
                                        <div>
                                            <i
                                                className="bi bi-circle-fill"
                                                style={{
                                                    fontSize: '10px',
                                                    color: 'red',
                                                }}
                                            ></i>
                                        </div>
                                    }
                                />
                            )}
                        </Grid>
                    </>
                );
            },
        },
        {
            field: 'item_name',
            headerName: 'Item Name',
            sortable: false,
            filter: false,
            width: 265,
            flex: 1,
            resizable: false,
            cellRenderer: ({ data }: { data: CartItemDetails }) => {
                return (
                    <FWTypography>
                        {data.item_information.item_name}
                    </FWTypography>
                );
            },
        },
        {
            field: 'requisition',
            headerName: 'Order ID',
            sortable: false,
            filter: false,
            resizable: false,
            width: 120,
            flex: 1,
            cellRenderer: (params: any) => {
                return (
                    <FWTypography>
                        {getCustomRequisitionId(params.value) ?? ''}
                    </FWTypography>
                );
            },
        },

        {
            field: 'quantity',
            headerName: 'Pending quantity',
            sortable: false,
            filter: false,
            resizable: false,
            flex: 1,
            cellRenderer: (params: any) => {
                return (
                    <FWTypography>{`${params.value} ${params.data.measurement_unit_details.measurement_unit_name}`}</FWTypography>
                );
            },
        },
        {
            field: 'desired_price',
            headerName: 'Desired rate',
            sortable: false,
            filter: false,
            resizable: false,
            flex: 1,
            cellRenderer: ({ data }: { data: CartItemDetails }) => {
                return (
                    <>
                        <FWTypography marginRight={2}>
                            {`${data.pricing_information.currency_symbol} ${data.pricing_information.desired_price}`}
                        </FWTypography>
                    </>
                );
            },
        },
        {
            field: 'shipping_full_address',
            headerName: 'Shipping address',
            sortable: false,
            filter: false,
            resizable: false,
            flex: 1,
            cellRenderer: ({ data }: { data: CartItemDetails }) => {
                return (
                    <FWTypography>
                        {data.shipping_address.shipping_full_address}
                    </FWTypography>
                );
            },
        },
    ];
};
const ItemCountCell = ({ data }: { data: any }) => {
    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    return (
        <>
            <Box
                ref={(ref: any) => {
                    if (!ref) return;
                    ref.onclick = (e: any) => {
                        e.stopPropagation();
                        setAnchorEl(e.target);
                        setOpenPopup(true);
                    };
                }}
                sx={{
                    width: '100%',
                }}
            >
                <FWTypography
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        setOpenPopup(true);
                    }}
                    color={'primary.main'}
                >
                    {data.item_count}
                </FWTypography>
            </Box>
            <FWPopover
                open={openPopup}
                anchorEl={anchorEl}
                onClose={() => {
                    setOpenPopup(false);
                }}
            >
                {data.requisition_items.map((item: any, index: any) => (
                    <FWTypography key={index}>
                        {index + 1}. {item.item_information.item_name}
                    </FWTypography>
                ))}
            </FWPopover>
        </>
    );
};

const getNumberOfShippingAddress = (items: any[]) => {
    const values = new Set();
    items.forEach((item) => {
        values.add(item.shipping_address.shipping_address_id);
    });
    if (values.size > 1) return true;
    else if (values.size === 2 && values.has(null)) return false;
    else return false;
};

const getNumberOfEntities = (requisitions: any[]) => {
    const values = new Set();
    requisitions.forEach((requisition) => {
        values.add(requisition.buyer_entity);
    });
    if (values.size > 1) return true;
    else if (values.size === 2 && values.has(null)) return false;
    else return false;
};

const areDeliveryDatesValid = (item: any) => {
    let deliveryDates: any[] = item.delivery_schedule.map(
        (ds: any) => ds.delivery_date
    );
    const currentDate = new Date();
    let response = deliveryDates.every((value) => {
        if (value) {
            const deliveryDate = new Date(value);
            return deliveryDate > currentDate;
        } else return true;
    });
    return response;
};

const getWarningsForOrderItems = (
    requisition: any,
    shippingAddressId: Set<string | null>,
    isDeliveryDateInvalid: boolean,
    selectedItems: any
) => {
    let warnings: string[] = [];
    let errors = [];
    let shippingAddressArray = [...shippingAddressId];
    if (!isDeliveryDateInvalid) {
        errors.push('Delivery date cannot be in the past');
    }
    if (selectedItems.length === 0) {
        return { warnings, errors };
    }
    if (shippingAddressArray.length === 1) {
        if (
            shippingAddressArray[0] === '' &&
            requisition.shipping_address.shipping_address_id
        ) {
            warnings.push('Mismatch in shipping address');
        } else if (
            shippingAddressArray[0] !== '' &&
            shippingAddressArray[0] !==
                requisition.shipping_address.shipping_address_id
        ) {
            if (requisition.shipping_address.shipping_address_id === null) {
                warnings.push('Mismatch in shipping address');
            } else {
                errors.push(
                    'Item unavailable for selection due to a mismatch between the shipping addresses of the selected item(s)'
                );
            }
        }
    } else {
        let value = shippingAddressArray[0] || shippingAddressArray[1];
        let isFirstValueNull = shippingAddressArray[0] === null;
        if (
            isFirstValueNull
                ? value === requisition.shipping_address.shipping_address_id
                : !requisition.shipping_address.shipping_address_id
        ) {
            warnings.push('Mismatch in shipping address');
        } else if (
            value !== requisition.shipping_address.shipping_address_id &&
            requisition.shipping_address.shipping_address_id !== null
        ) {
            errors.push(
                'Item unavailable for selection due to a mismatch between the shipping addresses of the selected item(s)'
            );
        }
    }
    // if (
    //     requisition.shipping_address.shipping_address_id === undefined ||
    //     shippingAddressId.values().next() === null
    // ) {
    // } else if (
    //     [...shippingAddressId][0] !==
    //     requisition.shipping_address.shipping_address_id
    // ) {
    //     warnings.push('Mismatch in shipping address');
    // }
    return { warnings, errors };
};

const getWarningsForRequisitions = (requisition: any, entity: string) => {
    let warnings = [];
    if (entity !== '' && entity !== requisition.buyer_entity) {
        warnings.push('Entity does not match with selected Requisition');
    }
    return warnings;
};

const calculatePendingQuantity = (ds: any[]) => {
    let value = ds.reduce((total, current) => {
        return (
            total +
            (current.fulfilment_information.requisition -
                current.fulfilment_information.rfq)
        );
    }, 0);
    return value;
};
