import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
} from '@mui/material';
import { isEmpty, isEqual } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWInput } from '../../../Common/FWInput';
import { FWTypography } from '../../../Common/FWTypograhy';
import { IGlobalAppStore } from '../../../Redux/Store';
import { ItemCartTemplateStandardFieldsEnum } from '../../Interfaces/ItemCartTemplateInterfaces';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import { CartItemAccordionContext } from './CartItemAccordion';
import CartItemCustomFields from './CartItemCustomFields';

export default function CartItemAdditionalDetails() {
    const { updateItemData, errors, cartItemId, itemInCart } = useContext(
        CartItemAccordionContext
    );

    const { templateDetails } = useContext(ItemCartAccessContext);

    const item_additional_information = useSelector((store: IGlobalAppStore) =>
        itemInCart
            ? store.ItemCartStore.cartItems[cartItemId].item_information
                  .item_additional_details
            : store.ItemCartStore.itemsSavedForLater[cartItemId]
                  .item_information.item_additional_details
    );

    const [open, setOpen] = useState(false);

    console.log('errorserrors', errors);

    const additionalInformationError = useMemo(() => {
        return (
            Boolean(errors.item_additional_information) ||
            (errors.custom_fields[
                templateDetails.sections.ADDITIONAL_DETAILS.label
            ] !== undefined &&
                !isEqual(
                    errors.custom_fields[
                        templateDetails.sections.ADDITIONAL_DETAILS.label
                    ],
                    {}
                ))
        );
    }, [
        errors.custom_fields,
        errors.item_additional_information,
        templateDetails.sections.ADDITIONAL_DETAILS.label,
    ]);

    return (
        <Accordion
            expanded={open}
            onChange={() => setOpen((prev) => !prev)}
            sx={{
                backgroundColor: 'transparent',
                '&:before': {
                    display: 'none',
                },
            }}
            disableGutters
        >
            <AccordionSummary
                sx={{ padding: '0px', margin: '0 0.5rem 0 0.5rem' }}
            >
                <IconButton
                    size="small"
                    color="primary"
                    sx={{
                        alignSelf: 'flex-start',
                    }}
                >
                    {open ? (
                        <i className="bi bi-chevron-down"></i>
                    ) : (
                        <i className="bi bi-chevron-right"></i>
                    )}
                </IconButton>
                &nbsp;
                <Box className="flex flex">
                    <FWTypography
                        variant="h4"
                        fontWeight={500}
                        color={
                            additionalInformationError
                                ? 'error.main'
                                : 'text.primary'
                        }
                    >
                        {templateDetails.sections.ADDITIONAL_DETAILS.label}
                    </FWTypography>
                    &nbsp;
                </Box>
                &nbsp; &nbsp;
                {!open && (
                    <Box className="flex flex--aic" flexWrap={'wrap'}>
                        {/* additional info */}
                        {templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
                            (field) =>
                                field.field ===
                                ItemCartTemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
                        ) &&
                            templateDetails.fieldSettings.standardFields
                                .ADDITIONAL_INFORMATION.buyerSettings
                                .showField &&
                            !isEmpty(item_additional_information) && (
                                <>
                                    &nbsp;
                                    <FWTypography
                                        color="text.secondary"
                                        className="flex flex--aic"
                                    >
                                        |
                                    </FWTypography>
                                    &nbsp;
                                    <FWTypography
                                        color="text.secondary"
                                        className="flex flex--aic"
                                        whiteSpace={'pre-wrap'}
                                        sx={{
                                            whiteSpace: 'pre-wrap',
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {
                                            templateDetails.fieldSettings
                                                .standardFields
                                                .ADDITIONAL_INFORMATION.label
                                        }
                                        : {item_additional_information}
                                    </FWTypography>
                                </>
                            )}
                    </Box>
                )}
            </AccordionSummary>

            <AccordionDetails>
                <Box
                    display="grid"
                    gridTemplateColumns={{
                        lg: 'repeat(4, 1fr)',
                        sm: 'repeat(2, 1fr)',
                        xs: 'repeat(1, 1fr)',
                    }}
                    columnGap="1.5rem"
                    rowGap={'1rem'}
                >
                    {templateDetails.sections.ADDITIONAL_DETAILS.fieldList.map(
                        (singleField, idx) => {
                            if (singleField.fieldType === 'STANDARD') {
                                if (
                                    templateDetails.fieldSettings
                                        .standardFields[singleField.field]
                                        .buyerSettings.showField
                                )
                                    switch (singleField.field) {
                                        case ItemCartTemplateStandardFieldsEnum.ADDITIONAL_INFORMATION:
                                            return (
                                                <Box
                                                // sx={{ gridColumn: '2/ -1' }}
                                                >
                                                    <Box
                                                        className={
                                                            'flex flex--aic'
                                                        }
                                                    >
                                                        <FWTypography color="text.secondary">
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    singleField
                                                                        .field
                                                                ].label
                                                            }
                                                            {!templateDetails
                                                                .fieldSettings
                                                                .standardFields[
                                                                singleField
                                                                    .field
                                                            ].buyerSettings
                                                                .required &&
                                                                '(optional)'}
                                                        </FWTypography>
                                                        {templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            singleField.field
                                                        ].description.length >
                                                            0 && (
                                                            <>
                                                                &nbsp;
                                                                <FWTooltip
                                                                    title={
                                                                        templateDetails
                                                                            .fieldSettings
                                                                            .standardFields[
                                                                            singleField
                                                                                .field
                                                                        ]
                                                                            .description
                                                                    }
                                                                    placement="top"
                                                                >
                                                                    <Box
                                                                        color={
                                                                            'primary.main'
                                                                        }
                                                                    >
                                                                        <i className="bi bi-question-circle" />
                                                                    </Box>
                                                                </FWTooltip>
                                                            </>
                                                        )}
                                                    </Box>
                                                    <FWInput
                                                        // multiline
                                                        // maxRows={4}
                                                        value={
                                                            item_additional_information
                                                        }
                                                        onChange={(e) =>
                                                            updateItemData([
                                                                {
                                                                    field: 'item_additional_information',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            ])
                                                        }
                                                        error={
                                                            'item_additional_information' in
                                                            errors
                                                        }
                                                        helper={{
                                                            text:
                                                                'item_additional_information' in
                                                                errors
                                                                    ? errors[
                                                                          'item_additional_information'
                                                                      ]!
                                                                          .fieldMessageText
                                                                    : '',
                                                        }}
                                                    />
                                                </Box>
                                            );
                                    }
                                else return null;
                            } else {
                                return (
                                    <Box key={idx}>
                                        <CartItemCustomFields
                                            fieldName={singleField.field}
                                            templateDetails={templateDetails}
                                            sectionName={
                                                templateDetails.sections
                                                    .ADDITIONAL_DETAILS.label
                                            }
                                            updateItemData={updateItemData}
                                            openEditCustomFieldPopup={() => {}}
                                            isCustomFieldFromTemplate={true}
                                        />
                                    </Box>
                                );
                            }
                            return <></>;
                        }
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
