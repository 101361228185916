import { DialogContent, InputAdornment, TextField } from '@mui/material';
import { Fragment, useMemo } from 'react';
import FWAutocomplete from '../../../../Common/FWAutocomplete';
import FWIcon from '../../../../Common/FWIcon';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import { IItemSummary } from '../../Interfaces/ItemDirectoryInterface';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { debounce } from 'lodash';

interface IEntityItemLinkPopupProps {
    popupToggle: boolean;
    entityName: string;
    allItemsList: IItemSummary[];
    handlePopupToggle: () => void;
    handleLinkItem: (item: IItemSummary) => void;
    onSearchItem: (searchText: string) => void;
    onShowMore: () => void;
}

const EntityItemLinkPopup = ({
    popupToggle,
    handlePopupToggle,
    entityName,
    allItemsList,
    handleLinkItem,
    onSearchItem,
    onShowMore,
}: IEntityItemLinkPopupProps) => {
    const debounceSearch = useMemo(
        () =>
            debounce(async (value: string) => {
                onSearchItem(value);
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <FWPopupWithChildren
            handleClose={handlePopupToggle}
            open={popupToggle}
            title={`Add item to ${entityName}`}
            PaperProps={{
                sx: {
                    minWidth: '600px !important',
                },
            }}
        >
            <DialogContent style={{ paddingTop: 0, paddingBottom: '24px' }}>
                <div className="mt--5">
                    <FWAutocomplete
                        options={allItemsList}
                        sx={{
                            '.MuiOutlinedInput-root': {
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: '5px',
                            },
                        }}
                        value={null}
                        defaultValue={null}
                        onInputChange={(event, value) => {
                            if (
                                event === null ||
                                value === null ||
                                allItemsList.length === 0
                            )
                                return;
                            debounceSearch(value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoFocus
                                autoComplete="off"
                                placeholder="Select items to link to entity"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <FWIcon name="bi bi-search" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                        getOptionLabel={(option: IItemSummary) => {
                            let specs = '';
                            option.specifications.forEach((spec, idx) => {
                                const sep =
                                    idx === option.specifications.length - 1
                                        ? ''
                                        : ' | ';
                                specs +=
                                    spec.name +
                                    ': ' +
                                    spec.value.join(', ') +
                                    sep;
                            });
                            let label: string =
                                option.name +
                                ' | ' +
                                option.itemCode +
                                (option.specifications.length > 0
                                    ? ' | '
                                    : '') +
                                specs;
                            label = label.replace(/^\s+|\s+$/g, '');
                            return label;
                        }}
                        renderOption={(props: any, option: IItemSummary) => {
                            return (
                                <Fragment key={option.id}>
                                    {option.hasNext ? (
                                        <li
                                            {...props}
                                            onClick={(e: React.MouseEvent) => {
                                                e.stopPropagation();
                                                onShowMore();
                                            }}
                                        >
                                            <FWTypography
                                                color={'primary.main'}
                                            >
                                                Show More...
                                            </FWTypography>
                                        </li>
                                    ) : (
                                        <li {...props}>
                                            {option.name} | {option.itemCode}
                                            {option.specifications.length > 0
                                                ? ' | '
                                                : ''}
                                            {option.specifications.map(
                                                (spec, idx) => {
                                                    const sep =
                                                        idx ===
                                                        option.specifications
                                                            .length -
                                                            1
                                                            ? ''
                                                            : ' | ';
                                                    return (
                                                        <Fragment key={idx}>
                                                            {spec.name}:{' '}
                                                            {spec.value.join(
                                                                ', '
                                                            )}
                                                            {sep}
                                                        </Fragment>
                                                    );
                                                }
                                            )}
                                        </li>
                                    )}
                                </Fragment>
                            );
                        }}
                        onChange={(event, item: IItemSummary | null) => {
                            handleLinkItem(item!);
                        }}
                        filterOptions={(options, state) => options}
                        clearOnEscape
                        clearOnBlur
                        blurOnSelect
                    />
                </div>
            </DialogContent>
        </FWPopupWithChildren>
    );
};

export default EntityItemLinkPopup;
