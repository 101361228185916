import { cloneDeep } from 'lodash';
import {
    AllocationTypeEnum,
    CostTypeEnum,
    IAdditionalCostWithCurrencyAndValue,
    IAdditionalCostsWithValue,
} from '../../AdditionalCost/models/AdditionalCost.model';
import { IBuyerItemList } from '../../Events/Interfaces/Buyer/RFQ.model';
import {
    COSTING_DEFAULT_LEAD_TIME,
    COSTING_DEFAULT_LEAD_TIME_PERIOD,
} from '../Constants/CostingColDefs';
import {
    IHandleCostingDataChangeAdditionalItemCostingAdd,
    IHandleCostingDataChangeAdditionalItemCostingDelete,
    IHandleCostingDataChangeAdditionalItemCostingEdit,
    IHandleCostingDataChangeQuantity,
    IHandleCostingDataChangeRate,
    IHandleCostingDataChangeUnit,
    IHandleCostingDataNotes,
} from '../Hooks/useCostingCalculator';
import {
    AdditionalFinalCostingDataInterface,
    AdditionalFinalCostingDataWithCurrencyInterface,
    BOMDetailsItemType,
    IBOMCostingBackendStructure,
    IBOMDetailsResponse,
    ICostingBOM,
    ICostingHistoryById,
    ICostingItem,
} from '../Interfaces/CostingInterfaces.interface.';
import { ICostingCalculatorApiResponse } from '../Services/CostingApisCalls.service';

export const customSpecs = (
    item: string | null,
    itemList: IBuyerItemList[]
) => {
    // const attributes = item?.enterprise_item.attributes.attributes;
    const attributes = itemList.find(
        (i) => i.enterprise_item.enterprise_item_id === item
    )?.enterprise_item.attributes.attributes;

    if (attributes && attributes.length > 0) {
        return attributes.map((attr: any) => ({
            attribute_name: attr.attribute_name,
            attribute_value: attr.attribute_value.join(' / '),
        }));
        // .join(' | ');
    } else {
        return '';
    }
};

export const costingUnitsList = (
    value: ICostingItem | ICostingBOM,
    buyerItems: IBuyerItemList[]
) => {
    if (/* value.costType === 'ITEM' &&  */ value.itemId !== null) {
        const units = buyerItems.find(
            (item) => item.enterprise_item.enterprise_item_id === value.itemId
        )?.enterprise_item.measurement_units.item_measurement_units;
        return units ? units : [];
    }
    return [];
    // if (value.costType === 'ITEM') {
    //     return value.item !== null &&
    //         value.item?.enterprise_item.measurement_units
    //             .item_measurement_units.length > 0
    //         ? value.item?.enterprise_item.measurement_units
    //               .item_measurement_units
    //         : units;
    // } else {
    //     // TODO: Add units for other cost types
    //     return [
    //         {
    //             measurement_unit_id: 'units',
    //             measurement_unit_category: 'units',
    //             measurement_unit_value_type: 'units',
    //             measurement_unit_primary_name: 'units',
    //         },
    //     ];
    // }
};

// the 'recursivelyMapBackendBOMToFrontendBOMStructure2' && 'recursivelyMapBackendBOMToFrontendBOM' function
// are used to map the backend bom data to frontend costing BOM structure
const recursivelyMapBackendBOMToFrontendBOMStructure2: (
    data: BOMDetailsItemType
) => ICostingItem | ICostingBOM = (data) => {
    if (data.sub_bom === null) {
        return {
            costType: 'ITEM',
            lead_time: data.procurement_information.lead_time,
            lead_time_period: data.procurement_information.lead_time_period,
            uuid: null,
            projects: data.projects ?? [],
            notes: null,
            rate: '',
            vendor_rate: data.vendor_rate,
            conversion_rate: 1,

            quantity: data.quantity.toString(),
            vendor_entity: data.vendor_entity ?? '',
            unit: {
                measurement_unit_id: data.measurement_unit,
                measurement_unit_primary_name: '',
                abbreviation: '',
                measurement_unit_category: '',
                measurement_unit_value_type: '',
            },
            vendor: data.vendor_entity
                ? {
                      entity_id: data.vendor_entity,
                      entity_name: '',
                  }
                : null,
            vendor_currency: data.vendor_currency
                ? {
                      currency_code_id: data.vendor_currency,
                      currency_name: '',
                      currency_code_abbreviation: '',
                      currency_symbol: '',
                      currency_decimal: 2,
                  }
                : null,
            additional_costs: [],
            rfqItemId: null,
            rfq_event_details: null,
            rfq_bid_item: null,
            itemId: data.raw_material_item.enterprise_item_id,
            latestPO: null,
            custom_fields: {
                section_list: [],
            },
            custom_fields_negotiate: {
                section_list: [],
            },
            enterprise_item_details: data.enterprise_item_details,
        };
    } else {
        return {
            costType: 'BOM',
            uuid: null,
            conversion_rate: 1,
            rfqItemId: null,
            rfq_event_details: null,
            projects: data.projects ?? [],
            rfq_bid_item: null,
            lead_time: data.procurement_information.lead_time ?? null,
            lead_time_period:
                data.procurement_information.lead_time_period ?? null,
            bomId: data.sub_bom.enterprise_bom_id,
            itemId: data.sub_bom.enterprise_item.enterprise_item_id,
            vendor_entity: data.vendor_entity ?? '',
            notes: null,
            rate: '',
            vendor_rate: data.vendor_rate,
            quantity: data.quantity.toString(),
            unit: {
                measurement_unit_id: data.measurement_unit,
                measurement_unit_primary_name: '',
                abbreviation: '',
                measurement_unit_category: '',
                measurement_unit_value_type: '',
            },
            vendor: data.vendor_entity
                ? {
                      entity_id: data.vendor_entity,
                      entity_name: '',
                  }
                : null,
            vendor_currency: data.vendor_currency
                ? {
                      currency_code_id: data.vendor_currency,
                      currency_name: '',
                      currency_code_abbreviation: '',
                      currency_symbol: '',
                      currency_decimal: 2,
                  }
                : null,
            additional_costs: [],
            bomDetails: data.sub_bom.bom_items.map(
                recursivelyMapBackendBOMToFrontendBOMStructure2
            ),
            baseQuantity: '',
            latestPO: null,
            custom_fields: {
                section_list: [],
            },
            custom_fields_negotiate: {
                section_list: [],
            },
            enterprise_item_details: data.enterprise_item_details,
        };
    }
};

export const recursivelyMapBackendBOMToFrontendBOM: (
    data: IBOMDetailsResponse
) => ICostingBOM = (data) => {
    return {
        costType: 'BOM',
        uuid: null,
        projects: data.projects ?? [],
        rfqItemId: null,
        rfq_bid_item: null,
        bomId: data.enterprise_bom_id,
        itemId: data.enterprise_item.enterprise_item_id,
        notes: null,
        lead_time: null,
        lead_time_period: null,
        vendor_entity: data.vendor_entity ?? '',
        rate: '',
        conversion_rate: 1,
        quantity: data.quantity,
        unit: {
            measurement_unit_id: data.measurement_unit,
            measurement_unit_primary_name: '',
            abbreviation: '',
            measurement_unit_category: '',
            measurement_unit_value_type: '',
        },
        vendor: data.vendor_entity
            ? {
                  entity_id: data.vendor_entity,
                  entity_name: '',
              }
            : null,
        vendor_currency: data.vendor_currency
            ? {
                  currency_code_id: data.vendor_currency,
                  currency_name: '',
                  currency_code_abbreviation: '',
                  currency_symbol: '',
                  currency_decimal: 2,
              }
            : null,
        vendor_rate: data.vendor_rate,
        additional_costs: [],
        bomDetails: data.bom_items.map(
            recursivelyMapBackendBOMToFrontendBOMStructure2
        ),
        baseQuantity: '',
        latestPO: null,
        custom_fields: {
            section_list: [],
        },
        custom_fields_negotiate: {
            section_list: [],
        },
        enterprise_item_details: data.enterprise_item_details,
        rfq_event_details: null,
    };
};

// This function converts frontend costing item to backend payload structure
export const BOMBackendPayloadMapperFunction: (
    data: ICostingItem | ICostingBOM,
    setLeadtimeValue?: boolean
) => IBOMCostingBackendStructure = (data, setLeadtimeValue = true) => {
    // .map((item) => ({
    //         cost_type: item.costType,
    //         cost_name: item.costName,
    //         additional_cost_id: item.additional_cost_id,
    //         currency_id: null,
    //         allocation_type: item.allocation_type,
    //         cost_value: item.value.toString(),
    //         conversion_rate : item.
    //     }))
    return {
        rfq_event_item: data.rfqItemId,
        rfq_bid_item: data.rfq_bid_item?.rfq_bid_item_id,
        enterprise_item: data.itemId,
        enterprise_bom: data.costType === 'BOM' ? data.bomId : null,
        projects: data.projects.map((project) => project.project_id),
        lead_time: Boolean(data.lead_time)
            ? data.lead_time
            : setLeadtimeValue && !Boolean(data.lead_time_period)
            ? COSTING_DEFAULT_LEAD_TIME
            : null,
        lead_time_period: Boolean(data.lead_time_period)
            ? data.lead_time_period
            : setLeadtimeValue
            ? COSTING_DEFAULT_LEAD_TIME_PERIOD
            : null,
        costing_sheet_item_boms:
            data.costType === 'BOM'
                ? data.bomDetails.map((item) =>
                      BOMBackendPayloadMapperFunction(item, setLeadtimeValue)
                  )
                : [],
        quantity: (data.quantity as any) || '0',
        rate: (data.rate as any) || '0',
        notes: data.notes,
        measurement_unit_details: data.unit,
        measurement_unit: data.unit?.measurement_unit_id || null,
        additional_costs: data.additional_costs.map((cost) => ({
            ...cost,
            formula: cost.formula
                ? {
                      formula_string: null,
                      ...cost.formula,
                  }
                : null,
            currency_id: null,
            cost_value: +cost.cost_value.toString(),
            // Temp until cost currency is done
            cost_currency_id: cost.cost_currency_id ?? null,
            source_currency_id: cost.source_currency_id,
        })),
        vendor_rate: data.vendor_rate,
        costing_sheet_item_id: data.uuid,
        custom_fields: data.custom_fields,
        custom_fields_negotiate: data.custom_fields_negotiate,
        enterprise_item_details: data.enterprise_item_details,
        latest_po_item: data.latestPO,
        enterprise_bom_details: null,
        vendor_entity: data.vendor_entity || null,
        vendor_entity_details: null,
        vendor_currency: data.vendor_currency?.currency_code_id || null,
        vendor_currency_details: null,
        conversion_rate: data.conversion_rate,
    } as IBOMCostingBackendStructure;
};

// This function converts backend bom data to frontend costing item structure
export const BOMBackendToFrontEndMapperFunction: (
    data: ICostingCalculatorApiResponse,
    type: 'FETCH' | 'CREATE'
) => ICostingItem | ICostingBOM = (data, type) => {
    // .map((item) => ({
    //             costType: item.cost_type,
    //             costName: item.cost_name,
    //             allocation_type: item.allocation_type,
    //             value: item.cost_value,
    //             additional_cost_id : item.additional_cost_id,
    //             conversion_rate :

    //         }))
    if (data.enterprise_bom) {
        return {
            costType: 'BOM',
            additional_costs: data.additional_costs.map((cost) => ({
                ...cost,
                cost_value: +cost.cost_value,
            })),
            rfqItemId: data.rfq_event_item_id,
            lead_time: data.procurement_information?.lead_time ?? null,
            lead_time_period:
                data.procurement_information?.lead_time_period ?? null,
            rfq_bid_item: data.rfq_bid_item_details?.rfq_bid_item_id,
            bomId: data.enterprise_bom,
            projects: data.projects,
            itemId: data.enterprise_item_details?.enterprise_item_id,
            notes: data.notes,
            quantity: +data.quantity === 0 ? '' : data.quantity.toString(),
            rate:
                type === 'CREATE'
                    ? data.latest_po_item !== null
                        ? data.latest_po_item.price
                        : data.rate
                    : +data.rate === 0
                    ? data.rate
                    : data.rate.toString(),
            vendor_rate:
                type === 'CREATE'
                    ? data.latest_po_item !== null
                        ? data.latest_po_item.price
                        : data.vendor_rate
                    : +data.vendor_rate === 0
                    ? +data.vendor_rate
                    : data.vendor_rate,
            unit: data.latest_po_item
                ? {
                      measurement_unit_id:
                          data.latest_po_item.measurement_unit_id,
                      measurement_unit_primary_name:
                          data.latest_po_item.measurement_unit_primary_name,
                  }
                : data.measurement_unit_details,
            vendor: data.vendor_entity_details,
            vendor_currency: data.vendor_currency_details?.entry_id,
            uuid: data.costing_sheet_item_id,
            rfq_event_details: {
                custom_event_id: '',
                event_id: '',
                event_name: '',
                rfq_entry_id: '',
            },
            // bomDetails: data.costing_sheet_item_boms.map((data) =>
            //     BOMBackendToFrontEndMapperFunction(data, type)
            // ),

            baseQuantity:
                data.enterprise_bom_details?.base_quantity.toString()!,
            latestPO: data.latest_po_item,
            custom_fields: data.custom_fields,
            custom_fields_negotiate: data.custom_fields_negotiate,
            enterprise_item_details: data.enterprise_item_details,
            conversion_rate: data.conversion_rate,
            vendor_entity: data.vendor_entity_details?.entity_id,
        } as unknown as ICostingBOM;
    } else {
        return {
            costType: 'ITEM',
            rfqItemId: data.rfq_event_item_id,
            rfq_event_details: data.rfq_event_details,
            projects: data.projects,
            rfq_bid_item: data.rfq_bid_item_details,
            lead_time: Boolean(data.procurement_information?.lead_time)
                ? data.procurement_information?.lead_time
                : !Boolean(data.procurement_information?.lead_time_period)
                ? COSTING_DEFAULT_LEAD_TIME
                : null,
            lead_time_period:
                data.procurement_information?.lead_time_period ??
                COSTING_DEFAULT_LEAD_TIME_PERIOD,
            // conversion_rate :
            itemId: data.enterprise_item_details,
            conversion_rate: data.conversion_rate,
            notes: data.notes,
            quantity: +data.quantity === 0 ? '' : data.quantity.toString(),
            rate:
                type === 'CREATE'
                    ? data.latest_po_item === null
                        ? data.rate
                        : data.latest_po_item.price
                    : +data.rate === 0
                    ? data.rate
                    : data.rate.toString(),
            vendor_rate:
                type === 'CREATE'
                    ? data.latest_po_item === null
                        ? data.vendor_rate
                        : data.latest_po_item.price
                    : +data.vendor_rate === 0
                    ? +data.vendor_rate
                    : +data.vendor_rate,
            unit: data.latest_po_item
                ? {
                      measurement_unit_id:
                          data.latest_po_item.measurement_unit_id,
                      measurement_unit_primary_name:
                          data.latest_po_item.measurement_unit_primary_name,
                  }
                : data.measurement_unit_details,
            uuid: data.costing_sheet_item_id,

            vendor: data.vendor_entity_details,
            vendor_entity: data.vendor_entity_details?.entity_id,
            vendor_currency: data.vendor_currency_details?.entry_id,
            // additional_costs: data.additional_costs.map((cost) => {
            //     const isCostAutofilled = Boolean(cost.source_currency_id)
            //     if (isCostAutofilled) {
            //         return {
            //             ...cost
            //         }
            //     }
            //     return cost
            // }),
            additional_costs: data.additional_costs.map((cost) => ({
                ...cost,
                cost_value:
                    cost.cost_value || typeof cost.cost_value === 'number'
                        ? +cost.cost_value.toString()
                        : '0',
            })),
            latestPO: data.latest_po_item,
            custom_fields: data.custom_fields,
            custom_fields_negotiate: data.custom_fields_negotiate,
            enterprise_item_details: data.enterprise_item_details,
        } as unknown as ICostingItem;
        // .map((item) => ({
        //         costType: item.cost_type,
        //         costName: item.cost_name,
        //         additional_cost_id: item.additional_cost_id,
        //         allocation_type: item.allocation_type,
        //         value: item.cost_value,
        //     })),
    }
};

export const recursivelyRecalculateBomValues = (
    orgData: ICostingItem | ICostingBOM,
    view: 'SIMPLE' | 'ADVANCED'
) => {
    const data = cloneDeep(orgData);
    if (data.costType === 'ITEM') {
        return data;
    } else {
        const children = data.bomDetails.map((child) => {
            return recursivelyRecalculateBomValues(child, view);
        });
        const bomRate = +children.reduce((a, b) => {
            if (view === 'ADVANCED') {
                const childTotal = calculateTotalForAnItem(
                    // b.additional_costs.map((cost) => ({
                    //     costName: cost.cost_name,
                    //     costType: cost.cost_type,
                    //     value: cost.cost_value?.toString(),
                    //     allocation_type: cost.allocation_type,
                    //     cost_source: CostSourceEnum.ITEM_MASTER,
                    //     additional_cost_id: cost.additional_cost_id,
                    // })),
                    b.additional_costs,
                    +(b?.rate || 0),
                    +(b?.quantity || 0)
                ).total;
                return +a + +childTotal;
            } else {
                return +a + +(b?.rate || 0) * +(b?.quantity || 0);
            }
        }, 0);
        data.rate = (+(bomRate / +data.baseQuantity).toFixed(2)).toString();
        if (+data.rate === 0) data.rate = '';
        data.bomDetails = children;
        return { ...data };
    }
};

// recursive function to update a costing item data
export const recursivelyUpdateCostingItemData: (
    data: ICostingItem | ICostingBOM,
    payload:
        | IHandleCostingDataChangeQuantity
        | IHandleCostingDataChangeUnit
        | IHandleCostingDataChangeRate
        | IHandleCostingDataChangeAdditionalItemCostingAdd
        | IHandleCostingDataChangeAdditionalItemCostingEdit
        | IHandleCostingDataChangeAdditionalItemCostingDelete
        | IHandleCostingDataNotes
) => ICostingItem | ICostingBOM = (data, payload) => {
    const updatedData = cloneDeep(data);
    if (payload.value.uuid === updatedData.uuid) {
        switch (payload.type) {
            case 'ITEM_QUANTITY_CHANGE':
                updatedData.quantity = payload.value.quantity.toString();
                break;
            case 'ITEM_UNIT_CHANGE':
                updatedData.unit = payload.value.unit;
                break;
            case 'ITEM_RATE_CHANGE':
                updatedData.rate = payload.value.rate.toString() || '';
                updatedData.vendor_rate = payload.value.vendor_rate;
                break;
            case 'NOTES':
                updatedData.notes = payload.value.notes;
                break;
            case 'ADD_ADDITIONAL_ITEM_COSTING':
                updatedData.additional_costs.push(
                    payload.value.addAdditionalItemCharges
                );
                break;
            case 'EDIT_ADDITIONAL_ITEM_COSTING':
                updatedData.additional_costs =
                    payload.value.editAdditionalItemCharges;
                break;
            case 'REMOVE_ADDITIONAL_ITEM_COSTING':
                updatedData.additional_costs.splice(
                    payload.value.childIndex,
                    1
                );
                break;
            default:
                break;
        }
        return updatedData;
    } else {
        if (updatedData.costType === 'BOM') {
            updatedData.bomDetails = updatedData.bomDetails.map((item) =>
                recursivelyUpdateCostingItemData(item, payload)
            );
        } else {
        }
        return updatedData;
        // return recursivelyUpdateCostingItemData(data, payload);
    }
};

// export const calculateTotalForAnItem = (
//     data: AdditionalFinalCostingDataInterface[],
//     rate: number | string,
//     quantity: number
// ) => {
//     const calculatedRates: number[] = [];
//     data.forEach((charge) => {
//         if (charge.cost_type === 'PERCENTAGE') {
//             calculatedRates.push((+(charge.cost_value || 0) / 100) * +rate);
//         } else {
//             if (charge.allocation_type === 'PER_UNIT') {
//                 // rate += charge.cost_value;
//                 calculatedRates.push(+(charge.cost_value || 0));
//             } else {
//                 // rate += charge.cost_value / quantity;
//                 +quantity > 0 &&
//                     calculatedRates.push(
//                         +(charge.cost_value || 0) / +(quantity || 0)
//                     );
//             }
//         }
//     });
//     const effectiveRate = +(
//         +rate + calculatedRates.reduce((a, b) => +a + +b, 0)
//     ).toFixed(10);
//     return {
//         effectiveRate,
//         total: +quantity * +effectiveRate,
//     };
// };

export const calculateTotalForAnItem = (
    data: AdditionalFinalCostingDataInterface[],
    rate: number | string,
    quantity: number
) => {
    const numericRate = +rate;
    const numericQuantity = +quantity;

    let rateSum = 0;

    for (let i = 0; i < data.length; i++) {
        const charge = data[i];
        const costValue = +charge.cost_value || 0;

        if (charge.cost_type === 'PERCENTAGE') {
            rateSum += (costValue / 100) * numericRate;
        } else if (charge.allocation_type === 'PER_UNIT') {
            rateSum += costValue;
        } else {
            if (numericQuantity > 0) {
                rateSum += costValue / numericQuantity;
            }
        }
    }

    const effectiveRate = numericRate + rateSum;
    const total = numericQuantity * effectiveRate;

    return {
        effectiveRate: parseFloat(effectiveRate.toFixed(10)),
        total: parseFloat(total.toFixed(10)),
    };
};

export const recursivelyCalculateTotalForABOM = (
    data: ICostingItem | ICostingBOM
): number => {
    const clonedData = cloneDeep(data);
    if (clonedData.costType === 'BOM') {
        const bomRateArr = clonedData.bomDetails.map((item) => {
            return recursivelyCalculateTotalForABOM(item);
        });
        const bomRateItemsTotal = bomRateArr.reduce((a, b) => a + b, 0);
        return bomRateItemsTotal * +clonedData.quantity;
    } else {
        return calculateTotalForAnItem(
            clonedData.additional_costs,
            clonedData.rate,
            +clonedData.quantity
        ).total;
    }
};

export const resetAllRatesToLatestPOPrice: (
    data: (ICostingBOM | ICostingItem)[]
) => (ICostingBOM | ICostingItem)[] = (data) => {
    data.forEach((item) => {
        if (item.costType === 'BOM') {
            item.bomDetails = resetAllRatesToLatestPOPrice(item.bomDetails);
        } else {
            if (+(item.latestPO?.price || 0)) {
                item.rate = item.latestPO?.price?.toString() || '';
            }
        }
    });
    return data;
};

export const convertQouteCalculatorAdditionalCostToRfqAdditionalCost: (
    costs: AdditionalFinalCostingDataInterface[]
) => IAdditionalCostsWithValue[] = (costs) => {
    const formattedCost = costs.map((cost) => {
        if (cost.cost_type === CostTypeEnum.ABSOLUTE_VALUE) {
            return {
                costName: cost.cost_name,
                costType: CostTypeEnum.ABSOLUTE_VALUE,
                allocationType:
                    cost.allocation_type === 'OVERALL_QUANTITY'
                        ? AllocationTypeEnum.OVERALL_QUANTITY
                        : AllocationTypeEnum.PER_UNIT ?? null,
                value: cost.cost_value,
                source_currency_currency_code_abbreviation:
                    cost.source_currency_currency_code_abbreviation ?? null,
                source_currency_currency_code_symbol:
                    cost.source_currency_currency_symbol ?? null,
                cost_source: cost.cost_source ?? null,
                source_currency_id: cost.source_currency_id ?? null,
                conversion_rate: cost.conversion_rate ?? null,
                source_value: cost.source_value ?? null,
                additional_cost_id: cost.additional_cost_id,
                formula: cost.formula
                    ? {
                          formula_string: cost.formula.formula_string ?? '',
                          operands: cost.formula.operands ?? [],
                      }
                    : null,
                is_calculated: cost.is_calculated,
            };
        } else {
            return {
                costName: cost.cost_name,
                costType: CostTypeEnum.PERCENTAGE,
                allocationType: null,
                value: cost.cost_value,
                conversion_rate: cost.conversion_rate ?? null,
                cost_source: cost.cost_source ?? null,
                source_currency_id: cost.source_currency_id ?? null,
                source_value: cost.source_value ?? null,
                additional_cost_id: cost.additional_cost_id,
                formula: cost.formula
                    ? {
                          formula_string: cost.formula.formula_string ?? '',
                          operands: cost.formula.operands ?? [],
                      }
                    : null,
                is_calculated: cost.is_calculated,
            };
        }
    });
    return formattedCost;
};

export const convertRfqAdditionalCostToQouteCalculatorAdditionalCost: (
    cost: IAdditionalCostsWithValue
) => AdditionalFinalCostingDataInterface = (cost) => {
    if (cost.costType === CostTypeEnum.PERCENTAGE) {
        return {
            additional_cost_id: cost.additional_cost_id,
            allocation_type: cost.allocationType,
            cost_name: cost.costName,
            cost_source: cost.cost_source ?? null,
            source_currency_currency_code_abbreviation:
                cost.source_currency_currency_code_abbreviation ?? null,
            source_currency_currency_symbol:
                cost.source_currency_currency_code_symbol ?? null,
            cost_type: CostTypeEnum.PERCENTAGE,
            cost_value: (+cost.value).toString(),
            additional_cost_linkage_id: null,
            source_currency_id: cost.source_currency_id,
            source_value: cost.source_value,
            is_calculated: cost.is_calculated ?? true,
            conversion_rate: cost.conversion_rate ?? null,
            formula: cost.formula
                ? {
                      formula_string: cost.formula.formula_string ?? '',
                      operands: cost.formula.operands ?? [],
                  }
                : null,
        };
    } else {
        return {
            additional_cost_id: cost.additional_cost_id,
            allocation_type: cost.allocationType,
            cost_name: cost.costName,
            additional_cost_linkage_id: null,
            source_currency_currency_code_abbreviation:
                cost.source_currency_currency_code_abbreviation ?? null,
            source_currency_currency_symbol:
                cost.source_currency_currency_code_symbol ?? null,
            cost_source: cost.cost_source ?? null,
            cost_type: CostTypeEnum.ABSOLUTE_VALUE,
            cost_value: (+cost.value).toString(),
            source_currency_id: cost.source_currency_id,
            source_value: cost.source_value,
            is_calculated: cost.is_calculated ?? true,
            conversion_rate: cost.conversion_rate ?? null,
            formula: cost.formula
                ? {
                      formula_string: cost.formula.formula_string ?? '',
                      operands: cost.formula.operands ?? [],
                  }
                : null,
        };
    }
};

export const convertRfqAdditionalCostToQouteCalculatorAdditionalCostWithCurrency: (
    cost: IAdditionalCostWithCurrencyAndValue
) => AdditionalFinalCostingDataWithCurrencyInterface = (cost) => {
    if (cost.costType === CostTypeEnum.PERCENTAGE) {
        return {
            additional_cost_id: cost.additional_cost_id,
            currency_id: cost.currency_id,
            // Temp till fixed
            cost_currency_id: cost.cost_currency_id ?? null,
            source_value: cost.source_value,
            source_currency_currency_code_abbreviation: null,
            source_currency_currency_code_symbol: null,
            source_currency_id: null,
            allocation_type: cost.allocationType,
            autofill: cost.autofill ?? false,
            costName: cost.costName,
            cost_source: cost.cost_source ?? null,
            costType: CostTypeEnum.PERCENTAGE,
            value: (+cost.value).toString(),
            is_calculated: cost.is_calculated ?? true,
            formula: cost.formula
                ? {
                      formula_string: cost.formula.formula_string ?? '',
                      operands: cost.formula.operands ?? [],
                  }
                : null,
        };
    } else {
        return {
            additional_cost_id: cost.additional_cost_id,
            allocation_type: cost.allocationType,
            currency_id: cost.currency_id,
            // Temp till fixed
            source_value: cost.source_value,
            source_currency_currency_code_abbreviation:
                cost.source_currency_currency_code_abbreviation,
            source_currency_currency_code_symbol:
                cost.source_currency_currency_code_symbol,
            source_currency_id: cost.source_currency_id,
            autofill: cost.autofill ?? false,
            cost_currency_id: cost.cost_currency_id ?? null,
            costName: cost.costName,
            cost_source: cost.cost_source ?? null,
            costType: CostTypeEnum.ABSOLUTE_VALUE,
            value: (+cost.value).toString(),
            is_calculated: cost.is_calculated ?? true,
            formula: cost.formula
                ? {
                      formula_string: cost.formula.formula_string ?? '',
                      operands: cost.formula.operands ?? [],
                  }
                : null,
        };
    }
};

// export const convertRfqAdditionalCostToQouteCalculatorAdditionalCost: (
//     cost: IAdditionalCostsWithValue
// ) => AdditionalFinalCostingDataInterface = (cost) => {
//     if (cost.costType === CostTypeEnum.PERCENTAGE) {
//         return {
//             costName: cost.costName,
//             value: (+cost.value).toString(),
//             costType: 'PERCENTAGE',
//             additional_cost_id: cost.additional_cost_id,
//             allocation_type: cost.allocationType,
//         };
//     } else {
//         return {
//             costName: cost.costName,
//             value: (+cost.value).toString(),
//             costType: 'FLAT_RATE',
//             additional_cost_id: cost.additional_cost_id,
//             allocation_type:
//                 cost.allocationType === AllocationTypeEnum.OVERALL_QUANTITY
//                     ? 'OVERALL_QUANTITY'
//                     : 'PER_UNIT',
//             // costAllocation:
//             //     cost.allocationType === AllocationTypeEnum.OVERALL_QUANTITY
//             //         ? 'OVERALL_QUANTITY'
//             //         : 'PER_UNIT',
//         };
//     }
// };

export const getCostingItemsFromHistory = (
    currentHistoryData: ICostingHistoryById
) => {
    let historyItems: ICostingCalculatorApiResponse[] = (
        currentHistoryData.costing_sheet_items ?? []
    ).map((historyItem) => {
        return {
            delivery_schedule: [],
            additional_costs: historyItem.additional_costs,
            conversion_rate: historyItem.conversion_rate,
            costing_sheet: '',
            costing_sheet_item_boms: [],
            costing_sheet_item_id: historyItem.costing_sheet_item_id,
            created_by_user: historyItem.created_by_user,
            created_datetime: historyItem.created_datetime,
            custom_fields: historyItem.custom_fields,
            custom_fields_negotiate: historyItem.custom_fields_negotiate,
            deleted_by_user: historyItem.deleted_by_user,
            deleted_datetime: historyItem.deleted_datetime,
            enterprise_bom: null,

            enterprise_bom_details: historyItem.enterprise_bom_details,
            enterprise_item_details: historyItem.enterprise_item_details,
            latest_po_item: {
                measurement_unit_id: '',
                measurement_unit_primary_name: '',
                price: '',
            },
            measurement_unit: '',
            measurement_unit_details: historyItem.measurement_unit_details,
            modified_by_user: historyItem.modified_by_user,
            modified_datetime: '',
            notes: historyItem.notes,
            parent_costing_sheet_item_id: historyItem.parent_costing_sheet_item,
            procurement_information: historyItem.procurement_information,
            projects: historyItem.projects,
            quantity: historyItem.quantity?.toString(),
            rate: historyItem.rate?.toString(),
            rfq_bid_item_details: historyItem.rfq_bid_item_details,
            rfq_event_details: historyItem.rfq_event_details,
            rfq_event_item_id: historyItem.rfq_event_item,
            vendor_currency_details: historyItem.vendor_currency_details,
            vendor_entity_details: historyItem.vendor_entity_details,
            vendor_rate: historyItem.vendor_rate,
        };
    });
    // currentHistoryData.costing_sheet_items.forEach((historyItem) => {
    //     const itemIndexInHistory = costingData.findIndex(
    //         (item) => historyItem.costing_sheet_item_id === item.uuid
    //     );
    //     const costingItem = costingData[itemIndexInHistory];
    //     if (itemIndexInHistory !== -1) {
    //         const itemData: ICostingItem = {
    //             ...costingItem,
    //             additional_costs: historyItem.additional_costs,
    //             rate: historyItem.rate?.toString(),
    //             vendor_rate: historyItem.vendor_rate,
    //             custom_fields: historyItem.custom_fields,
    //             itemId: historyItem.enterprise_item,
    //             custom_fields_negotiate: historyItem.custom_fields_negotiate,
    //             conversion_rate: historyItem.conversion_rate,
    //             notes: historyItem.notes,
    //             lead_time:
    //                 historyItem.procurement_information.lead_time ?? null,
    //             lead_time_period:
    //                 historyItem.procurement_information.lead_time_period?.toString() ??
    //                 null,
    //             quantity: historyItem.quantity?.toString(),
    //             costType: 'ITEM',
    //         };
    //         historyItems.push(itemData);
    //     } else {
    //         const itemPreviouslyDeleted: ICostingItem = {
    //             additional_costs: historyItem.additional_costs,
    //             rate: historyItem.rate?.toString(),
    //             vendor_rate: historyItem.vendor_rate,
    //             conversion_rate: historyItem.conversion_rate,
    //             costType: 'ITEM',
    //             custom_fields: historyItem.custom_fields,
    //             custom_fields_negotiate: historyItem.custom_fields_negotiate,
    //             enterprise_item_details: historyItem.enterprise_item_details,
    //             itemId: historyItem.enterprise_item,
    //             uuid: historyItem.costing_sheet_item_id,
    //             latestPO: null,
    //             lead_time: historyItem.procurement_information.lead_time,
    //             lead_time_period:
    //                 historyItem.procurement_information.lead_time_period,
    //             notes: historyItem.notes,
    //             projects: [],
    //             quantity: historyItem.quantity?.toString(),
    //             rfq_bid_item: historyItem.rfq_bid_item,
    //             rfq_event_details: null,
    //             rfqItemId: null,
    //             vendor: null,
    //             unit: historyItem.measurement_unit_details,
    //             vendor_currency: null,
    //             vendor_entity: historyItem.vendor_entity,
    //         };
    //         historyItems.push(itemPreviouslyDeleted);
    //     }
    // });
    return historyItems;
};
