import React from 'react';
import { toast } from 'react-toastify';
import { IVLNItemCatalogue } from '../../VendorLinkedIn/Interfaces/VendorLNInterfaces';
import {
    deleteItemCatalogueApi,
    updateItemCatalogueApi,
} from '../../VendorLinkedIn/Services/VendorLinkedIn.service';
import { VendorProfileIdContext } from '../Containers/SellerProfileItemCatalogue';

export const useSellerProfileItemCatalogue = (
    item_catalogue_id: string,
    itemsCatalogueData: IVLNItemCatalogue
) => {
    const { vendorProfileId, removeItemFromList, updateItemCatalogueList } =
        React.useContext(VendorProfileIdContext);
    const deleteItem = async () => {
        try {
            await deleteItemCatalogueApi(vendorProfileId, item_catalogue_id);
            removeItemFromList(item_catalogue_id);
            toast.warning('Item deleted');
        } catch (error) {
            toast.error('Error deleting item');
        }
    };
    const handleDelete = () => {
        deleteItem();
    };

    const updateItem = async (data: {
        name: string;
        price: string;
        imageUrl: string;
        measurementUnitUuid: string;
        currencyCodeUuid: string;
    }) => {
        try {
            await updateItemCatalogueApi(
                vendorProfileId,
                item_catalogue_id,
                itemsCatalogueData,
                data
            );
            //
            updateItemCatalogueList({
                ...itemsCatalogueData,
                pricing_information: [
                    ...(+data.price > 0
                        ? [
                              {
                                  price: data.price,
                                  is_visible: true,
                                  currency_name: '',
                                  currency_symbol: '',
                                  currency_code_id: data.currencyCodeUuid,
                                  measurement_unit_id: data.measurementUnitUuid,
                                  measurement_unit_category: '',
                                  currency_code_abbreviation: '',
                                  measurement_unit_value_type: '',
                                  measurement_unit_primary_name: '',
                              },
                          ]
                        : []),
                ],
            });
        } catch (error) {}
    };

    const handleUpdate = (data: {
        name: string;
        price: string;
        imageUrl: string;
        measurementUnitUuid: string;
        currencyCodeUuid: string;
    }) => {
        updateItem(data);
    };

    return {
        handleDelete,
        handleUpdate,
    };
};
