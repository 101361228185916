import React, { useEffect, useMemo } from 'react';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import {
    Box,
    CircularProgress,
    DialogActions,
    DialogContent,
} from '@mui/material';
import { FWInput } from '../../Common/FWInput';
import { FWButton } from '../../Common/FWButton';
import { cloneDeep } from 'lodash';
import { IBuyerItemList } from '../../Events/Interfaces/Buyer/RFQ.model';
import { FWTypography } from '../../Common/FWTypograhy';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import TagsDisplay from '../../Global/Components/TagsDisplay';
import { FWCombinedInput } from '../../Common/FWCombinedInput';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { useItemCataloguePopup } from '../Hooks/useItemCataloguePopup';
import { IMeasurementUnitDetails } from '../../Models/MeasurementUnit.model';
import FWAutocomplete from '../../Common/FWAutocomplete';

const ItemCataloguePopup = ({
    open,
    onClose,
    initialData,
    onSave,
    selectedItemData,
}: {
    open: boolean;
    onClose: () => void;
    initialData: {
        name: string;
        price: string;
        imageUrl: string;
        measurementUnitUuid: string;
        currencyCodeUuid: string;
    };
    onSave: (data: {
        name: string;
        price: string;
        imageUrl: string;
        measurementUnitUuid: string;
        currencyCodeUuid: string;
    }) => void;
    selectedItemData: IBuyerItemList | null;
}) => {
    const [data, setData] = React.useState(cloneDeep(initialData));

    useEffect(() => {
        setData(cloneDeep(initialData));
    }, [initialData]);

    const handleClose = () => {
        onClose();
        setData(cloneDeep(initialData));
    };

    const { units, currencies, hookState } = useItemCataloguePopup();

    const pricingDataErrors: {
        [key: string]: boolean;
    } = useMemo(() => {
        const errors: { [key: string]: boolean } = {};
        if (+data.price > 0) {
            if (!data.measurementUnitUuid) {
                errors['measurementUnitUuid'] = true;
            }
            if (!data.currencyCodeUuid) {
                errors['currencyCodeUuid'] = true;
            }
        } else if (data.measurementUnitUuid) {
            if (!data.currencyCodeUuid) {
                errors['currencyCodeUuid'] = true;
            }
            if (+data.price === 0) {
                errors['price'] = true;
            }
        } else if (data.currencyCodeUuid) {
            if (!data.measurementUnitUuid) {
                errors['measurementUnitUuid'] = true;
            }
            if (+data.price === 0) {
                errors['price'] = true;
            }
        }

        return errors;
    }, [data.currencyCodeUuid, data.measurementUnitUuid, data.price]);

    return (
        <FWPopupWithChildren
            title={'Edit item data'}
            open={open}
            handleClose={handleClose}
        >
            <DialogContent>
                {selectedItemData && (
                    <Box>
                        <Box>
                            <FWTypography color={'text.secondary'}>
                                Item
                            </FWTypography>
                            <FWTypography>
                                {`${
                                    selectedItemData?.enterprise_item?.name ||
                                    '-'
                                }${
                                    selectedItemData?.enterprise_item.attributes
                                        .attributes.length > 0
                                        ? ` | ${selectedItemData?.enterprise_item.attributes.attributes.map(
                                              (val) =>
                                                  `${
                                                      val.attribute_name
                                                  }: ${val.attribute_value.join(
                                                      '/'
                                                  )}`
                                          )}`
                                        : ''
                                }`}
                            </FWTypography>
                        </Box>
                        <Box paddingTop={2}>
                            <FWTypography color={'text.secondary'}>
                                Description
                            </FWTypography>
                            <FWTypography>
                                {`${
                                    selectedItemData?.enterprise_item
                                        ?.description || '-'
                                }`}
                            </FWTypography>
                        </Box>
                        <Box paddingTop={2}>
                            <FWTypography color={'text.secondary'}>
                                Tags
                            </FWTypography>
                            {selectedItemData?.enterprise_item?.tags?.length >
                            0 ? (
                                <TagsDisplay
                                    tags={
                                        selectedItemData?.enterprise_item?.tags
                                    }
                                />
                            ) : (
                                '-'
                            )}
                        </Box>
                        &nbsp;
                    </Box>
                )}
                <FWInput
                    label="Alternate name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    error={data.name.trim() === ''}
                />
                &nbsp;
                <FWCombinedInput
                    label="Rate"
                    selectFieldMenuItems={currencies.map((currency, idx) => (
                        <FWMenuItem value={currency.entry_id} key={idx}>
                            {`${currency.currency_name}(${currency.currency_code_abbreviation})(${currency.currency_symbol})`}
                        </FWMenuItem>
                    ))}
                    selectFieldOnChange={(e) => {
                        setData({ ...data, currencyCodeUuid: e.target.value });
                    }}
                    selectFieldValue={data.currencyCodeUuid}
                    textFieldOnChange={(e) => {
                        setData({ ...data, price: e.target.value });
                    }}
                    textFieldValue={data.price}
                    isDropLeft={false}
                    allowOnly="DECIMAL_NUMBER"
                    error={
                        !!pricingDataErrors.price ||
                        !!pricingDataErrors.currencyCodeUuid
                    }
                    helperText={
                        !!pricingDataErrors.price ||
                        !!pricingDataErrors.currencyCodeUuid
                            ? 'Price and currency are required'
                            : ''
                    }
                />
                &nbsp;
                <FWAutocomplete
                    options={units}
                    renderInput={(params) => (
                        <FWInput
                            {...params}
                            label="Measurement unit"
                            error={!!pricingDataErrors.measurementUnitUuid}
                            helper={{
                                text: pricingDataErrors.measurementUnitUuid
                                    ? 'Measurement unit is required'
                                    : '',
                            }}
                        />
                    )}
                    getOptionLabel={(option: IMeasurementUnitDetails) =>
                        option.measurement_unit_primary_name
                    }
                    onChange={(e, value: IMeasurementUnitDetails) => {
                        setData({
                            ...data,
                            measurementUnitUuid: value
                                ? value.measurement_unit_id
                                : value,
                        });
                    }}
                    value={
                        units.find(
                            (val) =>
                                val.measurement_unit_id ===
                                data.measurementUnitUuid
                        ) || null
                    }
                    // isOptionEqualToValue={(option, value) =>
                    //     option.measurement_unit_id === value.measurement_unit_id
                    // }
                />
                &nbsp;
                <FWInput
                    label="Image URL"
                    value={data.imageUrl}
                    onChange={(e) =>
                        setData({ ...data, imageUrl: e.target.value })
                    }
                />
                &nbsp;
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '20px 24px',
                }}
            >
                <FWButton
                    variant="outlined"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Cancel
                </FWButton>
                <FWButton
                    variant="contained"
                    onClick={() => {
                        onSave(data);
                    }}
                    disabled={
                        !(hookState.state = HookStateValue.READY) ||
                        data.name.trim() === '' ||
                        Object.keys(pricingDataErrors).length > 0
                    }
                >
                    {!(hookState.state = HookStateValue.READY) ? (
                        <CircularProgress />
                    ) : (
                        'Save'
                    )}
                </FWButton>
            </DialogActions>
        </FWPopupWithChildren>
    );
};

export default ItemCataloguePopup;
