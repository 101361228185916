import {
    IQUoteItemDeliverySchedule,
    IQUoteItemDeliverySchedulePayload,
} from '../Interfaces/CostingInterfaces.interface.';
import { newQuoteSheetItemPayload } from '../Interfaces/QuoteItem.interface';

export const bomIndexToLabel = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];

export const blankQuoteItemDSForPayload: IQUoteItemDeliverySchedulePayload = {
    bom_item_id: null,
    cost_centre_id: null,
    customer_entity_id: null,
    delivery_date: null,
    delivery_schedule_item_id: null,
    general_ledger_id: null,
    parent_delivery_schedule_item_id: null,
    project_id: null,
    quantity: 0,
    requisition_item_id: null,
};

export const blankQuoteItemDS: IQUoteItemDeliverySchedule = {
    bom_item: null,
    cost_centre_id: null,
    customer_entity: null,
    delivery_date: null,
    general_ledger_id: null,
    project_id: null,
    cost_centre_name: '',
    delivery_schedule_item_id: null,
    general_ledger_code: '',
    project_code: '',
    quantity: 0,
};

export const blankQuoteItem: newQuoteSheetItemPayload = {
    additional_costs: [],
    delivery_schedule: [blankQuoteItemDSForPayload],
    measurement_unit_id: '',
    enterprise_item_id: '',
    bom_item_id: null,
    lead_time: 2,
    lead_time_period: 'WEEKS',
    custom_fields: {
        section_list: [],
    },
    quantity: 0,
    rate: 0,
};
