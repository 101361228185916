import React, { useMemo } from 'react';
import { IVLNItemCatalogue } from '../Interfaces/VendorLNInterfaces';

export const useVLNItemCatalogue = (
    itemsCatalogueList: IVLNItemCatalogue[]
) => {
    const [selectedFilter, setSelectedFilter] = React.useState<string>('All');
    const [searchText, setSearchText] = React.useState('');

    const filteredItemsCatalogueList = useMemo(
        () =>
            itemsCatalogueList.filter(
                (item) =>
                    item.name
                        .trim()
                        .toLowerCase()
                        .includes(searchText.trim().toLowerCase()) &&
                    (selectedFilter === 'All'
                        ? true
                        : item.tags.includes(selectedFilter))
            ),
        [itemsCatalogueList, searchText, selectedFilter]
    );

    const handleFilterChange = (filter: string) => {
        setSelectedFilter(filter);
    };

    const tagsWithCount: { [key: string]: number } = useMemo(() => {
        let tagsWithCount: { [key: string]: number } = {};
        itemsCatalogueList.forEach((item) => {
            item.tags.forEach((tag) => {
                if (tagsWithCount[tag]) {
                    tagsWithCount[tag] += 1;
                    return;
                }
                tagsWithCount[tag] = 1;
            });
        });
        tagsWithCount.All = itemsCatalogueList.length;
        return tagsWithCount;
    }, [itemsCatalogueList]);

    return {
        tagsWithCount,
        setSearchText,
        selectedFilter,
        handleFilterChange,
        filteredItemsCatalogueList,
    };
};
