import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { AuthContext } from '../../Contexts/AuthContext';
import { downloadFile } from '../../Global/Services/FileStorageService';
import { VerificationStatus } from '../../Redux/Slices/VerificationSlice';
import { IInvoiceForPo } from '../Interfaces/Invoice.model';
import {
    IPurchaseOrder,
    PurchaseOrderStatus,
    PurchaseOrderType,
} from '../Interfaces/PO.model';
import {
    getAllInvoicesForPurchaseOrder,
    getEntityDetails,
    getPreviewEntityDetails,
    getPreviewPurchaseOrder,
    getPurchaseOrder,
} from '../Services/PO.service';

export const usePurchaseOrder = (
    purchase_order_ID: string,
    enterprise_type: 'BUYER' | 'SELLER',
    isPreview: boolean = false
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [purchaseOrderData, setPurchaseOrderData] =
        React.useState<IPurchaseOrder>(blankPurchaseOrderData);
    const [allInvoicesForPO, setAllInvoicesForPO] = useState<
        IInvoiceForPo[] | null
    >(null);
    const { checkPermission } = React.useContext(AuthContext);
    const [templateId, setTemplateId] = React.useState<string | null>(null);
    const [entityId, setEntityId] = React.useState<string | null>(null);
    const [sellerEntityId, setSellerEntityId] = React.useState<string | null>(
        null
    );

    const updatePurchaseOrderData = React.useCallback(
        (newPurchaseOrderData: IPurchaseOrder) => {
            setPurchaseOrderData(newPurchaseOrderData);
        },
        []
    );

    const fetchPurchaseOrderData = React.useCallback(async () => {
        if (purchase_order_ID !== null && purchase_order_ID.trim().length > 0) {
            updateHookState(HookStateValue.LOADING);
            try {
                let fetchedPurchaseOrderDetails = isPreview
                    ? await getPreviewPurchaseOrder(purchase_order_ID)
                    : await getPurchaseOrder(
                          purchase_order_ID,
                          enterprise_type
                      );

                console.log(
                    'fetchedPurchaseOrderDetails',
                    fetchedPurchaseOrderDetails
                );

                let vendorEntityDetails = isPreview
                    ? await getPreviewEntityDetails(
                          fetchedPurchaseOrderDetails.seller_entity
                      )
                    : await getEntityDetails(
                          fetchedPurchaseOrderDetails.seller_entity
                      );

                let buyerEntityDetails = isPreview
                    ? await getPreviewEntityDetails(
                          fetchedPurchaseOrderDetails.buyer_entity
                      )
                    : await getEntityDetails(
                          fetchedPurchaseOrderDetails.buyer_entity
                      );

                if (buyerEntityDetails?.entity_logo?.length > 0) {
                    const newLogo = await downloadFile(
                        buyerEntityDetails.entity_logo.slice(-1)[0]
                            .attachment_id
                    );
                    fetchedPurchaseOrderDetails.buyer_information.buyer_entity_logo =
                        newLogo;
                }

                if (isPreview) {
                    fetchedPurchaseOrderDetails.buyer_information.buyer_entity_logo =
                        buyerEntityDetails.entity_logo_urls
                            ? buyerEntityDetails.entity_logo_urls[0]
                            : '';
                }

                if (vendorEntityDetails) {
                    fetchedPurchaseOrderDetails.seller_information.is_virtual =
                        vendorEntityDetails.is_virtual;
                    fetchedPurchaseOrderDetails.seller_information.verification_status =
                        vendorEntityDetails.verification_status;
                }
                setTemplateId(
                    fetchedPurchaseOrderDetails.event_additional_details
                        ?.template_id ?? 'DEFAULT'
                );
                setEntityId(fetchedPurchaseOrderDetails.buyer_entity);
                setSellerEntityId(fetchedPurchaseOrderDetails.seller_entity);
                updatePurchaseOrderData(fetchedPurchaseOrderDetails);
                updateHookState(HookStateValue.READY);
            } catch (err) {
                console.error(err);
                toast.error('Failed to retrieve purchase order details');
                updateHookState(HookStateValue.ERROR);
            }
        }
    }, [
        enterprise_type,
        isPreview,
        purchase_order_ID,
        updateHookState,
        updatePurchaseOrderData,
    ]);

    React.useEffect(() => {
        if (
            purchaseOrderData.purchase_order_id !== '' &&
            (purchaseOrderData.status === 'ONGOING' ||
                purchaseOrderData.status === 'COMPLETED')
        ) {
            let all_invoice_promise: Promise<IInvoiceForPo[]>;
            if (
                checkPermission(
                    'SELLER',
                    'INVOICE',
                    'VIEW',
                    purchaseOrderData.seller_entity
                )
            ) {
                all_invoice_promise = getAllInvoicesForPurchaseOrder(
                    purchaseOrderData.purchase_order_id,
                    'SELLER'
                );
            } else {
                all_invoice_promise = Promise.resolve([]);
            }
            all_invoice_promise
                .then((all_invoices_for_po) => {
                    setAllInvoicesForPO(
                        all_invoices_for_po
                            .filter(
                                (invoice) =>
                                    (invoice.status === 'ISSUED' ||
                                        invoice.status === 'ONGOING' ||
                                        invoice.status === 'COMPLETED' ||
                                        invoice.status === 'GOODS_RECEIVED' ||
                                        invoice.status === 'TERMINATED') &&
                                    invoice.invoice_type !==
                                        'SELLER_PREPAYMENT_INVOICE'
                            )
                            .sort((a, b) =>
                                moment(a.invoice_date) > moment(b.invoice_date)
                                    ? -1
                                    : moment(b.invoice_date) >
                                      moment(a.invoice_date)
                                    ? -1
                                    : 0
                            )
                    );
                })
                .catch((err) => {
                    setAllInvoicesForPO(null);
                });
        }
    }, [
        checkPermission,
        purchaseOrderData.purchase_order_id,
        purchaseOrderData.seller_entity,
        purchaseOrderData.status,
    ]);

    React.useEffect(() => {
        fetchPurchaseOrderData();
    }, [fetchPurchaseOrderData]);

    return {
        hookState,
        purchaseOrderData,
        updatePurchaseOrderData,
        allInvoicesForPO,
        templateId,
        entityId,
        sellerEntityId,
    };
};

const blankPurchaseOrderData: IPurchaseOrder = {
    purchase_order_id: '',
    custom_purchase_order_id: '',
    status: PurchaseOrderStatus.COMPLETED,
    purchase_order_type: PurchaseOrderType.FROM_EVENT,
    buyer_enterprise: '',
    buyer_entity: '',
    buyer_information: {
        billing_address: '',
        shipping_address: '',
        buyer_entity_name: '',
        billing_address_id: '',
        shipping_address_id: '',
        buyer_entity_address: '',
        buyer_identification_info: [],
        buyer_entity_logo: '',
    },
    buyer_contact_list: [],
    seller_enterprise: '',
    seller_entity: '',
    seller_information: {
        seller_address_id: '',
        seller_entity_name: '',
        seller_entity_address: '',
        seller_identification_info: [],
        verification_status: VerificationStatus.UNVERIFIED,
    },
    seller_contact_list: [],
    hold_information: null,
    termination_information: null,
    notes: '',
    seller_notes: '',
    event: '',
    event_name: '',
    additional_information: {
        last_action_information: {
            notes: '',
            user_id: '',
        },
        purchase_order_items_count: '',
    },
    terms_and_conditions: {
        data: '',
        name: '',
        terms_and_conditions_id: '',
    },
    pricing_information: {
        total: '',
        shipping: '',
        subtotal: '',
        currency_name: '',
        currency_symbol: '',
        currency_code_id: '',
        taxes_percentage: '',
        discount_percentage: '',
        currency_code_abbreviation: '',
    },
    submission_datetime: null,
    acceptance_datetime: null,
    final_buyer_approval: null,
    final_seller_approval: null,
    created_by_user_name: '',
    created_by_user: '',
    purchase_order_items: [],
    custom_fields: {
        section_list: [],
    },
    prepayment_percentage: null,
    deliverables_payment_terms: null,
    payment_type: null,
    payment_terms: null,
    cost_centre_id: null,
    custom_additional_information: null,
    gr_tolerance: null,
    gl_id: null,
    project_id: null,
    incoterm_id: null,
    lead_time: null,
    lead_time_period: null,
    requisition_information: [],
};
