import {
    Box,
    Checkbox,
    Chip,
    FormControlLabel,
    Radio,
    RadioGroup,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import FWAutocomplete, {
    AutocompleteStyledPaper,
} from '../../Common/FWAutocomplete';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';

import { FWTypography } from '../../Common/FWTypograhy';

import { memo, useEffect, useRef, useState } from 'react';

import { FWCombinedInput } from '../../Common/FWCombinedInput';
import { FWInput } from '../../Common/FWInput';
import {
    AttachmentField,
    BooleanField,
    ChoiceField,
    CurrencyField,
    DateField,
    DateTimeField,
    DecimalField,
    IntegerField,
    TemplatesField,
    TextField,
} from '../FieldTypes.model';
import SingleDateTimeFieldRenderer from '../../ProjectGlCostCenter/components/SingleDateTimeFieldRenderer';

export const URL_IDENTIFICATION_REGEX =
    '((http|https)://)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';

export const checkIfValueIsLink = (value: string) => {
    const newLocal = URL_IDENTIFICATION_REGEX;
    const regex = new RegExp(newLocal);

    if (!(!isEmpty(value?.trim()) && regex.test(value?.trim() ?? ''))) {
        return false;
    }

    return true;
};

export type SingleFieldTypeRendererProps = {
    errors: boolean;
    showOnlySymbolForFieldTypeCurrency?: boolean;
    customDropDownAndInputSx?: {
        dropdown: SxProps<Theme>;
        input: SxProps<Theme>;
    };
    placeholder?: string;
    helper: {
        text: string;
        textColor?: 'primary' | 'error' | 'warning' | 'success' | 'text';
        icon?: boolean;
        iconColor?: 'primary' | 'error' | 'warning' | 'success';
    };
    disabled?: boolean;
} & (
    | {
          fieldType: 'BOOLEAN';
          constraints: BooleanField;
          value: boolean;
          updateHandler: (newValue: boolean) => void;
      }
    | {
          fieldType: 'CURRENCY';
          constraints: CurrencyField;
          value: {
              currency: string | null;
              decimal: string;
          };
          updateHandler: (newValue: {
              currency: string | null;
              decimal: string | '';
          }) => void;
      }
    | {
          fieldType: 'DATE';
          constraints: DateField;
          value: string | null;
          updateHandler: (newValue: string | null) => void;
      }
    | {
          fieldType: 'DATETIME';
          constraints: DateTimeField;
          value: string | null;
          updateHandler: (newValue: string | null) => void;
      }
    | {
          fieldType: 'INTEGER';
          constraints: IntegerField;
          value: string;
          updateHandler: (newValue: string) => void;
      }
    | {
          fieldType: 'FLOAT';
          constraints: DecimalField;
          value: string;
          updateHandler: (newValue: string) => void;
      }
    | {
          fieldType: 'SHORTTEXT' | 'LONGTEXT' | 'STRING';
          constraints: TextField;
          value: string;
          updateHandler: (newValue: string) => void;
      }
    | {
          fieldType: 'CHOICE';
          constraints: ChoiceField & {
              choiceType: 'DROPDOWN';
          };
          value: string | null;
          updateHandler: (newValue: string) => void;
      }
    | {
          fieldType: 'CHOICE';
          constraints: ChoiceField & {
              choiceType: 'MULTI_SELECT';
          };
          value: string[];
          updateHandler: (newValue: string[]) => void;
      }
    | {
          fieldType: 'TEMPLATE';
          constraints: TemplatesField;
          value: string[];
          updateHandler: (newValue: string[]) => void;
      }
    | {
          fieldType: 'ATTACHMENT';
          constraints: AttachmentField;
          value: string[];
          updateHandler: (newValue: string[]) => void;
      }
);

const SingleFieldTypeRenderer = ({
    fieldType,
    constraints,
    updateHandler,
    errors,
    value,
    helper,
    placeholder = '',
    showOnlySymbolForFieldTypeCurrency = false,
    customDropDownAndInputSx,
    disabled = false,
}: SingleFieldTypeRendererProps) => {
    const [itemInputFocused, setItemInputFocused] = useState(false);
    const inputData = useRef<HTMLInputElement | null>(null);
    const containerRef = useRef<HTMLInputElement>(null);

    const FWInputRef = useRef<HTMLInputElement | null>(null);
    const chipRef = useRef<HTMLInputElement>(null);
    const [limitTags, setLimitTags] = useState(1);

    useEffect(() => {
        if (FWInputRef.current && chipRef.current) {
            const inputWidth = FWInputRef.current.getBoundingClientRect().width;
            const tagWidth = chipRef.current.getBoundingClientRect().width;

            const tagsThatCanFit = Math.floor(inputWidth / tagWidth);

            if (tagsThatCanFit === 0) setLimitTags(1);
            else setLimitTags(tagsThatCanFit);
        }
    }, [FWInputRef, chipRef]);

    switch (fieldType) {
        case 'SHORTTEXT':
        case 'STRING':
        case 'LONGTEXT':
            const { maxLimit } = constraints;
            return (
                <FWInput
                    disabled={disabled}
                    value={value}
                    onChange={(e) => updateHandler(e.target.value)}
                    error={errors}
                    helper={helper}
                    placeholder={placeholder}
                    {...(maxLimit
                        ? {
                              inputProps: {
                                  maxLength: maxLimit,
                              },
                          }
                        : {})}
                />
            );
        case 'INTEGER':
            return (
                <FWInput
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    allowOnly="NUMBER"
                    onChange={(e) => updateHandler(e.target.value)}
                    error={errors}
                    helper={helper}
                />
            );
        case 'FLOAT':
            return (
                <FWInput
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                    allowOnly="DECIMAL_NUMBER"
                    onChange={(e) => {
                        updateHandler(e.target.value);
                    }}
                    error={errors}
                    helper={helper}
                />
            );
        case 'DATE':
            return (
                <FWInput
                    type="date"
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value ? value : ''}
                    onChange={(e) =>
                        updateHandler(
                            e.target.value.trim().length > 0
                                ? e.target.value
                                : null
                        )
                    }
                    error={errors}
                    helper={helper}
                />
            );

        case 'DATETIME':
            console.log('errors', errors);
            return (
                <SingleDateTimeFieldRenderer
                    value={value ? (value as string) : ''}
                    rendererProperty={{
                        gridSize: 12,
                        label: '',
                        required: true,
                        type: 'DATE',
                        is_hidden: false,
                    }}
                    updateHandler={(newVal: string | null) => {
                        updateHandler(newVal);
                    }}
                    error={errors}
                    helper={helper}
                />
            );
        case 'CURRENCY':
            return (
                <FWCombinedInput
                    selectDisabled={disabled}
                    inputDisabled={disabled}
                    dropdownSx={
                        customDropDownAndInputSx?.dropdown
                            ? customDropDownAndInputSx?.dropdown
                            : {
                                  width: '100%',
                              }
                    }
                    inputSx={
                        customDropDownAndInputSx?.input
                            ? customDropDownAndInputSx?.input
                            : { width: '260%' }
                    }
                    required={true}
                    isDropLeft={true}
                    allowOnly="DECIMAL_NUMBER"
                    textFieldValue={value.decimal}
                    adornMentPosition="end"
                    textFieldOnChange={(e) => {
                        updateHandler({
                            currency: value.currency,
                            decimal: e.target.value,
                        });
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '12px',
                                boxShadow: '0px 5px 16px 3px #00000024',
                                maxHeight: '30vh',
                            },
                        },
                    }}
                    selectFieldValue={value.currency}
                    error={Boolean(errors)}
                    helperText={helper?.text ?? ''}
                    inputClassName=""
                    selectFieldOnChange={(e) => {
                        updateHandler({
                            currency: e.target.value,
                            decimal: value.decimal,
                        });
                    }}
                    selectFieldMenuItems={constraints.choices.map((val, i) => {
                        let currencyOption = showOnlySymbolForFieldTypeCurrency
                            ? `${val.currency_symbol}`
                            : `${val.currency_code_abbreviation} (${val.currency_symbol})`;
                        return (
                            <FWMenuItem value={val.entry_id} key={i}>
                                {currencyOption}
                            </FWMenuItem>
                        );
                    })}
                />
            );
        case 'BOOLEAN':
            return (
                <div
                    // className="flex flex--aic"
                    style={{
                        justifyItems: 'left',
                        direction: 'ltr',
                    }}
                >
                    <RadioGroup
                        row
                        value={value}
                        onChange={(e) =>
                            updateHandler(JSON.parse(e.target.value))
                        }
                        color="error"
                    >
                        <FormControlLabel
                            value={false}
                            disabled={disabled}
                            control={<Radio />}
                            label="No"
                            color="error"
                        />
                        <FormControlLabel
                            value={true}
                            disabled={disabled}
                            control={<Radio />}
                            label="Yes"
                            color="error"
                        />
                    </RadioGroup>
                    {errors && (
                        <FWTypography
                            color={'error'}
                            sx={{
                                margin: '0px',
                                fontSize: '12px',
                                alignContent: 'left',
                                alignSelf: 'flex-start',
                                lineHeight: '1em',
                            }}
                        >
                            {helper.text}
                        </FWTypography>
                    )}
                </div>
            );

        case 'CHOICE':
            if (constraints.choiceType === 'DROPDOWN') {
                return (
                    // <Select
                    //     size="small"
                    //     fullWidth
                    //     value={constraints.choices.find(
                    //         (choice) => choice === value
                    //     )}
                    //     MenuProps={{
                    //         PaperProps: {
                    //             sx: {
                    //                 bgcolor: 'white',
                    //                 maxWidth: '10vw',
                    //                 maxHeight: '300px',
                    //                 boxShadow: '0px 5px 16px #C4C4c8',
                    //             },
                    //         },
                    //     }}
                    //     renderValue={(value) => (
                    //         <FWTypography>{value}</FWTypography>
                    //     )}
                    //     IconComponent={OpenDownIcon}
                    // >
                    //     {constraints.choices.map((option, index) => (
                    //         <FWMenuItem key={index} value={option}>
                    //             <Box width={'100%'}>
                    //                 <FWTypography
                    //                     sx={{
                    //                         overflow: 'hidden',
                    //                         textOverflow: 'ellipsis',
                    //                     }}
                    //                 >
                    //                     {option}
                    //                 </FWTypography>
                    //             </Box>
                    //         </FWMenuItem>
                    //     ))}
                    // </Select>

                    <FWAutocomplete
                        popupIcon={
                            <Box fontSize={'16px'} paddingTop={0.5}>
                                <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                            </Box>
                        }
                        disabled={disabled}
                        disableClearable
                        value={
                            constraints?.choices?.find(
                                (choice) => choice === value
                            ) ?? null
                        }
                        options={constraints.choices ?? []}
                        onChange={(e, newValue) => updateHandler(newValue)}
                        renderInput={(params) => (
                            <FWInput
                                {...params}
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                variant="outlined"
                                error={errors}
                                helper={helper}
                            />
                        )}
                        renderOption={(props, option) => {
                            return (
                                <FWMenuItem {...props} value={option}>
                                    <FWTypography>{option}</FWTypography>
                                </FWMenuItem>
                            );
                        }}
                        PaperComponent={(props) => (
                            <AutocompleteStyledPaper {...props} />
                        )}
                        fullWidth
                        isOptionEqualToValue={(option: string, value: any) => {
                            return value === option;
                        }}
                    />
                );
            } else if (constraints.choiceType === 'MULTI_SELECT') {
                return (
                    // <FWTooltip
                    //     title={
                    //         itemInputFocused
                    //             ? ''
                    //             : selectedOptions &&
                    //               isArray(selectedOptions) &&
                    //               selectedOptions.length > 0
                    //             ? selectedOptions.join(', ')
                    //             : ''
                    //     }
                    // >
                    //     <Box>
                    //         <Select
                    //             size="small"
                    //             fullWidth
                    //             multiple
                    //             value={constraints.choices.filter((choice) =>
                    //                 value?.includes(choice)
                    //             )}
                    //             renderValue={(abc: string[]) =>
                    //                 selectedOptions?.map(
                    //                     (option: string, index: number) => (
                    //                         <Chip
                    //                             key={index}
                    //                             sx={{
                    //                                 maxWidth:
                    //                                     '150px !important',
                    //                                 marginRight: '0.25rem',
                    //                             }}
                    //                             label={option}
                    //                             onDelete={() => {
                    //                                 setSelectedOptions(
                    //                                     (prev: any) => {
                    //                                         let newArr =
                    //                                             prev.filter(
                    //                                                 (
                    //                                                     prevOption: string
                    //                                                 ) =>
                    //                                                     prevOption !==
                    //                                                     option
                    //                                             );
                    //                                         updateHandler(
                    //                                             newArr
                    //                                         );
                    //                                         return newArr;
                    //                                     }
                    //                                 );

                    //                                 updateHandler(
                    //                                     selectedOptions.filter(
                    //                                         (item: string) =>
                    //                                             item !== option
                    //                                     ) as any
                    //                                 );
                    //                             }}
                    //                             onMouseDown={(event) => {
                    //                                 event.stopPropagation();
                    //                                 event.preventDefault();
                    //                             }}
                    //                         />
                    //                     )
                    //                 )
                    //             }
                    //             MenuProps={{
                    //                 PaperProps: {
                    //                     sx: {
                    //                         bgcolor: 'white',
                    //                         maxWidth: '10vw',
                    //                         maxHeight: '300px',
                    //                         boxShadow: '0px 5px 16px #C4C4c8',
                    //                     },
                    //                 },
                    //             }}
                    //             IconComponent={OpenDownIcon}
                    //         >
                    //             <FWMenuItem
                    //                 key={'Select All'}
                    //                 sx={{
                    //                     display: 'flex',
                    //                     justifyContent: 'space-between',
                    //                     width: 'inherit',
                    //                 }}
                    //             >
                    //                 <Box
                    //                     display="flex"
                    //                     onClick={(e) => {
                    //                         e.stopPropagation(); // prevent

                    //                         let ans: any;
                    //                         if (
                    //                             constraints.choices.length !==
                    //                             selectedOptions?.length
                    //                         ) {
                    //                             ans = constraints.choices;
                    //                             setSelectedOptions(ans);
                    //                         } else {
                    //                             ans = [];
                    //                             setSelectedOptions([]);
                    //                         }

                    //                         updateHandler(ans);
                    //                     }}
                    //                 >
                    //                     <Checkbox
                    //                         sx={{
                    //                             '&.MuiCheckbox-root': {
                    //                                 padding: '0px 9px',
                    //                             },
                    //                         }}
                    //                         checked={
                    //                             constraints.choices.length ===
                    //                             value?.length
                    //                         }
                    //                     />
                    //                     <FWTypography
                    //                         sx={{
                    //                             marginRight: '10px',
                    //                         }}
                    //                     >
                    //                         Select all
                    //                     </FWTypography>
                    //                 </Box>
                    //             </FWMenuItem>
                    //             {constraints.choices.map((choice, index) => {
                    //                 return (
                    //                     <FWMenuItem
                    //                         key={index}
                    //                         value={choice}
                    //                         sx={{ width: 'inherit' }}
                    //                     >
                    //                         <Box
                    //                             display="flex"
                    //                             onClick={() => {
                    //                                 if (
                    //                                     value?.includes(choice)
                    //                                 ) {
                    //                                     setSelectedOptions(
                    //                                         (prev: any) => {
                    //                                             let newArr =
                    //                                                 prev.filter(
                    //                                                     (
                    //                                                         prevOption: string
                    //                                                     ) =>
                    //                                                         prevOption !==
                    //                                                         choice
                    //                                                 );
                    //                                             updateHandler(
                    //                                                 newArr
                    //                                             );
                    //                                             return newArr;
                    //                                         }
                    //                                     );
                    //                                 } else {
                    //                                     setSelectedOptions(
                    //                                         (prev: any) => {
                    //                                             let newArr: any =
                    //                                                 [
                    //                                                     ...prev,
                    //                                                     choice,
                    //                                                 ];
                    //                                             updateHandler(
                    //                                                 newArr
                    //                                             );

                    //                                             return newArr;
                    //                                         }
                    //                                     );
                    //                                 }
                    //                             }}
                    //                         >
                    //                             <Checkbox
                    //                                 sx={{
                    //                                     '&.MuiCheckbox-root': {
                    //                                         padding: '0px 9px',
                    //                                     },
                    //                                 }}
                    //                                 checked={
                    //                                     selectedOptions.includes(
                    //                                         choice
                    //                                     ) ||
                    //                                     value?.includes(choice)
                    //                                 }
                    //                             />
                    //                             <FWTypography
                    //                                 sx={{
                    //                                     overflow: 'hidden',
                    //                                     textOverflow:
                    //                                         'ellipsis',
                    //                                 }}
                    //                             >
                    //                                 {choice}
                    //                             </FWTypography>
                    //                         </Box>
                    //                     </FWMenuItem>
                    //                 );
                    //             })}
                    //         </Select>
                    //     </Box>
                    // </FWTooltip>
                    <FWTooltip
                        title={
                            itemInputFocused
                                ? ''
                                : value && isArray(value) && value.length > 0
                                ? value.join(', ')
                                : ''
                        }
                    >
                        <Box ref={containerRef}>
                            <FWAutocomplete
                                disabled={disabled}
                                popupIcon={
                                    <Box fontSize={'16px'} paddingTop={0.5}>
                                        <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                                    </Box>
                                }
                                disableClearable
                                disableCloseOnSelect
                                value={constraints?.choices?.filter((choice) =>
                                    value?.includes(choice)
                                )}
                                options={constraints.choices ?? []}
                                onChange={(e, newValue) => {
                                    updateHandler(newValue);
                                    e.stopPropagation();
                                }}
                                renderOption={(
                                    props,
                                    option: string,
                                    { selected }
                                ) => {
                                    return (
                                        <FWMenuItem
                                            key={option}
                                            // value={option.rfq_item_id}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                backgroundColor: selected
                                                    ? 'white !important'
                                                    : 'transparent',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'rgba(0, 0, 0, 0.08) !important',
                                                },
                                            }}
                                            // onClick={(e) => e.stopPropagation()}
                                            {...props}
                                        >
                                            <Box display="flex" width={'100%'}>
                                                <Checkbox
                                                    sx={{
                                                        '&.MuiCheckbox-root': {
                                                            padding: '0px 9px',
                                                        },
                                                        '&:selected': {
                                                            backgroundColor:
                                                                'white !important',
                                                        },
                                                    }}
                                                    checked={selected}
                                                    onClick={() => {
                                                        let ans: any;
                                                        ans = [option];
                                                        updateHandler(ans);
                                                    }}
                                                />
                                                <FWTypography
                                                    sx={{
                                                        marginRight: '10px',
                                                        '&:selected': {
                                                            backgroundColor:
                                                                'white !important',
                                                        },
                                                    }}
                                                >
                                                    {option}
                                                </FWTypography>
                                            </Box>
                                        </FWMenuItem>
                                    );
                                }}
                                PaperComponent={(paperProps) => {
                                    const { children, ...restPaperProps } =
                                        paperProps;
                                    return (
                                        <AutocompleteStyledPaper
                                            {...restPaperProps}
                                            sx={{
                                                paddingTop: '10px',
                                            }}
                                        >
                                            {constraints?.choices &&
                                            constraints?.choices?.filter(
                                                (choice) =>
                                                    choice
                                                        ?.toLowerCase()
                                                        ?.includes(
                                                            (
                                                                inputData
                                                                    ?.current
                                                                    ?.value ||
                                                                ''
                                                            )?.toLowerCase()
                                                        )
                                            )?.length > 1 ? (
                                                <FWMenuItem
                                                    key={'Select All'}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                        paddingTop: '2px',
                                                    }}
                                                    disabled={disabled}
                                                >
                                                    <Box
                                                        display="flex"
                                                        width={'100%'}
                                                        onMouseDown={(e) =>
                                                            e.preventDefault()
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault(); // prevent

                                                            let ans: any;
                                                            if (
                                                                constraints
                                                                    ?.choices
                                                                    ?.length !==
                                                                value?.length
                                                            ) {
                                                                ans =
                                                                    constraints.choices;
                                                            } else {
                                                                ans = [];
                                                            }
                                                            updateHandler(ans);
                                                        }}
                                                    >
                                                        <Checkbox
                                                            sx={{
                                                                '&.MuiCheckbox-root':
                                                                    {
                                                                        padding:
                                                                            '0px 9px',
                                                                    },
                                                            }}
                                                            checked={
                                                                constraints
                                                                    .choices
                                                                    .length ===
                                                                value?.length
                                                            }
                                                        />
                                                        <Typography
                                                            component={'span'}
                                                            sx={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                        >
                                                            Select all
                                                        </Typography>
                                                    </Box>
                                                </FWMenuItem>
                                            ) : (
                                                <></>
                                            )}
                                            {children}
                                        </AutocompleteStyledPaper>
                                    );
                                }}
                                ref={FWInputRef}
                                renderInput={(params) => (
                                    <FWInput
                                        style={{
                                            overflow: 'hidden',
                                        }}
                                        {...params}
                                        iconStart={
                                            <i className="bi bi-search"></i>
                                        }
                                        maxRows={1}
                                        // multiline

                                        onFocus={() => {
                                            setItemInputFocused(true);
                                        }}
                                        onBlur={() => {
                                            setItemInputFocused(false);
                                        }}
                                        // inputRef={inputData}
                                        error={errors}
                                        helper={helper}
                                    />
                                )}
                                limitTags={limitTags}
                                fullWidth
                                renderTags={(
                                    newSelectedValue: string[],
                                    getTagProps
                                ) =>
                                    newSelectedValue.map(
                                        (option, index) =>
                                            true && (
                                                <Chip
                                                    ref={chipRef}
                                                    sx={{
                                                        maxWidth: `150px !important`,
                                                        backgroundColor:
                                                            'rgba(0, 0, 0, 0.08) !important',
                                                    }}
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                    onDelete={() => {
                                                        const updatedValue =
                                                            newSelectedValue.filter(
                                                                (item) =>
                                                                    item !==
                                                                    option
                                                            ) as any;
                                                        updateHandler(
                                                            updatedValue
                                                        );
                                                    }}
                                                    onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                    }}
                                                />
                                            )
                                    )
                                }
                                // limitTags={2}
                                multiple
                                // PaperComponent={(props) => (
                                //     <AutocompleteStyledPaper {...props} />
                                // )}

                                isOptionEqualToValue={(
                                    option: string,
                                    value: any
                                ) => {
                                    return value === option;
                                }}
                            />
                        </Box>
                    </FWTooltip>
                );
            } else return null;

        default:
            return null;
    }
};

export default memo(SingleFieldTypeRenderer);
