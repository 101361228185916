// import { transformPaymentTermsFromFrontendToBackend } from '../../../../../Global/Helpers/paymentTermsHelpers';
// import { cloneDeep } from 'lodash';
import { convertAdditionalCostBackendToFrontend } from '../../../../../AdditionalCost/helpers';
import { IAdditionalCostsWithValue } from '../../../../../AdditionalCost/models/AdditionalCost.model';
import { TemplateDetails } from '../../../../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
// import { getTemplateDataById } from '../../../../../BuyerTemplates/Services/template.service';
import { IAddresses } from '../../../../../Models/Address.model';
import {
    ICustomPODeliveryScheduleItem,
    IEntityIdentification,
    // IPODeliveryScheduleItem,
    IPurchaseOrderAlgoObject,
    IPurchaseOrderItem,
    IPurchaseOrderWithItems,
} from '../../../../../Models/AwardsAndPO.model';
import {
    IGetAllEventSeller,
    IGetRFQDetails,
    IGetRFQItemDetails,
} from '../../../../../Models/RFQ.model';
import { IUserDetails } from '../../../../../Models/UserDetails.model';
import { IVendorContactDetails } from '../../../../../PurchaseOrders/Interfaces/PO.model';
import { VerificationStatus } from '../../../../../Redux/Slices/VerificationSlice';
import { UpdateLinkedRfqValues } from '../POReview/helperfunctions';

export interface ISellerPoData {
    [key: string]: {
        promise_idx: string;
        data?: {
            identifications: IEntityIdentification[];
            addresses: IAddresses[];
            contact: IVendorContactDetails[] | null;
            verification_status: VerificationStatus;
        };
    };
}

export async function createAllPurchaseOrders(
    rfqDeets: IGetRFQDetails,
    rfqItemDeetsList: IGetRFQItemDetails[],
    deliveryScheduleItems: ICustomPODeliveryScheduleItem[],
    rfqSellers: IGetAllEventSeller[],
    buyer_identifications: IEntityIdentification[],
    sellerData: ISellerPoData,
    userDetails: IUserDetails,
    tncData: null | {
        terms_and_conditions_id: string;
        name: string;
        data: string;
    },
    staging_po_list: IPurchaseOrderWithItems[],
    rfqTemplateDetails: TemplateDetails,
    event_type?: 'DIRECT' | 'FROM_EVENT'
    // totalLimit: number = 0
): Promise<IPurchaseOrderAlgoObject> {
    let vendor_department_PO_map: IPurchaseOrderAlgoObject = {};
    let criteriaToGroupId: { [key: string]: string } = {};
    let groupCounter: number = 1;
    let _fieldsToSplitBy: string[] = [];

    rfqTemplateDetails.template_sections.forEach((section) => {
        section.section_items.forEach((item) => {
            if (item.can_split) {
                switch (item.name) {
                    case 'Cost center':
                        _fieldsToSplitBy.push('cost_centre_id');
                        break;
                    case 'GL':
                        _fieldsToSplitBy.push('general_ledger_id');
                        break;
                    case 'Project':
                        _fieldsToSplitBy.push('project_id');
                        break;
                    case 'Delivery date':
                        _fieldsToSplitBy.push('delivery_date');
                        break;
                    case 'Incoterms':
                        _fieldsToSplitBy.push('incoterm');
                        break;
                    case 'Payment terms':
                        _fieldsToSplitBy.push('paymentTerms');
                        break;
                    case 'Lead time':
                        _fieldsToSplitBy.push('procurement_information');
                        break;
                    case 'Additional information':
                        _fieldsToSplitBy.push('item_additional_details');
                        break;
                    default:
                        _fieldsToSplitBy.push(item.name);
                        break;
                }
            }
        });
    });

    let vendor_all_additional_costs: {
        [key: string]: {
            additional_costs: IAdditionalCostsWithValue[];
            taxes: IAdditionalCostsWithValue[];
            discounts: IAdditionalCostsWithValue[];
        };
    } = {};
    if (staging_po_list !== null && staging_po_list.length > 0) {
        for (let staging_po of staging_po_list!) {
            let curVend = staging_po.seller_entity;
            vendor_all_additional_costs[curVend] = {
                additional_costs: staging_po.additional_costs,
                taxes: staging_po.taxes,
                discounts: staging_po.discounts,
            };
        }
    }

    for (let deliveryScheduleItem of deliveryScheduleItems) {
        let key = '';
        for (let i = 0; i < _fieldsToSplitBy.length; i++) {
            const splitField = _fieldsToSplitBy[i];
            let value = 'none';
            if (
                [
                    'cost_centre_id',
                    'general_ledger_id',
                    'project_id',
                    'delivery_date',
                ].includes(splitField)
            ) {
                value =
                    String(
                        deliveryScheduleItem[
                            splitField as keyof ICustomPODeliveryScheduleItem
                        ]
                    ) ?? 'none';
            } else if (splitField === 'incoterm') {
                value =
                    String(
                        deliveryScheduleItem.po_item_details[
                            splitField as keyof IPurchaseOrderItem
                        ]
                    ) ?? 'none';
            } else if (
                ['paymentTerms', 'procurement_information'].includes(splitField)
            ) {
                value =
                    JSON.stringify(
                        deliveryScheduleItem.po_item_details[
                            splitField as keyof IPurchaseOrderItem
                        ]
                    ) ?? 'none';
            } else if (splitField === 'quantity_tolerance_percentage') {
                value = String(
                    deliveryScheduleItem.po_item_details.quantity_information
                        .quantity_tolerance_percentage
                );
            } else if (splitField === 'item_additional_details') {
                value = String(
                    deliveryScheduleItem.po_item_details.item_information
                        .item_additional_details
                );
            } else {
                let idx =
                    deliveryScheduleItem.po_item_details.custom_fields?.section_list.findIndex(
                        (section) =>
                            section.fields.find(
                                (field) => field.name === splitField
                            ) !== undefined
                    );
                if (idx !== -1 && idx !== undefined) {
                    let fieldType =
                        deliveryScheduleItem.po_item_details.custom_fields?.section_list[
                            idx
                        ].fields.find(
                            (field) => field.name === splitField
                        )?.type;

                    value =
                        String(
                            deliveryScheduleItem.po_item_details.custom_fields?.section_list[
                                idx
                            ].fields.find((field) => field.name === splitField)
                                ?.value
                        ) ?? 'none';

                    if (fieldType === 'MULTI_CHOICE') {
                        value = value.split(',').sort().join('-');
                    }
                }
            }
            key += (i > 0 ? '-' : '') + value;
        }

        if (!(key in criteriaToGroupId)) {
            criteriaToGroupId[key] = `group-${groupCounter}`;
            groupCounter += 1;
        }

        deliveryScheduleItem['split_group_id'] = criteriaToGroupId[key];
    }

    for (let deliveryScheduleItem of deliveryScheduleItems) {
        if (deliveryScheduleItem.is_selected) {
            let curSellerId = deliveryScheduleItem.seller_entity;
            let curSeller = rfqSellers.find(
                (x) => x.seller_entity_details.seller_entity_id === curSellerId
            );
            let curSellerName = curSeller
                ? curSeller.seller_entity_details.seller_entity_name
                : deliveryScheduleItem.vendor_name
                ? deliveryScheduleItem.vendor_name
                : '';

            //Genuine Error to be shown
            // if (curSellerName === '') {
            //
            // }
            let deptId =
                deliveryScheduleItem.department === null
                    ? ''
                    : deliveryScheduleItem.department;

            let rfqItemId =
                deliveryScheduleItem.po_item_details.additional_details
                    .rfq_item_id;

            if (
                !vendor_department_PO_map.hasOwnProperty(
                    curSellerId + deliveryScheduleItem.split_group_id
                )
            ) {
                vendor_department_PO_map[
                    curSellerId + deliveryScheduleItem.split_group_id
                ] = {};
            }
            let rfqDeetsToUse = await UpdateLinkedRfqValues(
                rfqDeets,
                curSellerName
            );
            if (
                !vendor_department_PO_map[
                    curSellerId + deliveryScheduleItem.split_group_id
                ].hasOwnProperty(deptId)
            ) {
                let all_additional_costs =
                    event_type === 'DIRECT'
                        ? {
                              additional_costs:
                                  convertAdditionalCostBackendToFrontend(
                                      rfqDeets.additional_costs
                                  ),
                              taxes: convertAdditionalCostBackendToFrontend(
                                  rfqDeets.taxes
                              ),
                              discounts: convertAdditionalCostBackendToFrontend(
                                  rfqDeets.discounts
                              ),
                          }
                        : vendor_all_additional_costs.hasOwnProperty(
                              curSellerId
                          )
                        ? vendor_all_additional_costs[curSellerId]
                        : {
                              additional_costs: [],
                              taxes: [],
                              discounts: [],
                          };
                vendor_department_PO_map[
                    curSellerId + deliveryScheduleItem.split_group_id
                ][deptId] = createPurchaseOrder(
                    rfqDeetsToUse,
                    deliveryScheduleItem.seller_entity,
                    deliveryScheduleItem.seller_enterprise,
                    deliveryScheduleItem.department,
                    curSellerName,
                    buyer_identifications,
                    sellerData,
                    userDetails,
                    tncData,
                    all_additional_costs,
                    curSeller
                        ? curSeller.seller_entity_details.is_virtual
                        : false,
                    rfqItemDeetsList[0]?.pricing_information
                        ?.currency_code_id ?? '',
                    rfqItemDeetsList[0]?.pricing_information?.currency_symbol ??
                        '',
                    rfqItemDeetsList[0]?.pricing_information
                        ?.currency_code_abbreviation ?? ''
                );
            }

            let current_PO: IPurchaseOrderWithItems =
                vendor_department_PO_map[
                    curSellerId + deliveryScheduleItem.split_group_id
                ][deptId];
            let po_item: IPurchaseOrderItem | null = null;
            for (let looping_po_item of current_PO.purchase_order_items) {
                if (
                    looping_po_item.additional_details.rfq_item_id === rfqItemId
                ) {
                    po_item = looping_po_item;
                    break;
                }
            }

            if (po_item === null) {
                let new_po_item = createPurchaseOrderItem(
                    rfqDeetsToUse,
                    rfqItemDeetsList,
                    deliveryScheduleItem,
                    userDetails
                );
                current_PO.purchase_order_items.push(new_po_item);
                po_item =
                    current_PO.purchase_order_items[
                        current_PO.purchase_order_items.length - 1
                    ];
            }

            // fix this later
            po_item.delivery_schedule_items.push(deliveryScheduleItem as any);
            po_item.quantity_information.quantity = (
                parseFloat(po_item.quantity_information.quantity) +
                parseFloat(deliveryScheduleItem.quantity)
            ).toString();
        }
    }

    return vendor_department_PO_map;
}

function createPurchaseOrderItem(
    rfqDeets: IGetRFQDetails, // rfq details
    rfqItemDeetsList: IGetRFQItemDetails[], // rfq item detail
    deliveryScheduleItem: ICustomPODeliveryScheduleItem,
    userDetails: IUserDetails
): IPurchaseOrderItem {
    let rfqItemObj = null;
    for (let loopingRfqItemObj of rfqItemDeetsList) {
        if (
            loopingRfqItemObj.rfq_item_entry_id ===
            deliveryScheduleItem.po_item_details.additional_details.rfq_item_id
        ) {
            rfqItemObj = loopingRfqItemObj;
            break;
        }
    }
    if (rfqItemObj === null) {
        throw new Error('Data problem.');
    }
    return {
        purchase_order_item_id: null,
        purchase_order: null,
        purchase_order_status: null,
        event: rfqDeets.event_id,
        buyer_enterprise: deliveryScheduleItem.buyer_enterprise,
        seller_enterprise: deliveryScheduleItem.seller_enterprise,
        buyer_entity: deliveryScheduleItem.buyer_entity,
        seller_entity: deliveryScheduleItem.seller_entity,
        seller_entity_name: '',
        buyer_item: rfqItemObj.item_information.buyer_item_id,
        item_information: rfqItemObj.item_information,
        fulfilment_information: {},
        quantity_information: {
            quantity: '0',
            quantity_tolerance_percentage:
                deliveryScheduleItem.po_item_details.quantity_information
                    .quantity_tolerance_percentage,
            measurement_unit_id:
                deliveryScheduleItem.po_item_details.quantity_information
                    .measurement_unit_id,
            measurement_unit_name:
                deliveryScheduleItem.po_item_details.quantity_information
                    .measurement_unit_name,
        },
        paymentTerms: deliveryScheduleItem.po_item_details.paymentTerms,

        pricing_information: {
            ...deliveryScheduleItem.po_item_details.pricing_information,
        },
        attribute_information: { ...rfqItemObj.attribute_information },
        property_information: [...rfqItemObj.property_information],
        additional_details: {
            rfq_item_id: rfqItemObj.rfq_item_entry_id,
            rfq_event_id: rfqDeets.rfq_entry_id,
        },
        incoterm: deliveryScheduleItem.po_item_details.incoterm,
        incoterm_abbreviation:
            deliveryScheduleItem.po_item_details.incoterm_abbreviation,
        procurement_information: {
            ...deliveryScheduleItem.po_item_details.procurement_information,
        },
        internal_notes: { notes: '' },
        external_notes: { notes: '' },
        internal_status: '',
        delivery_schedule_items: [],
        created_by_user: userDetails.user_id,
        created_by_user_name: userDetails.name.trim(),
        attachments: deliveryScheduleItem.po_item_details.attachments,
        custom_fields: deliveryScheduleItem.po_item_details.custom_fields,
        custom_fields_negotiate:
            deliveryScheduleItem.po_item_details.custom_fields_negotiate,

        buyer_delivery_schedule: [],
        delivery_schedule: [],
    };
}

export function createPurchaseOrder(
    rfqDeets: IGetRFQDetails,
    seller_entity_id: string,
    seller_enterprise_id: string,
    department: string | null,
    seller_entity_name: string,
    buyer_identifications: IEntityIdentification[],
    sellerData: ISellerPoData,
    userDetails: IUserDetails,
    tncData: null | {
        terms_and_conditions_id: string;
        name: string;
        data: string;
    },
    all_additional_costs: {
        additional_costs: IAdditionalCostsWithValue[];
        taxes: IAdditionalCostsWithValue[];
        discounts: IAdditionalCostsWithValue[];
    },
    is_virtual: boolean,
    item_currency_id: string,
    item_currency_symbol: string,
    item_currency_abbreviation: string
): IPurchaseOrderWithItems {
    let seller_primary_address_id = null;
    let seller_primary_address_full = '';
    let seller_addresses_filtered = sellerData[
        seller_entity_id
    ].data!.addresses.filter((row) => row.is_primary_address);
    if (seller_addresses_filtered.length > 0) {
        seller_primary_address_id =
            seller_addresses_filtered[0].address.address_id;
        seller_primary_address_full =
            seller_addresses_filtered[0].address.full_address;
    }
    // let {
    //     payment_type,
    //     prepayment_percentage,
    //     payment_terms,
    //     deliverables_payment_terms,
    // } = transformPaymentTermsFromFrontendToBackend({
    //     paymentTerms: rfqDeets.rfq_default_information.defaultPaymentTerms,
    // });

    //

    return {
        purchase_order_id: null,
        buyer_enterprise: rfqDeets.event_details.enterprise,
        seller_enterprise: seller_enterprise_id,
        event: rfqDeets.event_id,
        custom_purchase_order_id: '',
        hold_information: null,
        buyer_entity: rfqDeets.buyer_entity.buyer_entity_id,
        seller_entity: seller_entity_id,
        buyer_information: {
            billing_address_id: rfqDeets.address_information.billing_address_id,
            billing_address: rfqDeets.address_information.billing_address,
            shipping_address_id:
                rfqDeets.address_information.shipping_address_id,
            shipping_address: rfqDeets.address_information.shipping_address,
            buyer_entity_name: rfqDeets.buyer_entity.buyer_entity_name,
            buyer_entity_address: null,
            buyer_identification_info: buyer_identifications.filter(
                (row) => row.is_default
            ),
        },
        seller_information: {
            seller_entity_name: seller_entity_name,
            seller_address_id: seller_primary_address_id,
            seller_entity_address: seller_primary_address_full,
            entity_description: '',
            seller_identification_info: sellerData[
                seller_entity_id
            ].data!.identifications.filter((row) => row.is_default),
            is_virtual: is_virtual,
            verification_status:
                sellerData[seller_entity_id].data!.verification_status,
        },
        buyer_contact_list: [
            {
                buyer_contact_id: userDetails.user_id!,
                buyer_contact_name: `${userDetails.name}`,
                buyer_contact_phone:
                    userDetails.contacts !== null &&
                    userDetails.contacts.phone_numbers.length > 0
                        ? userDetails.contacts.phone_numbers[0].phone
                        : '',
                buyer_contact_email: userDetails.email,
            },
        ],
        seller_contact_list: sellerData[seller_entity_id].data!.contact ?? [],
        internal_status: null,
        status: null,
        termination_information: null,
        terms_and_conditions: tncData ?? {
            terms_and_conditions_id: '',
            name: '',
            data: '',
        },
        notes: '',
        seller_notes: null,
        pricing_information: {
            total: '0',
            subtotal: '0',
            shipping: '0',
            taxes_percentage: '0',
            discount_percentage: '0',
            currency_code_id:
                item_currency_id ??
                rfqDeets.rfq_default_information.default_currency_id,
            currency_name: '',
            currency_symbol:
                item_currency_symbol ??
                rfqDeets.rfq_default_information.default_currency_symbol,
            currency_code_abbreviation:
                item_currency_abbreviation ??
                rfqDeets.rfq_default_information.default_currency_abbreviation,
        },
        submission_datetime: null,
        acceptance_datetime: null,
        department: department,
        final_buyer_approval: null,
        final_seller_approval: null,
        created_by_user: null,
        modified_by_user: null,
        deleted_by_user: null,
        created_datetime: null,
        modified_datetime: null,
        deleted_datetime: null,
        purchase_order_items: [],
        custom_fields: rfqDeets.custom_fields,
        lead_time: rfqDeets.lead_time,
        lead_time_period: rfqDeets.lead_time_period,
        incoterm_id: rfqDeets.incoterm_id,
        payment_type: rfqDeets.payment_type,
        prepayment_percentage: rfqDeets.prepayment_percentage,
        payment_terms: rfqDeets.payment_terms,
        deliverables_payment_terms: rfqDeets.deliverables_payment_terms,
        cost_centre_id: rfqDeets.cost_centre_id,
        gl_id: rfqDeets.gl_id,
        project_id: rfqDeets.project_id,
        custom_additional_information: rfqDeets.custom_additional_information,
        gr_tolerance: rfqDeets.gr_tolerance,
        requisition_information: rfqDeets.requisition_information,
        additional_costs: all_additional_costs['additional_costs'],
        taxes: all_additional_costs['taxes'],
        discounts: all_additional_costs['discounts'],
    };
}

export function createLinkedPurchaseOrder(
    rfqDeets: IGetRFQDetails
): IPurchaseOrderWithItems {
    return {
        purchase_order_id: null,
        buyer_enterprise: rfqDeets.event_details.enterprise,
        seller_enterprise: '',
        event: rfqDeets.event_id,
        custom_purchase_order_id: '',
        hold_information: null,
        buyer_entity: rfqDeets.buyer_entity.buyer_entity_id,
        seller_entity: '',
        buyer_information: {
            billing_address_id: rfqDeets.address_information.billing_address_id,
            billing_address: rfqDeets.address_information.billing_address,
            shipping_address_id:
                rfqDeets.address_information.shipping_address_id,
            shipping_address: rfqDeets.address_information.shipping_address,
            buyer_entity_name: rfqDeets.buyer_entity.buyer_entity_name,
            buyer_entity_address: null,
            buyer_identification_info: [],
        },
        seller_information: {
            seller_entity_name: '',
            seller_address_id: '',
            seller_entity_address: '',
            entity_description: '',
            seller_identification_info: [],
            is_virtual: false,
            verification_status: VerificationStatus.UNVERIFIED,
        },
        buyer_contact_list: [
            {
                buyer_contact_id: '',
                buyer_contact_name: '',
                buyer_contact_phone: '',
                buyer_contact_email: '',
            },
        ],
        seller_contact_list: [],
        internal_status: null,
        status: null,
        termination_information: null,
        terms_and_conditions: {
            terms_and_conditions_id: '',
            name: '',
            data: '',
        },
        notes: '',
        seller_notes: null,
        pricing_information: {
            total: '0',
            subtotal: '0',
            shipping: '0',
            taxes_percentage: '0',
            discount_percentage: '0',
            currency_code_id:
                rfqDeets.rfq_default_information.default_currency_id,
            currency_name: '',
            currency_symbol:
                rfqDeets.rfq_default_information.default_currency_symbol,
            currency_code_abbreviation:
                rfqDeets.rfq_default_information.default_currency_abbreviation,
        },
        submission_datetime: null,
        acceptance_datetime: null,
        department: null,
        final_buyer_approval: null,
        final_seller_approval: null,
        created_by_user: null,
        modified_by_user: null,
        deleted_by_user: null,
        created_datetime: null,
        modified_datetime: null,
        deleted_datetime: null,
        purchase_order_items: [],
        custom_fields: rfqDeets.custom_fields,
        lead_time: rfqDeets.lead_time,
        lead_time_period: rfqDeets.lead_time_period,
        incoterm_id: rfqDeets.incoterm_id,

        payment_type: rfqDeets.payment_type,
        prepayment_percentage: rfqDeets.prepayment_percentage,
        payment_terms: rfqDeets.payment_terms,
        deliverables_payment_terms: rfqDeets.deliverables_payment_terms,
        cost_centre_id: rfqDeets.cost_centre_id,
        gl_id: rfqDeets.gl_id,
        project_id: rfqDeets.project_id,
        custom_additional_information: rfqDeets.custom_additional_information,
        gr_tolerance: rfqDeets.gr_tolerance,
        requisition_information: rfqDeets.requisition_information,
        additional_costs: [],
        discounts: [],
        taxes: [],
    };
}
