import { cloneDeep } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { VLNContext } from '../../Events/Components/Buyer/VendorLinkedInPopup';
import {
    EntityStatus,
    IEntityDetails,
} from '../../Global/Interfaces/EntityInterface';
import { getEntityDetails } from '../../PurchaseOrders/Services/PO.service';
import { VerificationStatus } from '../../Redux/Slices/VerificationSlice';
import { IVLNAbout, IVLNItemCatalogue } from '../Interfaces/VendorLNInterfaces';
import {
    fetchEmployeeListApi,
    fetchEntityAddress,
    fetchItemCatalogueListApi,
    fetchSellerProfileDataApi,
} from '../Services/VendorLinkedIn.service';

const initialVendorProfileDetails: IVLNAbout = {
    vendor_profile_id: '',
    competency: [],
    employees: [],
    certificates: [],
    quality_and_compliance: [],
    factory_locations: [],
    product_tags: [],
    custom_section: [],
};

const initialVendorEntityDetails: IEntityDetails = {
    entity_id: '',
    entity_name: '',
    entity_logo: [],
    buyer_status: EntityStatus.INACTIVE,
    seller_status: EntityStatus.INACTIVE,
    contacts: {
        emails: [],
        phone_numbers: [],
        websites: [],
    },
    default_procurement_currency: null,
    enterprise: '',
    entity_description: '',
    entity_doing_business_as: '',
    is_virtual: false,
    verification_status: VerificationStatus.UNVERIFIED,
    enterprise_name: '',
    vendor_profile: {
        vendor_profile_id: '',
    },
};

export const useVendorProfileDetails = (vendor_profile_id: string | null) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);
    const [vendorProfileDetails, setVendorProfileDetails] = useState<IVLNAbout>(
        cloneDeep(initialVendorProfileDetails)
    );
    const [sellerEntityDetails, setSellerEntityDetails] =
        useState<IEntityDetails>(cloneDeep(initialVendorEntityDetails));

    const [itemsCatalogueList, setItemsCatalogueList] = useState<
        IVLNItemCatalogue[]
    >([]);

    const { sellerEntityUuid } = useContext(VLNContext);

    const fetchVendorProfile = useCallback(async () => {
        try {
            if (!vendor_profile_id) return;
            updateHookState(HookStateValue.LOADING);
            const sellerProfileDataPromise =
                fetchSellerProfileDataApi(vendor_profile_id);

            const entityDetailsPromise = getEntityDetails(sellerEntityUuid);

            const itemCatalogueListPromise =
                fetchItemCatalogueListApi(vendor_profile_id);

            const factoryLocationsPromise =
                fetchEntityAddress(sellerEntityUuid);

            // const factoryLocationsPromise =
            //     fetchFactoryLocationsApi(vendor_profile_id);

            const employeeListPromise = await fetchEmployeeListApi(
                vendor_profile_id
            );

            const [
                sellerProfileDataResponse,
                entityDetailsResponse,
                itemCatalogueListResponse,
                factoryLocationsResponse,
                employeeListResponse,
            ] = await Promise.all([
                sellerProfileDataPromise,
                entityDetailsPromise,
                itemCatalogueListPromise,
                factoryLocationsPromise,
                employeeListPromise,
            ]);

            setVendorProfileDetails({
                ...sellerProfileDataResponse,
                factory_locations: factoryLocationsResponse,
                employees: employeeListResponse,
            });

            setSellerEntityDetails(entityDetailsResponse);
            setItemsCatalogueList(itemCatalogueListResponse);

            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);

            toast.error('Error fetching vendor profile details');
        }
    }, [sellerEntityUuid, updateHookState, vendor_profile_id]);

    useEffect(() => {
        fetchVendorProfile();
    }, [fetchVendorProfile]);

    return {
        hookState,
        vendorProfileDetails,
        sellerEntityDetails,
        itemsCatalogueList,
    };
};
