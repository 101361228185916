import React from 'react';
import VLNProfileMenuChips from '../../Components/Profile/VLNProfileMenuChips';
import VLNProfileAboutSection from './VLNProfileAboutSection';
import VLNProfileCertificationSection from './VLNProfileCertificationSection';
import VLNProfileItemCatalogSection from './VLNProfileItemCatalogSection';
import { useVendorProfileDetails } from '../../Hooks/useVendorProfileDetails';
import { HookStateValue } from '../../../Common/Hooks/StateHook';
import LazyFallback from '../../../Components/Shared/LazyFallBack';
import { Box } from '@mui/system';

const VendorLNProfileMainContainer = ({
    vendor_profile_id,
}: {
    vendor_profile_id: string | null;
}) => {
    const [currentSection, setCurrentSection] = React.useState<
        'about' | 'certifications' | 'itemCatalog'
    >('about');

    const {
        hookState,
        vendorProfileDetails,
        sellerEntityDetails,
        itemsCatalogueList,
    } = useVendorProfileDetails(vendor_profile_id);

    return hookState.state === HookStateValue.READY ? (
        <>
            <VLNProfileMenuChips
                currentSection={currentSection}
                setCurrentSection={setCurrentSection}
            />
            <Box padding={4}>
                {(() => {
                    switch (currentSection) {
                        case 'about':
                            return (
                                <VLNProfileAboutSection
                                    sellerEntityDetails={sellerEntityDetails}
                                    vendorProfileDetails={vendorProfileDetails}
                                />
                            );
                        case 'certifications':
                            return (
                                <VLNProfileCertificationSection
                                    certificates={
                                        vendorProfileDetails.certificates
                                    }
                                />
                            );
                        case 'itemCatalog':
                            return (
                                <VLNProfileItemCatalogSection
                                    itemsCatalogueList={itemsCatalogueList}
                                />
                            );
                    }
                })()}
            </Box>
        </>
    ) : (
        <Box className={'flex flex--aic'} width={'100%'} height={'100%'}>
            <LazyFallback />
        </Box>
    );
};

export default VendorLNProfileMainContainer;
