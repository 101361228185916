import {
    IAdditionalCost,
    IAdditionalCostsBackend,
    IDiscount,
} from '../../AdditionalCost/models/AdditionalCost.model';
import { TemplateItemOption } from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { IFieldTypeConstraints } from '../../FieldTypes/FieldTypes.model';
import { StandardPaymentReferenceDateEnum } from './PaymentTermsInterface';

export enum TemplateStandardFieldsEnum {
    QUANTITY = 'QUANTITY',
    DELIVERY_DATE = 'DELIVERY_DATE',
    BASE_RATE = 'BASE_RATE',
    SHIPPING_RATE = 'SHIPPING_RATE',
    PAYMENT_TERMS = 'PAYMENT_TERMS',
    LEAD_TIME = 'LEAD_TIME',
    INCOTERMS = 'INCOTERMS',
    GR_TOLERANCE = 'GR_TOLERANCE',
    REQUISITION = 'REQUISITION',
    ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
    ITEM_DESCRIPTION = 'ITEM_DESCRIPTION',
    PROJECT = 'PROJECT',
    COST_CENTER = 'COST_CENTER',
    GL = 'GL',
    ADDITIONAL_COSTS = 'ADDITIONAL_COSTS',
    TAX = 'TAX',
    DISCOUNT = 'DISCOUNT',
    OVERALL_ADDITIONAL_COSTS = 'OVERALL_ADDITIONAL_COSTS',
    OVERALL_TAX = 'OVERALL_TAX',
    OVERALL_DISCOUNT = 'OVERALL_DISCOUNT',
    EVENT_NAME = 'EVENT_NAME',
    EVENT_TYPE = 'EVENT_TYPE',
    BUYER_ENTITY = 'BUYER_ENTITY',
    TEMPLATE = 'TEMPLATE',
    EVENT_DEADLINE = 'EVENT_DEADLINE',
    CURRENCY = 'CURRENCY',
    CUSTOMER_NAME = 'CUSTOMER_NAME',
    DEFAULT_ITEM_TERMS = 'DEFAULT_ITEM_TERMS',
    BOM = 'BOM',
    BOM_ID = 'BOM_ID',
    VENDOR = 'VENDOR',
}

export const TemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: TemplateStandardFieldsEnum;
} = {
    Quantity: TemplateStandardFieldsEnum.QUANTITY,
    'Delivery date': TemplateStandardFieldsEnum.DELIVERY_DATE,
    'Target rate': TemplateStandardFieldsEnum.BASE_RATE,
    'Shipping rate': TemplateStandardFieldsEnum.SHIPPING_RATE,
    // Tax: TemplateStandardFieldsEnum.TAX,
    'Customer name': TemplateStandardFieldsEnum.CUSTOMER_NAME,
    'Payment terms': TemplateStandardFieldsEnum.PAYMENT_TERMS,
    Incoterms: TemplateStandardFieldsEnum.INCOTERMS,
    'Lead time': TemplateStandardFieldsEnum.LEAD_TIME,
    'GR tolerance': TemplateStandardFieldsEnum.GR_TOLERANCE,
    Requisition: TemplateStandardFieldsEnum.REQUISITION,
    'Additional information': TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION,
    Project: TemplateStandardFieldsEnum.PROJECT,
    'Cost center': TemplateStandardFieldsEnum.COST_CENTER,
    GL: TemplateStandardFieldsEnum.GL,
    'Additional costs': TemplateStandardFieldsEnum.ADDITIONAL_COSTS,
    Taxes: TemplateStandardFieldsEnum.TAX,
    Discount: TemplateStandardFieldsEnum.DISCOUNT,
    'Overall Additional costs':
        TemplateStandardFieldsEnum.OVERALL_ADDITIONAL_COSTS,
    'Overall Taxes': TemplateStandardFieldsEnum.OVERALL_TAX,
    'Overall Discount': TemplateStandardFieldsEnum.OVERALL_DISCOUNT,
    Currency: TemplateStandardFieldsEnum.CURRENCY,
    'Event deadline': TemplateStandardFieldsEnum.EVENT_DEADLINE,
    Template: TemplateStandardFieldsEnum.TEMPLATE,
    'Buyer Entity': TemplateStandardFieldsEnum.BUYER_ENTITY,
    'Event type': TemplateStandardFieldsEnum.EVENT_TYPE,
    'Event name': TemplateStandardFieldsEnum.EVENT_NAME,
    'Default item terms': TemplateStandardFieldsEnum.DEFAULT_ITEM_TERMS,
    BOM: TemplateStandardFieldsEnum.BOM,
    Vendor: TemplateStandardFieldsEnum.VENDOR,
};

export enum RfqStandardSections {
    ESSENTIAL_TERMS = 'ESSENTIAL_TERMS',
    PAYMENT_AND_DELIVERY_TERMS = 'PAYMENT_AND_DELIVERY_TERMS',
    ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
    EVENT_DETAILS = 'EVENT_DETAILS',
    VENDOR_INFORMATION = 'VENDOR_INFORMATION',
}

export type standardSectionsBackendName =
    | 'Payment and Delivery Terms'
    | 'Additional Details'
    | 'Item Terms';

export interface IRFQTemplateFieldSetting {
    standardFields: RfqTemplateStandardFieldSettingsObj;
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export type RfqTemplateStandardFieldSettingsObj = {
    [TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.BASE_RATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.GR_TOLERANCE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.INCOTERMS]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.ITEM_DESCRIPTION]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.CUSTOMER_NAME]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.LEAD_TIME]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.PAYMENT_TERMS]: ITemplatePaymentTermsOptions;
    [TemplateStandardFieldsEnum.QUANTITY]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.VENDOR]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.REQUISITION]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.SHIPPING_RATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.DELIVERY_DATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.PROJECT]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.BOM]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.BOM_ID]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.COST_CENTER]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.GL]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.EVENT_NAME]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.EVENT_TYPE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.TEMPLATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.EVENT_DEADLINE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.CURRENCY]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.BUYER_ENTITY]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.DEFAULT_ITEM_TERMS]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.ADDITIONAL_COSTS]: ITemplateAdditionalCostOptions;
    [TemplateStandardFieldsEnum.TAX]: ITemplateTaxesOptions;
    [TemplateStandardFieldsEnum.DISCOUNT]: ITemplateDiscountOptions;
    [TemplateStandardFieldsEnum.OVERALL_ADDITIONAL_COSTS]: ITemplateAdditionalCostOptions;
    [TemplateStandardFieldsEnum.OVERALL_TAX]: ITemplateTaxesOptions;
    [TemplateStandardFieldsEnum.OVERALL_DISCOUNT]: ITemplateDiscountOptions;
};

export interface IRfqTemplate {
    templateId: string;
    templateName: string;
    linked_templates?: {
        linked_po_template_id: string;
        linked_po_action: string;
    } | null;
    fieldSettings: IRFQTemplateFieldSetting;
    sections: IRfqTemplateSections;
    auto_reminders: {
        initial_reminder_hours: number;
        is_remainder_required: boolean;
        max_reminders: number;
        reminder_interval: number;

        // default_max_reminders: number;
        // default_reminder_interval: number;
        // default_reminder_required: boolean;
        // default_intital_reminder_hours: number;
    };
}

export type IRfqTemplateSections = {
    [section in RfqStandardSections]: IRfqTemplateSectionDetails;
};

export interface IRfqTemplateSectionDetails {
    label: string;
    fieldList: ISectionField[];
}

export type ISectionField =
    | {
          fieldType: 'STANDARD';
          field: TemplateStandardFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export type ITemplateStandardFieldOptionsBase = {
    label: string;
    description: string;
    // isItemLevel: boolean;
    buyerSettings: {
        showField: boolean;
        required: boolean;
    };
    sellerSettings: {
        showField: boolean;
        negotiable: boolean;
        isMandatory: boolean;
    };
    sendToPo?: boolean;
    canSplit?: boolean;
    fieldLevel?: 'ITEM' | 'OTHER' | 'DELIVERY_SCHEDULE';
};

export type ITemplateStandardFieldOptions =
    ITemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'STANDARD';
    };

export type ITemplatePaymentTermsOptions = ITemplateStandardFieldOptionsBase & {
    standardFieldCategory: 'PAYMENT_TERMS';
    paymentTermOptions:
        | ITemplatePaymentTermsOptionsInvoiceItemValues
        | ITemplatePaymentTermsOptionsPoItemPercentages;
};

export type ITemplatePaymentTermsOptionsInvoiceItemValues = {
    paymentType: 'invoiceItemValues';
    prepaymentAllowed: boolean;
    standardReferenceDateOptions: {
        [key in StandardPaymentReferenceDateEnum]: {
            label: string;
            enabled: boolean;
        };
    };
    customReferenceDateOptions: string[];
    allowOtherReferenceDate: boolean;
};

export type ITemplatePaymentTermsOptionsPoItemPercentages = {
    paymentType: 'poItemPercentages';
    prepaymentAllowed: boolean;
    deliverableNameOptions: string[];
    allowOtherDeliverableNames: boolean;
};

// type for Additional Cost and Discount
export type ITemplateAdditionalCostOptions =
    ITemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'ADDITIONAL_COSTS';
        additionalCostOptions: ({
            is_required: boolean;
        } & IAdditionalCost)[];
    };

export type ITemplateTaxesOptions = ITemplateStandardFieldOptionsBase & {
    standardFieldCategory: 'TAX';
    taxesOptions: ({
        is_required: boolean;
    } & IAdditionalCost)[];
};

export type ITemplateDiscountOptions = ITemplateStandardFieldOptionsBase & {
    standardFieldCategory: 'DISCOUNT';
    discountOptions: ({
        is_required: boolean;
    } & IDiscount)[];
};

export interface ITemplateCustomFieldOptions
    extends ITemplateStandardFieldOptionsBase {
    type: CustomFieldType;
    constraints: IFieldTypeConstraints;
}

export enum CustomFieldType {
    SHORT_TEXT = 'SHORT_TEXT',
    LONG_TEXT = 'LONG_TEXT',
    SHORTTEXT = 'SHORTTEXT',
    LONGTEXT = 'LONGTEXT',
    DECIMAL = 'DECIMAL',
    LOCATION = 'LOCATION',
    CURRENCY = 'CURRENCY',
    INTEGER = 'INTEGER',
    CHOICE = 'CHOICE',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    FLOAT = 'FLOAT',
    MULTI_CHOICE = 'MULTI_CHOICE',
    ATTACHMENT = 'ATTACHMENT',
    TEMPLATE = 'TEMPLATE',
    // MULTI_SELECT = 'MULTI_SELECT',
}

export interface IRFQTemplateBackendResponse {
    template_id: string;
    linked_templates?: {
        linked_po_template_id: string;
        linked_po_action: string;
    } | null;
    auto_reminders: {
        max_reminders: number;
        reminder_interval: number;
        is_remainder_required: boolean;
        initial_reminder_hours: number;
    };
    section_list: Array<{
        section_id: string;
        section_items: Array<IRFQTemplateSectionItemBackendResponse>;
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        name: string;
        alternate_name: string;
        description: string;
        sequence: number;
        is_visible: boolean;
        is_optional: boolean;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        template: string;
        permission_modules?: string[];
    }>;
    entity: {
        entity_name: string;
        entity_id: string;
    };
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    name: string;
    type: string;
    description: string;
    permission_modules: string[];
    tags: Array<string>;
    is_global: boolean;
    status: string;
    published_on: any;
    created_on: string;
    last_edited_on: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    enterprise: string;
    creator: string;
    last_edited_by: string;
}

export interface IRFQTemplateSectionItemBackendResponse {
    section_item_id: string;
    created_datetime: string;
    field_level: 'ITEM' | 'OTHER' | 'DELIVERY_SCHEDULE';

    modified_datetime: string;
    deleted_datetime: any;
    sequence: number;
    name: string;
    alternate_name: string;
    description: string;
    constraints: {
        max_limit?: number;
        min_limit?: number;
        field_type: string;
        decimal_places?: number;
        choices?: string[];
        choice_type?: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
    };
    can_remove: boolean;
    send_to_po?: boolean;
    can_split?: boolean;
    is_builtin_field: boolean;
    is_required: boolean;
    is_mandatory: boolean;
    is_variable: boolean;
    additional_information: {
        is_locked: boolean;
        // is_hidden: boolean;
        is_visible: boolean;
        is_negotiable: boolean;
        is_hidden: boolean;
        additional_cost_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        shipping_rate_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        taxes_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        discount_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    section: string;
    parent_section_item: string | null;
    permission_modules?: string[];
}

export interface IRFQTemplateItemBackendResponse {
    section_item_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    sequence: number;
    name: string;
    alternate_name: string;
    description: string;
    constraints: {
        max_limit?: number;
        min_limit?: number;
        field_type: string;
        choices?: string[];
        decimal_places?: number;
        choice_type?: 'DROPDOWN' | 'MULTI_SELECT';
    };
    can_remove: boolean;
    send_to_po?: boolean;
    can_split?: boolean;
    is_builtin_field: boolean;
    is_required: boolean;
    is_variable: boolean;
    additional_information: {
        is_visible: boolean;
        is_negotiable: boolean;
        is_hidden: boolean;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    section: string;
    parent_section_item: string | null;
    options?: TemplateItemOption[] | null;
}
