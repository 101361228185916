import { Box, Grid, IconButton } from '@mui/material';
import { isEmpty } from 'lodash';
import { Fragment, useContext } from 'react';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../Common/FWIcon';
import { FWInput } from '../../../../Common/FWInput';
import { FWLink } from '../../../../Common/FWLink';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { InfoBlock } from '../../Containers/ItemDirectory/ItemDetailsContainer';
import { IItemSpecification } from '../../Interfaces/ItemDirectoryInterface';
import { getDuplicateNames } from '../Settings/GRNQC/GRNQCSettings';
import { AuthContext } from '../../../../Contexts/AuthContext';

export enum SpecificationInfo {
    NAME = 'name',
    VALUE = 'value',
}

interface IItemSpecificationsProps {
    data: IItemSpecification[];
    emitSpecificationAction: (value: IItemSpecification[]) => void;
}

const ItemSpecifications = ({
    data,
    emitSpecificationAction,
}: IItemSpecificationsProps) => {
    const { authData } = useContext(AuthContext);

    const handleAddSpecification = () => {
        data.push({
            name: '',
            value: [''],
        });
        emitSpecificationAction([...data]);
    };

    const handleAddORSpecification = (index: number) => {
        let thisSpecification = data[index];
        thisSpecification.value.push('');
        emitSpecificationAction([...data]);
    };

    const handleRemoveORSpecification = (index: number, valIndex: number) => {
        let thisSpecification = data[index];
        thisSpecification.value.splice(valIndex, 1);
        emitSpecificationAction([...data]);
    };

    const handleSpecificationChange = (
        event: any,
        index: number,
        valIndex?: number
    ) => {
        let thisSpecification = data[index];
        if (valIndex !== undefined) {
            const values: any[] = thisSpecification[
                event.target.name as keyof IItemSpecification
            ] as [];
            values.splice(valIndex, 1, event.target.value);
        } else {
            thisSpecification[event.target.name as keyof IItemSpecification] =
                event.target.value;
        }
        emitSpecificationAction([...data]);
    };

    const handleRemoveSpecification = (index: number) => {
        data.splice(index, 1);
        emitSpecificationAction([...data]);
    };

    const duplicateSpecs = getDuplicateNames(
        data.map((s) => s.name.toLowerCase().trim()).filter((n) => !isEmpty(n))
    );

    const isDuplicateValue = (value: string, array: string[]) => {
        return (
            array
                .filter((val) => !isEmpty(val))
                .filter((val) => val.toLowerCase().trim() === value.trim())
                .length > 1
        );
    };

    return (
        <Grid container className="flex flex--aife">
            <Grid item xs={data.length > 0 ? 11 : 0}>
                {data.length > 0 && (
                    <Grid item className="flex" xs={12}>
                        <Grid item xs={12} className="flex">
                            <Grid item xs={5}>
                                <FWTypography color="text.secondary">
                                    Specification name
                                </FWTypography>
                            </Grid>
                            <Grid item xs={0.2} /> 
                            <Grid item xs={7}>
                                <FWTypography color="text.secondary">
                                    Specification value
                                </FWTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {data.map((spec: IItemSpecification, index: number) => {
                    return (
                        <Grid
                            item
                            className="flex flex--aifs"
                            sx={{ mb: '15px'}}
                            xs={12}
                            key={index}
                        >
                            <>
                                <Grid item xs={12}    className="flex">
                                    <InfoBlock item xs={5} sx={{ mb: 0,mr:0.4}}>
                                        <FWInput
                                            value={spec.name}
                                            name={SpecificationInfo.NAME}
                                            onChange={(event: any) => {
                                                handleSpecificationChange(
                                                    event,
                                                    index
                                                );
                                            }}
                                            error={duplicateSpecs.includes(
                                                spec.name.toLowerCase().trim()
                                            )}
                                            helper={{
                                                text: duplicateSpecs.includes(
                                                    spec.name
                                                        .toLowerCase()
                                                        .trim()
                                                )
                                                    ? `Duplicate name ${spec.name}`
                                                    : '',
                                            }}
                                            iconEnd={
                                                <FWTooltip title="Delete specification">
                                                    <div>
                                                        <Box
                                                            color="error.main"
                                                            onClick={() => {
                                                                handleRemoveSpecification(
                                                                    index
                                                                );
                                                            }}
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <i className="bi bi-trash flex flex--aic"></i>
                                                        </Box>
                                                    </div>
                                                </FWTooltip>
                                            }
                                            required
                                            disabled={
                                                authData.details?.role !==
                                                'ADMIN'
                                            }
                                        />
                                    </InfoBlock>

                                    <Grid item xs={7}>
                                        {spec.value.map((val, valIndex) => (
                                            <Grid
                                                container
                                                alignItems="center"
                                                marginBottom="5px"
                                                key={valIndex}
                                            >
                                                <InfoBlock
                                                    item
                                                    xs={8}
                                                    sx={{ mb: 0 }}
                                                    className="item--or--spec"
                                                >
                                                    <div className="flex flex--aic">
                                                        <FWInput
                                                            value={val}
                                                            name={
                                                                SpecificationInfo.VALUE
                                                            }
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                handleSpecificationChange(
                                                                    event,
                                                                    index,
                                                                    valIndex
                                                                );
                                                            }}
                                                            required
                                                            error={isDuplicateValue(
                                                                val,
                                                                spec.value
                                                            )}
                                                            helper={{
                                                                text: isDuplicateValue(
                                                                    val,
                                                                    spec.value
                                                                )
                                                                    ? `Duplicate value ${val}`
                                                                    : '',
                                                            }}
                                                            disabled={
                                                                authData.details
                                                                    ?.role !==
                                                                'ADMIN'
                                                            }
                                                        />
                                                    </div>
                                                </InfoBlock>

                                                <InfoBlock
                                                    item
                                                    xs={4}
                                                    sx={{ mb: 0 }}
                                                    className="item--or--spec"
                                                >
                                                    <div className="flex flex--aic">
                                                        <span
                                                            className="mr--5 flex"
                                                            /* style={{
                                                                visibility:
                                                                    valIndex ===
                                                                    spec.value
                                                                        .length -
                                                                        1
                                                                        ? 'hidden'
                                                                        : undefined,
                                                            }} */
                                                        >
                                                            OR
                                                        </span>
                                                        {spec.value.length >
                                                            1 && (
                                                            <FWTooltip
                                                                title="Remove variation"
                                                                placement="right"
                                                            >
                                                                <div>
                                                                    <IconButton
                                                                        sx={{
                                                                            width: '30px',
                                                                            height: '30px',
                                                                            display:
                                                                                'flex',
                                                                            alignItems:
                                                                                'center',
                                                                        }}
                                                                        onClick={() => {
                                                                            handleRemoveORSpecification(
                                                                                index,
                                                                                valIndex
                                                                            );
                                                                        }}
                                                                        color="error"
                                                                    >
                                                                        <FWIcon
                                                                            name="bi bi-dash-circle"
                                                                            size={
                                                                                22
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </div>
                                                            </FWTooltip>
                                                        )}
                                                        {authData.details
                                                            ?.role ===
                                                            'ADMIN' &&
                                                            valIndex ===
                                                                spec.value
                                                                    .length -
                                                                    1 && (
                                                                <FWTooltip
                                                                    title="Add variation"
                                                                    placement="right"
                                                                >
                                                                    <div>
                                                                        <IconButton
                                                                            sx={{
                                                                                width: '30px',
                                                                                height: '30px',
                                                                                display:
                                                                                    'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() => {
                                                                                handleAddORSpecification(
                                                                                    index
                                                                                );
                                                                            }}
                                                                            color="primary"
                                                                        >
                                                                            <FWIcon
                                                                                name="bi bi-plus-circle"
                                                                                size={
                                                                                    22
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                    </div>
                                                                </FWTooltip>
                                                            )}
                                                    </div>
                                                </InfoBlock>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </>
                        </Grid>
                    );
                })}
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    mb:
                        data.length > 0 &&
                        duplicateSpecs.includes(
                            data[data.length - 1].name.toLowerCase().trim()
                        )
                            ? '33px'
                            : '14px',
                }}
            >
                {authData.details?.role === 'ADMIN' ? (
                    <FWLink onClick={handleAddSpecification}>
                        Add new Specification
                    </FWLink>
                ) : (
                    '-'
                )}
            </Grid>
        </Grid>
    );
};

export default ItemSpecifications;
