import { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import {
    IPermissions,
    PermissionModule,
    PermissionPortal,
    PermissionType,
} from '../Models/AuthContext.model';
import { IUserPermission } from '../Models/UserDetails.model';
import { IEntity } from '../Organizations/Admin/Interfaces/EnterpriseInterface';
import { fetchUsersEntities } from '../Organizations/Admin/Services/EnterpriseService';
import { get, post } from '../Utils/api';
import { addInterceptor, instance, removeInterceptor } from '../Utils/config';
import {
    emailLoginErrorHandler,
    handleError,
    otpInitialiseErrorHndler,
    otpLoginErrorHndler,
} from './ErrorHandler';

// get new Access authData from refresh authData and also update permissions and user details context
export const refreshAccessToken = () => {
    /*refreshToken: a valid Jwt authData */
    return new Promise(async (resolve, reject) => {
        // validate user to get access authData
        /*function to get new access authData from a refresh authData if valid and present in local storage */
        let refreshToken =
            localStorage.getItem('refreshToken') ||
            sessionStorage.getItem('refreshToken');
        let idToken =
            localStorage.getItem('idToken') ||
            sessionStorage.getItem('idToken');
        if (
            refreshToken !== null &&
            refreshToken !== '' &&
            idToken !== null &&
            idToken !== ''
        ) {
            try {
                let response = await post<any, any>(
                    '/authentication/refresh/',
                    {
                        id_token: idToken,
                        refresh_token: refreshToken,
                    }
                );
                let access = response.data.access_token;
                addInterceptor(response.data.id_token, instance);
                if (localStorage.getItem('idToken'))
                    localStorage.setItem('idToken', response.data.id_token);
                else sessionStorage.setItem('idToken', response.data.id_token);
                let userDetailsPromise = get<any>(
                    '/organization/organization_user'
                );
                let userPermissionPromise = get<any>(
                    '/organization/user_permission?all_users=false'
                );
                let res_array = await Promise.all([
                    userDetailsPromise,
                    userPermissionPromise,
                ]);
                let userDetails = res_array[0].data;
                let userPermissions = res_array[1].data;

                resolve({
                    error: 'false',
                    permissions: userPermissions[userDetails!.user_id!],
                    details: userDetails,
                    access: access,
                });
            } catch (err) {
                reject();
            }
        } else {
            reject();
        }
    });
};

export const validateWithEmail = (email: string, password: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await post<any, any>(
                '/authentication/user/email/credentials/',
                { email: email, password: password }
            );
            resolve(res);
        } catch (err) {
            let err_resp = emailLoginErrorHandler(err);
            reject(err_resp.msg);
        }
    });
};
export const initialiseOTP = (
    username: string,
    isEmail: boolean,
    resent?: boolean
) => {
    return new Promise(async (resolve, reject) => {
        if (isEmail) {
            try {
                let res = await post<any, any>(
                    '/authentication/otp/email/login/initialize/',
                    { email: username }
                );
                resolve(res);
            } catch (err) {
                let err_resp = otpInitialiseErrorHndler(err);
                toast.error(err_resp.msg);
                reject(err_resp);
            }
        } else {
            try {
                let res = await post<any, any>(
                    '/authentication/otp/login/initialize/',
                    { username: username }
                );
                resolve(res);
            } catch (err) {
                let err_resp = otpInitialiseErrorHndler(err);
                toast.error(err_resp.msg);
                reject(err_resp);
            }
        }
    });
};

export const processLogin = async (
    access: any,
    idToken: any,
    refreshToken: any
) => {
    if (true) {
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('idToken', idToken);
    } else {
        sessionStorage.setItem('refreshToken', refreshToken);
        sessionStorage.setItem('idToken', idToken);
    }
    addInterceptor(idToken as string, instance);
    let userDetailsPromise = get<any>('/organization/organization_user');
    let userPermissionPromise = get<any>(
        '/organization/user_permission?all_users=false'
    );
    let res_array = await Promise.all([
        userDetailsPromise,
        userPermissionPromise,
    ]);
    let userDetails = res_array[0].data;
    let userPermissions = res_array[1].data!;
    return {
        error: 'false',
        permissions: userPermissions[userDetails!.user_id!],
        details: userDetails,
        access: access,
    };
};

export const validateWithOTP = (
    username: string,
    otp: string,
    isEmail: boolean
) => {
    return new Promise(async (resolve, reject) => {
        if (isEmail) {
            try {
                let res = await post<any, any>(
                    '/authentication/otp/email/login/verify/',
                    { email: username, otp: otp }
                );
                resolve(res);
            } catch (err) {
                let err_resp = otpLoginErrorHndler(err);
                reject(err_resp);
            }
        } else {
            try {
                let res = await post<any, any>(
                    '/authentication/otp/login/verify/',
                    { username: username, otp: otp }
                );
                resolve(res);
            } catch (err) {
                let err_resp = otpLoginErrorHndler(err);
                reject(err_resp);
            }
        }
    });
};
export const validateWithCredentials = (
    username: string,
    password: string,
    rememberUser: boolean
) => {
    /*
          username: a valid string already validated
          password: a valid string already  validated
          rememberUser: boolean field obtained from checkbox in frontend
      */

    return new Promise(async (resolve, reject) => {
        // validate user to get access authData
        try {
            let res = await post<any, any>('/authentication/login/', {
                username: username,
                password: password,
            });
            let loginResp = await processLogin(
                res.data.access_token,
                res.data.id_token,
                res.data.refresh_token
            );
            resolve(loginResp);
        } catch (err) {
            let err_resp = handleError(err);
            reject(err_resp.msg);
        }
    });
};

// TODO: don't call the API and just delete the authData from storgaes
export const logoutWithAccessToken = (
    refresh_token: string,
    user_id: string
) => {
    return new Promise(async (resolve, reject) => {
        // validate user to get access authData
        try {
            await post<any, any>('/authentication/logout/', {
                refresh_token: refresh_token,
                user_id,
            });
            removeInterceptor(instance);
            resolve({
                error: 'false',
                success: 'true',
            });
        } catch (err) {
            reject(handleError(err as AxiosError));
        }
    });
};

export function calculateRedirectRoute(
    current_path: string,
    original_path: { pathName: string; search: string },
    user_default_homepage: string
) {
    if (current_path !== '/' || current_path.includes('docuemnt_id'))
        return current_path;
    else if (original_path.pathName !== '/')
        return original_path.pathName + original_path.search;
    else return user_default_homepage + original_path.search;
}

export const generateUserEntitiesList = async (): Promise<{
    entityUids: string[];
    entities: IEntity[];
}> => {
    try {
        const entities = await fetchUsersEntities();
        const entityUids = entities.map((entity) => entity.entityId);
        return {
            entityUids,
            entities,
        };
    } catch (error) {
        return {
            entityUids: [],
            entities: [],
        };
    }
};

export const checkPermissionHelper = (
    portal: PermissionPortal,
    module: PermissionModule,
    permission: PermissionType,
    entity_id: string | null,
    modulePermissions: IPermissions
): boolean => {
    //
    if (portal === 'CUSTOMIZE') {
    }
    if (portal === 'GLOBAL_ADMIN') {
        return modulePermissions.GLOBAL_ADMIN;
    } else if (portal === 'SELLER') {
        // if (entity_id === null) {
        //     return (
        //         modulePermissions.SELLER !== null &&
        //         modulePermissions.SELLER.size > 0
        //     );
        // }
        if (modulePermissions.SELLER === null) {
            return false;
        }

        switch (module) {
            case null:
                return true;
            case 'QUOTE_CALCULATOR':
                if (modulePermissions.SELLER.QUOTE_CALCULATOR === null) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.SELLER.QUOTE_CALCULATOR.VIEW !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.SELLER.QUOTE_CALCULATOR.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'EDIT':
                        return (
                            modulePermissions.SELLER.QUOTE_CALCULATOR.EDIT !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.SELLER.QUOTE_CALCULATOR.EDIT.has(
                                    entity_id
                                ))
                        );
                    case 'APPROVE':
                        return (
                            modulePermissions.SELLER.QUOTE_CALCULATOR
                                .APPROVE !== null &&
                            (entity_id === null ||
                                modulePermissions.SELLER.QUOTE_CALCULATOR.APPROVE.has(
                                    entity_id
                                ))
                        );
                    case 'CALCULATOR_EDIT':
                        return (
                            modulePermissions.SELLER.QUOTE_CALCULATOR
                                .CALCULATOR_EDIT !== null &&
                            (entity_id === null ||
                                modulePermissions.SELLER.QUOTE_CALCULATOR.CALCULATOR_EDIT.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }
            case 'SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER':
                if (
                    modulePermissions.SELLER
                        .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER === null
                ) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.SELLER
                                .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER
                                .VIEW !== null &&
                            (entity_id === null ||
                                modulePermissions.SELLER.SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'EDIT':
                        return (
                            modulePermissions.SELLER
                                .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER
                                .EDIT !== null &&
                            (entity_id === null ||
                                modulePermissions.SELLER.SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER.EDIT.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }
        }
        return false;

        // return (
        //     modulePermissions.SELLER !== null &&
        //     modulePermissions.SELLER.has(entity_id)
        // );
    } else if (portal === 'BUYER') {
        if (modulePermissions.BUYER === null) {
            return false;
        }
        switch (module) {
            case null:
                return true;
            case 'EVENT':
                if (modulePermissions.BUYER.EVENT === null) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.BUYER.EVENT.VIEW !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.EVENT.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'EDIT':
                        return (
                            modulePermissions.BUYER.EVENT.EDIT !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.EVENT.EDIT.has(
                                    entity_id
                                ))
                        );
                    case 'APPROVE':
                        return (
                            modulePermissions.BUYER.EVENT.APPROVE !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.EVENT.APPROVE.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }
            case 'REQUISITION':
                if (modulePermissions.BUYER.REQUISITION === null) {
                    return false;
                }
                switch (permission) {
                    case 'REQUISITION_VIEW':
                        return (
                            modulePermissions.BUYER.REQUISITION
                                .REQUISITION_VIEW !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.REQUISITION.REQUISITION_VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'REQUISITION_EDIT':
                        return (
                            modulePermissions.BUYER.REQUISITION
                                .REQUISITION_EDIT !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.REQUISITION.REQUISITION_EDIT.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }

            case 'CART':
                if (modulePermissions.BUYER.CART === null) {
                    return false;
                }
                switch (permission) {
                    case 'CART_VIEW':
                        return (
                            modulePermissions.BUYER.CART.CART_VIEW !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.CART.CART_VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'CART_EDIT':
                        return (
                            modulePermissions.BUYER.CART.CART_EDIT !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.CART.CART_EDIT.has(
                                    entity_id
                                ))
                        );
                    case 'CART_APPROVE':
                        return (
                            modulePermissions.BUYER.CART.CART_APPROVE !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.CART.CART_APPROVE.has(
                                    entity_id
                                ))
                        );
                    case 'CART_TEMPLATE':
                        return (
                            modulePermissions.BUYER.CART.CART_TEMPLATE !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.CART.CART_TEMPLATE.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }

            case 'PURCHASE_ORDER':
                if (modulePermissions.BUYER.PURCHASE_ORDER === null) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.BUYER.PURCHASE_ORDER.VIEW !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.PURCHASE_ORDER.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'EDIT':
                        return (
                            modulePermissions.BUYER.PURCHASE_ORDER.EDIT !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.PURCHASE_ORDER.EDIT.has(
                                    entity_id
                                ))
                        );
                    case 'ADMIN':
                        return (
                            modulePermissions.BUYER.PURCHASE_ORDER.ADMIN !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.PURCHASE_ORDER.ADMIN.has(
                                    entity_id
                                ))
                        );
                    case 'APPROVE':
                        return (
                            modulePermissions.BUYER.PURCHASE_ORDER.APPROVE !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.PURCHASE_ORDER.APPROVE.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }

            case 'INVOICE':
                if (modulePermissions.BUYER.INVOICE === null) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.BUYER.INVOICE.VIEW !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.INVOICE.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'VERIFY':
                        return (
                            modulePermissions.BUYER.INVOICE.VERIFY !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.INVOICE.VERIFY.has(
                                    entity_id
                                ))
                        );

                    case 'EDIT':
                        return (
                            modulePermissions.BUYER.INVOICE.EDIT !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.INVOICE.EDIT.has(
                                    entity_id
                                ))
                        );
                    case 'ADMIN':
                        return (
                            modulePermissions.BUYER.INVOICE.ADMIN !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.INVOICE.ADMIN.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }
            case 'GOODS_RECEIPT':
                if (modulePermissions.BUYER.GOODS_RECEIPT === null) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.BUYER.GOODS_RECEIPT.VIEW !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.GOODS_RECEIPT.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'EDIT':
                        return (
                            modulePermissions.BUYER.GOODS_RECEIPT.EDIT !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.GOODS_RECEIPT.EDIT.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }

            case 'QUALITY_CHECK':
                if (modulePermissions.BUYER.QUALITY_CHECK === null) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.BUYER.QUALITY_CHECK.VIEW !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.QUALITY_CHECK.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'EDIT':
                        return (
                            modulePermissions.BUYER.QUALITY_CHECK.EDIT !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.QUALITY_CHECK.EDIT.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }

            case 'PAYMENT':
                if (modulePermissions.BUYER.PAYMENT === null) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.BUYER.PAYMENT.VIEW !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.PAYMENT.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'EDIT':
                        return (
                            modulePermissions.BUYER.PAYMENT.EDIT !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.PAYMENT.EDIT.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }
            case 'CONTRACT':
                if (modulePermissions.BUYER.CONTRACT === null) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.BUYER.CONTRACT.VIEW !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.CONTRACT.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'EDIT':
                        return (
                            modulePermissions.BUYER.CONTRACT.EDIT !== null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.CONTRACT.EDIT.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }
            case 'SUPPLIER_RELATIONSHIP_MANAGEMENT':
                if (
                    modulePermissions.BUYER.SUPPLIER_RELATIONSHIP_MANAGEMENT ===
                    null
                ) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.BUYER
                                .SUPPLIER_RELATIONSHIP_MANAGEMENT.VIEW !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.SUPPLIER_RELATIONSHIP_MANAGEMENT.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'EDIT':
                        return (
                            modulePermissions.BUYER
                                .SUPPLIER_RELATIONSHIP_MANAGEMENT.EDIT !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.BUYER.SUPPLIER_RELATIONSHIP_MANAGEMENT.EDIT.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }

            case 'CUSTOMER_PORTAL':
                return true;
        }
    } else if (portal === 'CUSTOMIZE') {
        if (modulePermissions.CUSTOMIZE === null) {
            return false;
        }

        switch (module) {
            case null:
                return true;
            case 'PROJECT':
                if (modulePermissions.CUSTOMIZE.PROJECT === null) {
                    return false;
                }
                switch (permission) {
                    case 'PROJECT_VIEW':
                        return (
                            modulePermissions.CUSTOMIZE.PROJECT.PROJECT_VIEW !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.CUSTOMIZE.PROJECT.PROJECT_VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'PROJECT_CREATE':
                        return (
                            modulePermissions.CUSTOMIZE.PROJECT
                                .PROJECT_CREATE !== null &&
                            (entity_id === null ||
                                modulePermissions.CUSTOMIZE.PROJECT.PROJECT_CREATE.has(
                                    entity_id
                                ))
                        );
                    case 'PROJECT_EDIT':
                        return (
                            modulePermissions.CUSTOMIZE.PROJECT.PROJECT_EDIT !==
                                null &&
                            (entity_id === null ||
                                modulePermissions.CUSTOMIZE.PROJECT.PROJECT_EDIT.has(
                                    entity_id
                                ))
                        );
                    case 'PROJECT_ASSIGN_USERS':
                        return (
                            modulePermissions.CUSTOMIZE.PROJECT
                                .PROJECT_ASSIGN_USERS !== null &&
                            (entity_id === null ||
                                modulePermissions.CUSTOMIZE.PROJECT.PROJECT_ASSIGN_USERS.has(
                                    entity_id
                                ))
                        );
                    case 'PROJECT_TEMPLATE':
                        return (
                            modulePermissions.CUSTOMIZE.PROJECT
                                .PROJECT_TEMPLATE !== null &&
                            (entity_id === null ||
                                modulePermissions.CUSTOMIZE.PROJECT.PROJECT_TEMPLATE.has(
                                    entity_id
                                ))
                        );
                    case 'PROJECT_ASSIGN_MANAGERS':
                        return (
                            modulePermissions.CUSTOMIZE.PROJECT
                                .PROJECT_ASSIGN_MANAGERS !== null &&
                            (entity_id === null ||
                                modulePermissions.CUSTOMIZE.PROJECT.PROJECT_ASSIGN_MANAGERS.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }
        }
    } else if (portal === 'CUSTOMER') {
        if (modulePermissions.CUSTOMER === null) {
            return false;
        }
        switch (module) {
            case null:
                return true;
            case 'QUOTE_CALCULATOR_CUSTOMER':
                if (
                    modulePermissions.CUSTOMER.QUOTE_CALCULATOR_CUSTOMER ===
                    null
                ) {
                    return false;
                }
                switch (permission) {
                    case 'VIEW':
                        return (
                            modulePermissions.CUSTOMER.QUOTE_CALCULATOR_CUSTOMER
                                .VIEW !== null &&
                            (entity_id === null ||
                                modulePermissions.CUSTOMER.QUOTE_CALCULATOR_CUSTOMER.VIEW.has(
                                    entity_id
                                ))
                        );
                    case 'EDIT':
                        return (
                            modulePermissions.CUSTOMER.QUOTE_CALCULATOR_CUSTOMER
                                .EDIT !== null &&
                            (entity_id === null ||
                                modulePermissions.CUSTOMER.QUOTE_CALCULATOR_CUSTOMER.EDIT.has(
                                    entity_id
                                ))
                        );
                    default:
                        return false;
                }
        }
    }
    return false;
};

export const createPermissionContext = (
    permissionsObj: Array<IUserPermission>,
    isAdmin: boolean,
    entityUids: string[]
): Promise<IPermissions> => {
    return new Promise<IPermissions>(async (resolve, reject) => {
        if (isAdmin) {
            try {
                const entitySet = new Set(entityUids);
                const permissionsObj: IPermissions = {
                    BUYER: {
                        BUYER: entitySet,
                        EVENT: {
                            VIEW: entitySet,
                            EDIT: entitySet,
                            APPROVE: entitySet,
                            APPROVAL_CRITERIA: null,
                        },
                        PURCHASE_ORDER: {
                            VIEW: entitySet,
                            EDIT: entitySet,
                            ADMIN: entitySet,
                            APPROVE: entitySet,
                            APPROVAL_CRITERIA: null,
                        },
                        CART: {
                            CART_VIEW: entitySet,
                            CART_EDIT: entitySet,
                            CART_APPROVE: entitySet,
                            CART_TEMPLATE: entitySet,
                        },
                        REQUISITION: {
                            REQUISITION_VIEW: entitySet,
                            REQUISITION_EDIT: entitySet,
                            REQUISITION_APPROVE: entitySet,
                        },
                        INVOICE: {
                            VIEW: entitySet,
                            VERIFY: entitySet,
                            EDIT: entitySet,
                            ADMIN: entitySet,
                        },
                        GOODS_RECEIPT: {
                            VIEW: entitySet,
                            EDIT: entitySet,
                        },
                        QUALITY_CHECK: {
                            VIEW: entitySet,
                            EDIT: entitySet,
                        },
                        PAYMENT: {
                            VIEW: entitySet,
                            EDIT: entitySet,
                        },
                        CONTRACT: {
                            VIEW: entitySet,
                            EDIT: entitySet,
                        },
                        SUPPLIER_RELATIONSHIP_MANAGEMENT: {
                            VIEW: entitySet,
                            EDIT: entitySet,
                            TEMPLATE: entitySet,
                        },
                    },
                    SELLER: {
                        SELLER: entitySet,
                        QUOTE_CALCULATOR: {
                            VIEW: entitySet,
                            EDIT: entitySet,
                            APPROVE: entitySet,
                            CALCULATOR_EDIT: entitySet,
                        },
                        SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER: {
                            VIEW: entitySet,
                            EDIT: entitySet,
                        },
                    },
                    CUSTOMIZE: {
                        PROJECT: {
                            PROJECT_VIEW: entitySet,
                            PROJECT_EDIT: entitySet,
                            PROJECT_CREATE: entitySet,
                            PROJECT_ASSIGN_USERS: entitySet,
                            PROJECT_ASSIGN_MANAGERS: entitySet,
                            PROJECT_TEMPLATE: entitySet,
                        },
                    },

                    CUSTOMER: {
                        CUSTOMER: entitySet,
                        QUOTE_CALCULATOR_CUSTOMER: {
                            VIEW: entitySet,
                            EDIT: entitySet,
                        },
                    },
                    GLOBAL_ADMIN: true,
                };
                resolve(permissionsObj);
            } catch (err) {
                reject(err);
            }
        }
        let permissions = isEmpty(permissionsObj) ? [] : permissionsObj; //TODO: Remove when sahil provides empty list instead of empty object

        //Initiate object with all possible keys. Will be made null later if required.
        let permissionObject: IPermissions = {
            BUYER: {
                BUYER: new Set<string>(),
                EVENT: {
                    VIEW: new Set<string>(),
                    EDIT: new Set<string>(),
                    APPROVE: new Set<string>(),
                    APPROVAL_CRITERIA: null,
                },
                CART: {
                    CART_VIEW: new Set<string>(),
                    CART_EDIT: new Set<string>(),
                    CART_APPROVE: new Set<string>(),
                    CART_TEMPLATE: new Set<string>(),
                },
                REQUISITION: {
                    REQUISITION_VIEW: new Set<string>(),
                    REQUISITION_EDIT: new Set<string>(),
                    REQUISITION_APPROVE: new Set<string>(),
                },
                PURCHASE_ORDER: {
                    VIEW: new Set<string>(),
                    EDIT: new Set<string>(),
                    ADMIN: new Set<string>(),
                    APPROVE: new Set<string>(),
                    APPROVAL_CRITERIA: null,
                },
                INVOICE: {
                    VIEW: new Set<string>(),
                    VERIFY: new Set<string>(),
                    EDIT: new Set<string>(),
                    ADMIN: new Set<string>(),
                },
                GOODS_RECEIPT: {
                    VIEW: new Set<string>(),
                    EDIT: new Set<string>(),
                },
                QUALITY_CHECK: {
                    VIEW: new Set<string>(),
                    EDIT: new Set<string>(),
                },
                PAYMENT: {
                    VIEW: new Set<string>(),
                    EDIT: new Set<string>(),
                },
                CONTRACT: {
                    VIEW: new Set<string>(),
                    EDIT: new Set<string>(),
                },
                SUPPLIER_RELATIONSHIP_MANAGEMENT: {
                    VIEW: new Set<string>(),
                    EDIT: new Set<string>(),
                    TEMPLATE: new Set<string>(),
                },
            },
            SELLER: {
                SELLER: new Set<string>(),
                QUOTE_CALCULATOR: {
                    VIEW: new Set<string>(),
                    EDIT: new Set<string>(),
                    APPROVE: new Set<string>(),
                    CALCULATOR_EDIT: new Set<string>(),
                },
                SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER: {
                    VIEW: new Set<string>(),
                    EDIT: new Set<string>(),
                },
            },
            CUSTOMIZE: {
                PROJECT: {
                    PROJECT_ASSIGN_USERS: new Set<string>(),
                    PROJECT_ASSIGN_MANAGERS: new Set<string>(),
                    PROJECT_CREATE: new Set<string>(),
                    PROJECT_EDIT: new Set<string>(),
                    PROJECT_TEMPLATE: new Set<string>(),
                    PROJECT_VIEW: new Set<string>(),
                },
            },

            CUSTOMER: {
                CUSTOMER: new Set<string>(),
                QUOTE_CALCULATOR_CUSTOMER: {
                    VIEW: new Set<string>(),
                    EDIT: new Set<string>(),
                },
            },
            GLOBAL_ADMIN: isAdmin,
        };
        //Loop through all permissions and add entities to the object
        for (let p of permissions) {
            switch (p.action_api_group) {
                case 'BUYER':
                    permissionObject.BUYER!.BUYER!.add(p.entity_id);
                    break;

                case 'SELLER':
                    permissionObject.SELLER!.SELLER!.add(p.entity_id);
                    break;
                case 'QUOTE_VIEW':
                    permissionObject.SELLER!.QUOTE_CALCULATOR!.VIEW!.add(
                        p.entity_id
                    );
                    break;
                case 'QUOTE_EDIT':
                    permissionObject.SELLER!.QUOTE_CALCULATOR!.EDIT!.add(
                        p.entity_id
                    );
                    break;
                case 'QUOTE_APPROVE':
                    permissionObject.SELLER!.QUOTE_CALCULATOR!.APPROVE!.add(
                        p.entity_id
                    );
                    break;
                case 'QUOTE_CALCULATOR_EDIT':
                    permissionObject.SELLER!.QUOTE_CALCULATOR!.CALCULATOR_EDIT!.add(
                        p.entity_id
                    );
                    break;
                case 'EVENT_VIEW':
                    permissionObject.BUYER!.EVENT!.VIEW!.add(p.entity_id);
                    break;
                case 'EVENT_EDIT':
                    permissionObject.BUYER!.EVENT!.EDIT!.add(p.entity_id);
                    break;
                case 'EVENT_APPROVE':
                    permissionObject.BUYER!.EVENT!.APPROVE!.add(p.entity_id);
                    break;
                case 'CART_VIEW':
                    permissionObject.BUYER!.CART!.CART_VIEW!.add(p.entity_id);
                    break;
                case 'CART_EDIT':
                    permissionObject.BUYER!.CART!.CART_EDIT!.add(p.entity_id);
                    break;
                case 'CART_APPROVE':
                    permissionObject.BUYER!.CART!.CART_APPROVE!.add(
                        p.entity_id
                    );
                    break;
                case 'CART_TEMPLATE':
                    permissionObject.BUYER!.CART!.CART_TEMPLATE!.add(
                        p.entity_id
                    );
                    break;
                case 'REQUISITION_VIEW':
                    permissionObject.BUYER!.REQUISITION!.REQUISITION_VIEW!.add(
                        p.entity_id
                    );
                    break;
                case 'REQUISITION_EDIT':
                    permissionObject.BUYER!.REQUISITION!.REQUISITION_EDIT!.add(
                        p.entity_id
                    );
                    break;
                case 'REQUISITION_APPROVE':
                    permissionObject.BUYER!.REQUISITION!.REQUISITION_APPROVE!.add(
                        p.entity_id
                    );
                    break;
                case 'PURCHASE_ORDER_VIEW':
                    permissionObject.BUYER!.PURCHASE_ORDER!.VIEW!.add(
                        p.entity_id
                    );
                    break;
                case 'PURCHASE_ORDER_EDIT':
                    permissionObject.BUYER!.PURCHASE_ORDER!.EDIT!.add(
                        p.entity_id
                    );
                    break;
                case 'PURCHASE_ORDER_ADMIN':
                    permissionObject.BUYER!.PURCHASE_ORDER!.ADMIN!.add(
                        p.entity_id
                    );
                    break;
                case 'PURCHASE_ORDER_APPROVE':
                    permissionObject.BUYER!.PURCHASE_ORDER!.APPROVE!.add(
                        p.entity_id
                    );
                    break;
                case 'INVOICE_VIEW':
                    permissionObject.BUYER!.INVOICE!.VIEW!.add(p.entity_id);
                    break;

                case 'INVOICE_VERIFICATION':
                    permissionObject.BUYER!.INVOICE!.VERIFY!.add(p.entity_id);
                    break;
                case 'INVOICE_EDIT':
                    permissionObject.BUYER!.INVOICE!.EDIT!.add(p.entity_id);
                    break;
                case 'INVOICE_ADMIN':
                    permissionObject.BUYER!.INVOICE!.ADMIN!.add(p.entity_id);
                    break;
                case 'GOODS_RECEIPT_VIEW':
                    permissionObject.BUYER!.GOODS_RECEIPT!.VIEW!.add(
                        p.entity_id
                    );
                    break;
                case 'GOODS_RECEIPT_EDIT':
                    permissionObject.BUYER!.GOODS_RECEIPT!.EDIT!.add(
                        p.entity_id
                    );
                    break;
                case 'QUALITY_CHECK_VIEW':
                    permissionObject.BUYER!.QUALITY_CHECK!.VIEW!.add(
                        p.entity_id
                    );
                    break;
                case 'QUALITY_CHECK_EDIT':
                    permissionObject.BUYER!.QUALITY_CHECK!.EDIT!.add(
                        p.entity_id
                    );
                    break;
                case 'PAYMENT_VIEW':
                    permissionObject.BUYER!.PAYMENT!.VIEW!.add(p.entity_id);
                    break;
                case 'PAYMENT_EDIT':
                    permissionObject.BUYER!.PAYMENT!.EDIT!.add(p.entity_id);
                    break;
                case 'CONTRACT_VIEW':
                    permissionObject.BUYER!.CONTRACT!.VIEW!.add(p.entity_id);
                    break;
                case 'CONTRACT_EDIT':
                    permissionObject.BUYER!.CONTRACT!.EDIT!.add(p.entity_id);
                    break;
                case 'SUPPLIER_RELATIONSHIP_MANAGEMENT_VIEW':
                    permissionObject.BUYER!.SUPPLIER_RELATIONSHIP_MANAGEMENT!.VIEW!.add(
                        p.entity_id
                    );
                    break;
                case 'PROJECT_VIEW':
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_VIEW!.add(
                        p.entity_id
                    );
                    break;
                case 'PROJECT_EDIT':
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_EDIT!.add(
                        p.entity_id
                    );
                    break;
                case 'PROJECT_ASSIGN_USERS':
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_ASSIGN_USERS!.add(
                        p.entity_id
                    );
                    break;
                case 'PROJECT_ASSIGN_MANAGERS':
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_ASSIGN_MANAGERS!.add(
                        p.entity_id
                    );
                    break;

                case 'PROJECT_TEMPLATE':
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_TEMPLATE!.add(
                        p.entity_id
                    );
                    break;
                case 'PROJECT_CREATE':
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_CREATE!.add(
                        p.entity_id
                    );
                    break;
                case 'SUPPLIER_RELATIONSHIP_MANAGEMENT_EDIT':
                    permissionObject.BUYER!.SUPPLIER_RELATIONSHIP_MANAGEMENT!.EDIT!.add(
                        p.entity_id
                    );
                    break;
                case 'SUPPLIER_RELATIONSHIP_MANAGEMENT_TEMPLATE':
                    permissionObject.BUYER!.SUPPLIER_RELATIONSHIP_MANAGEMENT!.TEMPLATE!.add(
                        p.entity_id
                    );
                    break;
                case 'SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER_VIEW':
                    permissionObject.SELLER!.SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER!.VIEW!.add(
                        p.entity_id
                    );
                    break;
                case 'SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER_EDIT':
                    permissionObject.SELLER!.SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER!.EDIT!.add(
                        p.entity_id
                    );
                    break;
                case 'CUSTOMER':
                    permissionObject.CUSTOMER?.CUSTOMER?.add(p.entity_id);
                    break;
                case 'QUOTE_CUSTOMER_VIEW':
                    permissionObject.CUSTOMER!.QUOTE_CALCULATOR_CUSTOMER?.VIEW?.add(
                        p.entity_id
                    );
                    break;
                case 'QUOTE_CUSTOMER_EDIT':
                    permissionObject.CUSTOMER!.QUOTE_CALCULATOR_CUSTOMER?.EDIT?.add(
                        p.entity_id
                    );
                    break;
                default:
                    break;
            }
        }

        //Now, replace all empty sets with nulls instead
        permissionObject = {
            BUYER: {
                BUYER: permissionObject.BUYER!.BUYER!.size
                    ? permissionObject.BUYER!.BUYER
                    : null,
                EVENT:
                    permissionObject.BUYER!.EVENT!.VIEW!.size ||
                    permissionObject.BUYER!.EVENT!.EDIT!.size ||
                    permissionObject.BUYER!.EVENT!.APPROVE!.size
                        ? {
                              VIEW: permissionObject.BUYER!.EVENT!.VIEW!.size
                                  ? permissionObject.BUYER!.EVENT!.VIEW
                                  : null,
                              EDIT: permissionObject.BUYER!.EVENT!.EDIT!.size
                                  ? permissionObject.BUYER!.EVENT!.EDIT
                                  : null,
                              APPROVE: permissionObject.BUYER!.EVENT!.APPROVE!
                                  .size
                                  ? permissionObject.BUYER!.EVENT!.APPROVE
                                  : null,
                              APPROVAL_CRITERIA: null,
                          }
                        : null,
                CART:
                    permissionObject.BUYER!.CART!.CART_VIEW!.size ||
                    permissionObject.BUYER!.CART!.CART_EDIT!.size ||
                    permissionObject.BUYER!.CART!.CART_APPROVE!.size ||
                    permissionObject.BUYER!.CART!.CART_TEMPLATE!.size
                        ? {
                              CART_VIEW: permissionObject.BUYER!.CART!
                                  .CART_VIEW!.size
                                  ? permissionObject.BUYER!.CART!.CART_VIEW
                                  : null,
                              CART_EDIT: permissionObject.BUYER!.CART!
                                  .CART_EDIT!.size
                                  ? permissionObject.BUYER!.CART!.CART_EDIT
                                  : null,
                              CART_APPROVE: permissionObject.BUYER!.CART!
                                  .CART_APPROVE!.size
                                  ? permissionObject.BUYER!.CART!.CART_APPROVE
                                  : null,
                              CART_TEMPLATE: permissionObject.BUYER!.CART!
                                  .CART_TEMPLATE!.size
                                  ? permissionObject.BUYER!.CART!.CART_TEMPLATE
                                  : null,
                          }
                        : null,
                REQUISITION:
                    permissionObject.BUYER!.REQUISITION!.REQUISITION_VIEW!
                        .size ||
                    permissionObject.BUYER!.REQUISITION!.REQUISITION_EDIT!
                        .size ||
                    permissionObject.BUYER!.REQUISITION!.REQUISITION_APPROVE!
                        .size
                        ? {
                              REQUISITION_VIEW: permissionObject.BUYER!
                                  .REQUISITION!.REQUISITION_VIEW!.size
                                  ? permissionObject.BUYER!.REQUISITION!
                                        .REQUISITION_VIEW
                                  : null,
                              REQUISITION_EDIT: permissionObject.BUYER!
                                  .REQUISITION!.REQUISITION_EDIT!.size
                                  ? permissionObject.BUYER!.REQUISITION!
                                        .REQUISITION_EDIT
                                  : null,
                              REQUISITION_APPROVE: permissionObject.BUYER!
                                  .REQUISITION!.REQUISITION_APPROVE!.size
                                  ? permissionObject.BUYER!.REQUISITION!
                                        .REQUISITION_APPROVE
                                  : null,
                          }
                        : null,
                PURCHASE_ORDER:
                    permissionObject.BUYER!.PURCHASE_ORDER!.VIEW!.size ||
                    permissionObject.BUYER!.PURCHASE_ORDER!.EDIT!.size ||
                    permissionObject.BUYER!.PURCHASE_ORDER!.ADMIN!.size ||
                    permissionObject.BUYER!.PURCHASE_ORDER!.APPROVE!.size
                        ? {
                              VIEW: permissionObject.BUYER!.PURCHASE_ORDER!
                                  .VIEW!.size
                                  ? permissionObject.BUYER!.PURCHASE_ORDER!.VIEW
                                  : null,
                              EDIT: permissionObject.BUYER!.PURCHASE_ORDER!
                                  .EDIT!.size
                                  ? permissionObject.BUYER!.PURCHASE_ORDER!.EDIT
                                  : null,

                              ADMIN: permissionObject.BUYER!.PURCHASE_ORDER!
                                  .ADMIN!.size
                                  ? permissionObject.BUYER!.PURCHASE_ORDER!
                                        .ADMIN
                                  : null,
                              APPROVE: permissionObject.BUYER!.PURCHASE_ORDER!
                                  .APPROVE!.size
                                  ? permissionObject.BUYER!.PURCHASE_ORDER!
                                        .APPROVE
                                  : null,
                              APPROVAL_CRITERIA: null,
                          }
                        : null,
                INVOICE:
                    permissionObject.BUYER!.INVOICE!.VIEW!.size ||
                    permissionObject.BUYER!.INVOICE!.VERIFY!.size ||
                    permissionObject.BUYER!.INVOICE!.EDIT!.size ||
                    permissionObject.BUYER!.INVOICE!.ADMIN!.size
                        ? {
                              VIEW: permissionObject.BUYER!.INVOICE!.VIEW!.size
                                  ? permissionObject.BUYER!.INVOICE!.VIEW
                                  : null,
                              VERIFY: permissionObject.BUYER!.INVOICE!.VERIFY!
                                  .size
                                  ? permissionObject.BUYER!.INVOICE!.VERIFY
                                  : null,
                              EDIT: permissionObject.BUYER!.INVOICE!.EDIT!.size
                                  ? permissionObject.BUYER!.INVOICE!.EDIT
                                  : null,
                              ADMIN: permissionObject.BUYER!.INVOICE!.ADMIN!
                                  .size
                                  ? permissionObject.BUYER!.INVOICE!.ADMIN
                                  : null,
                          }
                        : null,
                GOODS_RECEIPT:
                    permissionObject.BUYER!.GOODS_RECEIPT!.VIEW!.size ||
                    permissionObject.BUYER!.GOODS_RECEIPT!.EDIT!.size
                        ? {
                              VIEW: permissionObject.BUYER!.GOODS_RECEIPT!.VIEW!
                                  .size
                                  ? permissionObject.BUYER!.GOODS_RECEIPT!.VIEW
                                  : null,
                              EDIT: permissionObject.BUYER!.GOODS_RECEIPT!.EDIT!
                                  .size
                                  ? permissionObject.BUYER!.GOODS_RECEIPT!.EDIT
                                  : null,
                          }
                        : null,
                QUALITY_CHECK:
                    permissionObject.BUYER!.QUALITY_CHECK!.VIEW!.size ||
                    permissionObject.BUYER!.QUALITY_CHECK!.EDIT!.size
                        ? {
                              VIEW: permissionObject.BUYER!.QUALITY_CHECK!.VIEW!
                                  .size
                                  ? permissionObject.BUYER!.QUALITY_CHECK!.VIEW
                                  : null,
                              EDIT: permissionObject.BUYER!.QUALITY_CHECK!.EDIT!
                                  .size
                                  ? permissionObject.BUYER!.QUALITY_CHECK!.EDIT
                                  : null,
                          }
                        : null,
                PAYMENT:
                    permissionObject.BUYER!.PAYMENT!.VIEW!.size ||
                    permissionObject.BUYER!.PAYMENT!.EDIT!.size
                        ? {
                              VIEW: permissionObject.BUYER!.PAYMENT!.VIEW!.size
                                  ? permissionObject.BUYER!.PAYMENT!.VIEW
                                  : null,
                              EDIT: permissionObject.BUYER!.PAYMENT!.EDIT!.size
                                  ? permissionObject.BUYER!.PAYMENT!.EDIT
                                  : null,
                          }
                        : null,
                CONTRACT:
                    permissionObject.BUYER!.CONTRACT!.VIEW!.size ||
                    permissionObject.BUYER!.CONTRACT!.EDIT!.size
                        ? {
                              VIEW: permissionObject.BUYER!.CONTRACT!.VIEW!.size
                                  ? permissionObject.BUYER!.CONTRACT!.VIEW
                                  : null,
                              EDIT: permissionObject.BUYER!.CONTRACT!.EDIT!.size
                                  ? permissionObject.BUYER!.CONTRACT!.EDIT
                                  : null,
                          }
                        : null,
                SUPPLIER_RELATIONSHIP_MANAGEMENT:
                    permissionObject.BUYER!.SUPPLIER_RELATIONSHIP_MANAGEMENT!
                        .VIEW!.size ||
                    permissionObject.BUYER!.SUPPLIER_RELATIONSHIP_MANAGEMENT!
                        .EDIT!.size ||
                    permissionObject.BUYER!.SUPPLIER_RELATIONSHIP_MANAGEMENT!
                        .TEMPLATE!.size
                        ? {
                              VIEW: permissionObject.BUYER!
                                  .SUPPLIER_RELATIONSHIP_MANAGEMENT!.VIEW!.size
                                  ? permissionObject.BUYER!
                                        .SUPPLIER_RELATIONSHIP_MANAGEMENT!.VIEW
                                  : null,
                              EDIT: permissionObject.BUYER!
                                  .SUPPLIER_RELATIONSHIP_MANAGEMENT!.EDIT!.size
                                  ? permissionObject.BUYER!
                                        .SUPPLIER_RELATIONSHIP_MANAGEMENT!.EDIT
                                  : null,
                              TEMPLATE: permissionObject.BUYER!
                                  .SUPPLIER_RELATIONSHIP_MANAGEMENT!.TEMPLATE!
                                  .size
                                  ? permissionObject.BUYER!
                                        .SUPPLIER_RELATIONSHIP_MANAGEMENT!
                                        .TEMPLATE
                                  : null,
                          }
                        : null,
            },
            SELLER: {
                SELLER: permissionObject.SELLER!.SELLER!.size
                    ? permissionObject.SELLER!.SELLER
                    : null,
                QUOTE_CALCULATOR:
                    permissionObject.SELLER!.QUOTE_CALCULATOR!.VIEW!.size ||
                    permissionObject.SELLER!.QUOTE_CALCULATOR!.EDIT!.size ||
                    permissionObject.SELLER!.QUOTE_CALCULATOR!.APPROVE!.size ||
                    permissionObject.SELLER!.QUOTE_CALCULATOR!.CALCULATOR_EDIT!
                        .size
                        ? {
                              VIEW: permissionObject.SELLER!.QUOTE_CALCULATOR!
                                  .VIEW!.size
                                  ? permissionObject.SELLER!.QUOTE_CALCULATOR!
                                        .VIEW
                                  : null,
                              EDIT: permissionObject.SELLER!.QUOTE_CALCULATOR!
                                  .EDIT!.size
                                  ? permissionObject.SELLER!.QUOTE_CALCULATOR!
                                        .EDIT
                                  : null,
                              APPROVE: permissionObject.SELLER!
                                  .QUOTE_CALCULATOR!.APPROVE!.size
                                  ? permissionObject.SELLER!.QUOTE_CALCULATOR!
                                        .APPROVE
                                  : null,
                              CALCULATOR_EDIT: permissionObject.SELLER!
                                  .QUOTE_CALCULATOR!.CALCULATOR_EDIT!.size
                                  ? permissionObject.SELLER!.QUOTE_CALCULATOR!
                                        .CALCULATOR_EDIT
                                  : null,
                          }
                        : null,
                SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER:
                    permissionObject.SELLER!
                        .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER!.VIEW!.size
                        ? {
                              VIEW: permissionObject.SELLER!
                                  .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER!
                                  .VIEW!.size
                                  ? permissionObject.SELLER!
                                        .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER!
                                        .VIEW
                                  : null,
                              EDIT: permissionObject.SELLER!
                                  .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER!
                                  .EDIT!.size
                                  ? permissionObject.SELLER!
                                        .SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER!
                                        .EDIT
                                  : null,
                          }
                        : null,
            },
            CUSTOMIZE: {
                PROJECT:
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_VIEW!.size ||
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_ASSIGN_USERS!
                        .size ||
                    permissionObject.CUSTOMIZE!.PROJECT!
                        .PROJECT_ASSIGN_MANAGERS!.size ||
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_EDIT!.size ||
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_CREATE!.size ||
                    permissionObject.CUSTOMIZE!.PROJECT!.PROJECT_TEMPLATE!.size
                        ? {
                              PROJECT_VIEW: permissionObject.CUSTOMIZE!.PROJECT!
                                  .PROJECT_VIEW!.size
                                  ? permissionObject.CUSTOMIZE!.PROJECT!
                                        .PROJECT_VIEW
                                  : null,
                              PROJECT_ASSIGN_MANAGERS:
                                  permissionObject.CUSTOMIZE!.PROJECT!
                                      .PROJECT_ASSIGN_MANAGERS!.size
                                      ? permissionObject.CUSTOMIZE!.PROJECT!
                                            .PROJECT_ASSIGN_MANAGERS
                                      : null,
                              PROJECT_ASSIGN_USERS: permissionObject.CUSTOMIZE!
                                  .PROJECT!.PROJECT_ASSIGN_USERS!.size
                                  ? permissionObject.CUSTOMIZE!.PROJECT!
                                        .PROJECT_ASSIGN_USERS
                                  : null,
                              PROJECT_CREATE: permissionObject.CUSTOMIZE!
                                  .PROJECT!.PROJECT_CREATE!.size
                                  ? permissionObject.CUSTOMIZE!.PROJECT!
                                        .PROJECT_CREATE
                                  : null,
                              PROJECT_EDIT: permissionObject.CUSTOMIZE!.PROJECT!
                                  .PROJECT_EDIT!.size
                                  ? permissionObject.CUSTOMIZE!.PROJECT!
                                        .PROJECT_EDIT
                                  : null,
                              PROJECT_TEMPLATE: permissionObject.CUSTOMIZE!
                                  .PROJECT!.PROJECT_TEMPLATE!.size
                                  ? permissionObject.CUSTOMIZE!.PROJECT!
                                        .PROJECT_TEMPLATE
                                  : null,
                          }
                        : null,
            },
            CUSTOMER: {
                CUSTOMER: permissionObject.CUSTOMER!.CUSTOMER!.size
                    ? permissionObject.CUSTOMER!.CUSTOMER
                    : null,
                QUOTE_CALCULATOR_CUSTOMER:
                    permissionObject.CUSTOMER!.QUOTE_CALCULATOR_CUSTOMER!.VIEW!
                        .size ||
                    permissionObject.CUSTOMER?.QUOTE_CALCULATOR_CUSTOMER!.EDIT!
                        .size
                        ? {
                              VIEW: permissionObject.CUSTOMER!
                                  .QUOTE_CALCULATOR_CUSTOMER!.VIEW!.size
                                  ? permissionObject.CUSTOMER!
                                        .QUOTE_CALCULATOR_CUSTOMER!.VIEW
                                  : null,
                              EDIT: permissionObject.CUSTOMER!
                                  .QUOTE_CALCULATOR_CUSTOMER!.EDIT!.size
                                  ? permissionObject.CUSTOMER!
                                        .QUOTE_CALCULATOR_CUSTOMER!.EDIT
                                  : null,
                          }
                        : null,
            },
            GLOBAL_ADMIN: permissionObject.GLOBAL_ADMIN,
        };

        //If all keys within buyer are null, make buyer itself null
        if (
            !permissionObject.BUYER!.BUYER &&
            !permissionObject.BUYER!.EVENT &&
            !permissionObject.BUYER!.CART &&
            !permissionObject.BUYER!.REQUISITION &&
            !permissionObject.BUYER!.PURCHASE_ORDER &&
            !permissionObject.BUYER!.INVOICE &&
            !permissionObject.BUYER!.GOODS_RECEIPT &&
            !permissionObject.BUYER!.QUALITY_CHECK &&
            !permissionObject.BUYER!.PAYMENT &&
            !permissionObject.BUYER!.SUPPLIER_RELATIONSHIP_MANAGEMENT
        ) {
            permissionObject.BUYER = null;
        }

        //If all keys within seller are null, make seller itself null
        if (
            !permissionObject.SELLER!.SELLER &&
            !permissionObject.SELLER!.QUOTE_CALCULATOR &&
            !permissionObject.SELLER!.SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER
        ) {
            permissionObject.SELLER = null;
        }

        //If all keys within customize are null, make customize itself null
        if (!permissionObject.CUSTOMIZE!.PROJECT) {
            permissionObject.CUSTOMIZE = null;
        }

        resolve(permissionObject);
    });
};

export const removeDecimalIfNoDigitAfterIt = (e: {
    target: { value: string };
}) => {
    const decimalRegex = /\d*\./;
    if (decimalRegex.test(e.target.value)) {
        const digitAfterDecimalRegex = /\d+\.\d+/;
        if (!digitAfterDecimalRegex.test(e.target.value)) {
            const removeDecimalRegex = /\.(\D*|0*)$/;
            if (removeDecimalRegex.test(e.target.value)) {
                return e.target.value.replace(removeDecimalRegex, '');
            }
        }
    } else {
        return e.target.value;
    }
};
