import { CircularProgress, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { debounce } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWInput } from '../../Common/FWInput';
import FWSelect from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import { customScrollbar } from '../../Common/Utils/UIUtils';
import { IGlobalAppStore } from '../../Redux/Store';
import ChatEventDetailsRow from '../Components/ChatEventDetailsRow';
import ChatSectionsExpandButton from '../Components/ChatSectionsExpandButton';
import { IChatEvent } from '../Interfaces/Chat.model';
import {
    getAllEventsListForBuyer,
    getAllEventsListForSeller,
} from '../Services/chat.service';

interface IChatEventDisplayContainerProps {
    updateEventID: (event_id: string, sub_event_id: string) => void;
    currentlySelectedEventID: string | null;
    user_type: 'buyer' | 'seller';
    setCurrentlySelectedEventStatus: React.Dispatch<
        React.SetStateAction<string>
    >;
    setShowEventsSection?: React.Dispatch<React.SetStateAction<boolean>>;
    sellerEntityUuid?: string;
}

const BUYER_EVENT_STATUSES_LIST = [
    ['DRAFTS', 'Draft'],
    // ['APPROVAL_PENDING', 'Approval pending'],
    // ['REWORK', 'Rework'],
    // ['AWARD_STAGE', 'Award stage'],
    // ['PURCHASE_ORDER_ISSUED', 'PO issued'],
    ['ONGOING', 'Ongoing'],
    // ['PAUSED', 'Paused'],
    ['FINISHED', 'Finished'],
];

const SELLER_EVENT_STATUSES_LIST = [
    // ['INVITATION_PENDING', 'Invitation'],
    // ['INVITATION_LAPSED', 'Invitation lapsed'],
    // ['INVITATION_REJECTED', 'Invitation rejected'],
    // ['BID_PENDING', 'Bid pending'],
    // ['BID_RECEIVED', 'Bid received'],
    // ['BID_SUBMITTED', 'Bid submitted'],
    // ['DEAL_WON', 'Deal won'],
    // ['DEAL_LOST', 'Deal lost'],
    // ['DEADLINE_PASSED', 'Deadline passed'],
    // ['PO_PENDING', 'PO pending'],
    // ['PO_RECEIVED', 'PO received'],
    // ['PO_REVIEWED', 'PO reviewed'],
    // ['FINISHED', 'Finished'],
    ['INVITATION', 'Invitation'],
    ['ONGOING', 'Ongoing'],
    ['FINISHED', 'Finished'],
];

export default function ChatEventDisplayContainer(
    props: IChatEventDisplayContainerProps
) {
    const [eventList, setEventList] = React.useState<IChatEvent[] | null>(null);
    const [filteredEventsList, setFilteredEventsList] = React.useState<
        IChatEvent[] | null
    >(null);
    const [selectedEventStatusFilter, setSelectedEventStatusFilter] =
        React.useState<string>('ALL');

    const getAllModulesUnreadCount = useCallback(
        (globalState: IGlobalAppStore) => {
            let moduleIDCountMap: { [key: string]: number } = {};
            Object.keys(globalState.ChatStore.messageCountDictionary).forEach(
                (key) => {
                    if (key !== 'total_count') {
                        moduleIDCountMap[key] = Object.values(
                            globalState.ChatStore.messageCountDictionary[key]
                        )?.filter((val) => val > 0)?.length;
                    }
                }
            );
            return moduleIDCountMap;
        },
        []
    );
    const allModulesUnreadCount: { [key: string]: number } = useSelector(
        getAllModulesUnreadCount,
        shallowEqual
    );

    // TODO: implement pagination using FWScrollObserver

    const [pageNo, setPageNo] = React.useState<number>(1);
    const [hasNextPage, setHasNextPage] = React.useState<boolean>(true);
    const [searchText, setSearchText] = React.useState<string>('');

    const listRef = useRef<HTMLDivElement>(null);

    // bring event with latest msg to the top
    const messagesReceived = useSelector(
        (state: IGlobalAppStore) => state.ChatStore.messagesReceived
    );

    // useEffect to get the list if all the events and store in state
    useEffect(() => {
        if (props.user_type === 'buyer') {
            getAllEventsListForBuyer(
                'all',
                pageNo,
                searchText,
                props.sellerEntityUuid
            )
                .then((res) => {
                    setEventList(res.data);
                    setFilteredEventsList(res.data);
                    setHasNextPage(res.metadata.has_next);
                })
                .catch((err) => {
                    toast.error("Couldn't fetch events list");
                });
        } else {
            getAllEventsListForSeller('all', pageNo, searchText)
                .then((res) => {
                    setEventList(res.data);
                    setFilteredEventsList(res.data);
                    setHasNextPage(res.metadata.has_next);
                })
                .catch((err) => {
                    toast.error("Couldn't fetch events list");
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sellerEntityUuid, props.user_type]);

    // update events list if new message is received
    useEffect(() => {
        setPageNo(1);
        if (messagesReceived.length > 0) {
            if (props.user_type === 'buyer') {
                getAllEventsListForBuyer(
                    'all',
                    pageNo,
                    searchText,
                    props.sellerEntityUuid
                )
                    .then((res) => {
                        setEventList(res.data);
                        setFilteredEventsList(res.data);
                        setHasNextPage(res.metadata.has_next);
                    })
                    .catch((err) => {
                        toast.error("Couldn't fetch events list");
                    });
            } else {
                getAllEventsListForSeller('all', pageNo, searchText)
                    .then((res) => {
                        setEventList(res.data);
                        setFilteredEventsList(res.data);
                    })
                    .catch((err) => {
                        toast.error("Couldn't fetch events list");
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sellerEntityUuid, props.user_type, messagesReceived]);

    const [chatLoader, setChatLoader] = useState(false);

    const handleFilterEventChange = useCallback(
        (e: any) => {
            setPageNo(1);
            const selectedEventStatusFilter = (
                e.target.value as string
            ).toLowerCase();

            // Start the loader
            setChatLoader(true);

            if (props.user_type === 'buyer') {
                getAllEventsListForBuyer(
                    selectedEventStatusFilter,
                    1,
                    searchText,
                    props.sellerEntityUuid
                )
                    .then((res) => {
                        setEventList(res.data);
                        setFilteredEventsList(res.data);
                        setHasNextPage(res.metadata.has_next);
                    })
                    .catch((err) => {
                        toast.error("Couldn't fetch events list");
                    })
                    .finally(() => {
                        // Stop the loader
                        setChatLoader(false);
                    });
            } else {
                getAllEventsListForSeller(
                    selectedEventStatusFilter,
                    1,
                    searchText
                )
                    .then((res) => {
                        setEventList(res.data);
                        setFilteredEventsList(res.data);
                    })
                    .catch((err) => {
                        toast.error("Couldn't fetch events list");
                    })
                    .finally(() => {
                        // Stop the loader
                        setChatLoader(false);
                    });
            }
        },
        [props.sellerEntityUuid, props.user_type, searchText]
    );

    const [isFetching, setIsFetching] = React.useState<boolean>(false);

    // Handle scrolling event
    const handleScroll = useCallback(() => {
        const listElement = listRef.current;

        if (listElement && !isFetching) {
            const scrollPosition =
                listElement.scrollTop + listElement.clientHeight;
            const totalHeight = listElement.scrollHeight;

            if (
                (scrollPosition === totalHeight ||
                    Math.floor(scrollPosition) + 1 === totalHeight) &&
                hasNextPage
            ) {
                setIsFetching(true);
                if (props.user_type === 'buyer') {
                    getAllEventsListForBuyer(
                        selectedEventStatusFilter.toLowerCase(),
                        pageNo + 1,
                        searchText,
                        props.sellerEntityUuid
                    )
                        .then((res) => {
                            setEventList((prev) =>
                                prev ? [...prev, ...res.data] : res.data
                            );
                            setFilteredEventsList((prev) =>
                                prev ? [...prev, ...res.data] : res.data
                            );
                            setHasNextPage(res.metadata.has_next);
                            setPageNo((prev) => prev + 1);
                            setIsFetching(false);
                        })
                        .catch((err) => {
                            toast.error("Couldn't fetch events list");
                            setIsFetching(false);
                        });
                } else {
                    getAllEventsListForSeller(
                        selectedEventStatusFilter.toLowerCase(),
                        pageNo + 1,
                        searchText
                    )
                        .then((res) => {
                            setEventList((prev) =>
                                prev ? [...prev, ...res.data] : res.data
                            );
                            setFilteredEventsList((prev) =>
                                prev ? [...prev, ...res.data] : res.data
                            );
                            setHasNextPage(res.metadata.has_next);
                            setPageNo((prev) => prev + 1);
                            setIsFetching(false);
                        })
                        .catch((err) => {
                            toast.error("Couldn't fetch events list");
                            setIsFetching(false);
                        });
                }
            }
        }
    }, [
        hasNextPage,
        isFetching,
        pageNo,
        props.sellerEntityUuid,
        props.user_type,
        searchText,
        selectedEventStatusFilter,
    ]);

    useEffect(() => {
        const listElement = listRef.current;
        if (listElement) {
            listElement.addEventListener('scroll', handleScroll);

            return () => {
                listElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll]);

    const memoizedSearch = useMemo(
        () =>
            debounce((search_string: string, callBack) => {
                setSearchText(search_string);
                setPageNo(1);
                if (props.user_type === 'buyer') {
                    getAllEventsListForBuyer(
                        selectedEventStatusFilter.toLowerCase(),
                        1,
                        search_string,
                        props.sellerEntityUuid
                    )
                        .then((res) => {
                            setEventList(res.data);
                            setFilteredEventsList(res.data);
                            setHasNextPage(res.metadata.has_next);
                            callBack();
                        })
                        .catch((err) => {
                            toast.error("Couldn't fetch events list");
                        });
                } else {
                    getAllEventsListForSeller(
                        selectedEventStatusFilter.toLowerCase(),
                        1,
                        search_string
                    )
                        .then((res) => {
                            setEventList(res.data);
                            setFilteredEventsList(res.data);
                            setHasNextPage(res.metadata.has_next);
                            callBack();
                        })
                        .catch((err) => {
                            toast.error("Couldn't fetch events list");
                        });
                }
            }, 1000),
        [props.sellerEntityUuid, props.user_type, selectedEventStatusFilter]
    );

    const handleSearchTextChange = useCallback(
        (e, callBack) => {
            memoizedSearch(e.target.value, callBack);
        },
        [memoizedSearch]
    );

    const location = useLocation();
    const currentPortalString = location.pathname.split('/')[1] ?? '';
    const currentPortal =
        currentPortalString.toUpperCase() === 'ADMIN'
            ? 'ADMIN'
            : location.pathname.includes('billing-management')
            ? 'BILLING'
            : currentPortalString.toUpperCase() === 'BUYER'
            ? 'BUYER'
            : currentPortalString.toUpperCase() === 'SELLER'
            ? 'SELLER'
            : currentPortalString.toUpperCase() === 'USER'
            ? 'PROFILE'
            : null;

    console.log('filteredEventsList', filteredEventsList);

    return (
        <Box
            /* borderRight={'1px solid'} */
            borderColor={'dividerColor.main'}
            // style={{ background: '#f1f8ff' }}
            sx={{ overflowX: 'hidden' }}
        >
            {/* Event search bar */}
            <Box padding={'10px'} style={{ display: 'flex' }}>
                {!props.sellerEntityUuid &&
                    currentPortal === 'BUYER' &&
                    props.currentlySelectedEventID &&
                    props.setShowEventsSection && (
                        <ChatSectionsExpandButton
                            onClick={() => {
                                props.setShowEventsSection &&
                                    props.setShowEventsSection(false);
                            }}
                            hidden={false}
                        />
                    )}
                <FWTypography fontWeight={'500'} fontSize={'1.25rem'} flex={1}>
                    Events
                </FWTypography>
                <br />
                <Box flex={1.5}>
                    <EventSearchField
                        event_list={eventList}
                        setFilteredEventsList={setFilteredEventsList}
                        onChange={handleSearchTextChange}
                    />
                </Box>
            </Box>
            {/* <Divider /> */}
            <Box display="flex" flexDirection={'column'}>
                {/* Event type filter */}
                <Box
                    className="flex flex--aic flex--jcsb"
                    padding={'10px'}
                    height={'70px'}
                >
                    <Typography>Event status:</Typography>
                    <FWSelect
                        sx={{ width: '10rem' }}
                        value={selectedEventStatusFilter}
                        defaultValue={'ALL'}
                        onChange={(e) => {
                            handleFilterEventChange(e);
                            setSelectedEventStatusFilter(
                                e.target.value as string
                            );
                        }}
                    >
                        <FWMenuItem value="ALL">All</FWMenuItem>
                        {props.user_type === 'buyer'
                            ? BUYER_EVENT_STATUSES_LIST.filter((val) =>
                                  props.sellerEntityUuid
                                      ? val[0] !== 'DRAFTS' &&
                                        val[0] !== 'APPROVAL_PENDING' &&
                                        val[0] !== 'REWORK'
                                      : true
                              ).map((status, idx) => (
                                  <FWMenuItem value={status[0]} key={idx}>
                                      {status[1]}
                                  </FWMenuItem>
                              ))
                            : SELLER_EVENT_STATUSES_LIST.map((status, idx) => (
                                  <FWMenuItem value={status[0]} key={idx}>
                                      {status[1]}
                                  </FWMenuItem>
                              ))}
                    </FWSelect>
                </Box>
                <Divider />
                {/* List of events */}
                <Box
                    overflow={'auto'}
                    // flex={1}
                    minHeight={'60px'}
                    maxHeight={
                        props.sellerEntityUuid
                            ? 'calc(100vh - 420px)'
                            : 'calc(100vh - 250px)'
                    }
                    className="event-list"
                    // style={{ background: '#f1f8ff' }}
                    sx={customScrollbar}
                    ref={listRef}
                >
                    {/* if event list is null show spinner */}
                    {filteredEventsList === null || chatLoader ? (
                        <Box className="flex flex--jcc" padding="0.5rem">
                            <CircularProgress color="primary" size={40} />
                        </Box>
                    ) : //  if there are no event show default text
                    filteredEventsList.length === 0 ? (
                        <Box
                            padding={'20px 10px'}
                            // borderBottom="1px solid"
                            // borderColor={'dividerColor.main'}
                        >
                            <FWTypography
                                color="text.secondary"
                                textAlign={'center'}
                            >
                                No events found
                            </FWTypography>
                        </Box>
                    ) : (
                        // if there are events show them
                        filteredEventsList.map((event_details, i) => (
                            <ChatEventDetailsRow
                                setCurrentlySelectedEventStatus={
                                    props.setCurrentlySelectedEventStatus
                                }
                                user_type={props.user_type}
                                containerSelector="event-list"
                                key={i}
                                eventDetails={event_details}
                                highlightBox={
                                    props.currentlySelectedEventID ===
                                    event_details?.event_id
                                }
                                unreadMsgCount={
                                    event_details?.event_id in
                                    allModulesUnreadCount
                                        ? allModulesUnreadCount[
                                              event_details.event_id
                                          ]
                                        : 0
                                }
                                updateEventID={props.updateEventID}
                            />
                        ))
                    )}
                    {filteredEventsList &&
                        filteredEventsList.length > 0 &&
                        hasNextPage &&
                        !chatLoader && (
                            <Box className="flex flex--jcc" padding="0.5rem">
                                <CircularProgress color="primary" size={40} />
                            </Box>
                        )}
                </Box>
            </Box>
        </Box>
    );
}

const EventSearchField = (props: {
    event_list: IChatEvent[] | null;
    setFilteredEventsList: (new_event_list: IChatEvent[] | null) => void;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        callBack: () => void
    ) => void;
}) => {
    const [showLoading, setShowLoading] = React.useState<boolean>(false);

    // const handleEventNameSearch = React.useMemo(
    //     () =>
    //         debounce((search_string: string) => {
    //             if (props.event_list !== null) {
    //                 if (search_string.trim() === '') {
    //                     props.setFilteredEventsList(props.event_list);
    //                 } else {
    //                     let newFilteredEventsList = props.event_list.filter(
    //                         (event) =>
    //                             event.event_name
    //                                 .toUpperCase()
    //                                 .includes(search_string.toUpperCase())
    //                     );
    //                     props.setFilteredEventsList(newFilteredEventsList);
    //                 }
    //             } else {
    //                 props.setFilteredEventsList(null);
    //             }
    //             setShowLoading(false);
    //         }, 1000),
    //     [props]
    // );

    return (
        <FWInput
            placeholder="Search all events"
            iconStart={<i className="bi bi-search"></i>}
            iconEnd={showLoading ? <CircularProgress size={20} /> : <></>}
            onChange={(e) => {
                setShowLoading(true);
                // handleEventNameSearch(e.target.value);
                props.onChange(e, () => {
                    setShowLoading(false);
                });
            }}
        />
    );
};
