import { cloneDeep, isArray } from 'lodash';
import { shallowEqual } from 'react-redux';
import {
    convertAdditionalCostBackendToFrontend,
    convertAdditionalCostFrontendToBackend,
} from '../../../AdditionalCost/helpers';
import { roundToNDecimalPlace } from '../../../Common/CurrencyUtilsComponent';
import { calculateNewItemTotalWithDecimalPlaces } from '../../../Components/Shared/totalCalculator';
import { convertTemplateTypeToCustomFieldItemTemplate } from '../../../Events/Constants/CustomFieldHelpers';
import {
    ICustomEventItemDetails,
    IEventItemCustomFieldsBackend,
} from '../../../Events/Interfaces/Buyer/RFQ.model';
import {
    IBOMForBomAdminForEvent,
    IBOMItemAlternativesForEvents,
    IBOMItemForAdminForEvent,
} from '../../../Events/Interfaces/Buyer/RFQBOM.model';
import { IMultipleCurrencyConversionRes } from '../../../Events/Services/Buyer/RFQ.service';
import { IRfqAnalyticsStoreListOfBomStructWIthHeiriacy } from '../../../Events/Slices/RfqAnalyticsSlice';
import { BackendPaymentType } from '../../../Global/Interfaces/PaymentTermsInterface';
import { PeriodEnum } from '../../../Global/Interfaces/PeriodInterface';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../../Global/Interfaces/TemplateInterface';
import { post } from '../../../Utils/api';
import {
    IBOMForBomAdmin,
    IBOMItemAlternativesForAdmin,
    IBOMItemForAdmin,
} from '../inerfaces/BomAdminInterface';
import {
    BomStandardSection,
    CustomFieldType,
    IBomTemplate,
} from '../inerfaces/IBomTemplateInterface';
import {
    IGetBomDetailsResponse,
    IGetSubBomItemDetailsResponse,
} from '../services/bomAdmin.services';

export const autoFillCustomFields = (
    customFields: IEventItemCustomFieldsBackend,
    templateDetails: IBomTemplate
) => {
    let reqCustomFields: IEventItemCustomFieldsBackend = {
        section_list: [{ name: 'item_terms', fields: [] }],
    };
    let itemTermsFields = templateDetails.section.ITEM_TERMS.fieldList
        .filter((field) => field.fieldType === 'CUSTOM')
        .map((field) => field.field);

    customFields.section_list.forEach((section) =>
        section.fields.forEach((field) => {
            if (itemTermsFields.find((f) => f === field.name)) {
                const templateField =
                    templateDetails.fieldSettings.customFields[field.name];
                if (templateField) {
                    reqCustomFields.section_list[0].fields.push({
                        name: templateField.label,
                        description: templateField.description,
                        type: field.type,
                        value: field.value,
                        is_required: templateField.buyerSettings.required,
                        is_visible: templateField.sellerSettings.showField,
                        is_locked: true,
                        is_negotiable: templateField.sellerSettings.negotiable,
                    });
                }
            } else {
                // reqCustomFields.section_list[0].fields.push(field);
            }
        })
    );
    return reqCustomFields;
};

export const convertBackendIBomDetailsToFrontendAdminBomDetails: (
    backendResponse: IGetBomDetailsResponse
) => IBOMForBomAdmin = (backendResponse) => {
    return {
        additional_details: {
            template_id:
                backendResponse.enterprise_item.bom_template.template_id,
        },
        additional_costs: backendResponse.additional_costs,
        bom_name: backendResponse.bom_name,
        base_bom_id: backendResponse.base_bom_id,
        bom_code: backendResponse.bom_code,
        bom_status: backendResponse.bom_status,
        buyer_enterprise: backendResponse.buyer_enterprise,
        currency: backendResponse.currency,
        custom_fields: backendResponse.custom_fields,
        enterprise_bom_id: backendResponse.enterprise_bom_id,
        enterprise_item: {
            ...backendResponse.enterprise_item,
            measurement_units:
                backendResponse.enterprise_item.measurement_units.item_measurement_units.map(
                    (measurementUnit) => ({
                        abbreviation: measurementUnit.abbreviation,
                        category: measurementUnit.measurement_unit_category,
                        id: measurementUnit.measurement_unit_id,
                        isVerifiedUnit: true,
                        name: measurementUnit.measurement_unit_primary_name,
                        type: measurementUnit.measurement_unit_value_type,
                    })
                ),
        } as any,
        entities: backendResponse.entities,

        is_primary: backendResponse.is_primary,
        is_subcontract: backendResponse.is_subcontract,
        locations: backendResponse.locations,
        selectedMeasurmentUnit: backendResponse.measurement_unit,
        measurement_unit:
            backendResponse.enterprise_item.measurement_units.item_measurement_units.map(
                (measurementUnit) => ({
                    abbreviation: measurementUnit.abbreviation,
                    category: measurementUnit.measurement_unit_category,
                    id: measurementUnit.measurement_unit_id,
                    isVerifiedUnit: true,
                    name: measurementUnit.measurement_unit_primary_name,
                    type: measurementUnit.measurement_unit_value_type,
                })
            ),
        notes: backendResponse.notes,
        quantity: (+backendResponse.quantity).toString(),
        status: backendResponse.status,
        total: (+backendResponse.total).toString(),
        bom_items: backendResponse.bom_items
            // .filter((bomItem) => bomItem.sub_bom_items !== null)
            .map((bom_item, idx) => {
                return {
                    selected: true,
                    alternates: {
                        alternate_bom_item:
                            bom_item.alternates?.map(
                                (alternative) =>
                                    ({
                                        additional_costs:
                                            alternative.alternate_bom_item
                                                .additional_costs,
                                        alternates: {
                                            alternate_bom_item: [] as any,
                                        },
                                        base_bom_item_id: alternative.bom_item,
                                        bom_item_id:
                                            alternative.alternate_bom_item
                                                .bom_item_id,
                                        cost_per_unit: (+alternative
                                            .alternate_bom_item
                                            .cost_per_unit).toString(),
                                        custom_fields:
                                            alternative.alternate_bom_item
                                                .custom_fields,
                                        description:
                                            alternative.alternate_bom_item
                                                .raw_material_item.description,
                                        enterprise_bom: bom_item.enterprise_bom,
                                        is_alternate:
                                            alternative.alternate_bom_item
                                                .is_alternate,
                                        is_bulk_material:
                                            alternative.alternate_bom_item
                                                .is_bulk_material,
                                        is_coproduct:
                                            alternative.alternate_bom_item
                                                .is_coproduct,
                                        measurement_unit:
                                            alternative.alternate_bom_item
                                                .measurement_unit,
                                        parent_bom_item:
                                            alternative.alternate_bom_item
                                                .parent_bom_item,
                                        parent_sub_bom_item:
                                            alternative.alternate_bom_item
                                                .parent_sub_bom_item,
                                        quantity: (+alternative
                                            .alternate_bom_item
                                            .quantity).toString(),
                                        raw_material_item: {
                                            attributes: alternative
                                                .alternate_bom_item
                                                .raw_material_item
                                                .attributes as any,
                                            bom_template: { template_id: '' },
                                            buyer_additional_costs:
                                                alternative.alternate_bom_item
                                                    .raw_material_item
                                                    .buyer_additional_costs,
                                            buyer_pricing_information:
                                                alternative.alternate_bom_item
                                                    .raw_material_item
                                                    .buyer_pricing_information,
                                            buyer_taxes:
                                                alternative.alternate_bom_item
                                                    .raw_material_item
                                                    .buyer_taxes,
                                            code: alternative.alternate_bom_item
                                                .raw_material_item.code,
                                            // custom_ids:
                                            //     alternative.alternate_bom_item
                                            //         .raw_material_item
                                            //         .custom_ids.custom_ids,
                                            // description:
                                            //     alternative.alternate_bom_item
                                            //         .raw_material_item
                                            //         .description,
                                            enterprise_item_id:
                                                alternative.alternate_bom_item
                                                    .raw_material_item
                                                    .enterprise_item_id,
                                            is_buyer:
                                                alternative.alternate_bom_item
                                                    .raw_material_item.is_buyer,
                                            // is_seller:
                                            //     alternative.alternate_bom_item
                                            //         .raw_material_item
                                            //         .is_seller,
                                            item_type:
                                                alternative.alternate_bom_item
                                                    .raw_material_item
                                                    .item_type,
                                            measurement_units:
                                                alternative.alternate_bom_item.raw_material_item.measurement_units.item_measurement_units.map(
                                                    (me) => ({
                                                        abbreviation:
                                                            me.abbreviation,
                                                        category:
                                                            me.measurement_unit_category,
                                                        id: me.measurement_unit_id,
                                                        isVerifiedUnit: true,
                                                        measurement_unit_primary_name:
                                                            me.measurement_unit_primary_name,
                                                        name: me.measurement_unit_primary_name,
                                                        type: me.measurement_unit_value_type,
                                                    })
                                                ),
                                            name: alternative.alternate_bom_item
                                                .raw_material_item.name,
                                            preferred_vendor_status:
                                                alternative.alternate_bom_item
                                                    .raw_material_item
                                                    .preferred_vendor_status,
                                            seller_additional_costs:
                                                alternative.alternate_bom_item
                                                    .raw_material_item
                                                    .seller_additional_costs,
                                            seller_pricing_information:
                                                alternative.alternate_bom_item
                                                    .raw_material_item
                                                    .seller_pricing_information,
                                            seller_taxes:
                                                alternative.alternate_bom_item
                                                    .raw_material_item
                                                    .seller_taxes,
                                            status: alternative
                                                .alternate_bom_item
                                                .raw_material_item.status,
                                            tags: alternative.alternate_bom_item
                                                .raw_material_item.tags,
                                        },
                                        selected: false,
                                        sub_bom:
                                            alternative.alternate_bom_item
                                                .sub_bom,
                                        sub_bom_items:
                                            alternative.alternate_bom_item
                                                .sub_bom_items,
                                        subtotal: (
                                            +alternative.alternate_bom_item
                                                .cost_per_unit *
                                            +alternative.alternate_bom_item
                                                .quantity
                                        ).toString(),
                                        alternate_bom_item_linkage_id:
                                            alternative.alternate_bom_item_linkage_id,
                                    } as IBOMItemAlternativesForAdmin)
                            ) ?? [],
                    },
                    rawMaterialQuantityPerUnitBom:
                        +bom_item.quantity /
                        (+backendResponse.quantity === 0
                            ? 1
                            : +backendResponse.quantity),
                    additional_costs: bom_item.additional_costs,
                    base_bom_item_id: bom_item.base_bom_item_id,
                    bom_item_id: bom_item.bom_item_id,
                    cost_per_unit: (+bom_item.cost_per_unit).toString(),
                    custom_fields: bom_item.custom_fields,
                    description: bom_item.description,
                    enterprise_bom: bom_item.enterprise_bom as any,
                    is_alternate: bom_item.is_alternate,
                    is_bulk_material: bom_item.is_bulk_material,
                    is_coproduct: bom_item.is_coproduct,
                    measurement_unit: bom_item.measurement_unit,
                    parent_bom_item: bom_item.parent_bom_item,
                    parent_sub_bom_item: bom_item.parent_bom_item,
                    quantity: (+bom_item.quantity).toString(),
                    raw_material_item: bom_item.raw_material_item
                        ? {
                              ...bom_item.raw_material_item,
                              measurement_units:
                                  bom_item.raw_material_item.measurement_units.item_measurement_units.map(
                                      (measurementUnit) => ({
                                          abbreviation:
                                              measurementUnit.abbreviation,
                                          category:
                                              measurementUnit.measurement_unit_category,
                                          id: measurementUnit.measurement_unit_id,
                                          isVerifiedUnit: true,
                                          name: measurementUnit.measurement_unit_primary_name,
                                          type: measurementUnit.measurement_unit_value_type,
                                      })
                                  ),
                          }
                        : (null as any),
                    sub_bom: bom_item.sub_bom
                        ? {
                              ...bom_item.sub_bom,

                              enterprise_item: {
                                  ...bom_item.sub_bom.enterprise_item,
                                  measurement_units:
                                      bom_item.sub_bom.enterprise_item.measurement_units.item_measurement_units.map(
                                          (measurementUnit) => ({
                                              abbreviation:
                                                  measurementUnit.abbreviation,
                                              category:
                                                  measurementUnit.measurement_unit_category,
                                              id: measurementUnit.measurement_unit_id,
                                              isVerifiedUnit: true,
                                              name: measurementUnit.measurement_unit_primary_name,
                                              type: measurementUnit.measurement_unit_value_type,
                                          })
                                      ),
                              },
                          }
                        : (null as any),
                    subtotal: (+bom_item.subtotal).toString(),
                    sub_bom_items:
                        bom_item.sub_bom_items?.map((sub_bom_items) =>
                            convertBackendSubBomItemToFrontendSubBomItem(
                                sub_bom_items,
                                bom_item.quantity
                            )
                        ) ?? null,
                };
            }),
    };
};

export const convertBackendSubBomItemToFrontendSubBomItem: (
    backendResponse: IGetSubBomItemDetailsResponse,
    subBomQuantity: string | number
) => IBOMItemForAdmin = (backendResponse, subBomQuantity) => {
    return {
        selected: true,
        alternates: {
            alternate_bom_item:
                backendResponse.alternates?.map(
                    (alternative) =>
                        ({
                            alternates: {
                                alternate_bom_item: [] as any,
                            },
                            base_bom_item_id: alternative.bom_item,
                            bom_item_id:
                                alternative.alternate_bom_item.bom_item_id,
                            cost_per_unit: (+alternative.alternate_bom_item
                                .cost_per_unit).toString(),
                            custom_fields:
                                alternative.alternate_bom_item.custom_fields,
                            description:
                                alternative.alternate_bom_item.raw_material_item
                                    .description,
                            enterprise_bom: backendResponse.enterprise_bom,
                            is_alternate:
                                alternative.alternate_bom_item.is_alternate,
                            is_bulk_material:
                                alternative.alternate_bom_item.is_bulk_material,
                            is_coproduct:
                                alternative.alternate_bom_item.is_coproduct,
                            measurement_unit:
                                alternative.alternate_bom_item.measurement_unit,
                            parent_bom_item:
                                alternative.alternate_bom_item.parent_bom_item,
                            parent_sub_bom_item:
                                alternative.alternate_bom_item
                                    .parent_sub_bom_item,
                            quantity: (+alternative.alternate_bom_item
                                .quantity).toString(),
                            raw_material_item: {
                                attributes: alternative.alternate_bom_item
                                    .raw_material_item.attributes as any,
                                bom_template: { template_id: '' },
                                buyer_additional_costs:
                                    alternative.alternate_bom_item
                                        .raw_material_item
                                        .buyer_additional_costs,
                                buyer_pricing_information:
                                    alternative.alternate_bom_item
                                        .raw_material_item
                                        .buyer_pricing_information,
                                buyer_taxes:
                                    alternative.alternate_bom_item
                                        .raw_material_item.buyer_taxes,
                                code: alternative.alternate_bom_item
                                    .raw_material_item.code,
                                // custom_ids:
                                //     alternative.alternate_bom_item
                                //         .raw_material_item.custom_ids
                                //         .custom_ids,
                                // description:
                                //     alternative.alternate_bom_item
                                //         .raw_material_item.description,
                                enterprise_item_id:
                                    alternative.alternate_bom_item
                                        .raw_material_item.enterprise_item_id,
                                is_buyer:
                                    alternative.alternate_bom_item
                                        .raw_material_item.is_buyer,
                                // is_seller:
                                //     alternative.alternate_bom_item
                                //         .raw_material_item.is_seller,
                                item_type:
                                    alternative.alternate_bom_item
                                        .raw_material_item.item_type,
                                measurement_units:
                                    alternative.alternate_bom_item.raw_material_item.measurement_units.item_measurement_units.map(
                                        (me: any) => ({
                                            abbreviation: me.abbreviation,
                                            category:
                                                me.measurement_unit_category,
                                            id: me.measurement_unit_id,
                                            isVerifiedUnit: true,
                                            measurement_unit_primary_name:
                                                me.measurement_unit_primary_name,
                                            name: me.measurement_unit_primary_name,
                                            type: me.measurement_unit_value_type,
                                        })
                                    ),
                                name: alternative.alternate_bom_item
                                    .raw_material_item.name,
                                preferred_vendor_status:
                                    alternative.alternate_bom_item
                                        .raw_material_item
                                        .preferred_vendor_status,
                                seller_additional_costs:
                                    alternative.alternate_bom_item
                                        .raw_material_item
                                        .seller_additional_costs,
                                seller_pricing_information:
                                    alternative.alternate_bom_item
                                        .raw_material_item
                                        .seller_pricing_information,
                                seller_taxes:
                                    alternative.alternate_bom_item
                                        .raw_material_item.seller_taxes,
                                status: alternative.alternate_bom_item
                                    .raw_material_item.status,
                                tags: alternative.alternate_bom_item
                                    .raw_material_item.tags,
                            },
                            selected: false,
                            sub_bom: alternative.alternate_bom_item.sub_bom,
                            sub_bom_items:
                                alternative.alternate_bom_item.sub_bom_items,
                            subtotal: (
                                +alternative.alternate_bom_item.cost_per_unit *
                                +alternative.alternate_bom_item.quantity
                            ).toString(),
                            alternate_bom_item_linkage_id:
                                alternative.alternate_bom_item_linkage_id,
                        } as IBOMItemAlternativesForAdmin)
                ) ?? [],
        },
        rawMaterialQuantityPerUnitBom:
            +backendResponse.quantity /
            (+subBomQuantity === 0 ? 1 : +subBomQuantity),
        additional_costs: backendResponse.additional_costs,
        base_bom_item_id: backendResponse.base_bom_item_id,
        bom_item_id: backendResponse.bom_item_id,
        cost_per_unit: (+backendResponse.cost_per_unit).toString(),
        custom_fields: backendResponse.custom_fields,
        description: backendResponse.description,
        enterprise_bom: backendResponse.enterprise_bom as any,
        is_alternate: backendResponse.is_alternate,
        is_bulk_material: backendResponse.is_bulk_material,
        is_coproduct: backendResponse.is_coproduct,
        measurement_unit: backendResponse.measurement_unit,
        parent_bom_item: backendResponse.parent_bom_item,
        parent_sub_bom_item: backendResponse.parent_bom_item,
        quantity: (+backendResponse.quantity).toString(),
        raw_material_item: backendResponse.raw_material_item
            ? {
                  ...backendResponse.raw_material_item,
                  measurement_units:
                      backendResponse.raw_material_item.measurement_units.item_measurement_units.map(
                          (measurementUnit) => ({
                              abbreviation: measurementUnit.abbreviation,
                              category:
                                  measurementUnit.measurement_unit_category,
                              id: measurementUnit.measurement_unit_id,
                              isVerifiedUnit: true,
                              name: measurementUnit.measurement_unit_primary_name,
                              type: measurementUnit.measurement_unit_value_type,
                          })
                      ),
              }
            : (null as any),
        sub_bom: backendResponse.sub_bom,
        subtotal: (+backendResponse.subtotal).toString(),
        sub_bom_items:
            backendResponse.sub_bom_items?.map((sub_bom_items) =>
                convertBackendSubBomItemToFrontendSubBomItem(
                    sub_bom_items,
                    backendResponse.quantity
                )
            ) ?? null,
    };
};

export const convertBackendIBomDetailsToFrontendAdminBomDetailsForEvents: (
    backendResponse: IGetBomDetailsResponse,
    listOfItems: ICustomEventItemDetails[],
    showBomConfigurationType: 'MANUAL' | 'NONE' | 'AUTOMATIC' | 'MANUAL_UPDATE',
    rfqTemplateDetails: IRfqTemplate
) => IBOMForBomAdminForEvent = (
    backendResponse,
    listOfItems,
    showBomConfigurationType,
    rfqTemplateDetails
) => {
    return {
        additional_details: {
            template_id:
                backendResponse.enterprise_item.bom_template.template_id,
        },
        base_bom_id: backendResponse.base_bom_id,
        bom_code: backendResponse.bom_code,
        bom_name: backendResponse.bom_name,
        bom_status: backendResponse.bom_status,
        buyer_enterprise: backendResponse.buyer_enterprise,
        currency: backendResponse.currency,
        custom_fields: backendResponse.custom_fields,
        enterprise_bom_id: backendResponse.enterprise_item.enterprise_item_id,
        enterprise_item: backendResponse.enterprise_item as any,
        entities: backendResponse.entities,

        is_primary: backendResponse.is_primary,
        is_subcontract: backendResponse.is_subcontract,
        locations: backendResponse.locations,
        selectedMeasurmentUnit: backendResponse.measurement_unit,
        measurement_unit:
            backendResponse.enterprise_item.measurement_units.item_measurement_units.map(
                (measurementUnit) => ({
                    abbreviation: measurementUnit.abbreviation,
                    category: measurementUnit.measurement_unit_category,
                    id: measurementUnit.measurement_unit_id,
                    isVerifiedUnit: true,
                    name: measurementUnit.measurement_unit_primary_name,
                    type: measurementUnit.measurement_unit_value_type,
                })
            ),
        notes: backendResponse.notes,
        quantity: (+backendResponse.quantity).toString(),
        status: backendResponse.status,
        total: (+backendResponse.total).toString(),
        additional_costs: [],
        custom_fields_negotiate: { section_list: [] },
        discounts: [],
        taxes: [],
        bom_items: backendResponse.bom_items.map((bom_item) => {
            const foundItem = listOfItems.find((item) =>
                item.delivery_schedule.some(
                    (ds) => ds.bom_item?.entry_id === bom_item.bom_item_id
                )
            );

            return {
                rfq_item_id: foundItem?.event_item_entry_id ?? null,
                selected:
                    showBomConfigurationType === 'MANUAL_UPDATE'
                        ? foundItem
                            ? true
                            : false
                        : true,
                alternates: {
                    alternate_bom_item:
                        bom_item.alternates?.map((alternative) => {
                            const foundItem = listOfItems.find((item) =>
                                item.delivery_schedule.some(
                                    (ds) =>
                                        ds.bom_item?.entry_id ===
                                        alternative.alternate_bom_item
                                            .bom_item_id
                                )
                            );

                            return {
                                rfq_item_id:
                                    foundItem?.event_item_entry_id ?? null,
                                alternates: {
                                    alternate_bom_item: [] as any,
                                },
                                base_bom_item_id:
                                    alternative.alternate_bom_item
                                        .base_bom_item_id,
                                bom_item_id:
                                    alternative.alternate_bom_item.bom_item_id,
                                cost_per_unit: (+alternative.alternate_bom_item
                                    .cost_per_unit).toString(),
                                custom_fields:
                                    alternative.alternate_bom_item
                                        .custom_fields,
                                description:
                                    alternative.alternate_bom_item
                                        .raw_material_item.description,
                                enterprise_bom: bom_item.enterprise_bom,
                                is_alternate:
                                    alternative.alternate_bom_item.is_alternate,
                                is_bulk_material:
                                    alternative.alternate_bom_item
                                        .is_bulk_material,
                                is_coproduct:
                                    alternative.alternate_bom_item.is_coproduct,
                                measurement_unit:
                                    alternative.alternate_bom_item
                                        .measurement_unit,
                                parent_bom_item:
                                    alternative.alternate_bom_item
                                        .parent_bom_item,
                                parent_sub_bom_item:
                                    alternative.alternate_bom_item
                                        .parent_sub_bom_item,
                                quantity:
                                    foundItem?.delivery_schedule
                                        .filter(
                                            (ds) =>
                                                ds.bom_item?.entry_id ===
                                                alternative.alternate_bom_item
                                                    .bom_item_id
                                        )
                                        .reduce(
                                            (acc, next) => acc + +next.quantity,
                                            0
                                        )
                                        .toString() ??
                                    (+alternative.alternate_bom_item
                                        .quantity).toString(),
                                raw_material_item: {
                                    attributes: alternative.alternate_bom_item
                                        .raw_material_item.attributes as any,
                                    bom_template: { template_id: '' },
                                    buyer_additional_costs:
                                        alternative.alternate_bom_item
                                            .raw_material_item
                                            .buyer_additional_costs,
                                    buyer_pricing_information:
                                        alternative.alternate_bom_item
                                            .raw_material_item
                                            .buyer_pricing_information,
                                    buyer_taxes:
                                        alternative.alternate_bom_item
                                            .raw_material_item.buyer_taxes,
                                    code: alternative.alternate_bom_item
                                        .raw_material_item.code,
                                    custom_ids: [],
                                    description: '',
                                    enterprise_item_id:
                                        alternative.alternate_bom_item
                                            .raw_material_item
                                            .enterprise_item_id,
                                    is_buyer:
                                        alternative.alternate_bom_item
                                            .raw_material_item.is_buyer,
                                    is_seller: false,
                                    item_type:
                                        alternative.alternate_bom_item
                                            .raw_material_item.item_type,
                                    measurement_units:
                                        alternative.alternate_bom_item.raw_material_item.measurement_units.item_measurement_units.map(
                                            (me) => ({
                                                abbreviation: me.abbreviation,
                                                category:
                                                    me.measurement_unit_category,
                                                id: me.measurement_unit_id,
                                                isVerifiedUnit: true,
                                                measurement_unit_primary_name:
                                                    me.measurement_unit_primary_name,
                                                name: me.measurement_unit_primary_name,
                                                type: me.measurement_unit_value_type,
                                            })
                                        ),
                                    name: alternative.alternate_bom_item
                                        .raw_material_item.name,
                                    preferred_vendor_status:
                                        alternative.alternate_bom_item
                                            .raw_material_item
                                            .preferred_vendor_status,
                                    seller_additional_costs:
                                        alternative.alternate_bom_item
                                            .raw_material_item
                                            .seller_additional_costs,
                                    seller_pricing_information:
                                        alternative.alternate_bom_item
                                            .raw_material_item
                                            .seller_pricing_information,
                                    seller_taxes:
                                        alternative.alternate_bom_item
                                            .raw_material_item.seller_taxes,
                                    status: alternative.alternate_bom_item
                                        .raw_material_item.status,
                                    tags: alternative.alternate_bom_item
                                        .raw_material_item.tags,
                                },
                                selected:
                                    showBomConfigurationType === 'MANUAL_UPDATE'
                                        ? foundItem
                                            ? true
                                            : false
                                        : false,
                                sub_bom: alternative.alternate_bom_item.sub_bom,
                                sub_bom_items:
                                    alternative.alternate_bom_item
                                        .sub_bom_items,
                                subtotal: (
                                    +alternative.alternate_bom_item
                                        .cost_per_unit *
                                    +alternative.alternate_bom_item.quantity
                                ).toString(),
                                alternate_bom_item_linkage_id:
                                    alternative.alternate_bom_item_linkage_id,
                                additional_costs: rfqTemplateDetails
                                    .fieldSettings.standardFields[
                                    TemplateStandardFieldsEnum.ADDITIONAL_COSTS
                                ].buyerSettings.showField
                                    ? foundItem
                                        ? convertAdditionalCostFrontendToBackend(
                                              foundItem.additional_costs
                                          )
                                        : alternative.alternate_bom_item.additional_costs.filter(
                                              (cost) =>
                                                  rfqTemplateDetails.fieldSettings.standardFields[
                                                      TemplateStandardFieldsEnum
                                                          .ADDITIONAL_COSTS
                                                  ].additionalCostOptions.find(
                                                      (c) =>
                                                          c.costName
                                                              .trim()
                                                              .toLowerCase() ===
                                                          cost.cost_name
                                                              .trim()
                                                              .toLowerCase()
                                                  ) !== undefined
                                          )
                                    : [],
                                custom_fields_negotiate: {
                                    section_list: [],
                                },
                                delivery_schedule: foundItem?.delivery_schedule
                                    .filter(
                                        (ds) =>
                                            ds.bom_item?.entry_id ===
                                            alternative.alternate_bom_item
                                                .bom_item_id
                                    )
                                    .map((ds) => ({
                                        bom_item: ds.bom_item,
                                        cost_centre_id: ds.cost_centre_id,
                                        delivery_date: ds.delivery_date,
                                        general_ledger_id: ds.general_ledger_id,
                                        project_id: ds.project_id,
                                        quantity: (+ds.quantity).toString(),
                                    })) ?? [
                                    {
                                        bom_item: null,
                                        cost_centre_id: null,
                                        delivery_date: null,
                                        general_ledger_id: null,
                                        project_id: null,
                                        quantity: (+alternative
                                            .alternate_bom_item
                                            .quantity).toString(),
                                    },
                                ],
                                discounts: foundItem
                                    ? convertAdditionalCostFrontendToBackend(
                                          foundItem.discounts
                                      )
                                    : [],
                                incoterm_id:
                                    foundItem?.incoterm_id ??
                                    'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
                                incoterm_name:
                                    foundItem?.incoterm_name ?? 'None',
                                lead_time: foundItem?.lead_time ?? '',
                                lead_time_period:
                                    foundItem?.lead_time_period ?? 'DAYS',
                                paymentTerms: foundItem?.paymentTerms ?? {
                                    paymentType: 'invoiceItemValues',
                                    appliedFrom: '',
                                    period: '',
                                    prepaymentPercentage: '',
                                    terms: '',
                                },
                                deliverables_payment_terms: null,
                                payment_terms: {
                                    applied_from: 'INVOICE_DATE',
                                    period: PeriodEnum.DAYS,
                                    term: 0,
                                },
                                payment_type:
                                    'PER_INVOICE_ITEM' as BackendPaymentType,
                                prepayment_percentage: 0,
                                requisition_approved:
                                    foundItem?.requisition_approved ?? false,
                                requisition_number:
                                    foundItem?.requisition_number ?? '',
                                taxes: foundItem
                                    ? convertAdditionalCostFrontendToBackend(
                                          foundItem.taxes
                                      )
                                    : [],
                                item_additional_information:
                                    foundItem?.item_additional_information ??
                                    '',
                                quantity_tolerance_percentage:
                                    foundItem?.quantity_tolerance_percentage ??
                                    '',
                                rawMaterialQuantityPerUnitBom:
                                    +alternative.alternate_bom_item.quantity /
                                    (+backendResponse.quantity === 0
                                        ? 1
                                        : +backendResponse.quantity),
                            } as IBOMItemAlternativesForEvents;
                        }) ?? [],
                },
                base_bom_item_id: bom_item.base_bom_item_id,
                bom_item_id: bom_item.bom_item_id,
                cost_per_unit:
                    foundItem?.price ?? (+bom_item.cost_per_unit).toString(),
                custom_fields: bom_item.custom_fields,
                description: bom_item.description,
                enterprise_bom: bom_item.enterprise_bom as any,
                is_alternate: bom_item.is_alternate,
                is_bulk_material: bom_item.is_bulk_material,
                is_coproduct: bom_item.is_coproduct,
                measurement_unit: bom_item.measurement_unit,
                parent_bom_item: bom_item.parent_bom_item,
                parent_sub_bom_item: bom_item.parent_bom_item,
                rawMaterialQuantityPerUnitBom:
                    +bom_item.quantity /
                    (+backendResponse.quantity === 0
                        ? 1
                        : +backendResponse.quantity),
                quantity:
                    foundItem?.delivery_schedule
                        .filter(
                            (ds) =>
                                ds.bom_item?.entry_id === bom_item.bom_item_id
                        )
                        .reduce((acc, next) => acc + +next.quantity, 0)
                        .toString() ?? (+bom_item.quantity).toString(),
                raw_material_item: bom_item.raw_material_item
                    ? {
                          ...bom_item.raw_material_item,
                          measurement_units:
                              bom_item.raw_material_item.measurement_units.item_measurement_units.map(
                                  (measurementUnit) => ({
                                      abbreviation:
                                          measurementUnit.abbreviation,
                                      category:
                                          measurementUnit.measurement_unit_category,
                                      id: measurementUnit.measurement_unit_id,
                                      isVerifiedUnit: true,
                                      name: measurementUnit.measurement_unit_primary_name,
                                      type: measurementUnit.measurement_unit_value_type,
                                  })
                              ),
                      }
                    : (null as any),
                sub_bom: bom_item.sub_bom
                    ? {
                          ...bom_item.sub_bom,

                          enterprise_item: {
                              ...bom_item.sub_bom.enterprise_item,
                              measurement_units:
                                  bom_item.sub_bom.enterprise_item.measurement_units.item_measurement_units.map(
                                      (measurementUnit) => ({
                                          abbreviation:
                                              measurementUnit.abbreviation,
                                          category:
                                              measurementUnit.measurement_unit_category,
                                          id: measurementUnit.measurement_unit_id,
                                          isVerifiedUnit: true,
                                          name: measurementUnit.measurement_unit_primary_name,
                                          type: measurementUnit.measurement_unit_value_type,
                                      })
                                  ),
                          },
                      }
                    : (null as any),
                subtotal: (+bom_item.subtotal).toString(),
                sub_bom_items:
                    bom_item.sub_bom_items?.map((sub_bom_items) =>
                        convertBackendSubBomItemToFrontendSubBomItemForEvents(
                            sub_bom_items,
                            listOfItems,
                            bom_item.quantity,
                            showBomConfigurationType,
                            rfqTemplateDetails
                        )
                    ) ?? null,
                additional_costs: rfqTemplateDetails.fieldSettings
                    .standardFields[TemplateStandardFieldsEnum.ADDITIONAL_COSTS]
                    .buyerSettings.showField
                    ? foundItem
                        ? convertAdditionalCostFrontendToBackend(
                              foundItem.additional_costs
                          )
                        : bom_item.additional_costs.filter(
                              (cost) =>
                                  rfqTemplateDetails.fieldSettings.standardFields[
                                      TemplateStandardFieldsEnum
                                          .ADDITIONAL_COSTS
                                  ].additionalCostOptions.find(
                                      (c) =>
                                          c.costName.trim().toLowerCase() ===
                                          cost.cost_name.trim().toLowerCase()
                                  ) !== undefined
                          )
                    : [],
                custom_fields_negotiate: {
                    section_list: [],
                },
                delivery_schedule: foundItem?.delivery_schedule
                    .filter(
                        (ds) => ds.bom_item?.entry_id === bom_item.bom_item_id
                    )
                    .map((ds) => ({
                        bom_item: ds.bom_item,
                        cost_centre_id: ds.cost_centre_id,
                        delivery_date: ds.delivery_date,
                        general_ledger_id: ds.general_ledger_id,
                        project_id: ds.project_id,
                        quantity: (+ds.quantity).toString(),
                    })) ?? [
                    {
                        bom_item: null,
                        cost_centre_id: null,
                        delivery_date: null,
                        general_ledger_id: null,
                        project_id: null,
                        quantity: (+bom_item.quantity).toString(),
                    },
                ],
                discounts: foundItem
                    ? convertAdditionalCostFrontendToBackend(
                          foundItem.discounts
                      )
                    : [],
                incoterm_id:
                    foundItem?.incoterm_id ??
                    'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
                incoterm_name: foundItem?.incoterm_name ?? 'None',
                lead_time: foundItem?.lead_time ?? '',
                lead_time_period: foundItem?.lead_time_period ?? 'DAYS',
                paymentTerms: foundItem?.paymentTerms ?? {
                    paymentType: 'invoiceItemValues',
                    appliedFrom: '',
                    period: '',
                    prepaymentPercentage: '',
                    terms: '',
                },
                deliverables_payment_terms: null,
                payment_terms: {
                    applied_from: 'INVOICE_DATE',
                    period: PeriodEnum.DAYS,
                    term: 0,
                },
                payment_type: 'PER_INVOICE_ITEM' as BackendPaymentType,
                prepayment_percentage: 0,
                requisition_approved: foundItem?.requisition_approved ?? false,
                requisition_number: foundItem?.requisition_number ?? '',
                taxes: foundItem
                    ? convertAdditionalCostFrontendToBackend(foundItem.taxes)
                    : [],
                item_additional_information:
                    foundItem?.item_additional_information ?? '',
                quantity_tolerance_percentage:
                    foundItem?.quantity_tolerance_percentage ?? '',
            };
        }),
    };
};

export const convertBackendSubBomItemToFrontendSubBomItemForEvents: (
    backendResponse: IGetSubBomItemDetailsResponse,
    listOfItems: ICustomEventItemDetails[],
    subBomQuantity: string | number,
    showBomConfigurationType: 'MANUAL' | 'NONE' | 'AUTOMATIC' | 'MANUAL_UPDATE',
    rfqTemplateDetails: IRfqTemplate
) => IBOMItemForAdminForEvent = (
    backendResponse,
    listOfItems,
    subBomQuantity,
    showBomConfigurationType,
    rfqTemplateDetails
) => {
    const foundItem = listOfItems.find((item) =>
        item.delivery_schedule.some(
            (ds) => ds.bom_item?.entry_id === backendResponse.bom_item_id
        )
    );

    return {
        rfq_item_id: foundItem?.event_item_entry_id ?? null,
        selected:
            showBomConfigurationType === 'MANUAL_UPDATE'
                ? foundItem
                    ? true
                    : false
                : true,
        alternates: {
            alternate_bom_item:
                backendResponse.alternates?.map(
                    (alternative) =>
                        ({
                            alternates: {
                                alternate_bom_item: [] as any,
                            },
                            base_bom_item_id: alternative.bom_item,
                            bom_item_id:
                                alternative.alternate_bom_item.bom_item_id,
                            cost_per_unit: (+alternative.alternate_bom_item
                                .cost_per_unit).toString(),
                            custom_fields:
                                alternative.alternate_bom_item.custom_fields,
                            description:
                                alternative.alternate_bom_item.raw_material_item
                                    .description,
                            enterprise_bom: backendResponse.enterprise_bom,
                            is_alternate:
                                alternative.alternate_bom_item.is_alternate,
                            is_bulk_material:
                                alternative.alternate_bom_item.is_bulk_material,
                            is_coproduct:
                                alternative.alternate_bom_item.is_coproduct,
                            measurement_unit:
                                alternative.alternate_bom_item.measurement_unit,
                            parent_bom_item:
                                alternative.alternate_bom_item.parent_bom_item,
                            parent_sub_bom_item:
                                alternative.alternate_bom_item
                                    .parent_sub_bom_item,
                            quantity: (+alternative.alternate_bom_item
                                .quantity).toString(),
                            raw_material_item: {
                                attributes: alternative.alternate_bom_item
                                    .raw_material_item.attributes as any,
                                bom_template: { template_id: '' },
                                buyer_additional_costs:
                                    alternative.alternate_bom_item
                                        .raw_material_item
                                        .buyer_additional_costs,
                                buyer_pricing_information:
                                    alternative.alternate_bom_item
                                        .raw_material_item
                                        .buyer_pricing_information,
                                buyer_taxes:
                                    alternative.alternate_bom_item
                                        .raw_material_item.buyer_taxes,
                                code: alternative.alternate_bom_item
                                    .raw_material_item.code,
                                // custom_ids:
                                //     alternative.alternate_bom_item
                                //         .raw_material_item.custom_ids
                                //         .custom_ids,
                                // description:
                                //     alternative.alternate_bom_item
                                //         .raw_material_item.description,
                                enterprise_item_id:
                                    alternative.alternate_bom_item
                                        .raw_material_item.enterprise_item_id,
                                is_buyer:
                                    alternative.alternate_bom_item
                                        .raw_material_item.is_buyer,
                                // is_seller:
                                //     alternative.alternate_bom_item
                                //         .raw_material_item.is_seller,
                                item_type:
                                    alternative.alternate_bom_item
                                        .raw_material_item.item_type,
                                measurement_units:
                                    alternative.alternate_bom_item.raw_material_item.measurement_units.item_measurement_units.map(
                                        (me: any) => ({
                                            abbreviation: me.abbreviation,
                                            category:
                                                me.measurement_unit_category,
                                            id: me.measurement_unit_id,
                                            isVerifiedUnit: true,
                                            measurement_unit_primary_name:
                                                me.measurement_unit_primary_name,
                                            name: me.measurement_unit_primary_name,
                                            type: me.measurement_unit_value_type,
                                        })
                                    ),
                                name: alternative.alternate_bom_item
                                    .raw_material_item.name,
                                preferred_vendor_status:
                                    alternative.alternate_bom_item
                                        .raw_material_item
                                        .preferred_vendor_status,
                                seller_additional_costs:
                                    alternative.alternate_bom_item
                                        .raw_material_item
                                        .seller_additional_costs,
                                seller_pricing_information:
                                    alternative.alternate_bom_item
                                        .raw_material_item
                                        .seller_pricing_information,
                                seller_taxes:
                                    alternative.alternate_bom_item
                                        .raw_material_item.seller_taxes,
                                status: alternative.alternate_bom_item
                                    .raw_material_item.status,
                                tags: alternative.alternate_bom_item
                                    .raw_material_item.tags,
                            },
                            selected: false,
                            sub_bom: alternative.alternate_bom_item.sub_bom,
                            sub_bom_items:
                                alternative.alternate_bom_item.sub_bom_items,
                            subtotal: (
                                +alternative.alternate_bom_item.cost_per_unit *
                                +alternative.alternate_bom_item.quantity
                            ).toString(),
                            alternate_bom_item_linkage_id:
                                alternative.alternate_bom_item_linkage_id,
                        } as IBOMItemAlternativesForEvents)
                ) ?? [],
        },
        base_bom_item_id: backendResponse.base_bom_item_id,
        bom_item_id: backendResponse.bom_item_id,
        cost_per_unit:
            foundItem?.price ?? (+backendResponse.cost_per_unit).toString(),
        custom_fields: backendResponse.custom_fields,
        description: backendResponse.description,
        enterprise_bom: backendResponse.enterprise_bom as any,
        is_alternate: backendResponse.is_alternate,
        is_bulk_material: backendResponse.is_bulk_material,
        is_coproduct: backendResponse.is_coproduct,
        measurement_unit: backendResponse.measurement_unit,
        parent_bom_item: backendResponse.parent_bom_item,
        parent_sub_bom_item: backendResponse.parent_bom_item,
        rawMaterialQuantityPerUnitBom:
            +backendResponse.quantity /
            (+subBomQuantity === 0 ? 1 : +subBomQuantity),
        quantity:
            foundItem?.delivery_schedule
                .filter(
                    (ds) =>
                        ds.bom_item?.entry_id === backendResponse.bom_item_id
                )
                .reduce((acc, next) => acc + +next.quantity, 0)
                .toString() ?? (+backendResponse.quantity).toString(),
        raw_material_item: backendResponse.raw_material_item
            ? {
                  ...backendResponse.raw_material_item,
                  measurement_units:
                      backendResponse.raw_material_item.measurement_units.item_measurement_units.map(
                          (measurementUnit) => ({
                              abbreviation: measurementUnit.abbreviation,
                              category:
                                  measurementUnit.measurement_unit_category,
                              id: measurementUnit.measurement_unit_id,
                              isVerifiedUnit: true,
                              name: measurementUnit.measurement_unit_primary_name,
                              type: measurementUnit.measurement_unit_value_type,
                          })
                      ),
              }
            : (null as any),
        sub_bom: backendResponse.sub_bom,
        subtotal: (+backendResponse.subtotal).toString(),
        sub_bom_items:
            backendResponse.sub_bom_items?.map((sub_bom_items) =>
                convertBackendSubBomItemToFrontendSubBomItemForEvents(
                    sub_bom_items,
                    listOfItems,
                    backendResponse.quantity,
                    showBomConfigurationType,
                    rfqTemplateDetails
                )
            ) ?? null,

        additional_costs: rfqTemplateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.ADDITIONAL_COSTS
        ].buyerSettings.showField
            ? foundItem
                ? convertAdditionalCostFrontendToBackend(
                      foundItem.additional_costs
                  )
                : backendResponse.additional_costs.filter(
                      (cost) =>
                          rfqTemplateDetails.fieldSettings.standardFields[
                              TemplateStandardFieldsEnum.ADDITIONAL_COSTS
                          ].additionalCostOptions.find(
                              (c) =>
                                  c.costName.trim().toLowerCase() ===
                                  cost.cost_name.trim().toLowerCase()
                          ) !== undefined
                  )
            : [],
        custom_fields_negotiate: {
            section_list: [],
        },
        delivery_schedule: foundItem?.delivery_schedule
            .filter(
                (ds) => ds.bom_item?.entry_id === backendResponse.bom_item_id
            )
            .map((ds) => ({
                bom_item: ds.bom_item,
                cost_centre_id: ds.cost_centre_id,
                delivery_date: ds.delivery_date,
                general_ledger_id: ds.general_ledger_id,
                project_id: ds.project_id,
                quantity: (+ds.quantity).toString(),
            })) ?? [
            {
                bom_item: null,
                cost_centre_id: null,
                delivery_date: null,
                general_ledger_id: null,
                project_id: null,
                quantity: (+backendResponse.quantity).toString(),
            },
        ],
        discounts: foundItem
            ? convertAdditionalCostFrontendToBackend(foundItem.discounts)
            : [],
        incoterm_id:
            foundItem?.incoterm_id ?? 'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
        incoterm_name: foundItem?.incoterm_name ?? 'None',
        lead_time: foundItem?.lead_time ?? '',
        lead_time_period: foundItem?.lead_time_period ?? 'DAYS',
        paymentTerms: foundItem?.paymentTerms ?? {
            paymentType: 'invoiceItemValues',
            appliedFrom: '',
            period: '',
            prepaymentPercentage: '',
            terms: '',
        },
        deliverables_payment_terms: null,
        payment_terms: {
            applied_from: 'INVOICE_DATE',
            period: PeriodEnum.DAYS,
            term: 0,
        },
        payment_type: 'PER_INVOICE_ITEM' as BackendPaymentType,
        prepayment_percentage: 0,
        requisition_approved: foundItem?.requisition_approved ?? false,
        requisition_number: foundItem?.requisition_number ?? '0',
        taxes: foundItem
            ? convertAdditionalCostFrontendToBackend(foundItem.taxes)
            : [],
        item_additional_information:
            foundItem?.item_additional_information ?? '',
        quantity_tolerance_percentage:
            foundItem?.quantity_tolerance_percentage ?? '',
    };
};

export const addItemLevelCustomFieldinBomItemsAndSubBomItems = (
    bomDetailsFromStore: IBOMForBomAdmin,
    templateDetails: IBomTemplate
) => {
    const bomDetailsFromStoreclone = cloneDeep(bomDetailsFromStore);

    const listOfCustomFieldsInItems =
        templateDetails.section.ITEM_TERMS.fieldList
            .filter((field) => field.fieldType === 'CUSTOM')
            .map((field) => field.field);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const listOfCustomFieldsInBom =
        templateDetails.section.BOM_DETAILS.fieldList
            .filter((field) => field.fieldType === 'CUSTOM')
            .map((field) => field.field);
    for (let bomItems of bomDetailsFromStoreclone.bom_items) {
        if (bomItems.raw_material_item !== null) {
            // code to add to item terms section in bom items customfields
            let foundIndexOfBomItemtermsSection =
                bomItems.custom_fields.section_list.findIndex(
                    (section) =>
                        section.name ===
                        BomStandardSection.ITEM_TERMS.toLowerCase()
                );
            if (foundIndexOfBomItemtermsSection === -1) {
                bomItems.custom_fields.section_list.push({
                    name: BomStandardSection.ITEM_TERMS.toLowerCase(),
                    fields: [],
                });
            }
            foundIndexOfBomItemtermsSection =
                bomItems.custom_fields.section_list.findIndex(
                    (section) =>
                        section.name ===
                        BomStandardSection.ITEM_TERMS.toLowerCase()
                );

            // below code to add section items

            // const listOfCustomFieldsInThisBomItems =
            //     bomItems.custom_fields.section_list[
            //         foundIndexOfBomItemtermsSection
            //     ].fields.map((field) => field.name);

            for (let customfieldFromTemplate of listOfCustomFieldsInItems) {
                const foundIndexOfBomItems =
                    bomItems.custom_fields.section_list[
                        foundIndexOfBomItemtermsSection
                    ].fields.findIndex(
                        (field) =>
                            field.name.toLowerCase() ===
                            customfieldFromTemplate.toLowerCase()
                    );

                if (
                    foundIndexOfBomItems === -1 &&
                    templateDetails.fieldSettings.customFields[
                        customfieldFromTemplate
                    ]
                ) {
                    const fieldSettingFromTemplate =
                        templateDetails.fieldSettings.customFields[
                            customfieldFromTemplate
                        ];

                    bomItems.custom_fields.section_list[
                        foundIndexOfBomItemtermsSection
                    ].fields.push({
                        description: fieldSettingFromTemplate.description,
                        type: convertTemplateTypeToCustomFieldItemTemplate(
                            fieldSettingFromTemplate.type
                        ),
                        is_locked: false,
                        is_negotiable: false,
                        is_required:
                            fieldSettingFromTemplate.buyerSettings.required,
                        is_visible: false,
                        name: customfieldFromTemplate,
                        value:
                            fieldSettingFromTemplate.type ===
                                CustomFieldType.LONG_TEXT ||
                            fieldSettingFromTemplate.type ===
                                CustomFieldType.SHORT_TEXT ||
                            fieldSettingFromTemplate.type ===
                                CustomFieldType.DECIMAL ||
                            fieldSettingFromTemplate.type ===
                                CustomFieldType.INTEGER
                                ? ''
                                : fieldSettingFromTemplate.type ===
                                  CustomFieldType.MULTI_CHOICE
                                ? []
                                : null,
                    });
                }
            }
        }
    }
    return bomDetailsFromStoreclone;
};

export const getAllBomItemIdsOfRawMaterialFromBomAdmin: (
    bomConfigurationAdminSetting: IGetBomDetailsResponse
) => Set<string> = (bomConfigurationAdminSetting: IGetBomDetailsResponse) => {
    let listOfBomIds = new Set<string>();

    for (let item of bomConfigurationAdminSetting.bom_items) {
        if (item.sub_bom_items !== null && item.sub_bom_items !== undefined) {
            const listOfBomIdsFromSubBom = item.sub_bom_items
                .map((sub_item) =>
                    getAllBomItemIdsOfRawMaterialFromBomAdminSubBomItems(
                        sub_item
                    )
                )
                .reduce((acc, setOfBomIds) => {
                    return new Set([
                        ...Array.from(acc),
                        ...Array.from(setOfBomIds),
                    ]);
                }, new Set<string>());

            listOfBomIds = new Set([
                ...Array.from(listOfBomIdsFromSubBom),
                ...Array.from(listOfBomIds),
            ]);
        } else {
            listOfBomIds.add(item.bom_item_id);
            for (let alternate of item.alternates) {
                listOfBomIds.add(alternate.alternate_bom_item.bom_item_id);
            }
        }
    }

    return listOfBomIds;
};

export const getAllBomItemIdsOfRawMaterialFromBomAdminSubBomItems: (
    bomConfigurationAdminSetting: IGetSubBomItemDetailsResponse
) => Set<string> = (
    bomConfigurationAdminSetting: IGetSubBomItemDetailsResponse
) => {
    let listOfBomIds = new Set<string>();

    if (bomConfigurationAdminSetting.sub_bom_items)
        for (let item of bomConfigurationAdminSetting.sub_bom_items) {
            if (
                item.sub_bom_items !== null &&
                item.sub_bom_items !== undefined
            ) {
                const listOfBomIdsFromSubBom = item.sub_bom_items
                    .map((sub_item) =>
                        getAllBomItemIdsOfRawMaterialFromBomAdminSubBomItems(
                            sub_item
                        )
                    )
                    .reduce((acc, setOfBomIds) => {
                        return new Set([
                            ...Array.from(acc),
                            ...Array.from(setOfBomIds),
                        ]);
                    }, new Set<string>());

                listOfBomIds = new Set([
                    ...Array.from(listOfBomIdsFromSubBom),
                    ...Array.from(listOfBomIds),
                ]);
            } else {
                listOfBomIds.add(item.bom_item_id);
            }
        }
    else {
        listOfBomIds.add(bomConfigurationAdminSetting.bom_item_id);
    }

    return listOfBomIds;
};

export const validateBomTopLevelDetails = (
    BomDetails: IBOMForBomAdmin,
    bomTemplateDetails: IBomTemplate
) => {
    const objOfErrorsAndWarning: {
        ERRORS: { [key: string]: boolean };
        WARNINGS: { [key: string]: boolean };
    } = {
        ERRORS: {},
        WARNINGS: {},
    };

    const fieldsOfBomLevel = bomTemplateDetails.section.BOM_DETAILS.fieldList;

    for (let field of fieldsOfBomLevel) {
        if (
            field.fieldType === 'CUSTOM' &&
            bomTemplateDetails.fieldSettings.customFields[field.field]
                .buyerSettings.showField &&
            bomTemplateDetails.fieldSettings.customFields[field.field]
                .buyerSettings.required
        ) {
            let tempfield;
            for (let section of BomDetails.custom_fields.section_list) {
                for (let f of section.fields) {
                    if (f.name === field.field) {
                        tempfield = f;
                    }

                    if (tempfield !== undefined) {
                        break;
                    }
                }
                if (tempfield !== undefined) {
                    break;
                }
            }

            if (
                tempfield === undefined ||
                getCustomFieldValueIsError(tempfield.value)
            ) {
                objOfErrorsAndWarning.ERRORS[field.field] = true;
            }
        } else if (
            field.fieldType === 'STANDARD' &&
            bomTemplateDetails.fieldSettings.standardFields[field.field]
                .buyerSettings.showField &&
            bomTemplateDetails.fieldSettings.standardFields[field.field]
                .buyerSettings.required
        ) {
            switch (field.field) {
                case 'BOM_ID':
                    if (BomDetails.bom_code.trim().length === 0) {
                        objOfErrorsAndWarning.ERRORS[field.field] = true;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    if (BomDetails.entities.length === 0) {
        objOfErrorsAndWarning.ERRORS['Entity'] = true;
    }
    if (+BomDetails.quantity <= 0) {
        objOfErrorsAndWarning.ERRORS['Quantity'] = true;
    }
    if (BomDetails.enterprise_item.enterprise_item_id.length === 0) {
        objOfErrorsAndWarning.ERRORS['Item'] = true;
    }

    return objOfErrorsAndWarning;
};

export const validateBomItemslDetails = (
    BomDetails: IBOMItemForAdmin,
    bomTemplateDetails: IBomTemplate,
    ChechCustomFields: boolean = true
) => {
    const objOfErrorsAndWarning: {
        ERRORS: { [key: string]: boolean };
        WARNINGS: { [key: string]: boolean };
    } = {
        ERRORS: {},
        WARNINGS: {},
    };

    const fieldsOfBomLevel = bomTemplateDetails.section.ITEM_TERMS.fieldList;

    for (let field of fieldsOfBomLevel) {
        if (
            ChechCustomFields &&
            field.fieldType === 'CUSTOM' &&
            bomTemplateDetails.fieldSettings.customFields[field.field]
                .buyerSettings.showField &&
            bomTemplateDetails.fieldSettings.customFields[field.field]
                .buyerSettings.required
        ) {
            let tempfield;
            for (let section of BomDetails.custom_fields.section_list) {
                for (let f of section.fields) {
                    if (f.name === field.field) {
                        tempfield = f;
                    }

                    if (tempfield !== undefined) {
                        break;
                    }
                }
                if (tempfield !== undefined) {
                    break;
                }
            }

            if (
                tempfield === undefined ||
                getCustomFieldValueIsError(tempfield.value)
            ) {
                objOfErrorsAndWarning.ERRORS[field.field] = true;
            }
        } else if (
            field.fieldType === 'STANDARD' &&
            bomTemplateDetails.fieldSettings.standardFields[field.field]
                .buyerSettings.showField &&
            bomTemplateDetails.fieldSettings.standardFields[field.field]
                .buyerSettings.required
        ) {
            // switch (field.field) {
            //     case TemplateStandardFieldsEnum.BOM_ID:
            //         if (BomDetails.quantity.trim().length === 0) {
            //             objOfErrorsAndWarning.ERRORS[field.field] = true;
            //         }
            //         break;
            //     default:
            //         break;
            // }
        }
    }

    if (!(+BomDetails.quantity > 0)) {
        objOfErrorsAndWarning.ERRORS['quantity'] = true;
    }
    if (!(+BomDetails.cost_per_unit > 0)) {
        objOfErrorsAndWarning.ERRORS['cost_per_unit'] = true;
    }
    return objOfErrorsAndWarning;
};

const getCustomFieldValueIsError = (
    val: string | boolean | string[] | null
) => {
    if (typeof val === 'string' && val.trim().length === 0) {
        return true;
    }
    if (isArray(val) && val.length === 0) {
        return true;
    }
    if (val === null) {
        return true;
    }

    return false;
};

export const getSubBomItemsTotal = (
    subBomItems: (IBOMItemForAdmin | IBOMItemForAdminForEvent)[]
) => {
    let totalCostForBom = 0;

    for (let item of subBomItems) {
        if (item.sub_bom_items !== null) {
            const currentCPU = getSubBomItemsTotal(
                item.sub_bom_items
            ).totalCostForBom;
            totalCostForBom += currentCPU;

            if (+item.quantity > 0) {
                item.cost_per_unit = roundToNDecimalPlace(
                    currentCPU / +item.quantity,
                    10
                ).toString();
                item.subtotal = (
                    +item.cost_per_unit * +item.quantity
                ).toString();
            } else item.cost_per_unit = '0';
        } else if (item.raw_material_item !== null && item.selected) {
            totalCostForBom += calculateNewItemTotalWithDecimalPlaces({
                additional_cost: convertAdditionalCostBackendToFrontend(
                    (item as unknown as IBOMForBomAdminForEvent)
                        .additional_costs ?? []
                ),
                decimalPlaces: 2,
                discount: convertAdditionalCostBackendToFrontend(
                    (item as unknown as IBOMForBomAdminForEvent).discounts ?? []
                ),
                tax: convertAdditionalCostBackendToFrontend(
                    (item as unknown as IBOMForBomAdminForEvent).taxes ?? []
                ),
                item_price: item.cost_per_unit,
                itemDS: [
                    {
                        quantity: item.quantity,
                    },
                ],
            });
        }
    }

    return { totalCostForBom, subBomItems };
};
// IBOMForBomAdmin | IBOMForBomAdminForEvent
// : (
//     {Bom: IBOMForBomAdmin | IBOMForBomAdminForEvent;
//     toCurrency: string}
// ) => Promise<IBOMForBomAdminForEvent | IBOMForBomAdmin>
export const convertAllTheBomValueToDifferentCurrency = async ({
    Bom,
    toCurrency,
    tempBom,
}:
    | {
          Bom: IBOMForBomAdmin;
          tempBom: IBOMForBomAdmin;
          toCurrency: string;
      }
    | {
          Bom: IBOMForBomAdminForEvent;
          tempBom: IBOMForBomAdminForEvent;
          toCurrency: string;
      }) => {
    //     toCurrency: string} => {
    // const tempBom = cloneDeep(Bom);

    const listOfCUrrency = getlistCurrencyFromBom(tempBom);

    const conversionRate = await getManyToManyCurrencyConversion(
        listOfCUrrency
            .filter((fromid) => fromid)
            .map((fromId) => ({
                currency_from_id: fromId,
                currency_to_id: toCurrency,
            }))
    );

    if (toCurrency !== tempBom.currency) {
        if (
            Bom.currency &&
            conversionRate[Bom.currency] &&
            conversionRate[Bom.currency][toCurrency]
        )
            tempBom.total = roundToNDecimalPlace(
                +tempBom.total * conversionRate[Bom.currency][toCurrency],
                10
            ).toString();
    }

    for (let item of tempBom.bom_items) {
        if (item.sub_bom && item.sub_bom_items) {
            item = convertAllTheBomSubValueToDifferentCurrency({
                conversionRate,
                tempBom: item,
                toCurrency,
            });
        } else {
            if (
                Bom.currency !== toCurrency &&
                Bom.currency &&
                conversionRate[Bom.currency] &&
                conversionRate[Bom.currency][toCurrency]
            ) {
                item.cost_per_unit = roundToNDecimalPlace(
                    +item.cost_per_unit *
                        conversionRate[Bom.currency][toCurrency],
                    10
                ).toString();
                item.subtotal = roundToNDecimalPlace(
                    +item.subtotal * conversionRate[Bom.currency][toCurrency],
                    10
                ).toString();
            }
        }
    }

    return tempBom;
};
// IBOMItemForAdmin | IBOMItemForAdminForEvent
export const convertAllTheBomSubValueToDifferentCurrency = ({
    toCurrency,
    tempBom,
    conversionRate,
}:
    | {
          tempBom: IBOMItemForAdmin;
          toCurrency: string;
          conversionRate: {
              [currency_from_id: string]: {
                  [currency_to_id: string]: number;
              };
          };
      }
    | {
          tempBom: IBOMItemForAdminForEvent;
          toCurrency: string;
          conversionRate: {
              [currency_from_id: string]: {
                  [currency_to_id: string]: number;
              };
          };
      }) => {
    if (tempBom.sub_bom) {
        if (
            toCurrency !== tempBom.sub_bom.currency &&
            tempBom.sub_bom.currency &&
            conversionRate[tempBom.sub_bom.currency] &&
            conversionRate[tempBom.sub_bom.currency][toCurrency]
        ) {
            tempBom.cost_per_unit = roundToNDecimalPlace(
                +tempBom.cost_per_unit *
                    conversionRate[tempBom.sub_bom.currency][toCurrency],
                10
            ).toString();
            tempBom.subtotal = roundToNDecimalPlace(
                +tempBom.cost_per_unit *
                    conversionRate[tempBom.sub_bom.currency][toCurrency],
                10
            ).toString();
        }

        if (tempBom.sub_bom_items) {
            for (let itemsub of tempBom.sub_bom_items) {
                if (itemsub.sub_bom && itemsub.sub_bom_items) {
                    itemsub = convertAllTheBomSubValueToDifferentCurrency({
                        conversionRate,
                        tempBom: itemsub,
                        toCurrency,
                    });
                } else {
                    if (
                        tempBom.sub_bom.currency !== toCurrency &&
                        tempBom.sub_bom.currency &&
                        conversionRate[tempBom.sub_bom.currency] &&
                        conversionRate[tempBom.sub_bom.currency][toCurrency]
                    ) {
                        itemsub.cost_per_unit = roundToNDecimalPlace(
                            +itemsub.cost_per_unit *
                                conversionRate[tempBom.sub_bom.currency][
                                    toCurrency
                                ],
                            10
                        ).toString();
                        itemsub.subtotal = roundToNDecimalPlace(
                            +itemsub.subtotal *
                                conversionRate[tempBom.sub_bom.currency][
                                    toCurrency
                                ],
                            10
                        ).toString();
                    }
                }
            }
        }
    }

    return tempBom;
};

const getlistCurrencyFromBom = (Bom: IBOMForBomAdmin) => {
    let listOfCUrrency: string[] = [Bom.currency];

    for (let item of Bom.bom_items) {
        if (item.sub_bom) {
            listOfCUrrency = listOfCUrrency.concat(
                ...getlistCurrencyFromSubBom(item)
            );
        }
    }

    return listOfCUrrency;
};

const getlistCurrencyFromSubBom = (subBom: IBOMItemForAdmin) => {
    let listOfCUrrency: string[] = [];

    if (subBom.sub_bom) {
        listOfCUrrency.push(subBom.sub_bom.currency);

        if (subBom.sub_bom_items) {
            for (let item of subBom.sub_bom_items) {
                if (item.sub_bom) {
                    listOfCUrrency = listOfCUrrency.concat(
                        ...getlistCurrencyFromSubBom(item)
                    );
                }
            }
        }
    }
    return listOfCUrrency;
};

export const getManyToManyCurrencyConversion = async (
    payload: {
        currency_from_id: string;
        currency_to_id: string;
    }[]
) => {
    try {
        const response = await post<any, IMultipleCurrencyConversionRes[]>(
            `/backbone/currency_conversions/`,
            payload
        );

        let res: {
            [currency_from_id: string]: {
                [currency_to_id: string]: number;
            };
        } = {};

        for (let cur of response.data) {
            res[cur.from_currency_id] = {};
        }

        for (let cur of response.data) {
            res[cur.from_currency_id][cur.to_currency_id] = cur.conversion_rate;
        }

        return res;
    } catch (err) {
        throw err;
    }
};

export const areAllOfbomItemsUnselected: (
    bomItems: IBOMItemForAdminForEvent[]
) => {
    selected: boolean;
    subBoms: IBOMItemForAdminForEvent[];
} = (bomItems: IBOMItemForAdminForEvent[]) => {
    let output: boolean = true;

    for (let i = 0; i < bomItems.length; i++) {
        if (bomItems[i].sub_bom_items) {
            const { selected, subBoms } = areAllOfbomItemsUnselected(
                cloneDeep(bomItems[i].sub_bom_items!)
            );

            const selectedForCurrentSubBom = selected ? false : true;

            bomItems[i].selected = selectedForCurrentSubBom;
            bomItems[i].sub_bom_items = subBoms;

            output = output && !selectedForCurrentSubBom;
        } else {
            output = output && !bomItems[i].selected;
        }
    }

    return { subBoms: bomItems, selected: output };
};

const findPath = (tree: any, path: any) => {
    let current = tree;
    const result = [];

    for (const key of path) {
        if (current[key]) {
            const keys = Object.keys(current);
            const index = keys.indexOf(key) + 1;
            result.push(index);
            current = current[key];
        } else {
            throw new Error('Invalid path');
        }
    }

    return result.join('.');
};

const parseInput = (input: string) => {
    const [treeKey, ...rest] = input.split('->').map((part) => part.trim());
    const path = rest.reverse();
    return [treeKey, path];
};

const generateOutput = (treeMap: any, input: string) => {
    let output = '';
    const [treeKey, path] = parseInput(input);
    const indexPath = findPath(treeMap[treeKey as string], path);
    if (indexPath.split('.').length === 1) {
        return `${treeKey}.${input.split('->')[1]}`;
    }

    return (output += `${treeKey}.${indexPath
        .split('.')
        .slice(1)
        .join('.')} ${input.split('->').slice(1).join('->')}`);
};

const buildTree = (input: string[]) => {
    const root: { [key: string]: any } = {};

    input.forEach((path) => {
        const parts = path.split('->').map((part) => part.trim());
        let currentLevel: { [key: string]: any } = root;

        parts.forEach((part) => {
            if (!currentLevel[part]) {
                currentLevel[part] = {};
            }
            currentLevel = currentLevel[part];
        });
    });

    return root;
};

export const arrangeIRfqAnalyticsStoreListOfBomStructWIthHeiriacyValues = (
    listOfBoms: IRfqAnalyticsStoreListOfBomStructWIthHeiriacy[]
) => {
    let temp: (IRfqAnalyticsStoreListOfBomStructWIthHeiriacy & {
        labelSplit: string[];
    })[] = cloneDeep(
        cloneDeep(listOfBoms).map((bom: any) => {
            bom.labelSplit = bom.label
                .split('->')
                .map((code: string) => code.trim());

            return bom;
        })
    );
    temp.sort((a, b) => a.labelSplit.length - b.labelSplit.length);

    let output: (IRfqAnalyticsStoreListOfBomStructWIthHeiriacy & {
        labelSplit: string[];
    })[] = temp.filter((bom) => bom.labelSplit.length === 1);

    for (let bom of temp.filter((bom) => bom.labelSplit.length > 1)) {
        const findIndex = output.findIndex((parentBom) => {
            // if (bom.labelSplit.length > 1) {
            //     bom.labelSplit[bom.labelSplit.length - 1] =
            //         bom.labelSplit[bom.labelSplit.length - 1].split('(')[0];
            // }

            const tp = cloneDeep(bom.labelSplit).slice(1);

            return shallowEqual(parentBom.labelSplit, tp);
        });

        if (findIndex > -1) {
            output = [
                ...output.slice(0, findIndex + 1),
                bom,
                ...output.slice(findIndex + 1),
            ];
        } else {
            output.push(bom);
        }
    }

    const bomMap: { [bomId: string]: number } = {};
    let bomCount = 1;

    for (let bom of output) {
        if (!bomMap[bom.enterprise_bom_id ?? '']) {
            bomMap[bom.enterprise_bom_id ?? ''] = bomCount;
            bomCount += 1;
        }
        bom.label = `${bomMap[bom.enterprise_bom_id ?? '']}->` + bom.label;
    }
    let treeData = buildTree(
        output.map(
            (bom) =>
                `${bomMap[bom.enterprise_bom_id ?? '']}->` +
                bom.label
                    .replace(`${bomMap[bom.enterprise_bom_id ?? '']}->`, '')
                    .split('->')
                    .reverse()
                    .join('->')
        )
    );
    output = output.map((bom) => ({
        ...bom,
        label: generateOutput(treeData, bom.label),
    }));

    return output;
};
