import { cloneDeep } from 'lodash';
import { TemplateDetails } from '../../../../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { getTemplateDataById } from '../../../../../BuyerTemplates/Services/template.service';
import {
    // LinkedEventDetails,
    fetchLinkedEventDetails,
    // useFetchLinkedEventDetailsQuery,
} from '../../../../../Events/Services/Buyer/RFQ.service';
import { transformPaymentTermsFromFrontendToBackend } from '../../../../../Global/Helpers/paymentTermsHelpers';
import {
    ISectionField,
    RfqStandardSections,
} from '../../../../../Global/Interfaces/TemplateInterface';
import { IAddresses } from '../../../../../Models/Address.model';
import {
    ICustomPODeliveryScheduleItem,
    IEntityIdentification,
    IPurchaseOrderAlgoObject,
    IPurchaseOrderItem,
    IPurchaseOrderWithItems,
} from '../../../../../Models/AwardsAndPO.model';
import {
    IGetAllEventSeller,
    IGetRFQDetails,
    IGetRFQItemDetails,
} from '../../../../../Models/RFQ.model';
import {
    IAvailableApprover,
    IEventApproval,
} from '../../../../../Models/RFQSummary.model';
import { IUserDetails } from '../../../../../Models/UserDetails.model';
import {
    IVendorContactDetails,
    PurchaseOrderDisplayStatusBuyer,
    PurchaseOrderDisplayStatusSeller,
    PurchaseOrderStatus,
} from '../../../../../PurchaseOrders/Interfaces/PO.model';
import { VerificationStatus } from '../../../../../Redux/Slices/VerificationSlice';
import { getPurchaseOrderTermsAndConditionsList } from '../../../../../Services/Organization/organizationOperations';
import {
    fetchAllPoItemsForEvent,
    fetchAllPoItemsLinkedForEvent,
    fetchAllRFQVendors,
    fetchRFQDetails,
    fetchRFQItemDetails,
    fetchRFQVendorItemContacts,
    getEntityAddresses,
    getEntityIdentificationNumbers,
    getRfqPoLinkInfo,
} from '../../../../../Services/events/eventOperations';
import {
    fetchNextApproverListForExistingPurchaseOrder,
    fetchNextApproverListForNewPurchaseOrder,
    fetchPOList,
    getPoApprovals,
} from '../../../../../Services/purchaseOrders/purchaseOrderOperations';
import { createAllPurchaseOrders } from '../POCreation/helperFunctions';
import { getCurrencyConversionRate } from '../../../../../Events/Services/Buyer/shared.service';

export interface IButtonBools {
    showRevert: boolean;
    showDelete: boolean;
    showReject: boolean;
    showRework: boolean;
    showSendForApproval: boolean;
    showAddApproval: boolean;
    showSubmit: boolean;
    showRescind: boolean;
}

export const getNextApproverListForPo = async (
    curPo: IPurchaseOrderWithItems
): Promise<IAvailableApprover[]> => {
    return new Promise<IAvailableApprover[]>(async (resolve, reject) => {
        try {
            const poItems: {
                purchase_order_item_id: string;
                delivery_schedule_items: string[];
            }[] = [];

            if (!curPo.purchase_order_id) {
                curPo.purchase_order_items.forEach((poItem) => {
                    const dsItemList = poItem.delivery_schedule_items.map(
                        (dsItem) => dsItem.delivery_schedule_item_id
                    );
                    if (dsItemList) {
                        poItems.push({
                            purchase_order_item_id:
                                poItem.delivery_schedule_items[0]
                                    .purchase_order_item,
                            delivery_schedule_items: dsItemList,
                        });
                    }
                });
                const nextApproverList =
                    await fetchNextApproverListForNewPurchaseOrder({
                        poItems,
                        buyerEntityId: curPo.buyer_entity,
                        discountPercentage:
                            curPo.pricing_information?.discount_percentage ??
                            '',
                        eventId: curPo.event,
                    });
                resolve(nextApproverList);
            } else {
                if (curPo.status === 'APPROVAL_PENDING') {
                    const nextApproverList =
                        await fetchNextApproverListForExistingPurchaseOrder({
                            poId: curPo.purchase_order_id,
                        });
                    resolve(nextApproverList);
                } else {
                    resolve([]);
                }
            }
        } catch (e) {
            reject(e);
        }
    });
};

export const getPoApprovalLog = async (
    poId: string | null
): Promise<IEventApproval[]> => {
    return new Promise<IEventApproval[]>(async (resolve, reject) => {
        try {
            if (!poId) {
                resolve([]);
            } else {
                const approvalLog = await getPoApprovals(poId);
                resolve(approvalLog);
            }
        } catch (e) {
            reject(e);
        }
    });
};

export function getButtonBools({
    curUserId,
    curPo,
    userHasPoApprovalPermission,
}: {
    curUserId: string;
    curPo: IPurchaseOrderWithItems;
    userHasPoApprovalPermission: boolean;
}) {
    return new Promise<IButtonBools>(async (resolve, reject) => {
        try {
            const [nextApproverList, approvalLog] = await Promise.all([
                getNextApproverListForPo(curPo),
                getPoApprovalLog(curPo.purchase_order_id),
            ]);

            const nextApproversExist = nextApproverList.length > 0;

            const userApprovalIsIssued = approvalLog.some(
                (row) => row.approverId === curUserId && row.status === 'ISSUED'
            );
            const userApprovalIsPending = approvalLog.some(
                (row) =>
                    row.approverId === curUserId && row.status === 'PENDING'
            );

            const numberOfPendingApprovals = approvalLog.filter(
                (row) => row.status === 'PENDING'
            ).length;

            if (
                curPo.purchase_order_id === null ||
                curPo.purchase_order_id === ''
            ) {
                resolve({
                    showRevert: true,
                    showDelete: false,
                    showReject: false,
                    showRework: false,
                    showSendForApproval: nextApproversExist,
                    showAddApproval: false,
                    showSubmit:
                        userHasPoApprovalPermission && !nextApproversExist,
                    showRescind: false,
                });
            } else {
                if (curPo.status === 'ISSUED') {
                    resolve({
                        showRevert: false,
                        showDelete: false,
                        showReject: false,
                        showRework: false,
                        showSendForApproval: false,
                        showAddApproval: false,
                        showSubmit: false,
                        showRescind: userHasPoApprovalPermission,
                    });
                } else if (curPo.status === 'APPROVAL_PENDING') {
                    if (userApprovalIsIssued) {
                        resolve({
                            showRevert: false,
                            showDelete: true,
                            showReject: false,
                            showRework: false,
                            showSendForApproval: false,
                            showAddApproval: false,
                            showSubmit: false,
                            showRescind: false,
                        });
                    } else if (userApprovalIsPending) {
                        const isFinalApprover =
                            !nextApproversExist &&
                            numberOfPendingApprovals === 1;
                        resolve({
                            showRevert: false,
                            showDelete: false,
                            showReject: true,
                            showRework: true,
                            showSendForApproval: false,
                            showAddApproval: !isFinalApprover,
                            showSubmit: isFinalApprover,
                            showRescind: false,
                        });
                    } else {
                        resolve({
                            showRevert: false,
                            showDelete: false,
                            showReject: false,
                            showRework: false,
                            showSendForApproval: false,
                            showAddApproval: false,
                            showSubmit: false,
                            showRescind: false,
                        });
                    }
                } else {
                    resolve({
                        showRevert: false,
                        showDelete: false,
                        showReject: false,
                        showRework: false,
                        showSendForApproval: false,
                        showAddApproval: false,
                        showSubmit: false,
                        showRescind: false,
                    });
                }
            }
        } catch (err) {
            reject(err);
        }
    });
}

export function getAllPurchaseOrderDetails(
    rfqEventID: string,
    currentUserDetails: IUserDetails,
    filtered_delivery_schedule_item_ids: string[],
    templateId: string,
    buyerEntityId: string,
    event_type: 'DIRECT' | 'FROM_EVENT'
) {
    return new Promise<IPurchaseOrderWithItems[]>(async (resolve, reject) => {
        try {
            let rfqDetailsPromise = fetchRFQDetails(
                rfqEventID,
                'BUYER',
                event_type
            );
            let rfqItemDetailsPromise = fetchRFQItemDetails(
                rfqEventID,
                event_type
            );
            let [rfqDetails, rfqItemDetails] = await Promise.all([
                rfqDetailsPromise,
                rfqItemDetailsPromise,
            ]);
            let rfqDetailsCopy = cloneDeep(rfqDetails);
            let eventID = rfqDetails.event_id;
            let po_items_promise = fetchAllPoItemsForEvent(eventID, event_type);
            let po_promise = fetchPOList('BUYER', null, null, null, eventID);
            let rfq_vendors_promise = fetchAllRFQVendors(eventID, event_type);
            let rfqTemplateDetailsPromise = getTemplateDataById(
                templateId,
                buyerEntityId
            );
            let allLinkedPoItems: IPurchaseOrderItem[] | null = null;
            let [po_items, po_list, rfq_vendors, rfqTemplateDetails] =
                await Promise.all([
                    po_items_promise,
                    po_promise,
                    rfq_vendors_promise,
                    rfqTemplateDetailsPromise,
                ]);

            if (
                event_type === 'FROM_EVENT' &&
                rfqTemplateDetails.linked_templates?.linked_po_template_id
            ) {
                let linked_po_template_id =
                    rfqTemplateDetails.linked_templates.linked_po_template_id;
                let linkedRfqTemplateDetailsPromise = getTemplateDataById(
                    linked_po_template_id,
                    buyerEntityId
                );
                rfqTemplateDetails = await linkedRfqTemplateDetailsPromise;
                allLinkedPoItems = await fetchAllPoItemsLinkedForEvent(
                    rfqDetails.event_id
                );
            }

            let po_list_with_items: IPurchaseOrderWithItems[] = [];
            for (let current_po of po_list) {
                current_po.purchase_order_items = [];
                for (let current_item of po_items) {
                    if (
                        current_po.purchase_order_id ===
                        current_item.purchase_order
                    ) {
                        if (
                            !current_item.custom_fields ||
                            !('section_list' in current_item.custom_fields)
                        ) {
                            current_item.custom_fields = { section_list: [] };
                        }
                        current_po.purchase_order_items.push(current_item);
                    }
                }
                let current_vendor = rfq_vendors.find(
                    (vendor) =>
                        vendor.seller_entity_details.seller_entity_id ===
                        current_po.seller_entity
                );

                current_po.seller_information.is_virtual = current_vendor
                    ? current_vendor.seller_entity_details.is_virtual
                    : false;
                current_po.seller_information.verification_status =
                    current_vendor
                        ? current_vendor.seller_entity_details
                              .verification_status
                        : VerificationStatus.UNVERIFIED;
                current_po.seller_information.entity_description =
                    current_vendor
                        ? current_vendor.seller_entity_details
                              .entity_description
                        : '';
                if (current_po.purchase_order_items.length > 0) {
                    po_list_with_items.push(current_po);
                }
            }
            let deliveryList: ICustomPODeliveryScheduleItem[] = [];
            let deliveryList_idx = 0;
            // if (event_type === 'FROM_EVENT') {
            //     await UpdateLinkedRfqValues(rfqDetails).then((res) => {
            //         rfqDetailsCopy = res;
            //     });
            // }
            for (let curPoItem of po_items) {
                if (
                    curPoItem.purchase_order === null ||
                    curPoItem.purchase_order === ''
                ) {
                    if (
                        !curPoItem.custom_fields ||
                        !('section_list' in curPoItem.custom_fields)
                    ) {
                        curPoItem.custom_fields = { section_list: [] };
                    }
                    if (event_type === 'FROM_EVENT') {
                        // let linkedTemplateDetails = await getTemplateDataById(
                        //     rfqTemplateDetails.linked_templates
                        //         .linked_po_template_id,
                        //     buyerEntityId
                        // );
                        await updateLinkedItemValues(
                            rfqDetails.rfq_entry_id,
                            rfqDetails.event_id,
                            cloneDeep(curPoItem),
                            rfqTemplateDetails,
                            allLinkedPoItems,
                            rfqDetails.pricing_information.currency_code_id
                        ).then((res) => {
                            curPoItem = res;
                        });
                    }
                    // merge the delivery schedule item and award
                    for (let delivery_item of curPoItem.delivery_schedule_items) {
                        let useThisDsItem =
                            filtered_delivery_schedule_item_ids.length === 0 ||
                            filtered_delivery_schedule_item_ids.includes(
                                delivery_item.delivery_schedule_item_id
                            );
                        deliveryList.push({
                            id: deliveryList_idx++,
                            is_selected: useThisDsItem,
                            vendor_name: curPoItem.seller_entity_name,
                            ...delivery_item,
                            po_item_details: curPoItem,
                        });
                    }
                }
            }
            if (deliveryList.length > 0) {
                let newlyCreatedPOs = await createPOs(
                    rfqDetailsCopy,
                    rfqItemDetails,
                    deliveryList,
                    rfq_vendors,
                    currentUserDetails,
                    event_type,
                    rfqTemplateDetails
                );
                resolve(newlyCreatedPOs.concat(po_list_with_items));
            } else {
                resolve(po_list_with_items);
            }
        } catch (err) {
            reject(err);
        }
    });
}

export async function UpdateLinkedRfqValues(
    rfqDetails: IGetRFQDetails,
    curSellerName: string
): Promise<IGetRFQDetails> {
    let rfqDetailsClone = cloneDeep(rfqDetails);
    // const { data: eventDetails } = useFetchLinkedEventDetailsQuery({
    // enterprise_type: 'BUYER',
    // rfq_event_id: rfqDetails.event_id,
    // type: 'FROM_EVENT',
    // });

    let rfqLinkedData = await fetchLinkedEventDetails(rfqDetails.event_id);

    if (!rfqLinkedData) {
        return rfqDetailsClone;
    }
    let rfqLinkedValues = rfqLinkedData.find(
        (rfqLinked) => rfqLinked.linked_vendor === null
    );

    if (!rfqLinkedValues) {
        rfqLinkedValues = rfqLinkedData.find(
            (rfqLinked) => rfqLinked.linked_vendor === curSellerName
        );
        if (!rfqLinkedValues) {
            return rfqDetailsClone;
        }
    }

    let misMatchFields: {
        [RfqStandardSections.EVENT_DETAILS]: ISectionField[];
        [RfqStandardSections.PAYMENT_AND_DELIVERY_TERMS]: ISectionField[];
        [RfqStandardSections.ADDITIONAL_DETAILS]: ISectionField[];
        [RfqStandardSections.ESSENTIAL_TERMS]: ISectionField[];
    } | null = {
        [RfqStandardSections.EVENT_DETAILS]: [],
        [RfqStandardSections.PAYMENT_AND_DELIVERY_TERMS]: [],
        [RfqStandardSections.ADDITIONAL_DETAILS]: [],
        [RfqStandardSections.ESSENTIAL_TERMS]: [],
    };

    const response = await getRfqPoLinkInfo(rfqDetails.rfq_entry_id);
    if (response) {
        misMatchFields = response;
    }

    if (misMatchFields && rfqLinkedValues) {
        rfqDetailsClone.rfq_default_information =
            rfqLinkedValues.rfq_default_information;

        const {
            payment_type: default_event_payment_type,
            prepayment_percentage: default_event_prepayment_percentage,
            payment_terms: default_event_payment_terms,
            deliverables_payment_terms:
                default_event_deliverables_payment_terms,
        } = transformPaymentTermsFromFrontendToBackend({
            paymentTerms:
                rfqLinkedValues.rfq_default_information.defaultPaymentTerms,
        });
        rfqDetailsClone.cost_centre_id =
            rfqDetailsClone.rfq_default_information.default_event_cost_centre_id;
        rfqDetailsClone.gl_id =
            rfqDetailsClone.rfq_default_information.default_event_gl_id;
        rfqDetailsClone.project_id =
            rfqDetailsClone.rfq_default_information.default_event_project_id;
        rfqDetailsClone.lead_time =
            rfqDetailsClone.rfq_default_information.default_event_lead_time;
        rfqDetailsClone.lead_time_period =
            rfqDetailsClone.rfq_default_information.default_event_lead_time_period;
        rfqDetailsClone.incoterm_id =
            rfqDetailsClone.rfq_default_information.default_event_incoterm_id;
        rfqDetailsClone.custom_additional_information =
            rfqDetailsClone.rfq_default_information
                .default_event_item_additional_information ?? '';
        rfqDetailsClone.gr_tolerance =
            rfqDetailsClone.rfq_default_information
                .default_event_quantity_tolerance_percentage ?? '0';
        rfqDetailsClone.payment_type = default_event_payment_type;
        rfqDetailsClone.prepayment_percentage =
            default_event_prepayment_percentage;
        rfqDetailsClone.payment_terms = default_event_payment_terms;
        rfqDetailsClone.deliverables_payment_terms =
            default_event_deliverables_payment_terms;
        rfqLinkedValues?.custom_fields?.section_list.forEach(
            (linkedSection) => {
                const poSectionIndex =
                    rfqDetailsClone.custom_fields?.section_list.findIndex(
                        (poSection) => poSection.name === linkedSection.name
                    );

                if (poSectionIndex !== -1 && poSectionIndex) {
                    linkedSection.fields.forEach((linkedField) => {
                        const fieldIndex =
                            rfqDetailsClone.custom_fields?.section_list[
                                poSectionIndex
                            ].fields.findIndex(
                                (field) => field.name === linkedField.name
                            );

                        if (fieldIndex !== -1 && fieldIndex) {
                            rfqDetailsClone.custom_fields!.section_list[
                                poSectionIndex
                            ].fields[fieldIndex] = linkedField;
                        } else {
                            rfqDetailsClone.custom_fields!.section_list[
                                poSectionIndex
                            ].fields.push(linkedField);
                        }
                    });
                } else {
                    rfqDetailsClone.custom_fields?.section_list.push(
                        linkedSection
                    );
                }
            }
        );

        rfqLinkedValues?.custom_fields_negotiate?.section_list.forEach(
            (linkedSection) => {
                const poSectionIndex =
                    rfqDetailsClone.custom_fields_negotiate?.section_list.findIndex(
                        (poSection) => poSection.name === linkedSection.name
                    );

                if (poSectionIndex !== -1 && poSectionIndex) {
                    linkedSection.fields.forEach((linkedField) => {
                        const fieldIndex =
                            rfqDetailsClone.custom_fields_negotiate?.section_list[
                                poSectionIndex
                            ].fields.findIndex(
                                (field) => field.name === linkedField.name
                            );

                        if (fieldIndex !== -1 && fieldIndex) {
                            rfqDetailsClone.custom_fields_negotiate!.section_list[
                                poSectionIndex
                            ].fields[fieldIndex] = linkedField;
                        } else {
                            rfqDetailsClone.custom_fields_negotiate!.section_list[
                                poSectionIndex
                            ].fields.push(linkedField);
                        }
                    });
                } else {
                    rfqDetailsClone.custom_fields_negotiate?.section_list.push(
                        linkedSection
                    );
                }
            }
        );
    }
    return rfqDetailsClone;
}

export async function updateLinkedItemValues(
    rfq_entry_id: string,
    event_id: string,
    po_item: IPurchaseOrderItem,
    rfqTemplateDetails: TemplateDetails,
    allLinkedPoItems: IPurchaseOrderItem[] | null,
    rfqCurrencyId: string
): Promise<IPurchaseOrderItem> {
    let misMatchFields: {
        [RfqStandardSections.EVENT_DETAILS]: ISectionField[];
        [RfqStandardSections.PAYMENT_AND_DELIVERY_TERMS]: ISectionField[];
        [RfqStandardSections.ADDITIONAL_DETAILS]: ISectionField[];
        [RfqStandardSections.ESSENTIAL_TERMS]: ISectionField[];
    } | null = {
        [RfqStandardSections.EVENT_DETAILS]: [],
        [RfqStandardSections.PAYMENT_AND_DELIVERY_TERMS]: [],
        [RfqStandardSections.ADDITIONAL_DETAILS]: [],
        [RfqStandardSections.ESSENTIAL_TERMS]: [],
    };

    let linkedData: IPurchaseOrderItem | null = null;

    linkedData =
        allLinkedPoItems?.find(
            (item) =>
                item.parent_purchase_order_item ===
                po_item.purchase_order_item_id
        ) || null;

    if (!linkedData) {
        linkedData =
            allLinkedPoItems?.find(
                (item) => item.parent_purchase_order_item === null
            ) || null;
        if (linkedData) {
            // let conversionRateMap = new Map<string, ICurrencyRate>();

            let vendor_id = linkedData.seller_entity;
            const conversionRate = await getCurrencyConversionRate(
                rfqCurrencyId,
                po_item.pricing_information.currency_code_id,
                rfq_entry_id,
                vendor_id
            );
            if (
                conversionRate &&
                conversionRate.rate &&
                conversionRate.rate !== 1
            ) {
                let convertedAdditionalCosts =
                    linkedData.pricing_information.additional_costs.map(
                        (cost) => {
                            if (cost.cost_type === 'PERCENTAGE') {
                                return cost;
                            } else {
                                return {
                                    ...cost,
                                    cost_value:
                                        +cost.cost_value *
                                        (conversionRate?.rate ?? 1),
                                    threshold: cost.threshold
                                        ? (
                                              +cost.threshold *
                                              (conversionRate?.rate ?? 1)
                                          ).toFixed(10)
                                        : cost.threshold,
                                };
                            }
                        }
                    );
                linkedData.pricing_information.additional_costs =
                    convertedAdditionalCosts;

                let convertedTaxes = linkedData.pricing_information.taxes.map(
                    (cost) => {
                        if (cost.cost_type === 'PERCENTAGE') {
                            return cost;
                        } else {
                            return {
                                ...cost,
                                cost_value:
                                    +cost.cost_value *
                                    (conversionRate?.rate ?? 1),
                                threshold: cost.threshold
                                    ? (
                                          +cost.threshold *
                                          (conversionRate?.rate ?? 1)
                                      ).toFixed(10)
                                    : cost.threshold,
                            };
                        }
                    }
                );
                linkedData.pricing_information.taxes = convertedTaxes;
            }
        }
    }

    const response = await getRfqPoLinkInfo(rfq_entry_id);
    if (response) {
        misMatchFields = response;
    }

    if (linkedData && misMatchFields) {
        if (misMatchFields[RfqStandardSections.ESSENTIAL_TERMS]?.length > 0) {
            misMatchFields[RfqStandardSections.ESSENTIAL_TERMS].forEach(
                (field) => {
                    if (field.fieldType === 'STANDARD') {
                        switch (field.field) {
                            case 'COST_CENTER':
                                po_item.delivery_schedule_items.forEach(
                                    (dsItem) => {
                                        dsItem.cost_centre =
                                            linkedData
                                                ?.delivery_schedule_items[0]
                                                ?.cost_centre ?? null;
                                        dsItem.cost_centre_id =
                                            linkedData
                                                ?.delivery_schedule_items[0]
                                                ?.cost_centre_id ?? null;
                                    }
                                );
                                break;
                            case 'GL':
                                po_item.delivery_schedule_items.forEach(
                                    (dsItem) => {
                                        dsItem.general_ledger =
                                            linkedData
                                                ?.delivery_schedule_items[0]
                                                ?.general_ledger ?? null;
                                        dsItem.general_ledger_id =
                                            linkedData
                                                ?.delivery_schedule_items[0]
                                                ?.general_ledger_id ?? null;
                                    }
                                );
                                break;
                            case 'PROJECT':
                                po_item.delivery_schedule_items.forEach(
                                    (dsItem) => {
                                        dsItem.project =
                                            linkedData
                                                ?.delivery_schedule_items[0]
                                                ?.project ?? null;
                                        dsItem.project_id =
                                            linkedData
                                                ?.delivery_schedule_items[0]
                                                ?.project_id ?? null;
                                    }
                                );
                                break;

                            case 'DELIVERY_DATE':
                                let deliveryDate =
                                    linkedData?.delivery_schedule_items[0]
                                        ?.delivery_date;
                                po_item.delivery_schedule_items.map(
                                    (dsItem) => {
                                        dsItem.delivery_date =
                                            deliveryDate ?? null;
                                        return dsItem;
                                    }
                                );
                                break;

                            default:
                                break;
                        }
                    }
                }
            );
        }
        if (
            misMatchFields[RfqStandardSections.PAYMENT_AND_DELIVERY_TERMS]
                ?.length > 0
        ) {
            misMatchFields[
                RfqStandardSections.PAYMENT_AND_DELIVERY_TERMS
            ].forEach((field) => {
                if (field.fieldType === 'STANDARD') {
                    switch (field.field) {
                        case 'PAYMENT_TERMS':
                            po_item.paymentTerms =
                                linkedData?.paymentTerms ?? null;
                            break;
                        case 'INCOTERMS':
                            po_item.incoterm = linkedData?.incoterm ?? '';
                            po_item.incoterm_abbreviation =
                                linkedData?.incoterm_abbreviation ?? '';
                            break;
                        case 'LEAD_TIME':
                            po_item.procurement_information =
                                linkedData?.procurement_information ?? {
                                    lead_time: '',
                                    lead_time_period: '',
                                };
                            break;
                        default:
                            break;
                    }
                }
            });
        }

        linkedData?.custom_fields?.section_list.forEach((linkedSection) => {
            const poSectionIndex =
                po_item.custom_fields?.section_list.findIndex(
                    (poSection) => poSection.name === linkedSection.name
                );

            if (poSectionIndex !== -1 && poSectionIndex) {
                linkedSection.fields.forEach((linkedField) => {
                    const fieldIndex = po_item.custom_fields?.section_list[
                        poSectionIndex
                    ].fields.findIndex(
                        (field) => field.name === linkedField.name
                    );

                    if (fieldIndex !== -1 && fieldIndex) {
                        po_item.custom_fields!.section_list[
                            poSectionIndex
                        ].fields[fieldIndex] = linkedField;
                    } else {
                        po_item.custom_fields!.section_list[
                            poSectionIndex
                        ].fields.push(linkedField);
                    }
                });
            } else {
                po_item.custom_fields?.section_list.push(linkedSection);
            }
        });

        linkedData?.custom_fields_negotiate?.section_list.forEach(
            (linkedSection) => {
                const poSectionIndex =
                    po_item.custom_fields_negotiate?.section_list.findIndex(
                        (poSection) => poSection.name === linkedSection.name
                    );

                if (poSectionIndex !== -1 && poSectionIndex) {
                    linkedSection.fields.forEach((linkedField) => {
                        const fieldIndex =
                            po_item.custom_fields_negotiate?.section_list[
                                poSectionIndex
                            ].fields.findIndex(
                                (field) => field.name === linkedField.name
                            );

                        if (fieldIndex !== -1 && fieldIndex) {
                            po_item.custom_fields_negotiate!.section_list[
                                poSectionIndex
                            ].fields[fieldIndex] = linkedField;
                        } else {
                            po_item.custom_fields_negotiate!.section_list[
                                poSectionIndex
                            ].fields.push(linkedField);
                        }
                    });
                } else {
                    po_item.custom_fields_negotiate?.section_list.push(
                        linkedSection
                    );
                }
            }
        );

        linkedData?.pricing_information?.additional_costs.forEach(
            (linkedAdditionalCost) => {
                const poAdditionalCostIndex =
                    po_item.pricing_information.additional_costs?.findIndex(
                        (poAdditionalCost) =>
                            poAdditionalCost.cost_name ===
                            linkedAdditionalCost.cost_name
                    );
                if (poAdditionalCostIndex !== -1) {
                    //
                    po_item.pricing_information.additional_costs[
                        poAdditionalCostIndex
                    ].cost_value = cloneDeep(linkedAdditionalCost.cost_value);
                } else {
                    po_item.pricing_information.additional_costs.push(
                        linkedAdditionalCost
                    );
                }
            }
        );
        linkedData?.pricing_information?.taxes.forEach(
            (linkedAdditionalCost) => {
                const poAdditionalCostIndex =
                    po_item.pricing_information.taxes?.findIndex(
                        (poAdditionalCost) =>
                            poAdditionalCost.cost_name ===
                            linkedAdditionalCost.cost_name
                    );
                if (poAdditionalCostIndex !== -1) {
                    //
                    po_item.pricing_information.taxes[
                        poAdditionalCostIndex
                    ].cost_value = cloneDeep(linkedAdditionalCost.cost_value);
                } else {
                    po_item.pricing_information.taxes.push(
                        linkedAdditionalCost
                    );
                }
            }
        );

        let additionalCostListFromTemplate: string[] = [];
        let taxesListFromTemplate: string[] = [];
        rfqTemplateDetails.template_sections.forEach((section) => {
            section.section_items.forEach((item) => {
                if (item.additional_information?.additional_cost_information) {
                    additionalCostListFromTemplate.push(
                        item.is_builtin_field ? item.alternate_name : item.name
                    );
                }
                if (item.additional_information?.taxes_information) {
                    taxesListFromTemplate.push(item.name);
                }
            });
        });

        // remove additional costs and taxes from po item if not present in template
        po_item.pricing_information.additional_costs =
            po_item.pricing_information.additional_costs.filter((cost) =>
                additionalCostListFromTemplate.includes(cost.cost_name)
            );
        po_item.pricing_information.taxes =
            po_item.pricing_information.taxes.filter((cost) =>
                taxesListFromTemplate.includes(cost.cost_name)
            );
    }

    return Promise.resolve(po_item);
}

interface ISellerPoDict {
    [key: string]: {
        promise_idx: string;
        data?: {
            identifications: IEntityIdentification[];
            addresses: IAddresses[];
            contact: IVendorContactDetails[] | null;
            verification_status: VerificationStatus;
        };
    };
}

export function createPOs(
    rfqDetails: IGetRFQDetails,
    rfqItemDetails: IGetRFQItemDetails[],
    deliveryScheduleItems: ICustomPODeliveryScheduleItem[],
    allRfqVendors: IGetAllEventSeller[],
    currentUserDetails: IUserDetails,
    event_type: 'DIRECT' | 'FROM_EVENT',
    rfqTemplateDetails: TemplateDetails
) {
    return new Promise<IPurchaseOrderWithItems[]>((resolve, reject) => {
        if (
            rfqDetails !== null &&
            rfqItemDetails !== null &&
            deliveryScheduleItems !== null &&
            deliveryScheduleItems.length > 0
        ) {
            //Find all the data that needs to be fetched
            let seller_data: ISellerPoDict = {};
            let seller_identifications_promises = [];
            let seller_address_promises = [];
            let seller_contact_promises = [];

            //Get unique sellers:
            let curIdx = 0;
            for (let deliveryRow of deliveryScheduleItems) {
                if (!seller_data.hasOwnProperty(deliveryRow.seller_entity)) {
                    seller_data[deliveryRow.seller_entity] = {
                        promise_idx: curIdx.toString(),
                    };
                    //Get all required data in parallel
                    let current_identification_promise =
                        getEntityIdentificationNumbers(
                            deliveryRow.seller_entity
                        );
                    seller_identifications_promises.push(
                        current_identification_promise
                    );
                    let current_address_promise = getEntityAddresses(
                        deliveryRow.seller_entity
                    );
                    seller_address_promises.push(current_address_promise);
                    const current_seller_contacts = fetchRFQVendorItemContacts(
                        rfqDetails.rfq_entry_id,
                        deliveryRow.seller_entity,
                        event_type
                    );
                    seller_contact_promises.push(current_seller_contacts);

                    curIdx = curIdx + 1;
                }
            }
            let seller_identifications_consolidated = Promise.all(
                seller_identifications_promises
            );

            let seller_address_consolidated = Promise.all(
                seller_address_promises
            );

            let buyer_identification_promise = getEntityIdentificationNumbers(
                rfqDetails.buyer_entity.buyer_entity_id
            );
            let tnc_promise = getPurchaseOrderTermsAndConditionsList(
                rfqDetails.buyer_entity.buyer_entity_id
            );
            let staging_po_promise = fetchPOList(
                'BUYER',
                'STAGING',
                null,
                null,
                rfqDetails.event_id
            );

            let seller_contacts_consolidated = Promise.all(
                seller_contact_promises
            );

            let full_promise = Promise.all([
                seller_identifications_consolidated,
                seller_address_consolidated,
                buyer_identification_promise,
                tnc_promise,
                staging_po_promise,
                seller_contacts_consolidated,
            ]);
            full_promise
                .then((full_result_arr) => {
                    let seller_identifications: IEntityIdentification[][] =
                        full_result_arr[0];
                    let seller_addresses = full_result_arr[1];
                    let seller_contacts = full_result_arr[5];
                    for (let unique_seller_entity_id in seller_data) {
                        let seller_contact =
                            seller_contacts[
                                parseInt(
                                    seller_data[unique_seller_entity_id]
                                        .promise_idx
                                )
                            ] ?? null;

                        let allUniqueVendorContacts: {
                            [key: string]: IVendorContactDetails;
                        } = {};

                        for (let contacts of seller_contact) {
                            if (contacts.seller_contacts)
                                for (let contact of contacts.seller_contacts) {
                                    allUniqueVendorContacts[
                                        contact.vendor_contact_id
                                    ] = contact;
                                }
                        }

                        let matchingSeller = allRfqVendors.find(
                            (vend) =>
                                vend.seller_entity_details.seller_entity_id ===
                                unique_seller_entity_id
                        );

                        seller_data[unique_seller_entity_id].data = {
                            identifications:
                                seller_identifications[
                                    parseInt(
                                        seller_data[unique_seller_entity_id]
                                            .promise_idx
                                    )
                                ],
                            addresses:
                                seller_addresses[
                                    parseInt(
                                        seller_data[unique_seller_entity_id]
                                            .promise_idx
                                    )
                                ],
                            contact: Object.values(allUniqueVendorContacts),
                            verification_status: matchingSeller
                                ? matchingSeller.seller_entity_details
                                      .verification_status
                                : VerificationStatus.UNVERIFIED,
                        };
                    }

                    let buyer_identifications = full_result_arr[2];
                    let tnc_list = full_result_arr[3];
                    let tnc_list_filtered = tnc_list.find(
                        (row) => row.is_default && row.status === 'ACTIVE'
                    );
                    let default_tnc_data: null | {
                        terms_and_conditions_id: string;
                        name: string;
                        data: string;
                    } = null;
                    if (tnc_list_filtered) {
                        default_tnc_data =
                            tnc_list_filtered.terms_and_conditions;
                    }
                    let staging_po_list = full_result_arr[4];

                    createAllPurchaseOrders(
                        rfqDetails,
                        rfqItemDetails,
                        deliveryScheduleItems,
                        allRfqVendors,
                        buyer_identifications,
                        seller_data,
                        currentUserDetails,
                        default_tnc_data,
                        staging_po_list,
                        rfqTemplateDetails,
                        event_type
                    )
                        .then((po_dict) => {
                            resolve(PoObjectToList(po_dict));
                        })
                        .catch((err) => {
                            reject(err);
                        });
                    // resolve(PoObjectToList(po_dict));
                })
                .catch((err) => {
                    reject(err);
                });
        } else {
            reject();
        }
    });
}

const PoObjectToList = (po_object: IPurchaseOrderAlgoObject) => {
    let po_list: IPurchaseOrderWithItems[] = [];
    for (let vendorId in po_object) {
        for (let deptId in po_object[vendorId]) {
            po_list.push(po_object[vendorId][deptId]);
        }
    }

    return po_list;
};

export const orderedPoList = (
    unorderedList: IPurchaseOrderWithItems[]
): IPurchaseOrderWithItems[] => {
    let draftPOs = unorderedList.filter(
        (po) => po.purchase_order_id === null || po.purchase_order_id === ''
    );
    let pendingPOs = unorderedList
        .filter((po) => po.status === 'APPROVAL_PENDING')
        .sort((a, b) =>
            (a.custom_purchase_order_id ?? '').localeCompare(
                b.custom_purchase_order_id ?? ''
            )
        );
    let issuedPOs = unorderedList
        .filter((po) => po.status === 'ISSUED')
        .sort((a, b) =>
            (a.custom_purchase_order_id ?? '').localeCompare(
                b.custom_purchase_order_id ?? ''
            )
        );
    let ongoingPOs = unorderedList
        .filter((po) => po.status === 'ONGOING')
        .sort((a, b) =>
            (a.custom_purchase_order_id ?? '').localeCompare(
                b.custom_purchase_order_id ?? ''
            )
        );
    let completedPOs = unorderedList
        .filter((po) => po.status === 'COMPLETED')
        .sort((a, b) =>
            (a.custom_purchase_order_id ?? '').localeCompare(
                b.custom_purchase_order_id ?? ''
            )
        );
    let terminatedPOs = unorderedList
        .filter((po) => po.status === 'TERMINATED')
        .sort((a, b) =>
            (a.custom_purchase_order_id ?? '').localeCompare(
                b.custom_purchase_order_id ?? ''
            )
        );
    let rescindedPOs = unorderedList
        .filter((po) => po.status === 'RESCINDED')
        .sort((a, b) =>
            (a.custom_purchase_order_id ?? '').localeCompare(
                b.custom_purchase_order_id ?? ''
            )
        );
    let declinedPOs = unorderedList
        .filter((po) => po.status === 'DECLINED')
        .sort((a, b) =>
            (a.custom_purchase_order_id ?? '').localeCompare(
                b.custom_purchase_order_id ?? ''
            )
        );
    let rejectedPOs = unorderedList
        .filter((po) => po.status === 'REJECTED')
        .sort((a, b) =>
            (a.custom_purchase_order_id ?? '').localeCompare(
                b.custom_purchase_order_id ?? ''
            )
        );
    return [
        ...draftPOs,
        ...pendingPOs,
        ...issuedPOs,
        ...ongoingPOs,
        ...completedPOs,
        ...terminatedPOs,
        ...rescindedPOs,
        ...declinedPOs,
        ...rejectedPOs,
    ];
};

export const getBuyerPurchaseOrderStatusForDisplay = (
    po_backend_status: string,
    po_is_on_hold: boolean,
    po_termination_is_requested: boolean
): PurchaseOrderDisplayStatusBuyer => {
    if (po_backend_status === PurchaseOrderStatus.ONGOING) {
        if (po_termination_is_requested) {
            return PurchaseOrderDisplayStatusBuyer.TERMINATION_REQUESTED;
        }
        if (po_is_on_hold) {
            return PurchaseOrderDisplayStatusBuyer.ON_HOLD;
        }
    }
    switch (po_backend_status) {
        case PurchaseOrderStatus.APPROVAL_PENDING:
            return PurchaseOrderDisplayStatusBuyer.APPROVAL_PENDING;
        case PurchaseOrderStatus.ISSUED:
            return PurchaseOrderDisplayStatusBuyer.ISSUED;
        case PurchaseOrderStatus.ONGOING:
            return PurchaseOrderDisplayStatusBuyer.ONGOING;
        case PurchaseOrderStatus.COMPLETED:
            return PurchaseOrderDisplayStatusBuyer.COMPLETED;
        case PurchaseOrderStatus.RESCINDED:
            return PurchaseOrderDisplayStatusBuyer.RESCINDED;
        case PurchaseOrderStatus.REJECTED:
            return PurchaseOrderDisplayStatusBuyer.REJECTED;
        case PurchaseOrderStatus.DECLINED:
            return PurchaseOrderDisplayStatusBuyer.DECLINED;
        case PurchaseOrderStatus.TERMINATED:
            return PurchaseOrderDisplayStatusBuyer.TERMINATED;
        default:
            return PurchaseOrderDisplayStatusBuyer.ISSUED;
    }
};

export const getSellerPurchaseOrderStatusForDisplay = (
    po_backend_status: string,
    po_is_on_hold: boolean,
    po_termination_is_requested: boolean
): PurchaseOrderDisplayStatusSeller => {
    if (po_backend_status === PurchaseOrderStatus.ONGOING) {
        if (po_termination_is_requested) {
            return PurchaseOrderDisplayStatusSeller.TERMINATION_REQUESTED;
        }
        if (po_is_on_hold) {
            return PurchaseOrderDisplayStatusSeller.ON_HOLD;
        }
    }
    switch (po_backend_status) {
        case PurchaseOrderStatus.ISSUED:
            return PurchaseOrderDisplayStatusSeller.ISSUED;
        case PurchaseOrderStatus.ONGOING:
            return PurchaseOrderDisplayStatusSeller.ONGOING;
        case PurchaseOrderStatus.COMPLETED:
            return PurchaseOrderDisplayStatusSeller.COMPLETED;
        case PurchaseOrderStatus.RESCINDED:
            return PurchaseOrderDisplayStatusSeller.RESCINDED;
        case PurchaseOrderStatus.DECLINED:
            return PurchaseOrderDisplayStatusSeller.DECLINED;
        case PurchaseOrderStatus.TERMINATED:
            return PurchaseOrderDisplayStatusSeller.TERMINATED;
        default:
            return PurchaseOrderDisplayStatusSeller.UNKNOWN;
    }
};
