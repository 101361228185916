import { Grid } from '@mui/material';
import React from 'react';
import VLNProfileItemComponent from './VLNProfileItemComponent';
import { IVLNItemCatalogue } from '../../../Interfaces/VendorLNInterfaces';

const VLNProfileItemCatalogItems = ({
    itemsCatalogueList,
    canEdit,
}: {
    itemsCatalogueList: IVLNItemCatalogue[];
    canEdit?: boolean;
}) => {
    return (
        <Grid container spacing={4}>
            {itemsCatalogueList.map((itemsCatalogueData, i) => (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={i}>
                    <VLNProfileItemComponent
                        itemsCatalogueData={itemsCatalogueData}
                        canEdit={canEdit}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default VLNProfileItemCatalogItems;
