// import { useListOfCostCenterQuery } from '../services/costCenter.services';

import { cloneDeep, debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    useGetAllCurrencyOptionsQuery,
    useGetDefaultCurrencyIdQuery,
} from '../../AdditionalCost/Services/additionalCosts.services';
import {
    IUserPermissionFEStructure,
    ProjectPermissionsPriorityList,
} from '../../BuyerTemplates/Component/ShareSectionPopup';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import {
    convertUTCtoLocal,
    getFWDate,
    getFWDateTime,
} from '../../Common/Utils/DateUtils';
import { AuthContext } from '../../Contexts/AuthContext';
import { ILoading } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import {
    evaluateTokenNodes,
    getTokenNodes,
} from '../../Formula/DatesFormula/DateTokenizers';
import { ProjectPermissions } from '../../Organizations/Admin/Interfaces/UsersInterface';
import { IEntityViewBuyerMaster } from '../../Organizations/SellerAdmin/BuyerMaster/Interfaces/SellerAdminBuyerInterfaces.model';
import {
    BlankProject,
    cleanUpProjectCustomfields,
    convertProjectDetailsResponseToProjectDetailsPayload,
    convertTemplateDetailsToCFPayload,
    getEditableValuesForCustomFields,
    getProjectAttachmentIdsFromCF,
    trimProjectPayload,
    udpateProjectSectionMode,
    validateProjectDetails,
} from '../helpers/projectHelpers';
import {
    CreateProjectErrorFieldEnum,
    IProjectDetailsResponse,
    IProjectErrors,
    IProjectInformation,
    IProjectPayload,
    IProjectSectionMode,
    IProjectSectionStatus,
    IUserPermissionForSection,
    ProjectSectionMode,
    ProjectStatusEnum,
    ProjectTemplateStandardFieldSettingObjOptions,
} from '../interface/project.model';
import {
    getProjectSubmissionDateTimeHistoryList,
    restoreProjectVersion,
    updateProjectApi,
    updateSectionStatus,
    useGetAssignUserListQuery,
    useGetProjectDetailsQuery,
    useListOfBuyerEntitiesQuery,
} from '../services/project.services';
import { useProjectTemplateHooks } from './useProjectTemplateHooks';

export enum userPermissionEnum {
    NONE = 'NONE',
    EDIT = 'EDIT',
    VIEW = 'VIEW',
    ASSIGN_USER = 'ASSIGN_USER',
}

export const useProjectHook = (projectId: string) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.READY);

    const { data: allCurrencies } = useGetAllCurrencyOptionsQuery({});

    // project details
    const [projectDetails, setProjectDetails] =
        useState<IProjectPayload>(BlankProject);

    const { authData, checkPermission } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation<{ templateId: string; entityId: string }>();

    const [redirectUserToNextSection, setRedirectionUserToNextSection] =
        useState(false);
    const [sectionIndex, setSectionIndex] = useState<number>(-1);

    const [projectCreationSteps, setProjectCreationSteps] = useState<string[]>(
        []
    );
    const updateNextSectionRedirection = useCallback(
        (redirectUser: boolean) => {
            setRedirectionUserToNextSection(redirectUser);
        },
        []
    );

    const [error, setError] = useState<IProjectErrors>({ PROJECT_DETAILS: {} });

    const [projectSectionMode, setProjectSectionMode] =
        useState<IProjectSectionMode>({
            PROJECT_DETAILS: ProjectSectionMode.VIEW,
        });

    const [buyerEntities, setBuyerEntities] = useState<
        IEntityViewBuyerMaster[]
    >([]);

    const [selectEntityAndTemplatePopup, setSelectEntityandTemplatePopup] =
        useState<boolean>(false);

    const handleEntityTemplatePopupClose = () => {
        setSelectEntityandTemplatePopup(false);
    };

    const [loadingState, setLoadingState] = useState<ILoading>({
        loading: false,
        error: false,
        saved: true,
        error_msg: '',
    });

    const currentUserId = useMemo(() => {
        return authData.details?.user_id;
    }, [authData.details?.user_id]);

    const [projectInformation, setProjectInformation] =
        useState<IProjectInformation>({
            enterpriseName: '',
            createdBy: '',
            createdOn: getFWDate('TODAY'),
            modifiedBy: '',
            modifiedOn: getFWDate('TODAY'),
            status: '',
            projectName: '',
            buyerEntityName: '',
            projectId: '',
            sectionStatus: {
                PROJECT_DETAILS: ProjectStatusEnum.DRAFT,
            },
        });

    const [currentUserPermission, setCurrentUserPermission] =
        useState<userPermissionEnum>(userPermissionEnum.NONE);

    const {
        data: projectDetailsResponse,
        isLoading,
        isFetching,
        isError: projectDetailsResponseError,
        refetch: refetchData,
    } = useGetProjectDetailsQuery(projectId, {
        skip: projectId === '' || projectId === undefined,
        refetchOnMountOrArgChange: true,
    });

    const { templateDetails, hookState: templateHookState } =
        useProjectTemplateHooks(
            projectDetails.buyer_entity_id,
            projectDetails.template_id
        );

    const { data: buyerEntitiesList, refetch: refetchBuyerEntityList } =
        useListOfBuyerEntitiesQuery(
            {
                entityId: projectDetails.buyer_entity_id,
            },
            {
                skip: !Boolean(projectDetails.buyer_entity_id),
            }
        );

    useEffect(() => {
        try {
            refetchBuyerEntityList();
        } catch (e) {}
    }, [refetchBuyerEntityList]);

    useEffect(() => {
        if (buyerEntitiesList) {
            setBuyerEntities(buyerEntitiesList);
        }
    }, [buyerEntitiesList]);

    useEffect(() => {
        if (!Boolean(projectDetailsResponse)) {
            updateHookState(HookStateValue.LOADING);
        } else if (!isLoading && projectDetailsResponseError) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [
        isLoading,
        projectDetailsResponse,
        projectDetailsResponseError,
        updateHookState,
    ]);

    const isCurrentUserCreator = useMemo(() => {
        return (
            currentUserId === projectDetailsResponse?.created_by_user.user_id
        );
    }, [currentUserId, projectDetailsResponse?.created_by_user.user_id]);

    const doesCurrentUserHaveProjectAssignPermissionInAdmin = useMemo(() => {
        return checkPermission(
            'CUSTOMIZE',
            'PROJECT',
            'PROJECT_ASSIGN_USERS',
            projectDetailsResponse?.buyer_entity ?? null
        );
    }, [checkPermission, projectDetailsResponse?.buyer_entity]);

    const doesCurrentUserHaveProjectAssignManagerPermissionInAdmin =
        useMemo(() => {
            return checkPermission(
                'CUSTOMIZE',
                'PROJECT',
                'PROJECT_ASSIGN_MANAGERS',
                projectDetailsResponse?.buyer_entity ?? null
            );
        }, [checkPermission, projectDetailsResponse?.buyer_entity]);

    const isUserProjectManager = useMemo(() => {
        let permissionInTemplate: IUserPermissionForSection | null = null;

        if (
            templateHookState.state === HookStateValue.READY &&
            projectDetailsResponse
        ) {
            if (templateDetails.assigned_users.TEMPLATE_LEVEL?.length > 0) {
                if (
                    templateDetails.assigned_users.TEMPLATE_LEVEL.some(
                        (user) => user.user_id === currentUserId
                    )
                ) {
                    permissionInTemplate =
                        templateDetails.assigned_users.TEMPLATE_LEVEL.find(
                            (user) => user.user_id === currentUserId
                        ) ?? null;
                }
            } else if (
                doesCurrentUserHaveProjectAssignManagerPermissionInAdmin &&
                currentUserId
            ) {
                permissionInTemplate = {
                    user_id: currentUserId,
                    permission: ProjectPermissions.PROJECT_ASSIGN_MANAGERS,
                };
            }

            const isUserProjectManager =
                (projectDetailsResponse?.project_managers?.length === 0 &&
                    permissionInTemplate?.permission ===
                        ProjectPermissions.PROJECT_ASSIGN_MANAGERS) ||
                (currentUserId !== undefined &&
                    projectDetailsResponse?.project_managers.some(
                        (manager) => manager.user_id === currentUserId
                    ));

            return isUserProjectManager;
        }

        return false;
    }, [
        currentUserId,
        doesCurrentUserHaveProjectAssignManagerPermissionInAdmin,
        projectDetailsResponse,
        templateDetails.assigned_users.TEMPLATE_LEVEL,
        templateHookState,
    ]);

    const fieldThatHaveFormula = useMemo(() => {
        const sections = Object.keys(templateDetails.fieldSettings);

        const fieldsSectionWise = sections.reduce(
            (acc, section) => {
                const standardFields: ProjectTemplateStandardFieldSettingObjOptions[] =
                    Object.keys(
                        templateDetails.fieldSettings[section].standardFields
                    ) as ProjectTemplateStandardFieldSettingObjOptions[];

                const standardFielsWithFormula = standardFields.filter(
                    (field) => {
                        if (
                            templateDetails.fieldSettings[section]
                                .standardFields[field].formula &&
                            templateDetails.fieldSettings[section]
                                .standardFields[field].formula
                                ?.formula_string &&
                            (templateDetails.fieldSettings[section]
                                .standardFields[field].formula?.formula_string
                                ?.length ?? 0) > 0
                        ) {
                            return true;
                        }

                        return false;
                    }
                );

                const customFields: string[] = Object.keys(
                    templateDetails.fieldSettings[section].customFields
                );
                const customFielsWithFormula = customFields.filter((field) => {
                    if (
                        templateDetails.fieldSettings[section].customFields[
                            field
                        ]?.formula &&
                        templateDetails.fieldSettings[section].customFields[
                            field
                        ].formula?.formula_string &&
                        (templateDetails.fieldSettings[section].customFields[
                            field
                        ].formula?.formula_string?.length ?? 0) > 0
                    ) {
                        return true;
                    }

                    return false;
                });

                return {
                    ...acc,
                    [section]: {
                        standardFields: standardFielsWithFormula,
                        customFields: customFielsWithFormula,
                    },
                };
            },
            {} as {
                [section: string]: {
                    standardFields: ProjectTemplateStandardFieldSettingObjOptions[];
                    customFields: string[];
                };
            }
        );
        return fieldsSectionWise;
    }, [templateDetails.fieldSettings]);

    // formula are getting updated here
    const fieldsToSectionIndex: { [field: string]: number } = useMemo(() => {
        const out: { [field: string]: number } = {
            // 'start date': 0,
        };

        // projectDetails.custom_sections.forEach((section, sectionIndex) => {
        //     section.custom_fields.forEach((field, fieldIndex) => {
        //         out[field.name.toLowerCase()] = sectionIndex;
        //     });
        // });

        let sectionIndex = 0;
        for (let section of projectDetails.custom_sections) {
            for (let field of section.custom_fields) {
                out[field.name.toLowerCase()] = sectionIndex;
            }

            sectionIndex += 1;
        }

        return out;
    }, [projectDetails.custom_sections]);

    const [
        projectSubmissionTimeHistoryList,
        setProjectSubmissionTimeHistoryList,
    ] = useState<string[]>([]);

    useEffect(() => {
        if (projectDetailsResponse) {
            getProjectSubmissionDateTimeHistoryList(
                projectDetailsResponse.project_id
            )
                .then((response: string[]) => {
                    setProjectSubmissionTimeHistoryList(response);
                })
                .catch((err) => {
                    console.error(err);
                    setProjectSubmissionTimeHistoryList([]);
                });
        }
    }, [projectDetailsResponse]);

    const getProjectDetailsWithNewFormulaValue: (
        project: IProjectPayload
    ) => IProjectPayload = useCallback(
        (project) => {
            const formulaFieldWithValues: {
                [section: string]: {
                    standard_field: {
                        [key in ProjectTemplateStandardFieldSettingObjOptions]: string;
                    };
                    custom_field: { [key: string]: string };
                };
            } = {};

            for (let section of Object.keys(fieldThatHaveFormula)) {
                const sectionDic = fieldThatHaveFormula[section];

                for (let standField of sectionDic.standardFields) {
                    if (formulaFieldWithValues[section])
                        formulaFieldWithValues[section].standard_field[
                            standField
                        ] = '';
                    else
                        formulaFieldWithValues[section] = {
                            custom_field: {},
                            standard_field: {
                                ASSIGNED_TO: '',
                                ATTACHMENTS: '',
                                DEADLINE_DATE: '',
                                DESCRIPTION: '',
                                END_DATE: '',
                                INTERNAL_NOTES: '',
                                PROJECT_CODE: '',
                                PROJECT_MANAGER: '',
                                PROJECT_NAME: '',
                                START_DATE: '',
                                STATUS: '',
                                [standField as ProjectTemplateStandardFieldSettingObjOptions]:
                                    '',
                            },
                        };
                }

                for (let customField of sectionDic.customFields) {
                    const tokenNode = getTokenNodes(
                        templateDetails.fieldSettings[section].customFields[
                            customField
                        ].formula?.formula_string ?? ''
                    );

                    const val = evaluateTokenNodes(
                        tokenNode,
                        (referenceField) => {
                            if (typeof referenceField === 'string') {
                                const sectionIndex =
                                    fieldsToSectionIndex[
                                        referenceField.toLowerCase()
                                    ];

                                if (sectionIndex >= 0) {
                                    return (
                                        (
                                            project.custom_sections[
                                                sectionIndex
                                            ].custom_fields.find(
                                                (f) =>
                                                    f.name
                                                        .trim()
                                                        .toLowerCase() ===
                                                    referenceField.toLowerCase()
                                            ) as any
                                        )?.datetime_value ||
                                        (
                                            project.custom_sections[
                                                sectionIndex
                                            ].custom_fields.find(
                                                (f) =>
                                                    f.name
                                                        .trim()
                                                        .toLowerCase() ===
                                                    referenceField.toLowerCase()
                                            ) as any
                                        )?.date_value ||
                                        (
                                            project.custom_sections[
                                                sectionIndex
                                            ].custom_fields.find(
                                                (f) =>
                                                    f.name
                                                        .trim()
                                                        .toLowerCase() ===
                                                    referenceField.toLowerCase()
                                            ) as any
                                        )?.text_value ||
                                        (
                                            project.custom_sections[
                                                sectionIndex
                                            ].custom_fields.find(
                                                (f) =>
                                                    f.name
                                                        .trim()
                                                        .toLowerCase() ===
                                                    referenceField.toLowerCase()
                                            ) as any
                                        )?.multi_choice_value ||
                                        (
                                            project.custom_sections[
                                                sectionIndex
                                            ].custom_fields.find(
                                                (f) =>
                                                    f.name
                                                        .trim()
                                                        .toLowerCase() ===
                                                    referenceField.toLowerCase()
                                            ) as any
                                        )?.integer_value ||
                                        (
                                            project.custom_sections[
                                                sectionIndex
                                            ].custom_fields.find(
                                                (f) =>
                                                    f.name
                                                        .trim()
                                                        .toLowerCase() ===
                                                    referenceField.toLowerCase()
                                            ) as any
                                        )?.decimal_value
                                    );
                                } else if (referenceField === 'start date') {
                                    return (
                                        project.validity_from?.split('T')[0] ??
                                        ''
                                    );
                                }

                                return '';
                            } else {
                                // assuming its an array
                                // first index is fields and second is section name
                                try {
                                    let data = project.custom_sections.find(
                                        (s) =>
                                            s.name.toLowerCase() ===
                                            referenceField[1].toLowerCase()
                                    );

                                    switch (referenceField[0]) {
                                        case 'last submitted datetime':
                                            return (
                                                convertUTCtoLocal(
                                                    projectSubmissionTimeHistoryList[0],
                                                    'ISO'
                                                ) ?? ''
                                            );

                                        case 'first submitted datetime':
                                            return (
                                                convertUTCtoLocal(
                                                    projectSubmissionTimeHistoryList[
                                                        projectSubmissionTimeHistoryList.length -
                                                            1
                                                    ],
                                                    'ISO'
                                                ) ?? ''
                                            );
                                        case 'start date':
                                            return (
                                                convertUTCtoLocal(
                                                    data?.start_time ?? '',
                                                    'ISO'
                                                ) ?? ''
                                            );
                                        case 'last modified datetime':
                                            return (
                                                convertUTCtoLocal(
                                                    data?.last_modified_time ??
                                                        '',
                                                    'ISO'
                                                ) ?? ''
                                            );
                                        default:
                                            return '';
                                    }
                                } catch (e) {
                                    console.error(e);
                                    return '';
                                }
                            }
                        },
                        customField
                    );

                    let customFieldValue = val;

                    const sectionIndex =
                        fieldsToSectionIndex[customField.toLowerCase()];
                    const fieldCustomFieldindex = project.custom_sections[
                        sectionIndex
                    ]?.custom_fields.findIndex(
                        (f) =>
                            f.name.toLowerCase().trim() ===
                            customField.toLowerCase()
                    );

                    if (
                        typeof customFieldValue === 'string' &&
                        customFieldValue.includes('T') &&
                        customFieldValue.includes(':')
                    ) {
                        customFieldValue = Date.parse(customFieldValue) as any;
                    }

                    if (
                        fieldCustomFieldindex > -1 &&
                        !isNaN(customFieldValue as any)
                    ) {
                        const fieldCustomField = cloneDeep(
                            project.custom_sections[sectionIndex]
                                ?.custom_fields[fieldCustomFieldindex]
                        );
                        if ('datetime_value' in fieldCustomField) {
                            fieldCustomField.datetime_value = moment(
                                new Date(+customFieldValue)
                            )
                                .toISOString()
                                .replace('.000Z', '')
                                .slice(0, -3);
                        }
                        if ('date_value' in fieldCustomField) {
                            fieldCustomField.date_value = moment(
                                new Date(+customFieldValue)
                            )
                                .toISOString()
                                .split('T')[0];
                        }
                        project.custom_sections[sectionIndex].custom_fields[
                            fieldCustomFieldindex
                        ] = fieldCustomField;
                    }

                    if (formulaFieldWithValues[section])
                        formulaFieldWithValues[section].custom_field[
                            customField
                        ] = customFieldValue;
                    else
                        formulaFieldWithValues[section] = {
                            standard_field: {
                                ASSIGNED_TO: '',
                                ATTACHMENTS: '',
                                DEADLINE_DATE: '',
                                DESCRIPTION: '',
                                END_DATE: '',
                                INTERNAL_NOTES: '',
                                PROJECT_CODE: '',
                                PROJECT_MANAGER: '',
                                PROJECT_NAME: '',
                                START_DATE: '',
                                STATUS: '',
                            },
                            custom_field: {
                                [customField]: customFieldValue,
                            },
                        };
                }
            }

            return project;
        },
        [
            fieldThatHaveFormula,
            fieldsToSectionIndex,
            projectSubmissionTimeHistoryList,
            templateDetails.fieldSettings,
        ]
    );

    const { data: defaultCurrencyId } = useGetDefaultCurrencyIdQuery(
        {
            entityId: projectDetailsResponse?.buyer_entity ?? '',
        },
        {
            skip: !Boolean(projectDetailsResponse?.buyer_entity),
        }
    );

    useEffect(() => {
        if (projectDetailsResponse) {
            const projectDetailsFromResponse =
                convertProjectDetailsResponseToProjectDetailsPayload(
                    projectDetailsResponse
                );

            if (templateDetails) {
                const customSections = convertTemplateDetailsToCFPayload(
                    templateDetails,
                    projectDetailsFromResponse,
                    currentUserId ?? '',
                    isCurrentUserCreator,
                    doesCurrentUserHaveProjectAssignPermissionInAdmin,
                    defaultCurrencyId ?? '',
                    sectionIndex
                );

                if (
                    isUserProjectManager &&
                    projectDetailsFromResponse.project_manager_ids?.length ===
                        0 &&
                    currentUserId
                ) {
                    projectDetailsFromResponse.project_manager_ids = [
                        currentUserId,
                    ];
                } else if (
                    !isUserProjectManager &&
                    currentUserId &&
                    projectDetailsFromResponse.project_manager_ids?.includes(
                        currentUserId
                    )
                ) {
                    projectDetailsFromResponse.project_manager_ids =
                        projectDetailsFromResponse.project_manager_ids.filter(
                            (id) => id !== currentUserId
                        );
                }
                projectDetailsFromResponse.custom_sections =
                    cloneDeep(customSections);

                const sectionModes = udpateProjectSectionMode(
                    projectDetailsFromResponse,
                    Object.keys(templateDetails.sections),
                    currentUserId ?? '',
                    templateDetails,
                    isUserProjectManager,
                    doesCurrentUserHaveProjectAssignPermissionInAdmin
                );

                setProjectSectionMode(sectionModes);

                let projectSectionStatus: IProjectSectionStatus = {};

                projectDetailsFromResponse.custom_sections.forEach(
                    (section) => {
                        const sectionName = section.name;

                        const sectionStatus =
                            section.status as ProjectStatusEnum;

                        projectSectionStatus[sectionName] = sectionStatus;
                    }
                );

                setProjectInformation({
                    createdBy: projectDetailsResponse.created_by_user.name,
                    createdOn: getFWDateTime(
                        projectDetailsResponse.created_datetime
                    ),
                    enterpriseName: '',
                    buyerEntityName: projectDetailsResponse.buyer_entity_name,
                    modifiedBy: projectDetailsResponse.modified_by_user.name,
                    modifiedOn: getFWDateTime(
                        projectDetailsResponse.modified_datetime
                    ),
                    status: projectDetailsResponse.project_status,
                    projectId: projectDetailsResponse.project_id,
                    projectName: projectDetailsResponse.project_name,
                    sectionStatus: projectSectionStatus,
                });
            }
            setProjectDetails(
                getProjectDetailsWithNewFormulaValue(projectDetailsFromResponse)
            );
            updateHookState(HookStateValue.READY);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentUserId,
        doesCurrentUserHaveProjectAssignPermissionInAdmin,
        // getProjectDetailsWithNewFormulaValue,
        isCurrentUserCreator,
        isFetching,
        isLoading,
        isUserProjectManager,
        projectDetails.project_code,
        projectDetailsResponse,
        templateDetails,
        updateHookState,
    ]);

    useEffect(() => {
        if (hookState.state === HookStateValue.READY) {
            setProjectDetails((prev) =>
                getProjectDetailsWithNewFormulaValue(prev)
            );
        }
    }, [getProjectDetailsWithNewFormulaValue, hookState.state]);

    useEffect(() => {
        if (projectDetailsResponse) {
            const entityId = projectDetailsResponse.buyer_entity;

            const checkAssignUserPermission =
                authData.modulePermissions.CUSTOMIZE?.PROJECT?.PROJECT_ASSIGN_USERS?.has(
                    entityId
                ) ?? false;

            if (checkAssignUserPermission) {
                setCurrentUserPermission(userPermissionEnum.ASSIGN_USER);
            } else {
                const checkProjectEditPermission =
                    authData.modulePermissions.CUSTOMIZE?.PROJECT?.PROJECT_EDIT?.has(
                        entityId
                    ) ?? false;

                if (checkProjectEditPermission) {
                    setCurrentUserPermission(userPermissionEnum.EDIT);
                } else {
                    setCurrentUserPermission(userPermissionEnum.VIEW);
                }
            }
        }
    }, [authData.modulePermissions.CUSTOMIZE?.PROJECT, projectDetailsResponse]);

    useEffect(() => {
        // change it back to view
        if (
            currentUserPermission === userPermissionEnum.VIEW &&
            Boolean(projectId)
        ) {
            history.push(`/custom/cost-tracking/projects/${projectId}/draft`);
        }
    }, [currentUserPermission, history, projectId]);

    const [userPermissionList, setUserOptions] = useState<
        IUserPermissionFEStructure[]
    >([]);

    const { data: userPermissionListResponse, refetch: refetchUserList } =
        useGetAssignUserListQuery(
            {
                permissionList: [
                    ProjectPermissions.PROJECT_EDIT,
                    ProjectPermissions.PROJECT_ASSIGN_USERS,
                    ProjectPermissions.PROJECT_ASSIGN_MANAGERS,
                ],
                entityid: projectDetails.buyer_entity_id,
                allUsers: true,
            },
            {
                skip: !Boolean(projectDetails.buyer_entity_id),
            }
        );

    useEffect(() => {
        if (userPermissionListResponse !== undefined) {
            let userPermissionListFiltered: IUserPermissionFEStructure[] = [];

            userPermissionListResponse.forEach((user) => {
                const currUserIndexInList =
                    userPermissionListFiltered.findIndex(
                        (userInList) => userInList.user_id === user.user_id
                    );

                if (currUserIndexInList === -1) {
                    userPermissionListFiltered.push({
                        name: user.name,
                        user_id: user.user_id,
                        permissions: [user.action_api_group],
                    });
                } else {
                    userPermissionListFiltered[
                        currUserIndexInList
                    ].permissions = Array.from(
                        new Set([
                            ...userPermissionListFiltered[currUserIndexInList]
                                .permissions,
                            user.action_api_group,
                        ])
                    ).sort(
                        (a: string, b: string) =>
                            ProjectPermissionsPriorityList[
                                b as ProjectPermissions
                            ] -
                            ProjectPermissionsPriorityList[
                                a as ProjectPermissions
                            ]
                    );
                }
            });

            setUserOptions(userPermissionListFiltered);
        }
    }, [userPermissionListResponse]);

    // const [fetchProjectCount] = useGetProjectTabCountMutation({
    //     fixedCacheKey: 'projectTabCount',
    // });

    useEffect(() => {
        try {
            refetchUserList();
        } catch (e) {}
    }, [
        refetchUserList,
        projectDetails.template_id,
        projectDetails.buyer_entity_id,
    ]);

    // useEffect(() => {
    //     refreshData();
    // }, [refreshData]);

    useEffect(() => {
        if (!isLoading && projectDetailsResponse) {
            setProjectInformation((prev) => ({
                ...prev,
                createdOn: getFWDate(projectDetailsResponse.created_datetime),
                modifiedOn: getFWDate(projectDetailsResponse.modified_datetime),
                status: projectDetailsResponse.project_status,
            }));
        }
    }, [isLoading, projectDetailsResponse]);

    useEffect(() => {
        const templateId = location?.state?.templateId;
        const entityId = location?.state?.entityId;

        if (templateId && entityId) {
            setProjectDetails((prev) => ({
                ...prev,
                buyer_entity_id: entityId,
                template_id: templateId,
            }));
        } else {
            setSelectEntityandTemplatePopup(true);
        }
    }, [location?.state]);

    const updatePayloadWithTemplateInformation = useCallback(() => {
        updateHookState(HookStateValue.LOADING);
        setProjectDetails((prev) => {
            let newProjectDetails = cloneDeep(prev);

            if (templateDetails) {
                const customSections = convertTemplateDetailsToCFPayload(
                    templateDetails,
                    newProjectDetails,
                    currentUserId ?? '',
                    isCurrentUserCreator,
                    doesCurrentUserHaveProjectAssignPermissionInAdmin,
                    defaultCurrencyId ?? '',
                    sectionIndex
                );

                newProjectDetails.custom_sections = cloneDeep(customSections);
            }

            return newProjectDetails;
        });
        updateHookState(HookStateValue.READY);
    }, [
        currentUserId,
        defaultCurrencyId,
        doesCurrentUserHaveProjectAssignPermissionInAdmin,
        isCurrentUserCreator,
        sectionIndex,
        templateDetails,
        updateHookState,
    ]);

    useEffect(() => {
        if (!Boolean(projectId)) {
            updatePayloadWithTemplateInformation();
        }
    }, [projectId, updatePayloadWithTemplateInformation]);

    const updateProjectPost = useCallback(() => {
        let projectToPost = trimProjectPayload({ ...projectDetails });

        if (
            projectToPost.validity_from !== null &&
            !projectToPost.validity_from.includes('T')
        )
            projectToPost.validity_from =
                projectToPost.validity_from + 'T00:00:00.000Z';

        if (
            projectToPost.validity_to !== null &&
            !projectToPost.validity_to.includes('T')
        )
            projectToPost.validity_to =
                projectToPost.validity_to + 'T00:00:00.000Z';

        projectToPost = cleanUpProjectCustomfields(projectToPost);

        projectToPost.attachment_ids =
            getProjectAttachmentIdsFromCF(projectToPost);

        if (projectId)
            updateProjectApi(projectId, projectToPost)
                .then((res) => {
                    // history.push('/custom/cost-tracking/projects/');
                    toast.success('Project Updated Successfully');
                    refetchData();
                })
                .catch((err) => {
                    toast.error('Project Updated Failed');
                });
    }, [projectDetails, projectId, refetchData]);

    const saveProjectDetailsDebounced = useMemo(
        () =>
            debounce(
                async (_details: IProjectPayload, refetch: boolean = false) => {
                    let projectToPost = cloneDeep(_details);

                    if (
                        projectToPost.validity_from !== '' &&
                        projectToPost.validity_from !== null &&
                        !projectToPost.validity_from.includes('T')
                    )
                        projectToPost.validity_from =
                            projectToPost.validity_from + 'T00:00:00.000Z';

                    if (
                        projectToPost.validity_to !== '' &&
                        projectToPost.validity_to !== null &&
                        !projectToPost.validity_to.includes('T')
                    )
                        projectToPost.validity_to =
                            projectToPost.validity_to + 'T00:00:00.000Z';
                    projectToPost = cleanUpProjectCustomfields(projectToPost);
                    projectToPost.attachment_ids =
                        getProjectAttachmentIdsFromCF(projectToPost);

                    if (
                        projectToPost.project_status === ProjectStatusEnum.DRAFT
                    ) {
                        projectToPost.status = ProjectStatusEnum.ACTIVE;
                    }
                    setLoadingState((prev) => ({ ...prev, loading: true }));

                    try {
                        let newProjectDetails: IProjectDetailsResponse =
                            await updateProjectApi(projectId, projectToPost);

                        const newProjectPaylaod =
                            convertProjectDetailsResponseToProjectDetailsPayload(
                                newProjectDetails
                            );

                        setProjectInformation((prev) => ({
                            ...prev,
                            modifiedOn: getFWDateTime(
                                newProjectDetails.modified_datetime
                            ),
                            modifiedBy: newProjectDetails.modified_by_user.name,
                            status: newProjectDetails.project_status,
                        }));

                        // setProjectDetails(newProjectPaylaod);

                        setProjectDetails((prev) => {
                            let newPrev = cloneDeep(prev);

                            newPrev.custom_sections.forEach((customSection) => {
                                const customSectionInPayload =
                                    newProjectPaylaod.custom_sections.find(
                                        (section) =>
                                            section.name === customSection.name
                                    );

                                if (customSectionInPayload) {
                                    customSection.status =
                                        customSectionInPayload.status;

                                    customSection.custom_section_id =
                                        customSectionInPayload.custom_section_id;

                                    customSection.custom_fields.forEach(
                                        (customField) => {
                                            const customFieldInPayload =
                                                customSectionInPayload.custom_fields.find(
                                                    (field) =>
                                                        field.name ===
                                                        customField.name
                                                );
                                            if (customFieldInPayload) {
                                                customField.custom_field_id =
                                                    customFieldInPayload.custom_field_id;
                                            }
                                        }
                                    );
                                }
                            });

                            return newPrev;
                        });

                        if (refetch) {
                            try {
                                refetchData();
                            } catch (e) {}
                        }

                        setLoadingState(() => ({
                            saved: true,
                            loading: false,
                            error: false,
                            error_msg: '',
                        }));
                    } catch (err) {
                        setLoadingState((prev) => ({
                            saved: false,
                            loading: false,
                            error: true,
                            error_msg: "Couldn't save data",
                        }));
                    }
                    setLoadingState((prev) => ({ ...prev, loading: false }));
                },
                2500
            ),
        [projectId, refetchData]
    );

    const MemoizedSaveProjectDetails = useCallback(
        async (
            newProjectDetails: IProjectPayload,
            refetch: boolean = false
        ) => {
            setLoadingState((prev) => ({ ...prev, saved: false }));
            await saveProjectDetailsDebounced(newProjectDetails, refetch);
        },
        [saveProjectDetailsDebounced]
    );

    const handleSectionUpdate = useCallback(
        async (sectionName: string, newStatus: ProjectStatusEnum) => {
            try {
                let newProjectPayload = cloneDeep(projectDetails);
                const sectionIndex =
                    newProjectPayload.custom_sections.findIndex(
                        (section) => section.name === sectionName
                    );

                if (sectionIndex !== -1) {
                    const currentSectionMode = cloneDeep(
                        newProjectPayload.custom_sections[sectionIndex].status
                    );
                    newProjectPayload.custom_sections[sectionIndex].status =
                        newStatus;

                    // const sectionModes = udpateProjectSectionMode(
                    //     newProjectPayload,
                    //     Object.keys(templateDetails.sections),
                    //     currentUserId ?? '',
                    //     templateDetails,
                    //     isUserProjectManager,
                    //     doesCurrentUserHaveProjectAssignPermissionInAdmin
                    // );
                    //

                    // setProjectSectionMode(sectionModes);

                    if (
                        currentSectionMode !== newStatus &&
                        newProjectPayload.custom_sections[sectionIndex]
                            .custom_section_id
                    ) {
                        await updateSectionStatus(
                            projectId,
                            newProjectPayload.custom_sections[sectionIndex]
                                .custom_section_id ?? '',
                            newStatus
                        );
                    }
                }
            } catch (err) {
                throw new Error('Failed to update the section status');
            }
        },
        [projectDetails, projectId]
    );

    const handleSectionSubmit = useCallback(
        async (sectionName: string) => {
            try {
                let newProjectPayload = cloneDeep(projectDetails);

                const sectionIndex =
                    newProjectPayload.custom_sections.findIndex(
                        (section) => section.name === sectionName
                    );

                if (sectionIndex !== -1) {
                    newProjectPayload.custom_sections[sectionIndex].status =
                        'SUBMITTED';

                    const sectionModes = udpateProjectSectionMode(
                        newProjectPayload,
                        Object.keys(templateDetails.sections),
                        currentUserId ?? '',
                        templateDetails,
                        isUserProjectManager,
                        doesCurrentUserHaveProjectAssignPermissionInAdmin
                    );

                    setProjectSectionMode(sectionModes);

                    const nextSectionExists =
                        newProjectPayload.custom_sections?.length > 1 &&
                        newProjectPayload.custom_sections[sectionIndex + 1] !==
                            undefined;

                    if (nextSectionExists) {
                        const nextSectionName =
                            newProjectPayload.custom_sections[sectionIndex + 1]
                                .name;

                        const nextSectionPermission =
                            sectionModes[nextSectionName];

                        updateNextSectionRedirection(
                            nextSectionPermission === 'EDIT'
                        );
                    }

                    await updateSectionStatus(
                        projectId,
                        newProjectPayload.custom_sections[sectionIndex]
                            .custom_section_id ?? '',
                        ProjectStatusEnum.SUBMITTED
                    );
                    await MemoizedSaveProjectDetails(newProjectPayload, true);
                }
            } catch (err) {
                throw new Error('Failed to submit section');
            }
        },
        [
            projectDetails,
            templateDetails,
            currentUserId,
            isUserProjectManager,
            doesCurrentUserHaveProjectAssignPermissionInAdmin,
            projectId,
            MemoizedSaveProjectDetails,
            updateNextSectionRedirection,
        ]
    );

    const handleProjectSubmit = useCallback(async () => {
        let projectToPost = trimProjectPayload({ ...projectDetails });

        if (
            projectToPost.validity_from !== null &&
            !projectToPost.validity_from.includes('T')
        )
            projectToPost.validity_from =
                projectToPost.validity_from + 'T00:00:00.000Z';

        if (
            projectToPost.validity_to !== null &&
            !projectToPost.validity_to.includes('T')
        )
            projectToPost.validity_to =
                projectToPost.validity_to + 'T00:00:00.000Z';

        projectToPost = cleanUpProjectCustomfields(projectToPost);

        projectToPost.project_status = ProjectStatusEnum.SUBMITTED;

        if (projectId) {
            try {
                let newProjectDetails: IProjectDetailsResponse =
                    await updateProjectApi(projectId, projectToPost);

                const newProjectPaylaod =
                    convertProjectDetailsResponseToProjectDetailsPayload(
                        newProjectDetails
                    );

                setProjectDetails(
                    getProjectDetailsWithNewFormulaValue(newProjectPaylaod)
                );
                toast.success('Project successfully submitted.');
                history.push('/custom/cost-tracking/projects/');
                setLoadingState(() => ({
                    saved: true,
                    loading: false,
                    error: false,
                    error_msg: '',
                }));
            } catch (err) {
                setLoadingState((prev) => ({
                    saved: false,
                    loading: false,
                    error: true,
                    error_msg: "Couldn't save data",
                }));
            }
        }
    }, [
        getProjectDetailsWithNewFormulaValue,
        history,
        projectDetails,
        projectId,
    ]);

    const handleProjectSectionDiscardChanges = useCallback(
        async (sectionId: string) => {
            try {
                const restoredProjectSectionVersion =
                    await restoreProjectVersion(projectId, sectionId);

                let newProjectDetails = cloneDeep(projectDetails);

                let currentCustomSectionIndex =
                    newProjectDetails.custom_sections.findIndex(
                        (section) => section.custom_section_id === sectionId
                    );

                if (currentCustomSectionIndex !== -1) {
                    newProjectDetails.custom_sections[
                        currentCustomSectionIndex
                    ] = getEditableValuesForCustomFields(
                        restoredProjectSectionVersion
                    );

                    newProjectDetails.custom_sections[
                        currentCustomSectionIndex
                    ].status = ProjectStatusEnum.SUBMITTED;
                }

                const customSections = convertTemplateDetailsToCFPayload(
                    templateDetails,
                    newProjectDetails,
                    currentUserId ?? '',
                    isCurrentUserCreator,
                    doesCurrentUserHaveProjectAssignPermissionInAdmin,
                    defaultCurrencyId ?? '',
                    sectionIndex
                );

                newProjectDetails.custom_sections = customSections;

                const sectionModes = udpateProjectSectionMode(
                    newProjectDetails,
                    Object.keys(templateDetails.sections),
                    currentUserId ?? '',
                    templateDetails,
                    isUserProjectManager,
                    doesCurrentUserHaveProjectAssignPermissionInAdmin
                );

                setProjectSectionMode(sectionModes);

                setProjectDetails(
                    getProjectDetailsWithNewFormulaValue(newProjectDetails)
                );
                try {
                    refetchData();
                } catch (e) {}
            } catch (err) {}
        },
        [
            currentUserId,
            defaultCurrencyId,
            doesCurrentUserHaveProjectAssignPermissionInAdmin,
            getProjectDetailsWithNewFormulaValue,
            isCurrentUserCreator,
            isUserProjectManager,
            projectDetails,
            projectId,
            refetchData,
            sectionIndex,
            templateDetails,
        ]
    );

    const updateProject = useCallback(
        (
            newProjectValue: IProjectPayload,
            key: keyof IProjectPayload | 'assigned_users'
        ) => {
            let validationField: CreateProjectErrorFieldEnum | undefined;

            if (key in CreateProjectErrorFieldEnum) {
                validationField = key as CreateProjectErrorFieldEnum;
            }

            MemoizedSaveProjectDetails(newProjectValue, false);

            if (validationField) {
                setError(
                    validateProjectDetails({
                        errors: error,
                        field: validationField,
                        newProjectDetails: newProjectValue,
                        templateDetails: templateDetails,
                    })
                );
            }

            setProjectDetails(
                getProjectDetailsWithNewFormulaValue(newProjectValue)
            );
        },
        [
            MemoizedSaveProjectDetails,
            error,
            getProjectDetailsWithNewFormulaValue,
            templateDetails,
        ]
    );

    const validateAllFields = useCallback(() => {
        setError((prev) => {
            return validateProjectDetails({
                newProjectDetails: projectDetails,
                errors: prev,
                field: 'ALL',
                templateDetails,
            });
        });
    }, [projectDetails, templateDetails]);

    useEffect(() => {
        validateAllFields();
    }, [validateAllFields]);

    const updateSectionModes = useCallback(
        (sectionName: string, mode: ProjectSectionMode) => {
            setProjectSectionMode((prev) => {
                let newPrev = cloneDeep(prev);

                newPrev[sectionName] = mode;

                return newPrev;
            });
        },
        []
    );

    return {
        projectDetails,
        templateDetails,
        selectEntityAndTemplatePopup,
        loadingState,
        hookState,
        error,
        allCurrencies,
        userPermissionList,
        currentUserPermission,
        projectInformation,
        redirectUserToNextSection,
        updateProject,
        handleEntityTemplatePopupClose,
        updateNextSectionRedirection,
        updateProjectPost,
        handleSectionSubmit,
        updateSectionModes,
        buyerEntities,
        projectSectionMode,
        handleSectionUpdate,
        handleProjectSubmit,
        handleProjectSectionDiscardChanges,
        refetchData,
        sectionIndex,
        setSectionIndex,
        projectCreationSteps,
        setProjectCreationSteps,
    };
};

export default useProjectHook;
