import { Box, IconButton } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FWMenu, FWMenuItem } from '../../Common/FWCustomMenu';
import FWPopover from '../../Common/FWPopover';
import { FWPopup } from '../../Common/FWPopup';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import { getFWDateTime } from '../../Common/Utils/DateUtils';
import CloneCostingSheetPopup from '../Components/CloneCostingSheetPopup';
import {
    ICostingDashboard,
    ICreateCostingSheetPayload,
} from '../Interfaces/CostingInterfaces.interface.';
import { deleteCostingSheetApi } from '../Services/CostingApisCalls.service';
import { getCostingDashboardStatusLabelAndColor } from './CostingDashboardHelper';

export const costingColDefs = (showOptions: boolean): ColDef[] => [
    {
        headerName: 'Sheet ID',
        field: 'custom_costing_sheet_id',
        sortable: true,
        filter: true,
        resizable: true,
        width: 130,
        flex: 10,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
    },
    {
        headerName: 'Sheet Name',
        field: 'name',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 155,
        flex: 35,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
    },
    {
        headerName: 'Status',
        field: 'status',
        sortable: false,
        filter: true,
        resizable: true,
        minWidth: 155,
        flex: 10,
        cellRenderer: (params: any) => {
            let { color, label } = getCostingDashboardStatusLabelAndColor(
                params.value,
                params?.data?.version
            );
            if (label === 'Customer_rejected') {
                label = 'Rejected';
                color = 'red';
            } else if (label === 'Customer_accepted') {
                label = 'Accepted';
                color = 'success.main';
            }
            return <FWTypography color={color}>{label}</FWTypography>;
        },
    },
    {
        headerName: 'Entity',
        field: 'seller_entity_name',
        sortable: true,
        filter: true,
        resizable: true,
        flex: 10,
        // width: 150,
        cellRenderer: (params: { data: ICostingDashboard }) => (
            <FWTypography>
                {params.data.seller_entity_details.entity_name}
            </FWTypography>
        ),
    },
    {
        headerName: 'Customer name',
        field: 'customer_entity_name',
        sortable: true,
        filter: true,
        resizable: true,
        flex: 10,
        // width: 150,
        cellRenderer: (params: { data: ICostingDashboard }) => (
            <FWTypography>
                {Boolean(params.data?.customer_entity_details?.entity_name)
                    ? params.data.customer_entity_details?.entity_name
                    : '-'}
            </FWTypography>
        ),
    },
    // {
    //     headerName: 'Items',
    //     field: 'costing_sheet_items_count',
    //     sortable: true,
    //     filter: true,
    //     resizable: true,
    //     flex: 10,
    //     // width: 150,
    //     cellRenderer: (params: { data: ICostingDashboard }) => (
    //         <ItemCount
    //             itemDetails={params.data.item_details}
    //             itemsCount={params.data.item_details?.length}
    //         />
    //     ),
    // },
    {
        headerName: 'View',
        field: 'view',
        sortable: true,
        filter: true,
        flex: 10,
        resizable: true,
        // width: 1100,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
    },
    {
        headerName: 'Total Cost',
        field: 'total',
        sortable: true,
        filter: true,
        resizable: true,
        flex: 10,
        // width: 250,
        cellRenderer: (params: any) => (
            <FWTypography>
                {FWNumberFormatter(
                    params.value.toFixed(2),
                    params.data.currency_details.currency_code_abbreviation
                )}
            </FWTypography>
        ),
    },
    {
        headerName: 'Last edited by',
        field: 'modified_by_user_name',
        sortable: true,
        filter: true,
        resizable: true,
        // width: 350,
        cellRenderer: (params: any) => (
            <FWTypography>{params.value}</FWTypography>
        ),
    },
    {
        headerName: 'Last edited on',
        field: 'modified_datetime',
        sortable: true,
        filter: true,
        resizable: true,
        // width: 150,
        cellRenderer: (params: any) => (
            <FWTypography color={'text.secondary'}>
                {getFWDateTime(params.value)}
            </FWTypography>
        ),
    },
    ...(showOptions
        ? [
              {
                  headerName: 'Options',
                  // field: 'action',
                  sortable: true,
                  filter: true,
                  resizable: true,
                  width: 110,
                  cellRenderer: (params: any) => (
                      <div style={{ margin: '0 auto' }}>
                          <ItemMenu
                              sheetId={params.data.costing_sheet_id}
                              context={params.context}
                              sheetName={params.data.name}
                          />
                      </div>
                  ),
              },
          ]
        : []),
];

export const ItemCount = ({
    itemDetails,
    itemsCount,
}: {
    itemDetails: {
        enterprise_item_details: {
            name: string;
        };
        enterprise_bom__bom_code: string | null;
    }[];
    itemsCount: number;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<any>) =>
        setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <Box
            ref={(ref: any) => {
                if (!ref) return;
                ref.onclick = (e: any) => {
                    e.stopImmediatePropagation();
                    // handleClick(e);
                };
            }}
            onMouseOver={(e) => itemDetails.length > 0 && handleClick(e)}
            onMouseOut={handleClose}
        >
            <FWTypography
                sx={{ cursor: 'pointer' }}
                // onClick={handleClick}
                color={itemDetails.length > 0 ? 'primary.main' : 'text.primary'}
            >
                {itemDetails.length}
            </FWTypography>
            <FWPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    pointerEvents: 'none',
                    maxHeight: '410px',
                }}
            >
                {
                    <>
                        {itemDetails.map((item, index: number) => (
                            <FWTypography key={index}>
                                {index + 1}.{' '}
                                {item.enterprise_bom__bom_code
                                    ? `${item?.enterprise_item_details?.name} | ${item.enterprise_bom__bom_code}`
                                    : item?.enterprise_item_details?.name}
                            </FWTypography>
                        ))}
                    </>
                }
            </FWPopover>
        </Box>
    );
};

const ItemMenu = ({
    sheetId,
    context,
    sheetName,
}: {
    sheetId: string;
    context: any;
    sheetName: string;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const [deleteConfirm, setDeleteConfirm] = useState(false);

    const {
        fetchCostingDashboardData,
        currentPage,
        searchText,
        sortFields,
        handleCloneSheet,
        // costingList
    } = context;

    const handleDelete = async () => {
        try {
            await deleteCostingSheetApi(sheetId);
            fetchCostingDashboardData(currentPage, searchText, sortFields);
            toast.warning('Costing sheet deleted.');
        } catch (error) {
            toast.error('Couldn’t delete costing sheet. Please try again.');
        }
        setDeleteConfirm(false);
    };

    return (
        <div
            ref={(ref: any) => {
                if (!ref) return;
                ref.onclick = (e: any) => {
                    e.stopImmediatePropagation();
                    handleClick(e);
                };
            }}
        >
            <IconButton
                id="table-item-menu-button"
                aria-controls={open ? 'item-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                color="primary"
            >
                <i className="bi bi-three-dots"></i>
            </IconButton>
            <FWMenu
                id="item-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'table-item-menu-button',
                }}
            >
                <CloneCostingSheetPopup
                    type="MENU_ITEM"
                    initialSheetName={sheetName}
                    onClick={() => {
                        handleClose();
                    }}
                    onClickConfirm={(newSheetName) => {
                        handleCloneSheet(sheetId, newSheetName);
                    }}
                    // costingList={costingList}
                />
                <FWMenuItem
                    onClick={() => {
                        setDeleteConfirm(true);
                        handleClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    Delete sheet
                </FWMenuItem>
            </FWMenu>

            <FWPopup
                open={deleteConfirm}
                msg="Are you sure you want to delete this sheet?"
                onClickConfirm={handleDelete}
                setOpen={setDeleteConfirm}
                title="Delete sheet"
                btnColor="error"
            />
        </div>
    );
};

export const COSTING_DEFAULT_LEAD_TIME = '2';
export const COSTING_DEFAULT_LEAD_TIME_PERIOD = 'WEEKS';
export const blankCostingSheetPayload: ICreateCostingSheetPayload = {
    additional_costs: [],
    currency_code_id: '',
    custom_fields: {
        section_list: [],
    },
    custom_fields_negotiate: {
        section_list: [],
    },
    customer_contact_ids: [],
    customer_entity_id: null,
    admin_conversions_selected: true,
    custom_currency_conversions: [],
    deadline_datetime: null,
    name: '',
    seller_entity_id: '',
    template_id: '',
    validity_datetime: null,
};
