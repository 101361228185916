import React from 'react';
import CostingItemAutocomplete from '../../Costing/Components/CostingItemAutocomplete';
import { useSellerProfileItemCatalogue } from '../Hooks/useSellerProfileItemCatalogue';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import LazyFallback from '../../Components/Shared/LazyFallBack';
import { Box } from '@mui/material';
import ItemCataloguePopup from '../Components/ItemCataloguePopup';
import VLNProfileItemCatalogItems from '../../VendorLinkedIn/Components/Profile/Item Catalogue/VLNProfileItemCatalogItems';
import { IVLNItemCatalogue } from '../../VendorLinkedIn/Interfaces/VendorLNInterfaces';
import { IBuyerItemList } from '../../Events/Interfaces/Buyer/RFQ.model';

export const VendorProfileIdContext = React.createContext({
    vendorProfileId: '',
    removeItemFromList: (item_catalogue_id: string) => {},
    updateItemCatalogueList: (item: IVLNItemCatalogue) => {},
    buyerItems: [] as IBuyerItemList[],
});

const SellerProfileItemCatalogue = ({
    entity_id,
    vendorProfileId,
}: {
    entity_id: string;
    vendorProfileId: string;
}) => {
    const {
        buyerItems,
        filteredBuyerItems,
        itemCatalogueList,
        hookState,
        onItemSelect,
        selectedItem,
        onAddItemToCatalogue,
        removeItemFromList,
        updateItemCatalogueList,
    } = useSellerProfileItemCatalogue(entity_id, vendorProfileId);
    return hookState.state !== HookStateValue.READY ? (
        <LazyFallback />
    ) : (
        <>
            <div className="flex flex--jcfe">
                <Box width={'45%'} marginBottom={2}>
                    <CostingItemAutocomplete
                        buyerItems={filteredBuyerItems}
                        onChanges={onItemSelect}
                        placeholder="Search for items to add to catalog"
                    />
                </Box>
            </div>
            {/* <FWDataGrid
                rows={itemCatalogueList}
                columns={ItemCatalogueColDef}
            /> */}
            <VendorProfileIdContext.Provider
                value={{
                    vendorProfileId,
                    removeItemFromList,
                    updateItemCatalogueList,
                    buyerItems,
                }}
            >
                <VLNProfileItemCatalogItems
                    itemsCatalogueList={itemCatalogueList}
                    canEdit
                />
            </VendorProfileIdContext.Provider>
            {!!selectedItem && (
                <ItemCataloguePopup
                    open={!!selectedItem}
                    initialData={{
                        imageUrl: '',
                        name: selectedItem?.enterprise_item.name || '',
                        price:
                            selectedItem?.enterprise_item
                                .seller_pricing_information?.price || '',
                        currencyCodeUuid:
                            selectedItem?.enterprise_item
                                .seller_pricing_information?.currency_code_id ||
                            '',
                        measurementUnitUuid: selectedItem?.enterprise_item
                            .seller_pricing_information
                            ? selectedItem?.enterprise_item.measurement_units
                                  ?.item_measurement_units?.[0]
                                  .measurement_unit_id || ''
                            : '',
                    }}
                    onClose={() => onItemSelect(null)}
                    onSave={onAddItemToCatalogue}
                    selectedItemData={
                        buyerItems.find(
                            (val) =>
                                val.enterprise_item.enterprise_item_id ===
                                selectedItem?.enterprise_item
                                    ?.enterprise_item_id
                        ) ?? null
                    }
                />
            )}
        </>
    );
};

export default SellerProfileItemCatalogue;
