import { Backdrop, CircularProgress } from '@mui/material';

export default function LoadingBackDrop() {
    return (
        <Backdrop
            style={{
                zIndex: 1000000,
                color: '#fff',
            }}
            open={true}
        >
            <CircularProgress style={{ color: 'black' }} />
        </Backdrop>
    );
}
