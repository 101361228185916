import { ReactElement } from 'react';
import { CurrencyDetails } from '../../Historical/Interfaces/IHistoricalAnalyticsDropdowns.model';
import { ICurrencyDetails } from '../../Models/Currency.model';
import { RequisitionItem } from '../Slices/RequisitionSlice';

export enum RequisitionStatusEnum {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    REQUISITION_APPROVAL_PENDING = 'REQUISITION_APPROVAL_PENDING',
    REWORK = 'REWORK',
    REJECTED = 'REJECTED',
    SUBMITTED = 'SUBMITTED',
    PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
    FULFILLED = 'FULFILLED',
    COMPLETED = 'COMPLETED',
    CLOSED = 'CLOSED',
    ONGOING = 'ONGOING',
}

export interface IRequisition {
    uuid: string;
    displayId: string;
    name: string;
    approved: boolean;
    currencyDetails: ICurrencyDetails | null;
    buyerEntityId: string;
    status: RequisitionStatusEnum;
    // TODO: Add type for customFields
    template_id: string;
    items: IRequisitionItem[];
}

export interface IRequisitionItem {
    // Main item details
    uuid: string;
    itemDetails: {
        enterpriseItemUuid: string;
        name: string;
        description: string;
        code: string;
        tags: string[];
        standardSpecs: {
            // Attributes from item directory
            name: string;
            values: string[];
        }[];
        customSpecs: {
            // Attributes added to the item on the fly in the requisition
            name: string;
            values: string[];
        }[];
    };
    skuUuidList: string[];

    // Pricing details
    currencyDetails: CurrencyDetails | null; //Taken from the requisition. Cannot change per item.
    measurementUnitDetails: {
        uuid: string;
        name: string;
        valueType: 'INTEGER' | 'DECIMAL';
    };
    baseRate: string;
    shippingRate: string;
    taxes: {
        name: string;
        percentage: string;
    }[];

    // Quantity details
    deliveryDetails: IRequisitionItemDeliveryCategory[];

    // Payment & Delivery Terms
    paymentTerms: {
        // TODO: Add later on (after new payment terms is implemented)
        // Prepayment percentage will be here too.
    };
    leadTime: {
        value: string;
        period: 'DAYS' | 'WEEKS' | 'MONTHS';
    };
    incoterms: {
        uuid: string;
        abbreviation: string;
    };
    shippingAddress: {
        uuid: string;
        name: string;
        fullAddress: string;
    };

    // Vendor Details
    vendors: IRequisitionItemVendor[];
    vendorNotes: string;
    vendorQuestions: IRequisitionVendorQuestion[];

    // Additional Details
    internalNotes: string;
}

export interface RequisitionDashboardOptions {
    data: RequisitionDetails;
    rowData: RequisitionDetails[] | null;
    context: {};
    test: boolean;
}

export interface IRequisitionItemVendor {
    entityUuid: string;
    entityName: string;
    // TODO: Add more as required (e.g. isPreferred, Tags, Contacts )
}

export interface IRequisitionVendorQuestion {
    // TODO: Add later on - UI unknown.
}
export interface IRequisitionItemDeliveryCategory {
    projectCode: string;
    costCenter: string;
    glCode: string;
    deliverySchedule: IRequisitionItemDelivery[];
}

export interface IRequisitionItemDelivery {
    quantity: string;
    deliveryDate: string;
}

export interface IRequistionDetailsResponse {
    data: RequisitionDetails[];
    count: { all: number; draft: number };
}

export interface RequisitionDetails {
    requisition_id: string;
    requisition_items: RequisitionItem[];
    item_count: number;
    buyer_entity_name: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    custom_requisition_id: string;
    requisition_name: string;
    source_type: string;
    notes: any;
    requisition_default_information: RequisitionDefaultInformation;
    status: RequisitionStatusEnum;
    tags: any;
    additional_details: AdditionalDetails;
    total: any;
    created_by_user: string;
    created_by_user_name: string;
    modified_by_user: string;
    deleted_by_user: any;
    buyer_enterprise: string;
    buyer_entity: string;
}

export interface RequisitionDefaultInformation {
    default_currency_id: string;
}

export interface AdditionalDetails {
    template_id: string;
}

// export interface RequisitionItem {
//     requisition_item_id: string;
//     additional_costs: any[];
//     taxes: any[];
//     discounts: any[];
//     created_datetime: string;
//     modified_datetime: string;
//     deleted_datetime: any;
//     shipping_address: ShippingAddress;
//     item_information: ItemInformation;
//     attribute_information: AttributeInformation;
//     quantity: number;
//     prepayment_percentage: any;
//     payment_type: any;
//     payment_terms: any;
//     deliverables_payment_terms: any;
//     procurement_information: ProcurementInformation;
//     pricing_information: PricingInformation;
//     delivery_information: any[];
//     custom_fields: CustomFields;
//     questions: Questions;
//     created_by_user: string;
//     modified_by_user: string;
//     deleted_by_user: any;
//     requisition: string;
//     enterprise_item: string;
//     measurement_unit: string;
//     incoterm: any;
//     seller_entities: any[];
//     seller_contacts: any[];
// }

export interface ShippingAddress {
    shipping_address_id: any;
    shipping_full_address: any;
}

export interface ItemInformation {
    item_id: string;
    item_code: string;
    item_name: string;
    custom_ids: any[];
    custom_item_name: any;
    item_description: string;
    item_additional_details: string;
}

export interface AttributeInformation {
    buyer_item_attributes: any[];
    custom_item_attributes: CustomItemAttribute[];
}

export interface CustomItemAttribute {
    attribute_name: string;
    attribute_value: string[];
    attribute_exclude: boolean;
}

export interface ProcurementInformation {
    lead_time: any;
    lead_time_period: any;
}

export interface PricingInformation {
    currency_name: string;
    desired_price: any;
    currency_symbol: string;
    currency_code_id: string;
    shipping_per_unit: any;
    additional_charges: AdditionalCharge[];
    currency_code_abbreviation: string;
}

export interface AdditionalCharge {
    charge_name: string;
    charge_value: number;
}

export interface CustomFields {
    section_items: any[];
}

export interface Questions {
    section_items: any[];
}

export type IRequisitionItemFieldValidation = {
    type: 'WARNING' | 'ERROR';
    fieldMessageText: string;
    popupMessageText: string;
    suppress: boolean;
    HelperComponent?: () => ReactElement;
};

export type IRequisitionItemValidation = {
    specifications?: IRequisitionItemFieldValidation;
    quantity?: IRequisitionItemFieldValidation;
    measurementUnit?: IRequisitionItemFieldValidation;
    price?: IRequisitionItemFieldValidation;
    shipping_address?: IRequisitionItemFieldValidation;
    additional_charges?: IRequisitionItemFieldValidation;
    lead_time?: IRequisitionItemFieldValidation;
    lead_time_period?: IRequisitionItemFieldValidation;
    excessive_lead_time?: IRequisitionItemFieldValidation;
    incoterm_id?: IRequisitionItemFieldValidation;
    incoterm?: IRequisitionItemFieldValidation;
    vendor_count?: IRequisitionItemFieldValidation;
    quantity_tolerance_percentage?: IRequisitionItemFieldValidation;
    requisition_number?: IRequisitionItemFieldValidation;
    requisition_approval?: IRequisitionItemFieldValidation;
    item_additional_information?: IRequisitionItemFieldValidation;
    customFields: {
        [sectionName: string]: {
            [customFieldName: string]: IRequisitionItemFieldValidation;
        };
    };
    deliveryScheduleOverall: IRequisitionItemFieldValidation[];
    deliveryScheduleRows: {
        quantity?: IRequisitionItemFieldValidation;
        date?: IRequisitionItemFieldValidation;
        customerEntityId?: IRequisitionItemFieldValidation;
        cost_centre_id?: IRequisitionItemFieldValidation;
        project_id?: IRequisitionItemFieldValidation;
        general_ledger_id?: IRequisitionItemFieldValidation;
    }[];
    prepaymentPercentage?: IRequisitionItemFieldValidation;
    paymentTerms_prepayment?: IRequisitionItemFieldValidation;
    paymentTerms_invoiceItem_terms?: IRequisitionItemFieldValidation;
    paymentTerms_invoiceItem_period?: IRequisitionItemFieldValidation;
    paymentTerms_invoiceItem_appliedFrom?: IRequisitionItemFieldValidation;
    paymentTerms_poItem_overallPercentage?: IRequisitionItemFieldValidation;
    paymentTerms_poItem_allocationRows: {
        rowAllocationPercentage?: IRequisitionItemFieldValidation;
        rowTerms?: IRequisitionItemFieldValidation;
        rowPeriod?: IRequisitionItemFieldValidation;
        rowDeliverableName?: IRequisitionItemFieldValidation;
        rowDate?: IRequisitionItemFieldValidation;
    }[];
    cheaperContractAvailable?: IRequisitionItemFieldValidation;
    noContractVendorSelected?: IRequisitionItemFieldValidation;
    taxes?: IRequisitionItemFieldValidation;
    additional_cost?: IRequisitionItemFieldValidation;
    discounts?: IRequisitionItemFieldValidation;
};

export type updateRequisitionItemDetailsActionType =
    | keyof RequisitionItem
    | 'currency'
    | 'item_additional_information'
    | 'lead_time_period'
    | 'lead_time'
    | 'quantity_tolerance_percentage'
    | 'price'
    | 'item_currency'
    | 'paymentTerms_prepayment'
    | 'paymentTerms_prepaymentPercentage'
    | 'paymentTerms_poItem_overallPercentage'
    | 'paymentTerms_invoiceItem_terms'
    | 'paymentTerms_invoiceItem_period'
    | 'paymentTerms_invoiceItem_appliedFrom'
    | 'paymentTerms_poItem_allocationRows'
    | 'deliveryScheduleOverall';
