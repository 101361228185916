import {
    // DialogContent,
    // DialogTitle,
    Drawer,
    IconButton,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import {
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { FWInput } from '../../Common/FWInput';
import { AuthContext } from '../../Contexts/AuthContext';
// import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { HelpPageActionsActions } from '../../Redux/Reducers/HelpInformationReducers';
import {
    HelpInternalPageName,
    HelpPageName,
    IHelpInformationState,
    getHelpInternalPageNameBasedOnUrl,
    updateHelpInformation,
} from '../../Redux/Slices/HelpInformationSlice';
import { useHelpInformation } from '../Hooks/useHelpInformation';
import { IUserViewPermissions } from '../Interfaces/HelpPermissionsInterface';
import { IHelpSBS } from '../Interfaces/HelpSBSInterface';
import FAQHelpTabPanel from './FAQHelpTabPanel';
import HelpGlossary from './HelpGlossary';
import HelpStepByStep from './HelpStepByStep';
import VideosHelpTabPanel from './VideosHelpTabPanel';

interface HelpInformationContainerProps {
    currentPage: keyof typeof HelpPageName;
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const HelpInformationContainer = ({
    currentPage,
}: HelpInformationContainerProps) => {
    const { pathname } = useLocation();
    const location = useLocation();
    const query = useQuery();
    const dispatch = useDispatch();

    const { helpData } = useHelpInformation();

    const contentRef = useRef<HTMLDivElement>();

    const {
        pageName,
        pageInternalName: stirePageInternalName,
        openHelp,
    }: IHelpInformationState = useSelector(
        ({
            HelpInformationStore,
        }: {
            HelpInformationStore: IHelpInformationState;
        }) => HelpInformationStore
    );

    const [tabValue, setTabValue] = useState('VIDEOS');
    const [toggleAccordion, setToggleAccordion] = useState<Set<string>>(
        new Set()
    );
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        !openHelp && setSearchText('');
        setToggleAccordion(new Set());
    }, [openHelp]);

    const helpInternalPageName: undefined | keyof typeof HelpInternalPageName =
        useMemo(() => {
            return getHelpInternalPageNameBasedOnUrl(location.pathname);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [location.pathname, query]);
    const toggleHelpPopup = () => {
        dispatch(
            updateHelpInformation({
                type: HelpPageActionsActions.TOGGLE_HELP,
            })
        );
        dispatch(
            updateHelpInformation({
                type: HelpPageActionsActions.UPDATE_NAME,
                value: HelpPageName[currentPage],
            })
        );

        dispatch(
            updateHelpInformation({
                type: HelpPageActionsActions.UPDATE_INTERNAL_NAME,
                value: helpInternalPageName
                    ? HelpInternalPageName[helpInternalPageName]
                    : null,
            })
        );
    };

    const {
        authData: { modulePermissions },
        checkPermission,
    } = useContext(AuthContext);

    const [userViewPermissions, setUserViewPermissions] =
        useState<IUserViewPermissions>({
            EVENT: false,
            GLOBAL: false,
            GOODS_RECEIPT: false,
            INVOICE: false,
            PAYMENT: false,
            PURCHASE_ORDER: false,
            QUALITY_CHECK: false,
            ADMIN: false,
            PRICING_AND_SUBSCRIPTION: false,
            QUOTE_CALCULATOR: false,
            TEMPLATES: false,
            ADDITIONAL_FIELDS: false,
            ITEM_ANALYTICS: false,
            CART: false,
            ITEM_TAG_LINKAGE: false,
            HISTORICAL_ANALYTICS: false,
            CUSTOM_ANALYTICS: false,
            PROJECT: false,
            SUPPLIER_RELATIONSHIP_MANAGEMENT_BUYER: false,
        });
    useEffect(() => {
        const pathNameArr = pathname.split('/');
        if (pathNameArr[1].toUpperCase() === 'BUYER') {
            setUserViewPermissions(() => {
                return {
                    EVENT: checkPermission('BUYER', 'EVENT', 'VIEW', null),
                    GLOBAL: false,
                    GOODS_RECEIPT: checkPermission(
                        'BUYER',
                        'GOODS_RECEIPT',
                        'VIEW',
                        null
                    ),
                    INVOICE: checkPermission('BUYER', 'INVOICE', 'VIEW', null),
                    PAYMENT: checkPermission('BUYER', 'PAYMENT', 'VIEW', null),
                    PURCHASE_ORDER: checkPermission(
                        'BUYER',
                        'PURCHASE_ORDER',
                        'VIEW',
                        null
                    ),
                    QUALITY_CHECK: checkPermission(
                        'BUYER',
                        'QUALITY_CHECK',
                        'VIEW',
                        null
                    ),
                    ADMIN: checkPermission('GLOBAL_ADMIN', null, null, null),
                    PRICING_AND_SUBSCRIPTION: true,
                    QUOTE_CALCULATOR: false,
                    TEMPLATES: checkPermission(
                        'GLOBAL_ADMIN',
                        null,
                        null,
                        null
                    ),
                    ADDITIONAL_FIELDS: checkPermission(
                        'GLOBAL_ADMIN',
                        null,
                        null,
                        null
                    ),
                    ITEM_ANALYTICS:
                        checkPermission('GLOBAL_ADMIN', null, null, null) ||
                        checkPermission('BUYER', null, null, null),
                    CART:
                        checkPermission('GLOBAL_ADMIN', null, null, null) ||
                        checkPermission('BUYER', null, null, null),
                    ITEM_TAG_LINKAGE:
                        checkPermission('GLOBAL_ADMIN', null, null, null) ||
                        checkPermission('BUYER', null, null, null),
                    HISTORICAL_ANALYTICS:
                        checkPermission('GLOBAL_ADMIN', null, null, null) ||
                        checkPermission('BUYER', null, null, null),
                    CUSTOM_ANALYTICS:
                        checkPermission('GLOBAL_ADMIN', null, null, null) ||
                        checkPermission('BUYER', null, null, null),
                    PROJECT:
                        checkPermission('GLOBAL_ADMIN', null, null, null) ||
                        checkPermission('BUYER', null, null, null),
                    SUPPLIER_RELATIONSHIP_MANAGEMENT_BUYER:
                        checkPermission('GLOBAL_ADMIN', null, null, null) ||
                        checkPermission('BUYER', null, null, null),
                };
            });
        } else {
            setUserViewPermissions(() => ({
                EVENT: true,
                GLOBAL: true,
                GOODS_RECEIPT: true,
                INVOICE: true,
                PAYMENT: true,
                PURCHASE_ORDER: true,
                QUALITY_CHECK: true,
                ADMIN: checkPermission('GLOBAL_ADMIN', null, null, null),
                PRICING_AND_SUBSCRIPTION: checkPermission(
                    'GLOBAL_ADMIN',
                    null,
                    null,
                    null
                ),
                QUOTE_CALCULATOR: checkPermission(
                    'SELLER',
                    'QUOTE_CALCULATOR',
                    'VIEW',
                    null
                ),
                TEMPLATES: checkPermission('GLOBAL_ADMIN', null, null, null),
                ADDITIONAL_FIELDS: checkPermission(
                    'GLOBAL_ADMIN',
                    null,
                    null,
                    null
                ),
                ITEM_ANALYTICS:
                    checkPermission('GLOBAL_ADMIN', null, null, null) ||
                    checkPermission('BUYER', null, null, null),
                CART:
                    checkPermission('GLOBAL_ADMIN', null, null, null) ||
                    checkPermission('BUYER', null, null, null),
                ITEM_TAG_LINKAGE:
                    checkPermission('GLOBAL_ADMIN', null, null, null) ||
                    checkPermission('BUYER', null, null, null),
                HISTORICAL_ANALYTICS:
                    checkPermission('GLOBAL_ADMIN', null, null, null) ||
                    checkPermission('BUYER', null, null, null),
                CUSTOM_ANALYTICS:
                    checkPermission('GLOBAL_ADMIN', null, null, null) ||
                    checkPermission('BUYER', null, null, null),
                PROJECT:
                    checkPermission('GLOBAL_ADMIN', null, null, null) ||
                    checkPermission('BUYER', null, null, null),
                SUPPLIER_RELATIONSHIP_MANAGEMENT_BUYER:
                    checkPermission('GLOBAL_ADMIN', null, null, null) ||
                    checkPermission('BUYER', null, null, null),
            }));
        }
    }, [pathname, modulePermissions, checkPermission]);

    useLayoutEffect(() => {
        // had to use delay 'cause querySelector is returning null meaning it is trying to get the elem before it has been mounted.
        setTimeout(() => {
            if (openHelp) {
                // requestAnimationFrame(() => {
                const section: HTMLElement | null = document.querySelector(
                    `[data-scroll-section="help-scroll-section-${pageName}"]`
                );
                if (section) {
                    contentRef!.current!.scrollTo({
                        top: section.offsetTop - 165,
                        // behavior: 'smooth',
                    });
                }
                // });
            }
        }, 0);
    }, [pageName, openHelp, contentRef, tabValue]);

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        setSearchText(value);
    };

    const [viewGuide, setViewGuide] = useState<IHelpSBS>({
        state: false,
        sbsArr: null,
    });

    const [viewGlossary, setViewGlossary] = useState(false);

    const getIdFromRoute = () => {
        const path = location.pathname.slice(1).split('/');
        let id = path[0];

        if (path.length === 2 && path[1] === 'invoices') {
            id += 'InvoiceDashboard';
        } else if (path.includes('summary')) {
            id += 'InvoiceSummary';
        } else if (path.includes('prepayment')) {
            if (path.includes('create')) {
                id +=
                    'PrepaymentInvoice_createPrepaymentInvoicePageNotifications';
                return id;
            } else {
                id += 'PrepaymentInvoice_PrepaymentInvoicePageNotifications';
                return id;
            }
        }

        id += '_notifications';
        return id;
    };
    const [defaultOpen, setDefaultOpen] = useState<boolean>(true);
    return (
        <>
            <FWTooltip title="Look for help">
                <div>
                    <IconButton
                        id={getIdFromRoute()}
                        color="primary"
                        onClick={toggleHelpPopup}
                        sx={{
                            lineHeight: 1,
                        }}
                    >
                        <FWIcon name="bi bi-question-circle" size={20} />
                    </IconButton>
                </div>
            </FWTooltip>
            <Drawer
                anchor={'right'}
                open={openHelp}
                onClose={() => {
                    toggleHelpPopup();
                    setViewGuide({
                        state: false,
                        sbsArr: null,
                    });
                    setDefaultOpen(true);
                }}
                PaperProps={{
                    sx: {
                        height: '100vh',
                        width: '500px',
                        boxShadow: '0 0 6px rgba(0, 0, 0, 0.16)',
                        background: '#fff',
                    },
                }}
                BackdropProps={{
                    sx: {
                        background: 'transparent',
                    },
                }}
                ModalProps={{
                    disableScrollLock: true,
                }}
            >
                <Box
                    /*  position={'sticky'}
                        top={0} */
                    padding={2}
                    bgcolor={'white'}
                    zIndex={1}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '0 10px 10px 10px',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h4">
                                Help and Support
                            </Typography>
                            <FWTooltip title="Glossary">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={(e) => {
                                        setViewGlossary((prev) => !prev);
                                        setSearchText('');
                                    }}
                                >
                                    <i
                                        className={
                                            viewGlossary
                                                ? 'bi bi-lightbulb-fill'
                                                : 'bi bi-lightbulb'
                                        }
                                        style={{ fontSize: '1rem' }}
                                    />
                                </IconButton>
                            </FWTooltip>
                            <FWTooltip title="Contact us">
                                <a href="mailto:support@factwise.io">
                                    <IconButton color="primary" size="small">
                                        <i
                                            className={'bi bi-envelope'}
                                            style={{ fontSize: '1rem' }}
                                        />
                                    </IconButton>
                                </a>
                            </FWTooltip>
                        </Box>

                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleHelpPopup();
                                setViewGuide({
                                    state: false,
                                    sbsArr: null,
                                });
                            }}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: '2rem' }}
                            />
                        </IconButton>
                    </div>

                    {!viewGlossary && !viewGuide.state && (
                        <>
                            <FWInput
                                onChange={handleInputChange}
                                placeholder="Search help resources"
                                iconStart={<i className="bi bi-search"></i>}
                            />
                            <Tabs
                                value={tabValue}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    newValue: string
                                ) => setTabValue(newValue)}
                                variant="fullWidth"
                            >
                                <Tab label="Guide" value="VIDEOS" />
                                <Tab label="FAQs" value="FAQS" />
                            </Tabs>
                        </>
                    )}
                </Box>
                {viewGlossary ? (
                    <HelpGlossary
                        setSearchText={setSearchText}
                        setViewGlossary={setViewGlossary}
                    />
                ) : viewGuide.state ? (
                    <HelpStepByStep
                        viewGuide={viewGuide}
                        setViewGuide={setViewGuide}
                        setSearchText={setSearchText}
                    />
                ) : (
                    <Box
                        ref={contentRef}
                        padding="0 20px 0px 20px"
                        overflow={'scroll'}
                        // maxHeight={'100%'}
                    >
                        <VideosHelpTabPanel
                            tabValue={tabValue}
                            helpData={helpData}
                            pageName={pageName}
                            internalPageName={stirePageInternalName}
                            searchText={searchText}
                            toggleAccordion={toggleAccordion}
                            setToggleAccordion={setToggleAccordion}
                            viewGuide={viewGuide}
                            setViewGuide={setViewGuide}
                            userViewPermissions={userViewPermissions}
                            defaultOpen={defaultOpen}
                            setDefaultOpen={setDefaultOpen}
                        />
                        <FAQHelpTabPanel
                            tabValue={tabValue}
                            helpData={helpData}
                            pageName={pageName}
                            internalPageName={stirePageInternalName}
                            searchText={searchText}
                            toggleAccordion={toggleAccordion}
                            setToggleAccordion={setToggleAccordion}
                            userViewPermissions={userViewPermissions}
                            defaultOpen={defaultOpen}
                            setDefaultOpen={setDefaultOpen}
                        />
                    </Box>
                )}
            </Drawer>

            {/* <FWPopupWithChildren
                open={openHelp}
                handleClose={toggleHelpPopup}
                title="Help and Support"
                PaperProps={{
                    sx: {
                        maxWidth: '991px',
                        width: '100%',
                    },
                }}
            >
                <DialogTitle>
                    <FWInput
                        onChange={handleInputChange}
                        placeholder="Search help resources"
                        iconStart={<i className="bi bi-search"></i>}
                    />
                </DialogTitle>
                <DialogContent sx={{}} ref={contentRef}>
                    <Tabs
                        value={tabValue}
                        onChange={(
                            event: React.SyntheticEvent,
                            newValue: string
                        ) => setTabValue(newValue)}
                        variant="fullWidth"
                    >
                        <Tab label="Videos" value="VIDEOS" />
                        <Tab label="FAQs" value="FAQS" />
                    </Tabs>
                    <Box padding="0 12px 0px 12px">
                        <VideosHelpTabPanel
                            tabValue={tabValue}
                            helpData={helpData}
                            pageName={pageName}
                            searchText={searchText}
                            toggleAccordion={toggleAccordion}
                            setToggleAccordion={setToggleAccordion}
                        />
                        <FAQHelpTabPanel
                            tabValue={tabValue}
                            helpData={helpData}
                            pageName={pageName}
                            searchText={searchText}
                            toggleAccordion={toggleAccordion}
                            setToggleAccordion={setToggleAccordion}
                        />
                    </Box>
                </DialogContent>
            </FWPopupWithChildren> */}
        </>
    );
};

export default HelpInformationContainer;
