import {
    Box,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    TemplateData,
    templateType,
} from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import FWAutocomplete from '../../Common/FWAutocomplete';
import { FWButton } from '../../Common/FWButton';
import { FWInput } from '../../Common/FWInput';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { FWTypography } from '../../Common/FWTypograhy';
import { useGetListOfTemplatesForEntityQuery } from '../../Global/Helpers/templateApiSlice';
import { IEntity } from '../../Organizations/Admin/Interfaces/EnterpriseInterface';
import { useGetEntityListQuery } from '../../Organizations/Admin/Services/EnterpriseService';

export interface IEntityAndTemplateSelection {
    open: boolean;
    handleClose: () => void;
    url: string;
    templateType: templateType;
    showCancel: boolean;
    title: string;
    customHandlerForSave?: () => void;
    callDefaultSaveHandler?: boolean;
    customUpdateHandler?: any;
    entityIdUpdateKey?: string;
    templateIdUpdateKey?: string;
    customTemplateList?: TemplateData[];
    accessDefaultTemplateList?: boolean;
}

export default function EntityAndTemplateSelectionPopup({
    open,
    handleClose,
    showCancel,
    templateType,
    title,
    url,
    customHandlerForSave,
    callDefaultSaveHandler = true,
    customUpdateHandler,
    entityIdUpdateKey = 'entity_id',
    templateIdUpdateKey = 'template_id',
    customTemplateList,
    accessDefaultTemplateList = true,
}: IEntityAndTemplateSelection) {
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

    const [essentials, setEssentials] = useState<{
        entity_id: string;
        template_id: string;
    }>({
        entity_id: '',
        template_id: '',
    });
    const { data: listOfEntities, refetch } = useGetEntityListQuery({});

    useEffect(() => {
        try {
            refetch();
        } catch (err) {}
    }, [refetch, essentials]);

    const { data: listOfTemplates, refetch: refetchTemplateDetails } =
        useGetListOfTemplatesForEntityQuery(
            {
                entityId: essentials?.entity_id,
                templateType: templateType as any,
            },
            {
                skip:
                    essentials?.entity_id === '' ||
                    accessDefaultTemplateList === false,
            }
        );

    const history = useHistory();

    useEffect(() => {
        try {
            refetchTemplateDetails();
        } catch (err) {
            console.error(err);
        }
    }, [refetchTemplateDetails]);

    const handlePopupClose = () => {
        setEssentials({
            entity_id: '',
            template_id: '',
        });
        if (customUpdateHandler) {
            customUpdateHandler(entityIdUpdateKey, '');
            customUpdateHandler(templateIdUpdateKey, '');
        }

        setSubmitButtonClicked(false);
        handleClose();
    };

    const handleConfirmClick = () => {
        setSubmitButtonClicked(true);
        if (customHandlerForSave) {
            customHandlerForSave();
        }

        if (callDefaultSaveHandler) {
            history.push({
                pathname: url,
                state: {
                    templateId: essentials.template_id,
                    entityId: essentials.entity_id,
                },
            });
        }

        handlePopupClose();
    };

    useEffect(() => {}, [essentials]);

    const handleEntityChange = useCallback(
        (newEntity: IEntity | null) => {
            if (newEntity === null) {
                setEssentials(() => ({
                    entity_id: '',
                    template_id: '',
                }));
                customUpdateHandler(entityIdUpdateKey, '');
            } else {
                setEssentials((prev) => ({
                    entity_id: newEntity.entityId,
                    template_id: '',
                }));
                if (customUpdateHandler) {
                    customUpdateHandler(entityIdUpdateKey, newEntity?.entityId);
                }
            }
        },
        [customUpdateHandler, entityIdUpdateKey]
    );

    const handleTemplateChange = useCallback(
        (newTemplate: TemplateData | null) => {
            if (newTemplate === null) {
                setEssentials((prev) => ({
                    ...prev,
                    template_id: '',
                }));
                customUpdateHandler(templateIdUpdateKey, '');
            } else {
                setEssentials((prev) => ({
                    ...prev,
                    template_id: newTemplate.template_id,
                }));
                if (customUpdateHandler) {
                    customUpdateHandler(
                        templateIdUpdateKey,
                        newTemplate?.template_id
                    );
                }
            }
        },
        [customUpdateHandler, templateIdUpdateKey]
    );

    useEffect(() => {
        if (essentials.entity_id === '' && listOfEntities !== undefined) {
            handleEntityChange(listOfEntities[0]);
        }
    }, [essentials.entity_id, handleEntityChange, listOfEntities]);

    useEffect(() => {
        if (accessDefaultTemplateList) {
            if (listOfTemplates !== undefined) {
                if (listOfTemplates?.length > 0) {
                    if (
                        essentials.template_id === '' ||
                        listOfTemplates.find(
                            (temp) =>
                                temp.template_id === essentials.template_id
                        ) === undefined
                    )
                        handleTemplateChange(listOfTemplates[0]);
                }
            }
        } else {
            if (customTemplateList) {
                if (customTemplateList?.length > 0) {
                    if (
                        essentials.template_id === '' ||
                        customTemplateList.find(
                            (temp) =>
                                temp.template_id === essentials.template_id
                        ) === undefined
                    )
                        handleTemplateChange(customTemplateList[0]);
                }
            }
        }
    }, [
        accessDefaultTemplateList,
        customTemplateList,
        essentials.template_id,
        handleTemplateChange,
        listOfTemplates,
    ]);

    return (
        <FWPopupWithChildren
            open={open}
            handleClose={handlePopupClose}
            showClose={showCancel}
            applyTitleStyling={false}
            title={title}
        >
            <DialogContent>
                <Grid
                    container
                    gap={2}
                    display={'flex'}
                    flexDirection={'column'}
                >
                    <FormControl fullWidth required>
                        <FWTypography color={'text.secondary'}>
                            Entity
                        </FWTypography>
                        <FWAutocomplete
                            options={listOfEntities ?? []}
                            popupIcon={
                                <Box fontSize={'16px'}>
                                    <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                                </Box>
                            }
                            fullWidth
                            value={
                                listOfEntities?.find(
                                    (entity) =>
                                        entity.entityId ===
                                        essentials?.entity_id
                                ) ?? null
                            }
                            onChange={(e, newValue: IEntity) => {
                                handleEntityChange(newValue);
                            }}
                            getOptionLabel={(option: IEntity) =>
                                `${option.entityName}`
                            }
                            renderInput={(params) => {
                                return (
                                    <FWInput
                                        {...params}
                                        required
                                        error={false}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth required>
                        <FWTypography color={'text.secondary'}>
                            Template
                        </FWTypography>
                        <FWAutocomplete
                            // disabled={
                            //     listOfTemplatesOfSelectedEntity?.length === 1
                            // }
                            options={
                                accessDefaultTemplateList
                                    ? listOfTemplates ?? []
                                    : customTemplateList
                                    ? customTemplateList.filter(
                                          (temp) =>
                                              temp.template_entity_id ===
                                              essentials.entity_id
                                      )
                                    : []
                            }
                            popupIcon={
                                <Box fontSize={'16px'}>
                                    <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                                </Box>
                            }
                            fullWidth
                            disableClearable
                            value={
                                accessDefaultTemplateList
                                    ? listOfTemplates?.find(
                                          (temp) =>
                                              temp.template_id ===
                                              essentials?.template_id
                                      ) ?? null
                                    : customTemplateList?.find(
                                          (temp) =>
                                              temp.template_id ===
                                              essentials?.template_id
                                      ) ?? null
                            }
                            onChange={(e, newValue: TemplateData) => {
                                handleTemplateChange(newValue);
                            }}
                            getOptionLabel={(option: TemplateData) =>
                                `${option.template_name}`
                            }
                            renderInput={(params) => {
                                return (
                                    <FWInput
                                        {...params}
                                        required
                                        error={false}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{ paddingRight: '1.5rem', marginBottom: '1.5rem' }}
            >
                {showCancel && (
                    <FWButton
                        variant="outlined"
                        onClick={handlePopupClose}
                        color="primary"
                    >
                        Cancel
                    </FWButton>
                )}
                <FWButton
                    variant="contained"
                    color="primary"
                    // onClick={completeDeleteProcess}
                    disabled={
                        !Boolean(essentials.entity_id) ||
                        !Boolean(essentials.template_id) ||
                        (accessDefaultTemplateList
                            ? !Boolean(
                                  listOfTemplates?.find(
                                      (temp) =>
                                          temp.template_id ===
                                          essentials?.template_id
                                  )
                              )
                            : !Boolean(
                                  customTemplateList?.find(
                                      (temp) =>
                                          temp.template_id ===
                                          essentials?.template_id
                                  )
                              )) ||
                        submitButtonClicked
                    }
                    onClick={handleConfirmClick}
                    id={'buyerEventsDashboard_confirmDeleteEvent'}
                >
                    Confirm
                </FWButton>
            </DialogActions>
        </FWPopupWithChildren>
    );
}
