import {
    Autocomplete,
    Box,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
} from '@mui/material';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { FWButton, FWIconButton } from '../../../../Common/FWButton';
import { FWInput } from '../../../../Common/FWInput';
import FWPortal from '../../../../Common/FWPortal';
import { FWTypography } from '../../../../Common/FWTypograhy';
import ItemCustomIds from '../../Components/ItemDirectory/ItemCustomIds';
import ItemSpecifications from '../../Components/ItemDirectory/ItemSpecifications';
import ItemStandardTerms, {
    STField,
} from '../../Components/ItemDirectory/ItemStandardTerms';
import {
    ItemReducerAction,
    ItemUpdateActions,
} from '../../Hooks/ItemDirectory/ItemDirectoryHook';
import {
    IItemCustomId,
    IItemDetails,
    IItemMeasurementUnits,
    IItemSpecification,
    IItemStandardTermsOptions,
    IMeasurement,
} from '../../Interfaces/ItemDirectoryInterface';
import {
    IItemAddEditResponse,
    useGetItemTagLinkToVendorTabCountMutation,
    validateItemCode,
} from '../../Services/ItemDirectoryService';
import { InfoBlock, LongtextBox } from './ItemDetailsContainer';

import { debounce, isEqual } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetAdditionalCostFromAdminQuery } from '../../../../AdditionalCost/Services/additionalCosts.services';
import { IAdditionalCostsBackend } from '../../../../AdditionalCost/models/AdditionalCost.model';
import { capitaliseFirstLetter } from '../../../../BuyerTemplates/Component/TemplateSuggestedFieldsSection';
import { AutocompleteStyledPaper } from '../../../../Common/FWAutocomplete';
import { FWCombinedInput } from '../../../../Common/FWCombinedInput';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import LazyFallback from '../../../../Components/Shared/LazyFallBack';
import { AuthContext } from '../../../../Contexts/AuthContext';
import TagsInput from '../../../../Global/Components/TagsInput';
import { ICurrencyDetails } from '../../../../Models/Currency.model';
import { IGlobalAppStore } from '../../../../Redux/Store';
import { FieldNameDescription } from '../../../../SRM/Components/DraftDocumentCustomFields';
import UOMMeasurementViewPopup from '../../../../UOM&Currency/Components/UOM/UOMMeasurementViewPopup';
import {
    IINewItemTemplate,
    useNewItemAdditionalCost,
} from '../../Hooks/ItemDirectory/ItemAdditionalCostHook';
import ItemAdditionalCost from './ItemAdditionalCost';
import ItemCustomFields from './ItemCustomFields';

export enum ItemPageType {
    ADD = 'ADD',
    EDIT = 'EDIT',
}

// export interface IINewItemTemplate {
//     customFields: {
//         name: string;
//         fields: IItemCustomField[];
//     }[];
//     additionalCosts: {
//         name: string;
//         fields: IItemAdditionalCostField[];
//         isHidden: boolean;
//     }[];
//     itemFields: {
//         [key: string]: IItemCustomField;
//     };
// }

interface IItemAddEditContainerProps {
    type: ItemPageType;
    data: IItemDetails;
    ogData: IItemDetails;
    measurements: IItemMeasurementUnits;
    fetchItemMeasurements: () => void;

    additionalCostsToDisplay?: string[];
    itemTemplateSectionList?: IINewItemTemplate | undefined;
    setAdditionalCostToDisplay?: Dispatch<SetStateAction<string[]>>;
    setItemTemplateSectionList?: Dispatch<
        SetStateAction<IINewItemTemplate | undefined>
    >;
    getTemplateFields?: () => void;
    getPreselectedAdditionalCost?: (itemId: any) => Promise<
        | {
              additionalCosts:
                  | {
                        buyerAdditionalCosts: IAdditionalCostsBackend[];
                        sellerAdditionalCosts: IAdditionalCostsBackend[];
                    }
                  | undefined;
              additionalCostNames: string[];
          }
        | undefined
    >;
    globalTags: string[];
    currencies: ICurrencyDetails[];
    isValidDetails: boolean;
    codeUsed?: boolean;
    event?: boolean;
    handleChange: (action: ItemReducerAction) => void;
    handleItemSave: () => Promise<IItemAddEditResponse>;
    emitSaveAction: (
        response: IItemAddEditResponse,
        isFinishedGood: boolean
    ) => void;
    shouldRedirect?: boolean;
}

const ItemAddEditContainer = ({
    type,
    data,
    ogData,
    handleChange,
    measurements,
    globalTags,
    currencies,
    additionalCostsToDisplay,
    itemTemplateSectionList,
    setAdditionalCostToDisplay,
    getPreselectedAdditionalCost,
    getTemplateFields,
    setItemTemplateSectionList,
    isValidDetails,
    handleItemSave,
    emitSaveAction,
    codeUsed,
    event,
    shouldRedirect = true,
    fetchItemMeasurements,
}: IItemAddEditContainerProps) => {
    const SINGLE_ENTITY = useSelector(
        (store: IGlobalAppStore) => store.VerificationStore.hasSignleEntity
    );

    const history = useHistory();

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [newMeasurementUnitPopup, setNewMeasurementUnitPopup] =
        useState(false);
    const [
        newMeasurementUnitSelectedFromPopup,
        setNewMeasurementUnitSelectedFromPopup,
    ] = useState<string | null>(null);
    const [allMeasurementUnits, setAllMeasurementUnits] =
        useState<IItemMeasurementUnits>({});
    const categoriesOrder = [
        'UNITS',
        'WEIGHT',
        'VOLUME',
        'LENGTH',
        'AREA',
        'TEMPERATURE',
        'ENERGY',
        'TIME',
        'OTHER',
    ];

    const measurementsArrayUnsorted = Object.keys(allMeasurementUnits).map(
        (item) => allMeasurementUnits[item]
    );

    const measurementsArray = measurementsArrayUnsorted
        .filter((unit) => unit.isVerifiedUnit)
        .concat(
            measurementsArrayUnsorted.filter((unit) => !unit.isVerifiedUnit)
        );

    useEffect(() => {
        if (newMeasurementUnitSelectedFromPopup) {
            const foundMeasurementUnit = measurementsArray.find(
                (measure) => measure.id === newMeasurementUnitSelectedFromPopup
            );

            if (foundMeasurementUnit) {
                handleChange({
                    type: ItemUpdateActions.MEASUREMENTS,
                    value: [foundMeasurementUnit],
                });
                setNewMeasurementUnitSelectedFromPopup(null);
            }
        }
    }, [handleChange, measurementsArray, newMeasurementUnitSelectedFromPopup]);

    const customSort = (a: IMeasurement, b: IMeasurement) =>
        categoriesOrder.indexOf(a.category) -
        categoriesOrder.indexOf(b.category);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let res = measurementsArray.sort(customSort);

    const openMenu = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    const { authData } = useContext(AuthContext);

    useEffect(() => {
        setAllMeasurementUnits(measurements);
        // setAdditionalCostsToDisplay([]);
        return () => {
            if (setItemTemplateSectionList)
                setItemTemplateSectionList(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measurements]);

    const handleSTValuesChange = (
        type: STField,
        value: IItemStandardTermsOptions | null
    ) => {
        handleChange({
            type: ItemUpdateActions[type],
            value,
        });
    };

    const handleSpecificationEmitData = (value: IItemSpecification[]) => {
        handleChange({
            type: ItemUpdateActions.SPECIFICATION,
            value,
        });
    };

    // const handleCustomFieldChange = (value : I)

    const handleCustomIdsEmitData = (value: IItemCustomId[]) => {
        handleChange({
            type: ItemUpdateActions.CUSTOM_IDS,
            value,
        });
    };

    const handleValueChange = (event: {
        target: { name: ItemUpdateActions; value: string };
    }) => {
        handleChange({
            type: ItemUpdateActions[event.target.name],
            value: event.target.value,
        } as ItemReducerAction);
    };

    const [fetchItemTagCount] = useGetItemTagLinkToVendorTabCountMutation({
        fixedCacheKey: 'itemTagsLinkToVendor',
    });

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();

        const itemSaved = await handleItemSave();
        fetchItemTagCount({
            query_data: {
                tag_type: 'ITEM',
            },
        });
        emitSaveAction(itemSaved, itemSaved.isFinishedGood);
        if (shouldRedirect) history.push('/admin/items/item-directory');
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [itemId, setItemId] = useState<string>('');

    const location = useLocation();
    useEffect(() => {
        if (type === 'EDIT') {
            let itemEditPage = location.pathname.split('/').filter(Boolean);

            let itemid = itemEditPage.reduce(
                (longest: string, current: string) =>
                    current.length > longest.length ? current : longest,
                ''
            );

            setItemId(itemid);
        }
    }, [location.pathname, type]);

    const {
        newItemTemplateSectionList,
        newGetTemplateFields,
        customFieldSetting,
    } = useNewItemAdditionalCost(data, handleChange, type);

    const [templateDetails, setTemplateDetails] = useState<IINewItemTemplate>();

    useEffect(() => {
        if (type === ItemPageType.ADD && newItemTemplateSectionList) {
            setTemplateDetails(newItemTemplateSectionList);
        } else if (type === ItemPageType.EDIT && itemTemplateSectionList) {
            setTemplateDetails(itemTemplateSectionList);
        }
    }, [itemTemplateSectionList, newItemTemplateSectionList, type]);

    useEffect(() => {
        if (getTemplateFields) getTemplateFields();
    }, [getTemplateFields]);

    const checkCustomFieldError = useCallback(() => {
        return (
            data?.custom_fields &&
            data.custom_fields?.section_list &&
            data.custom_fields.section_list[0]?.fields?.some((field) => {
                let fieldInfo =
                    templateDetails?.customFields?.section_list[0]?.fields?.find(
                        (tempField) => tempField.name === field.name
                    );

                if (
                    fieldInfo &&
                    fieldInfo?.is_required &&
                    (field.value === '' || field.value === null)
                ) {
                    return true;
                }
                return false;
            })
        );
    }, [data.custom_fields, templateDetails?.customFields?.section_list]);

    const [misMatchedCosts, setMisMatchedCosts] =
        useState<IAdditionalCostsBackend[]>();

    const { data: adminAdditionalCost } = useGetAdditionalCostFromAdminQuery(
        {}
    );

    const fillInMisMatchedFields = useCallback(() => {
        if (adminAdditionalCost) {
            let fieldsInDataNotInTemplate = data?.additionalCost?.filter(
                (cost) =>
                    !templateDetails?.additionalCosts[0]?.fields?.some(
                        (c) =>
                            c.additional_cost_information.additional_cost_id ===
                            cost.additional_cost_id
                    )
            );
            setMisMatchedCosts(fieldsInDataNotInTemplate);
        }
    }, [
        adminAdditionalCost,
        data?.additionalCost,
        templateDetails?.additionalCosts,
    ]);

    const handleMismatchedCostRemove = (
        type: 'SINGLE' | 'ALL',
        costId: string | null = null
    ) => {
        let filteredAdditionalCostList: IAdditionalCostsBackend[] = [];
        if (type === 'SINGLE') {
            filteredAdditionalCostList = data?.additionalCost?.filter(
                (cost) => cost.additional_cost_id !== costId
            );
        } else {
            filteredAdditionalCostList = data.additionalCost?.filter(
                (cost) =>
                    !templateDetails?.additionalCosts[0]?.fields.some(
                        (tempCost) =>
                            tempCost.additional_cost_information
                                .additional_cost_id === cost.additional_cost_id
                    )
            );
        }
        handleChange({
            type: ItemUpdateActions.ADDITIONAL_COST,
            value: filteredAdditionalCostList,
        });
        handleChange({
            type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
            value: filteredAdditionalCostList,
        });
        handleChange({
            type: ItemUpdateActions.SELLER_ADDITIONAL_COST,
            value: filteredAdditionalCostList,
        });
    };

    useEffect(() => {
        debouncedSearch(data.itemCode);
        //eslint-disable-next-line
    }, [data.itemCode]);

    const [codeError, setError] = useState(false);
    const debouncedSearch = useMemo(
        () =>
            debounce(async (text: string) => {
                try {
                    let resp: any = await validateItemCode(text.trim());
                    if (resp.data.item_exists) {
                        setError(true);
                    } else setError(false);
                } catch (err) {
                    console.log(err);
                }
            }, 1000),
        []
    );
    useEffect(() => {
        fillInMisMatchedFields();
    }, [fillInMisMatchedFields]);

    const BottomButtons = (
        <Grid container className="flex--jcfs pr--15 mt--10">
            <Grid item>
                <FWButton
                    variant="contained"
                    onClick={handleFormSubmit}
                    disabled={
                        !isValidDetails ||
                        (codeError && type === ItemPageType.ADD) ||
                        isEqual(ogData, data) ||
                        checkCustomFieldError() ||
                        misMatchedCosts?.length !== 0 ||
                        data.additionalCost.some(
                            (ac) => !Boolean(ac.cost_value?.toString())
                        )
                    }
                >
                    {false ? (
                        <CircularProgress
                            size={24}
                            style={{
                                color: '#c4c4c8',
                            }}
                        />
                    ) : (
                        'Save'
                    )}
                </FWButton>
            </Grid>
        </Grid>
    );

    // const ToolTipRender = ({ index }: { index: number }) => {
    //     if (
    //         Boolean(data?.buyerPricingInformation?.currencyCodeId) &&
    //         Boolean(data.sellerPricingInformation.currencyCodeId)
    //     ) {
    //         return (
    //             <FWTooltip
    //                 title={`For buyer, the currency is ${currencies
    //                     .filter(
    //                         (currency) =>
    //                             currency.entry_id ===
    //                             data.buyerPricingInformation.currencyCodeId
    //                     )
    //                     .map(
    //                         (currency) => currency.currency_name
    //                     )} and for seller, the currency is ${currencies
    //                     .filter(
    //                         (currency) =>
    //                             currency.entry_id ===
    //                             data.sellerPricingInformation.currencyCodeId
    //                     )
    //                     .map((currency) => currency.currency_name)} `}
    //             >
    //                 <Box>
    //                     <FWIcon
    //                         color={'primary.main'}
    //                         size={16}
    //                         name="bi bi-info-circle"
    //                     />
    //                 </Box>
    //             </FWTooltip>
    //         );
    //     } else if (
    //         Boolean(data.buyerPricingInformation?.currencyCodeId) &&
    //         !Boolean(data.sellerPricingInformation.currencyCodeId)
    //     ) {
    //         return (
    //             <FWTooltip
    //                 title={`For buyer, the currency is ${currencies
    //                     .filter(
    //                         (currency) =>
    //                             currency.entry_id ===
    //                             data.buyerPricingInformation.currencyCodeId
    //                     )
    //                     .map((currency) => currency.currency_name)}`}
    //             >
    //                 <Box>
    //                     <FWIcon
    //                         color={'primary.main'}
    //                         size={16}
    //                         name="bi bi-info-circle"
    //                     />
    //                 </Box>
    //             </FWTooltip>
    //         );
    //     } else if (
    //         !Boolean(data.buyerPricingInformation.currencyCodeId) &&
    //         Boolean(data.sellerPricingInformation.currencyCodeId)
    //     ) {
    //         return (
    //             <FWTooltip
    //                 title={`For seller, the currency is ${currencies
    //                     .filter(
    //                         (currency) =>
    //                             currency.entry_id ===
    //                             data.sellerPricingInformation.currencyCodeId
    //                     )
    //                     .map((currency) => currency.currency_name)}`}
    //             >
    //                 <Box>
    //                     <FWIcon
    //                         color={'primary.main'}
    //                         size={16}
    //                         name="bi bi-info-circle"
    //                     />
    //                 </Box>
    //             </FWTooltip>
    //         );
    //     }
    //     return <></>;
    // };

    useEffect(() => {
        setAllMeasurementUnits(measurements);
    }, [measurements]);

    // const filterAdditionalCostNewFunc = (cost: IItemAdditionalCostField) => {
    //     if (data.buyerPricingInformation.additionalCost) {
    //         if (
    //             data.buyerPricingInformation.additionalCost.some(
    //                 (buyerAC) =>
    //                     buyerAC.additional_cost_id ===
    //                     cost.additional_cost_information.additional_cost_id
    //             )
    //         ) {
    //             let filteredBuyerAdditionalCost =
    //                 data.buyerPricingInformation.additionalCost.filter(
    //                     (buyerAC) =>
    //                         buyerAC.additional_cost_id !==
    //                         cost.additional_cost_information.additional_cost_id
    //                 );

    //             handleChange({
    //                 type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                 value: filteredBuyerAdditionalCost,
    //             });
    //             handleChange({
    //                 type: ItemUpdateActions.ADDITIONAL_COST,
    //                 value: filteredBuyerAdditionalCost,
    //             });
    //             if (setAdditionalCostToDisplay) {
    //                 setAdditionalCostToDisplay((prev) => {
    //                     let filteredCostNames = prev.filter(
    //                         (costName) => costName !== cost.name
    //                     );
    //                     return filteredCostNames;
    //                 });
    //             }
    //         } else {
    //             let obj: IAdditionalCostsBackend = {
    //                 allocation_type:
    //                     cost.additional_cost_information.allocation_type ??
    //                     null,
    //                 cost_name: cost.name,
    //                 cost_type: cost.additional_cost_information
    //                     .cost_type as CostTypeEnum,
    //                 cost_value: +(cost.value?.toString() ?? 0) ?? 0,
    //                 additional_cost_id:
    //                     cost.additional_cost_information.additional_cost_id,
    //             };

    //             if (setAdditionalCostToDisplay) {
    //                 setAdditionalCostToDisplay((prev) => [...prev, cost.name]);
    //             }
    //             handleChange({
    //                 type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                 value: [
    //                     ...data.buyerPricingInformation.additionalCost,
    //                     obj,
    //                 ],
    //             });
    //             handleChange({
    //                 type: ItemUpdateActions.ADDITIONAL_COST,
    //                 value: [
    //                     ...data.buyerPricingInformation.additionalCost,
    //                     obj,
    //                 ],
    //             });
    //         }
    //     }
    // };

    useEffect(() => {
        newGetTemplateFields();
    }, [newGetTemplateFields]);

    // const renderAdditionalCostListThings = () => {
    //     return itemTemplateSectionList?.additionalCosts[0].fields.map(
    //         (cost, index) => {
    //             let foundAdditionalCost = data.additionalCost?.findIndex(
    //                 (c) =>
    //                     c.additional_cost_id ===
    //                     cost.additional_cost_information.additional_cost_id
    //             );

    //             if (
    //                 !cost.is_hidden &&
    //                 additionalCostsToDisplay?.includes(cost.name)
    //             )
    //                 return (
    //                     <Grid
    //                         item
    //                         xs={12}
    //                         key={index}
    //                         sx={{
    //                             display: 'flex',
    //                             gap: '0.5rem',
    //                         }}
    //                     >
    //
    //                         <Grid item xs={6}>
    //                             <FWInput
    //                                 label={cost.name}
    //                                 required={true}
    //                                 allowOnly="DECIMAL_NUMBER"
    //                                 autoFocus={false}
    //                                 placeholder="Enter price"
    //                                 value={
    //                                     data.additionalCost
    //                                         ? data.additionalCost[
    //                                               foundAdditionalCost
    //                                           ]?.cost_value
    //                                             ? data.additionalCost[
    //                                                   foundAdditionalCost
    //                                               ]?.cost_value
    //                                             : 0
    //                                         : 0
    //                                 }
    //                                 maxNumberOfDecimal={
    //                                     data.additionalCost[foundAdditionalCost]
    //                                         ?.cost_type ===
    //                                     CostTypeEnum.PERCENTAGE
    //                                         ? 4
    //                                         : 10
    //                                 }
    //                                 onChange={(e) => {
    //
    //                                     let val = [...data.additionalCost];

    //                                     if (foundAdditionalCost !== -1) {
    //                                         if (
    //                                             (data.additionalCost &&
    //                                                 data.additionalCost[
    //                                                     foundAdditionalCost
    //                                                 ].cost_type ===
    //                                                     CostTypeEnum.PERCENTAGE) ||
    //                                             (!data.additionalCost[
    //                                                 foundAdditionalCost
    //                                             ] &&
    //                                                 cost
    //                                                     .additional_cost_information
    //                                                     .cost_type ===
    //                                                     CostTypeEnum.PERCENTAGE)
    //                                         ) {
    //                                             if (+e.target.value <= 100) {
    //                                                 val[
    //                                                     foundAdditionalCost
    //                                                 ].cost_value =
    //                                                     e.target.value;

    //                                                 handleChange({
    //                                                     type: ItemUpdateActions.ADDITIONAL_COST,
    //                                                     value: val,
    //                                                 });

    //                                                 let buyerAdditionalCosts =
    //                                                     data
    //                                                         .buyerPricingInformation
    //                                                         .additionalCost;

    //                                                 if (
    //                                                     buyerAdditionalCosts?.some(
    //                                                         (buyerAC) =>
    //                                                             buyerAC.additional_cost_id ===
    //                                                             val[
    //                                                                 foundAdditionalCost
    //                                                             ]
    //                                                                 .additional_cost_id
    //                                                     )
    //                                                 ) {
    //                                                     let costIndex =
    //                                                         buyerAdditionalCosts.findIndex(
    //                                                             (buyerAC) =>
    //                                                                 buyerAC.additional_cost_id ===
    //                                                                 val[
    //                                                                     foundAdditionalCost
    //                                                                 ]
    //                                                                     .additional_cost_id
    //                                                         );
    //                                                     buyerAdditionalCosts[
    //                                                         costIndex
    //                                                     ].cost_value =
    //                                                         e.target.value;

    //                                                     handleChange({
    //                                                         type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                         value: buyerAdditionalCosts,
    //                                                     });
    //                                                 } else if (
    //                                                     data
    //                                                         .buyerPricingInformation
    //                                                         .additionalCost
    //                                                 ) {
    //                                                     handleChange({
    //                                                         type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                         value: [
    //                                                             ...data
    //                                                                 .buyerPricingInformation
    //                                                                 .additionalCost,
    //                                                             val[
    //                                                                 foundAdditionalCost
    //                                                             ],
    //                                                         ],
    //                                                     });
    //                                                 } else {
    //                                                     handleChange({
    //                                                         type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                         value: [
    //                                                             val[
    //                                                                 foundAdditionalCost
    //                                                             ],
    //                                                         ],
    //                                                     });
    //                                                 }
    //                                             }
    //                                         }

    //                                         // if data is not percentage
    //                                         else {
    //                                             val[
    //                                                 foundAdditionalCost
    //                                             ].cost_value = e.target.value;

    //                                             handleChange({
    //                                                 type: ItemUpdateActions.ADDITIONAL_COST,
    //                                                 value: val,
    //                                             });

    //                                             let buyerAdditionalCosts =
    //                                                 data.buyerPricingInformation
    //                                                     .additionalCost;

    //                                             if (
    //                                                 buyerAdditionalCosts?.some(
    //                                                     (buyerAC) =>
    //                                                         buyerAC.additional_cost_id ===
    //                                                         val[
    //                                                             foundAdditionalCost
    //                                                         ].additional_cost_id
    //                                                 )
    //                                             ) {
    //                                                 let costIndex =
    //                                                     buyerAdditionalCosts.findIndex(
    //                                                         (buyerAC) =>
    //                                                             buyerAC.additional_cost_id ===
    //                                                             val[
    //                                                                 foundAdditionalCost
    //                                                             ]
    //                                                                 .additional_cost_id
    //                                                     );
    //                                                 buyerAdditionalCosts[
    //                                                     costIndex
    //                                                 ].cost_value =
    //                                                     e.target.value;

    //                                                 handleChange({
    //                                                     type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                     value: buyerAdditionalCosts,
    //                                                 });
    //                                             } else if (
    //                                                 data.buyerPricingInformation
    //                                                     .additionalCost
    //                                             ) {
    //                                                 handleChange({
    //                                                     type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                     value: [
    //                                                         ...data
    //                                                             .buyerPricingInformation
    //                                                             .additionalCost,
    //                                                         val[
    //                                                             foundAdditionalCost
    //                                                         ],
    //                                                     ],
    //                                                 });
    //                                             } else {
    //                                                 handleChange({
    //                                                     type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                     value: [
    //                                                         val[
    //                                                             foundAdditionalCost
    //                                                         ],
    //                                                     ],
    //                                                 });
    //                                             }
    //                                         }
    //                                     } else if (foundAdditionalCost === -1) {
    //
    //                                         let buyerAdditionalCost =
    //                                             data.buyerPricingInformation
    //                                                 .additionalCost;

    //                                         if (buyerAdditionalCost) {
    //                                             handleChange({
    //                                                 type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                 value: [
    //                                                     ...buyerAdditionalCost,
    //                                                 ],
    //                                             });
    //                                         }
    //                                     }
    //                                 }}
    //                             />
    //                         </Grid>

    //                         <Grid
    //                             item
    //                             xs={0.3}
    //                             sx={{ display: 'flex', alignItems: 'center' }}
    //                         >
    //                             <Grid item paddingTop={2.5} marginY={'auto'}>
    //                                 <ToolTipRender index={index} />
    //                             </Grid>
    //                         </Grid>

    //                         <Grid
    //                             item
    //                             xs={0.5}
    //                             sx={{
    //                                 display: 'flex',
    //                                 alignItems: 'center',
    //                             }}
    //                             paddingTop={2.5}
    //                         >
    //                             <FWTooltip title={`Remove additional cost`}>
    //                                 <Box>
    //                                     <FWIconButton
    //                                         color="error"
    //                                         onClick={
    //                                             () =>
    //                                                 filterAdditionalCostNewFunc(
    //                                                     cost
    //                                                 )
    //                                             // filterAdditionalCost(
    //                                             //     cost,
    //                                             //     index
    //                                             // )
    //                                         }
    //                                         sx={{
    //                                             width: '30px',
    //                                             height: '30px',
    //                                         }}
    //                                     >
    //                                         <FWIcon
    //                                             name="bi bi-dash-circle"
    //                                             size={22}
    //                                         />
    //                                     </FWIconButton>
    //                                 </Box>
    //                             </FWTooltip>
    //                         </Grid>
    //                     </Grid>
    //                 );
    //             else return null;
    //         }
    //     );
    // };

    // const RenderNewItemAdditionalCost = () => {
    //     return (
    //         <Grid>
    //             {templateDetails?.additionalCosts[0].fields.map(
    //                 (cost, index) => {
    //                     let foundAdditionalCost = data.additionalCost.findIndex(
    //                         (c) =>
    //                             c.additional_cost_id ===
    //                             cost.additional_cost_information
    //                                 .additional_cost_id
    //                     );

    //

    //                     if (
    //                         !cost.is_hidden &&
    //                         newItemAdditionalCostToDisplay?.includes(cost.name)
    //                     )
    //                         return (
    //                             <Grid
    //                                 item
    //                                 xs={12}
    //                                 key={index}
    //                                 sx={{
    //                                     display: 'flex',
    //                                     gap: '0.5rem',
    //                                 }}
    //                             >
    //                                 <Grid item xs={6}>
    //                                     <FWInput
    //                                         label={cost.name}
    //                                         required={true}
    //                                         allowOnly="DECIMAL_NUMBER"
    //                                         placeholder="Enter price"
    //                                         value={
    //                                             data.additionalCost
    //                                                 ? data.additionalCost[
    //                                                       foundAdditionalCost
    //                                                   ]?.cost_value
    //                                                     ? data.additionalCost[
    //                                                           foundAdditionalCost
    //                                                       ]?.cost_value
    //                                                     : 0
    //                                                 : 0
    //                                         }
    //                                         maxNumberOfDecimal={
    //                                             data.additionalCost[
    //                                                 foundAdditionalCost
    //                                             ]?.cost_type ===
    //                                             CostTypeEnum.PERCENTAGE
    //                                                 ? 4
    //                                                 : 10
    //                                         }
    //                                         onChange={(e) => {
    //                                             let val = [
    //                                                 ...data.additionalCost,
    //                                             ];

    //                                             if (
    //                                                 foundAdditionalCost !== -1
    //                                             ) {
    //                                                 if (
    //                                                     (data.additionalCost &&
    //                                                         data.additionalCost[
    //                                                             foundAdditionalCost
    //                                                         ].cost_type ===
    //                                                             CostTypeEnum.PERCENTAGE) ||
    //                                                     (!data.additionalCost[
    //                                                         foundAdditionalCost
    //                                                     ] &&
    //                                                         cost
    //                                                             .additional_cost_information
    //                                                             .cost_type ===
    //                                                             CostTypeEnum.PERCENTAGE)
    //                                                 ) {
    //                                                     if (
    //                                                         +e.target.value <=
    //                                                         100
    //                                                     ) {
    //                                                         val[
    //                                                             foundAdditionalCost
    //                                                         ].cost_value =
    //                                                             e.target.value;

    //                                                         handleChange({
    //                                                             type: ItemUpdateActions.ADDITIONAL_COST,
    //                                                             value: val,
    //                                                         });

    //                                                         let buyerAdditionalCosts =
    //                                                             data
    //                                                                 .buyerPricingInformation
    //                                                                 .additionalCost;

    //                                                         if (
    //                                                             buyerAdditionalCosts?.some(
    //                                                                 (buyerAC) =>
    //                                                                     buyerAC.additional_cost_id ===
    //                                                                     val[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .additional_cost_id
    //                                                             )
    //                                                         ) {
    //                                                             let costIndex =
    //                                                                 buyerAdditionalCosts.findIndex(
    //                                                                     (
    //                                                                         buyerAC
    //                                                                     ) =>
    //                                                                         buyerAC.additional_cost_id ===
    //                                                                         val[
    //                                                                             foundAdditionalCost
    //                                                                         ]
    //                                                                             .additional_cost_id
    //                                                                 );
    //                                                             buyerAdditionalCosts[
    //                                                                 costIndex
    //                                                             ].cost_value =
    //                                                                 e.target.value;

    //                                                             handleChange({
    //                                                                 type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                                 value: buyerAdditionalCosts,
    //                                                             });
    //                                                         } else if (
    //                                                             data
    //                                                                 .buyerPricingInformation
    //                                                                 .additionalCost
    //                                                         ) {
    //                                                             handleChange({
    //                                                                 type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                                 value: [
    //                                                                     ...data
    //                                                                         .buyerPricingInformation
    //                                                                         .additionalCost,
    //                                                                     val[
    //                                                                         foundAdditionalCost
    //                                                                     ],
    //                                                                 ],
    //                                                             });
    //                                                         } else {
    //                                                             handleChange({
    //                                                                 type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                                 value: [
    //                                                                     val[
    //                                                                         foundAdditionalCost
    //                                                                     ],
    //                                                                 ],
    //                                                             });
    //                                                         }
    //                                                     }
    //                                                 }

    //                                                 // if data is not percentage
    //                                                 else {
    //                                                     val[
    //                                                         foundAdditionalCost
    //                                                     ].cost_value =
    //                                                         e.target.value;

    //                                                     handleChange({
    //                                                         type: ItemUpdateActions.ADDITIONAL_COST,
    //                                                         value: val,
    //                                                     });

    //                                                     let buyerAdditionalCosts =
    //                                                         data
    //                                                             .buyerPricingInformation
    //                                                             .additionalCost;

    //                                                     if (
    //                                                         buyerAdditionalCosts?.some(
    //                                                             (buyerAC) =>
    //                                                                 buyerAC.additional_cost_id ===
    //                                                                 val[
    //                                                                     foundAdditionalCost
    //                                                                 ]
    //                                                                     .additional_cost_id
    //                                                         )
    //                                                     ) {
    //                                                         let costIndex =
    //                                                             buyerAdditionalCosts.findIndex(
    //                                                                 (buyerAC) =>
    //                                                                     buyerAC.additional_cost_id ===
    //                                                                     val[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .additional_cost_id
    //                                                             );
    //                                                         buyerAdditionalCosts[
    //                                                             costIndex
    //                                                         ].cost_value =
    //                                                             e.target.value;

    //                                                         handleChange({
    //                                                             type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                             value: buyerAdditionalCosts,
    //                                                         });
    //                                                     } else if (
    //                                                         data
    //                                                             .buyerPricingInformation
    //                                                             .additionalCost
    //                                                     ) {
    //                                                         handleChange({
    //                                                             type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                             value: [
    //                                                                 ...data
    //                                                                     .buyerPricingInformation
    //                                                                     .additionalCost,
    //                                                                 val[
    //                                                                     foundAdditionalCost
    //                                                                 ],
    //                                                             ],
    //                                                         });
    //                                                     } else {
    //                                                         handleChange({
    //                                                             type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                             value: [
    //                                                                 val[
    //                                                                     foundAdditionalCost
    //                                                                 ],
    //                                                             ],
    //                                                         });
    //                                                     }
    //                                                 }
    //                                             } else if (
    //                                                 foundAdditionalCost === -1
    //                                             ) {
    //
    //                                                 let buyerAdditionalCost =
    //                                                     data
    //                                                         .buyerPricingInformation
    //                                                         .additionalCost;

    //                                                 if (cost) {
    //                                                     let newCost: IAdditionalCostsBackend =
    //                                                         {
    //                                                             allocation_type:
    //                                                                 (cost
    //                                                                     .additional_cost_information
    //                                                                     .allocation_type as AllocationTypeEnum) ??
    //                                                                 null,
    //                                                             cost_name:
    //                                                                 cost.name,
    //                                                             cost_type: cost
    //                                                                 .additional_cost_information
    //                                                                 .cost_type as CostTypeEnum,
    //                                                             cost_value: +(
    //                                                                 cost.value?.toString() ??
    //                                                                 ''
    //                                                             ),
    //                                                             additional_cost_id:
    //                                                                 cost
    //                                                                     .additional_cost_information
    //                                                                     .additional_cost_id,
    //                                                         };

    //                                                     if (
    //                                                         buyerAdditionalCost
    //                                                     ) {
    //                                                         handleChange({
    //                                                             type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                             value: [
    //                                                                 ...buyerAdditionalCost,
    //                                                                 newCost,
    //                                                             ],
    //                                                         });
    //                                                     }
    //                                                 }
    //                                             }
    //                                         }}
    //                                     />
    //                                 </Grid>

    //                                 <Grid
    //                                     item
    //                                     xs={0.3}
    //                                     sx={{
    //                                         display: 'flex',
    //                                         alignItems: 'center',
    //                                     }}
    //                                 >
    //                                     <Grid
    //                                         item
    //                                         paddingTop={2.5}
    //                                         marginY={'auto'}
    //                                     >
    //                                         <ToolTipRender index={index} />
    //                                     </Grid>
    //                                 </Grid>

    //                                 <Grid
    //                                     item
    //                                     xs={0.5}
    //                                     sx={{
    //                                         display: 'flex',
    //                                         alignItems: 'center',
    //                                     }}
    //                                     paddingTop={2.5}
    //                                 >
    //                                     <FWTooltip
    //                                         title={`Remove additional cost`}
    //                                     >
    //                                         <Box>
    //                                             <FWIconButton
    //                                                 color="error"
    //                                                 onClick={() =>
    //                                                     filterNewItemAdditionalCost(
    //                                                         cost,
    //                                                         index
    //                                                     )
    //                                                 }
    //                                                 sx={{
    //                                                     width: '30px',
    //                                                     height: '30px',
    //                                                 }}
    //                                             >
    //                                                 <FWIcon
    //                                                     name="bi bi-dash-circle"
    //                                                     size={22}
    //                                                 />
    //                                             </FWIconButton>
    //                                         </Box>
    //                                     </FWTooltip>
    //                                 </Grid>

    //                                 {/* <Grid item xs={4} marginTop={'auto'}>
    //                             <FormGroup
    //                                 sx={{
    //                                     display: 'flex',
    //                                     flexDirection: 'row',
    //                                     flexWrap: 'nowrap',

    //                                     alignItems: 'flex-end',
    //                                 }}
    //                             >
    //                                 <FormControlLabel
    //                                     control={
    //                                         <Checkbox
    //                                             checked={
    //                                                 data.buyerPricingInformation.additionalCost?.find(
    //                                                     (c) =>
    //                                                         data.additionalCost[
    //                                                             foundAdditionalCost
    //                                                         ]
    //                                                             ?.additional_cost_id ===
    //                                                         c.additional_cost_id
    //                                                 )
    //                                                     ? true
    //                                                     : false
    //                                             }
    //                                             onChange={(e, checked) => {
    //                                                 const temp = cloneDeep(
    //                                                     data
    //                                                         .buyerPricingInformation
    //                                                         .additionalCost ??
    //                                                         []
    //                                                 );
    //                                                 if (checked) {
    //                                                     handleChange({
    //                                                         type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                         value: [
    //                                                             ...temp,
    //                                                             {
    //                                                                 additional_cost_id:
    //                                                                     data
    //                                                                         .additionalCost[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .additional_cost_id,
    //                                                                 allocation_type:
    //                                                                     data
    //                                                                         .additionalCost[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .allocation_type,
    //                                                                 cost_name:
    //                                                                     data
    //                                                                         .additionalCost[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .cost_name,
    //                                                                 cost_type:
    //                                                                     data
    //                                                                         .additionalCost[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .cost_type,
    //                                                                 cost_value:
    //                                                                     data
    //                                                                         .additionalCost[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .cost_value,
    //                                                             },
    //                                                         ],
    //                                                     });
    //                                                 } else if (
    //                                                     data.sellerPricingInformation.additionalCost?.some(
    //                                                         (cost) =>
    //                                                             cost.cost_name ===
    //                                                             data
    //                                                                 .additionalCost[
    //                                                                 foundAdditionalCost
    //                                                             ].cost_name
    //                                                     )
    //                                                 ) {
    //                                                     handleChange({
    //                                                         type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
    //                                                         value: temp.filter(
    //                                                             (c) =>
    //                                                                 c.additional_cost_id !==
    //                                                                 data
    //                                                                     .additionalCost[
    //                                                                     foundAdditionalCost
    //                                                                 ]
    //                                                                     .additional_cost_id
    //                                                         ),
    //                                                     });
    //                                                 }
    //                                             }}
    //                                             disabled={
    //                                                 !(
    //                                                     authData.details
    //                                                         ?.role === 'ADMIN'
    //                                                 )
    //                                             }
    //                                         />
    //                                     }
    //                                     label="Buyer"
    //                                 />
    //
    //                                 <FormControlLabel
    //                                     control={
    //                                         <Checkbox
    //                                             checked={
    //                                                 data.sellerPricingInformation.additionalCost?.find(
    //                                                     (c) =>
    //                                                         data.additionalCost[
    //                                                             foundAdditionalCost
    //                                                         ]
    //                                                             ?.additional_cost_id ===
    //                                                         c.additional_cost_id
    //                                                 )
    //                                                     ? true
    //                                                     : false
    //                                             }
    //                                             onChange={(e, checked) => {
    //                                                 const temp = cloneDeep(
    //                                                     data
    //                                                         .sellerPricingInformation
    //                                                         .additionalCost ??
    //                                                         []
    //                                                 );
    //
    //                                                 if (checked) {
    //
    //                                                     handleChange({
    //                                                         type: ItemUpdateActions.SELLER_ADDITIONAL_COST,
    //                                                         value: [
    //                                                             ...temp,
    //                                                             {
    //                                                                 additional_cost_id:
    //                                                                     data
    //                                                                         .additionalCost[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .additional_cost_id,
    //                                                                 allocation_type:
    //                                                                     data
    //                                                                         .additionalCost[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .allocation_type,
    //                                                                 cost_name:
    //                                                                     data
    //                                                                         .additionalCost[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .cost_name,
    //                                                                 cost_type:
    //                                                                     data
    //                                                                         .additionalCost[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .cost_type,
    //                                                                 cost_value:
    //                                                                     data
    //                                                                         .additionalCost[
    //                                                                         foundAdditionalCost
    //                                                                     ]
    //                                                                         .cost_value,
    //                                                             },
    //                                                         ],
    //                                                     });
    //                                                 } else if (
    //                                                     data.buyerPricingInformation.additionalCost?.some(
    //                                                         (cost) =>
    //                                                             cost.cost_name ===
    //                                                             data
    //                                                                 .additionalCost[
    //                                                                 foundAdditionalCost
    //                                                             ].cost_name
    //                                                     )
    //                                                 ) {
    //                                                     handleChange({
    //                                                         type: ItemUpdateActions.SELLER_ADDITIONAL_COST,
    //                                                         value: temp.filter(
    //                                                             (c) =>
    //                                                                 c.additional_cost_id !==
    //                                                                 data
    //                                                                     .additionalCost[
    //                                                                     foundAdditionalCost
    //                                                                 ]
    //                                                                     .additional_cost_id
    //                                                         ),
    //                                                     });
    //                                                 }
    //                                             }}
    //                                             disabled={
    //                                                 !(
    //                                                     authData.details
    //                                                         ?.role === 'ADMIN'
    //                                                 )
    //                                             }
    //                                         />
    //                                     }
    //                                     label="Seller"
    //                                 />
    //                             </FormGroup>
    //                         </Grid> */}
    //                             </Grid>
    //                         );
    //                     else return null;
    //                 }
    //             )}
    //         </Grid>
    //     );
    // };

    if (templateDetails === undefined) {
        return <LazyFallback />;
    }

    return (
        <>
            {/* {hookState.state === HookStateValue.LOADING && <LazyFallback />} */}

            <Grid container>
                <InfoBlock item xs={3}>
                    <FieldNameDescription
                        name={templateDetails.itemFields['Item_code'].name}
                        description={
                            templateDetails.itemFields['Item_code'].description
                        }
                        required={
                            templateDetails.itemFields['Item_code'].is_required
                        }
                    />
                    <FWInput
                        value={data.itemCode}
                        name={ItemUpdateActions.ITEM_CODE}
                        onChange={handleValueChange}
                        disabled={
                            !(authData.details?.role === 'ADMIN') ||
                            type === ItemPageType.EDIT
                        }
                        error={
                            (codeError || data.itemCode === '') &&
                            type === ItemPageType.ADD
                        }
                        helper={{
                            text:
                                data.itemCode === '' &&
                                type === ItemPageType.ADD
                                    ? 'Item code cannot be empty'
                                    : codeError && type === ItemPageType.ADD
                                    ? `Duplicate item code`
                                    : '',
                            textColor: 'error',
                        }}
                    />
                </InfoBlock>
                <InfoBlock item xs={3}>
                    <FWTypography color="secondary">Item type</FWTypography>
                    <Autocomplete
                        size="small"
                        fullWidth
                        PaperComponent={(props) => (
                            <AutocompleteStyledPaper {...props} />
                        )}
                        id="user-detail-reports-to"
                        options={['FINISHED_GOOD', 'RAW_MATERIAL']}
                        getOptionLabel={(
                            option: 'FINISHED_GOOD' | 'RAW_MATERIAL'
                        ) =>
                            `${capitaliseFirstLetter(
                                option.toLowerCase().replaceAll('_', ' ')
                            )}`
                        }
                        renderOption={(
                            props: any,
                            option: 'FINISHED_GOOD' | 'RAW_MATERIAL'
                        ) => {
                            return (
                                <li {...props} key={option}>
                                    {capitaliseFirstLetter(
                                        option
                                            .toLowerCase()
                                            .replaceAll('_', ' ')
                                    )}
                                </li>
                            );
                        }}
                        value={data.item_type}
                        onChange={(
                            e: any,
                            options: 'FINISHED_GOOD' | 'RAW_MATERIAL'
                        ) => {
                            handleChange({
                                type: ItemUpdateActions.ITEM_TYPE,
                                value: options,
                            });
                        }}
                        isOptionEqualToValue={(
                            opt: 'FINISHED_GOOD' | 'RAW_MATERIAL',
                            val: 'FINISHED_GOOD' | 'RAW_MATERIAL'
                        ) => {
                            return opt === val;
                        }}
                        renderInput={(params) => (
                            <>
                                <TextField {...params} variant="outlined" />
                            </>
                        )}
                        disableClearable
                        disabled={!(authData.details?.role === 'ADMIN')}
                    />
                </InfoBlock>

                <InfoBlock item xs={6}>
                    {console.log('templateDetails', templateDetails)}
                    <FieldNameDescription
                        name={templateDetails.itemFields['Item_name'].name}
                        description={
                            templateDetails.itemFields['Item_name'].description
                        }
                        required={
                            templateDetails.itemFields['Item_name'].is_required
                        }
                    />
                    <FWInput
                        // label="Item name"
                        value={data.itemName}
                        name={ItemUpdateActions.NAME}
                        onChange={handleValueChange}
                        disabled={!(authData.details?.role === 'ADMIN')}
                    />
                </InfoBlock>
            </Grid>

            <Grid container>
                <InfoBlock item xs={12}>
                    <Grid container>
                        <Grid item xs={12} style={{ marginBottom: '30px' }}>
                            <FormControl fullWidth>
                                <div className="flex flex--aic">
                                    <FieldNameDescription
                                        name={
                                            templateDetails.itemFields[
                                                'Measurement_unit'
                                            ].name
                                        }
                                        description={
                                            templateDetails.itemFields[
                                                'Measurement_unit'
                                            ].description
                                        }
                                        required={
                                            templateDetails.itemFields[
                                                'Measurement_unit'
                                            ].is_required
                                        }
                                    />
                                    &nbsp;
                                    {authData.details?.role === 'ADMIN' && (
                                        <FWTooltip title="Add measurement unit">
                                            <Box
                                                color="primary"
                                                sx={{ margin: '0px' }}
                                            >
                                                <FWIconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setNewMeasurementUnitPopup(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <i className="bi bi-plus-circle" />
                                                </FWIconButton>
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </div>

                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    PaperComponent={(props) => (
                                        <AutocompleteStyledPaper {...props} />
                                    )}
                                    groupBy={(option) => option.category}
                                    id="user-detail-reports-to"
                                    options={measurementsArray}
                                    getOptionLabel={(option: IMeasurement) =>
                                        `${option.name} ${
                                            option.abbreviation
                                                ? `(${option.abbreviation})`
                                                : ''
                                        }`
                                    }
                                    renderOption={(
                                        props: any,
                                        option: IMeasurement
                                    ) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {option.name}{' '}
                                                {Boolean(option.abbreviation)
                                                    ? `(${option.abbreviation})`
                                                    : ``}
                                            </li>
                                        );
                                    }}
                                    value={data.measurementUnits[0]}
                                    onChange={(
                                        e: any,
                                        options: IMeasurement
                                    ) => {
                                        handleChange({
                                            type: ItemUpdateActions.MEASUREMENTS,
                                            value: [options],
                                        });
                                    }}
                                    isOptionEqualToValue={(
                                        opt: IMeasurement,
                                        val: IMeasurement
                                    ) => {
                                        return opt.id === val.id;
                                    }}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                            />
                                        </>
                                    )}
                                    disableClearable
                                    disabled={
                                        !(authData.details?.role === 'ADMIN')
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {!templateDetails?.itemFields.Description.is_hidden && (
                            <LongtextBox container>
                                <FieldNameDescription
                                    name={
                                        templateDetails.itemFields[
                                            'Description'
                                        ].name
                                    }
                                    description={
                                        templateDetails.itemFields[
                                            'Description'
                                        ].description
                                    }
                                    required={
                                        templateDetails.itemFields[
                                            'Description'
                                        ].is_required
                                    }
                                />
                                <FWInput
                                    // label="Item description (optional)"

                                    multiline
                                    maxRows={4}
                                    minRows={2}
                                    sx={{ paddingRight: '0' }}
                                    value={data.description}
                                    name={ItemUpdateActions.DESCRIPTION}
                                    onChange={handleValueChange}
                                    disabled={
                                        !(authData.details?.role === 'ADMIN')
                                    }
                                />
                            </LongtextBox>
                        )}

                        {!templateDetails?.itemFields.Tag.is_hidden && (
                            <Grid item xs={12} style={{ marginBottom: '30px' }}>
                                <FormControl fullWidth>
                                    <Box
                                        className="flex flex--aic"
                                        sx={{ marginBottom: '4px' }}
                                    >
                                        <FWTypography color="text.secondary">
                                            Item Tags
                                        </FWTypography>
                                    </Box>
                                    <TagsInput
                                        value={data.tags ?? []}
                                        updateData={(newValue) =>
                                            handleChange({
                                                type: ItemUpdateActions.TAGS,
                                                value: newValue,
                                            })
                                        }
                                        options={globalTags}
                                        disabled={
                                            !(
                                                authData.details?.role ===
                                                'ADMIN'
                                            )
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        )}

                        <Grid container>
                            <InfoBlock
                                item
                                xs={12}
                                sx={{ alignSelf: 'flex-end' }}
                            >
                                <FormGroup sx={{ flexDirection: 'row' }}>
                                    {!templateDetails?.itemFields
                                        .Procurement_item.is_hidden && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={data.isBuyer}
                                                    onChange={(e) => {
                                                        const checkValue =
                                                            e.target.checked;
                                                        if (
                                                            checkValue ||
                                                            data.isSeller
                                                        )
                                                            handleChange({
                                                                type: ItemUpdateActions.IS_BUYER,
                                                                value: checkValue,
                                                            });
                                                    }}
                                                    disabled={
                                                        !(
                                                            authData.details
                                                                ?.role ===
                                                            'ADMIN'
                                                        )
                                                    }
                                                />
                                            }
                                            label="Buyer"
                                        />
                                    )}
                                    {!templateDetails?.itemFields.Selling_price
                                        .is_hidden && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={data.isSeller}
                                                    onChange={(e) => {
                                                        const checkValue =
                                                            e.target.checked;
                                                        if (
                                                            checkValue ||
                                                            data.isBuyer
                                                        )
                                                            handleChange({
                                                                type: ItemUpdateActions.IS_SELLER,
                                                                value: checkValue,
                                                            });
                                                    }}
                                                    disabled={
                                                        !(
                                                            authData.details
                                                                ?.role ===
                                                            'ADMIN'
                                                        )
                                                    }
                                                />
                                            }
                                            label="Seller"
                                        />
                                    )}
                                </FormGroup>
                            </InfoBlock>
                            {(!templateDetails?.itemFields.Procurement_item
                                .is_hidden ||
                                !templateDetails?.itemFields.Selling_price
                                    .is_hidden) && (
                                <InfoBlock item xs={8}>
                                    <Box
                                        className={'flex flex--aic'}
                                        marginBottom={'30px'}
                                        gap={2}
                                    >
                                        {data.isBuyer &&
                                            !templateDetails?.itemFields
                                                .Procurement_item.is_hidden && (
                                                <Grid item xs={6}>
                                                    <FWCombinedInput
                                                        selectDisabled={
                                                            !(
                                                                authData.details
                                                                    ?.role ===
                                                                'ADMIN'
                                                            )
                                                        }
                                                        inputDisabled={
                                                            !(
                                                                authData.details
                                                                    ?.role ===
                                                                'ADMIN'
                                                            )
                                                        }
                                                        required={true}
                                                        isDropLeft={true}
                                                        label="Buyer default price"
                                                        allowOnly="DECIMAL_NUMBER"
                                                        textFieldplaceholder="Enter price"
                                                        selectFieldplaceholder="Select currency"
                                                        textFieldValue={
                                                            data
                                                                .buyerPricingInformation
                                                                .price || ''
                                                        }
                                                        textFieldOnChange={(
                                                            e
                                                        ) =>
                                                            handleChange({
                                                                type: ItemUpdateActions.BUYER_PRICING_INFORMATION,
                                                                value: {
                                                                    ...data.buyerPricingInformation,
                                                                    price: +e
                                                                        .target
                                                                        .value,
                                                                    // price: +e.target.value.split(' ')[0]
                                                                },
                                                            })
                                                        }
                                                        selectFieldValue={
                                                            data
                                                                .buyerPricingInformation
                                                                .currencyCodeId ||
                                                            ''
                                                        }
                                                        selectFieldOnChange={(
                                                            e
                                                        ) =>
                                                            handleChange({
                                                                type: ItemUpdateActions.BUYER_PRICING_INFORMATION,
                                                                value: {
                                                                    ...data.buyerPricingInformation,
                                                                    currencyCodeId:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        selectFieldMenuItems={currencies.map(
                                                            (val, i) => (
                                                                <FWMenuItem
                                                                    value={
                                                                        val.entry_id
                                                                    }
                                                                    key={i}
                                                                >
                                                                    {`${val.currency_code_abbreviation} (${val.currency_symbol})`}
                                                                </FWMenuItem>
                                                            )
                                                        )}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    backgroundColor:
                                                                        'white',
                                                                    borderRadius:
                                                                        '12px',
                                                                    boxShadow:
                                                                        '0px 5px 16px 3px #00000024',
                                                                    maxHeight:
                                                                        '30vh',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                        {data.isSeller &&
                                            !templateDetails?.itemFields
                                                .Selling_price.is_hidden && (
                                                <Grid item xs={6}>
                                                    <FWCombinedInput
                                                        required={true}
                                                        isDropLeft={true}
                                                        label="Seller default price"
                                                        allowOnly="DECIMAL_NUMBER"
                                                        textFieldplaceholder="Enter price"
                                                        selectFieldplaceholder="Select currency"
                                                        textFieldValue={
                                                            data
                                                                .sellerPricingInformation
                                                                .price || ''
                                                        }
                                                        textFieldOnChange={(
                                                            e
                                                        ) =>
                                                            handleChange({
                                                                type: ItemUpdateActions.SELLER_PRICING_INFORMATION,
                                                                value: {
                                                                    ...data.sellerPricingInformation,
                                                                    price: +e
                                                                        .target
                                                                        .value,
                                                                },
                                                            })
                                                        }
                                                        selectFieldValue={
                                                            data
                                                                .sellerPricingInformation
                                                                .currencyCodeId ||
                                                            ''
                                                        }
                                                        selectFieldOnChange={(
                                                            e
                                                        ) =>
                                                            handleChange({
                                                                type: ItemUpdateActions.SELLER_PRICING_INFORMATION,
                                                                value: {
                                                                    ...data.sellerPricingInformation,
                                                                    currencyCodeId:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        selectFieldMenuItems={currencies.map(
                                                            (val, i) => (
                                                                <FWMenuItem
                                                                    value={
                                                                        val.entry_id
                                                                    }
                                                                    key={i}
                                                                >
                                                                    {`${val.currency_code_abbreviation} (${val.currency_symbol})`}
                                                                </FWMenuItem>
                                                            )
                                                        )}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    backgroundColor:
                                                                        'white',
                                                                    borderRadius:
                                                                        '12px',
                                                                    boxShadow:
                                                                        '0px 5px 16px 3px #00000024',
                                                                    maxHeight:
                                                                        '30vh',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                    </Box>
                                </InfoBlock>
                            )}

                            {/* {type === ItemPageType.EDIT ? () : ()} */}
                            {/*  */}
                            {/* {type === ItemPageType.EDIT &&
                                templateDetails &&
                                !templateDetails?.additionalCosts[0].isHidden &&
                                templateDetails?.additionalCosts[0].fields
                                    .length > 0 && (
                                    <InfoBlock item xs={12}>
                                        <Grid item xs={3}>
                                            <Typography
                                                color={'text.secondary'}
                                            >
                                                Select additional costs
                                            </Typography>
                                            <Select
                                                size="small"
                                                fullWidth
                                                multiple
                                                sx={{ marginBottom: '1.25rem' }}
                                                renderValue={() =>
                                                    // additionalCostsToDisplay?.map(
                                                    //     (option) => {
                                                    //         return (
                                                    //             <Chip
                                                    //                 label={
                                                    //                     option
                                                    //                 }
                                                    //                 sx={{
                                                    //                     marginRight:
                                                    //                         '5px',
                                                    //                 }}
                                                    //             />
                                                    //         );
                                                    //     }
                                                    // )
                                                    templateDetails?.additionalCosts[0].fields.map(
                                                        (option) => {
                                                            if (
                                                                additionalCostsToDisplay?.includes(
                                                                    option.name
                                                                )
                                                            ) {
                                                                return (
                                                                    <Chip
                                                                        label={
                                                                            option.name
                                                                        }
                                                                        sx={{
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                            return <></>;
                                                        }
                                                    )
                                                }
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            bgcolor: 'white',
                                                            maxHeight: '300px',
                                                            boxShadow:
                                                                '0px 5px 16px #C4C4c8',
                                                        },
                                                    },
                                                }}
                                                value={
                                                    additionalCostsToDisplay ??
                                                    []
                                                }
                                                IconComponent={OpenDownIcon}
                                            >
                                                {templateDetails &&
                                                    templateDetails
                                                        ?.additionalCosts[0]
                                                        .fields.length > 1 && (
                                                        <MenuItem
                                                            key={'Select All'}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                const temp =
                                                                    cloneDeep(
                                                                        data.additionalCost
                                                                    );

                                                                templateDetails?.additionalCosts[0].fields.forEach(
                                                                    (cost) => {
                                                                        if (
                                                                            cost.is_hidden
                                                                        )
                                                                            return;

                                                                        if (
                                                                            data.additionalCost.find(
                                                                                (
                                                                                    c
                                                                                ) =>
                                                                                    c.additional_cost_id ===
                                                                                    cost
                                                                                        .additional_cost_information
                                                                                        .additional_cost_id
                                                                            ) ===
                                                                            undefined
                                                                        ) {
                                                                            temp.push(
                                                                                {
                                                                                    allocation_type:
                                                                                        cost
                                                                                            .additional_cost_information
                                                                                            .allocation_type ??
                                                                                        null,
                                                                                    cost_source:
                                                                                        cost
                                                                                            .additional_cost_information
                                                                                            .cost_source,
                                                                                    cost_name:
                                                                                        cost.name,
                                                                                    cost_type:
                                                                                        cost
                                                                                            .additional_cost_information
                                                                                            .cost_type ??
                                                                                        CostTypeEnum.PERCENTAGE,
                                                                                    cost_value: 0,
                                                                                    additional_cost_id:
                                                                                        cost
                                                                                            .additional_cost_information
                                                                                            .additional_cost_id,
                                                                                }
                                                                            );
                                                                        } else {
                                                                            
                                                                        }
                                                                    }
                                                                );

                                                                if (
                                                                    setAdditionalCostToDisplay
                                                                ) {
                                                                    setAdditionalCostToDisplay(
                                                                        temp.map(
                                                                            (
                                                                                cost
                                                                            ) =>
                                                                                cost.cost_name
                                                                        )
                                                                    );
                                                                }

                                                                handleChange({
                                                                    type: ItemUpdateActions.ADDITIONAL_COST,
                                                                    value: temp,
                                                                });
                                                                handleChange({
                                                                    type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
                                                                    value: temp,
                                                                });
                                                                handleChange({
                                                                    type: ItemUpdateActions.SELLER_ADDITIONAL_COST,
                                                                    value: temp,
                                                                });
                                                            }}
                                                        >
                                                            <Box
                                                                display="flex"
                                                                width={'100%'}
                                                            >
                                                                <Checkbox
                                                                    sx={{
                                                                        '&.MuiCheckbox-root':
                                                                            {
                                                                                padding:
                                                                                    '0px 9px',
                                                                            },
                                                                    }}
                                                                    checked={
                                                                        additionalCostsToDisplay?.length ===
                                                                        itemTemplateSectionList
                                                                            ?.additionalCosts[0]
                                                                            .fields
                                                                            .length
                                                                    }
                                                                />
                                                                <Typography
                                                                    component={
                                                                        'span'
                                                                    }
                                                                    sx={{
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    Select all
                                                                </Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    )}

                                                {templateDetails?.additionalCosts[0].fields.map(
                                                    (cost, index) =>
                                                        !cost.is_hidden && (
                                                            <MenuItem
                                                                key={index}
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'space-between',
                                                                }}
                                                                onClick={() =>
                                                                    // filterAdditionalCost(
                                                                    //     cost,
                                                                    //     index
                                                                    // )
                                                                    filterAdditionalCostNewFunc(
                                                                        cost
                                                                    )
                                                                }
                                                            >
                                                                <Box
                                                                    display="flex"
                                                                    width={
                                                                        '100%'
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        sx={{
                                                                            '&.MuiCheckbox-root':
                                                                                {
                                                                                    padding:
                                                                                        '0px 9px',
                                                                                },
                                                                        }}
                                                                        checked={additionalCostsToDisplay?.includes(
                                                                            cost.name
                                                                        )}
                                                                    />
                                                                    <Typography
                                                                        component={
                                                                            'span'
                                                                        }
                                                                        sx={{
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            cost.name
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        )
                                                )}
                                            </Select>
                                        </Grid>
                                        <InfoBlock item xs={12}>
                                            <Grid
                                                item
                                                xs={6}
                                                marginBottom={'10px'}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '1rem',
                                                }}
                                            >
                                                {renderAdditionalCostListThings()}
                                            </Grid>
                                        </InfoBlock>
                                    </InfoBlock>
                                )} */}
                            {/* {type === ItemPageType.ADD &&
                                templateDetails &&
                                !templateDetails?.additionalCosts[0].isHidden &&
                                templateDetails?.additionalCosts[0].fields
                                    .length > 0 && (
                                    <InfoBlock item xs={12}>
                                        <Grid item xs={3}>
                                            <Typography
                                                color={'text.secondary'}
                                            >
                                                Select additional costs
                                            </Typography>
                                            <Select
                                                size="small"
                                                fullWidth
                                                multiple
                                                sx={{ marginBottom: '1.25rem' }}
                                                renderValue={() =>
                                                    templateDetails?.additionalCosts[0].fields.map(
                                                        (option) => {
                                                            if (
                                                                newItemAdditionalCostToDisplay.includes(
                                                                    option.name
                                                                )
                                                            ) {
                                                                return (
                                                                    <Chip
                                                                        label={
                                                                            option.name
                                                                        }
                                                                        sx={{
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                            return <></>;
                                                        }
                                                    )
                                                }
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            bgcolor: 'white',
                                                            maxHeight: '300px',
                                                            boxShadow:
                                                                '0px 5px 16px #C4C4c8',
                                                        },
                                                    },
                                                }}
                                                value={
                                                    newItemAdditionalCostToDisplay
                                                }
                                                IconComponent={OpenDownIcon}
                                            >
                                                {templateDetails &&
                                                    templateDetails
                                                        ?.additionalCosts[0]
                                                        .fields.length > 1 && (
                                                        <MenuItem
                                                            key={'Select All'}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}
                                                            onClick={() => {
                                                                const temp =
                                                                    cloneDeep(
                                                                        data.additionalCost
                                                                    );

                                                                if (
                                                                    data
                                                                        .additionalCost
                                                                        .length !==
                                                                    templateDetails
                                                                        .additionalCosts[0]
                                                                        .fields
                                                                        .length
                                                                ) {
                                                                    templateDetails?.additionalCosts[0].fields.forEach(
                                                                        (
                                                                            cost
                                                                        ) => {
                                                                            if (
                                                                                cost.is_hidden
                                                                            )
                                                                                return;

                                                                            if (
                                                                                data.additionalCost.find(
                                                                                    (
                                                                                        c
                                                                                    ) =>
                                                                                        c.additional_cost_id ===
                                                                                        cost
                                                                                            .additional_cost_information
                                                                                            .additional_cost_id
                                                                                ) ===
                                                                                undefined
                                                                            ) {
                                                                                temp.push(
                                                                                    {
                                                                                        allocation_type:
                                                                                            cost
                                                                                                .additional_cost_information
                                                                                                .allocation_type ??
                                                                                            null,
                                                                                        cost_source:
                                                                                            cost
                                                                                                .additional_cost_information
                                                                                                .cost_source,
                                                                                        cost_name:
                                                                                            cost.name,
                                                                                        cost_type:
                                                                                            cost
                                                                                                .additional_cost_information
                                                                                                .cost_type ??
                                                                                            CostTypeEnum.PERCENTAGE,
                                                                                        cost_value: 0,
                                                                                        additional_cost_id:
                                                                                            cost
                                                                                                .additional_cost_information
                                                                                                .additional_cost_id,
                                                                                    }
                                                                                );
                                                                            }
                                                                        }
                                                                    );

                                                                    setNewItemAdditionalCostToDisplay(
                                                                        temp.map(
                                                                            (
                                                                                c
                                                                            ) =>
                                                                                c.cost_name
                                                                        )
                                                                    );

                                                                    handleChange(
                                                                        {
                                                                            type: ItemUpdateActions.ADDITIONAL_COST,
                                                                            value: temp,
                                                                        }
                                                                    );
                                                                    handleChange(
                                                                        {
                                                                            type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
                                                                            value: temp,
                                                                        }
                                                                    );
                                                                    handleChange(
                                                                        {
                                                                            type: ItemUpdateActions.SELLER_ADDITIONAL_COST,
                                                                            value: temp,
                                                                        }
                                                                    );
                                                                } else {
                                                                    setNewItemAdditionalCostToDisplay(
                                                                        []
                                                                    );

                                                                    handleChange(
                                                                        {
                                                                            type: ItemUpdateActions.ADDITIONAL_COST,
                                                                            value: [],
                                                                        }
                                                                    );
                                                                    handleChange(
                                                                        {
                                                                            type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
                                                                            value: [],
                                                                        }
                                                                    );
                                                                    handleChange(
                                                                        {
                                                                            type: ItemUpdateActions.SELLER_ADDITIONAL_COST,
                                                                            value: [],
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Box
                                                                display="flex"
                                                                width={'100%'}
                                                            >
                                                                <Checkbox
                                                                    sx={{
                                                                        '&.MuiCheckbox-root':
                                                                            {
                                                                                padding:
                                                                                    '0px 9px',
                                                                            },
                                                                    }}
                                                                    checked={
                                                                        newItemAdditionalCostToDisplay?.length ===
                                                                        templateDetails
                                                                            ?.additionalCosts[0]
                                                                            .fields
                                                                            .length
                                                                    }
                                                                />
                                                                <Typography
                                                                    component={
                                                                        'span'
                                                                    }
                                                                    sx={{
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    Select all
                                                                </Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    )}

                                                {templateDetails?.additionalCosts[0].fields.map(
                                                    (cost, index) =>
                                                        !cost.is_hidden && (
                                                            <MenuItem
                                                                key={index}
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'space-between',
                                                                }}
                                                                onClick={() =>
                                                                    filterNewItemAdditionalCost(
                                                                        cost,
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Box
                                                                    display="flex"
                                                                    width={
                                                                        '100%'
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        sx={{
                                                                            '&.MuiCheckbox-root':
                                                                                {
                                                                                    padding:
                                                                                        '0px 9px',
                                                                                },
                                                                        }}
                                                                        checked={newItemAdditionalCostToDisplay?.includes(
                                                                            cost.name
                                                                        )}
                                                                    />
                                                                    <Typography
                                                                        component={
                                                                            'span'
                                                                        }
                                                                        sx={{
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            cost.name
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        )
                                                )}
                                            </Select>
                                        </Grid>
                                        <InfoBlock item xs={12}>
                                            <Grid
                                                item
                                                xs={6}
                                                marginBottom={'10px'}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '1rem',
                                                }}
                                            >
                                                {renderNewItemAdditionalCost()}
                                            </Grid>
                                        </InfoBlock>
                                    </InfoBlock>
                                )} */}
                        </Grid>
                    </Grid>

                    <Grid marginBottom={2}>
                        {!templateDetails?.additionalCosts[0]?.isHidden && (
                            <Grid item xs={4}>
                                <ItemAdditionalCost
                                    data={data}
                                    handleChange={handleChange}
                                    templateDetails={templateDetails}
                                    buyerCurrency={
                                        currencies.find(
                                            (currency) =>
                                                currency.entry_id ===
                                                data.buyerPricingInformation
                                                    .currencyCodeId
                                        )?.currency_name ?? ''
                                    }
                                    sellerCurrency={
                                        currencies.find(
                                            (currency) =>
                                                currency.entry_id ===
                                                data.sellerPricingInformation
                                                    .currencyCodeId
                                        )?.currency_name ?? ''
                                    }
                                    misMatchedCosts={misMatchedCosts ?? []}
                                    tooltipTextForEachRemove=""
                                    removeAdditionalCosts={(
                                        type: 'SINGLE' | 'ALL',
                                        costId?: string | null
                                    ) => {
                                        handleMismatchedCostRemove(
                                            type,
                                            costId
                                        );
                                    }}
                                />
                            </Grid>
                        )}
                        {/* {misMatchedCosts && (
                            <Grid item xs={4}>
                                <MismatchedAdditionalCost
                                    misMatchedCosts={misMatchedCosts}
                                    tooltipTextForEachRemove=""
                                    removeAdditionalCosts={(
                                        type: 'SINGLE' | 'ALL',
                                        costId?: string | null
                                    ) => {
                                        handleMismatchedCostRemove(
                                            type,
                                            costId
                                        );
                                    }}
                                />
                            </Grid>
                        )} */}
                    </Grid>

                    {!templateDetails?.itemFields.Notes.is_hidden && (
                        <LongtextBox>
                            <FWInput
                                // style={{ width: '50%' }}

                                label={`Notes and external links ${
                                    itemTemplateSectionList?.itemFields.Notes
                                        .is_required
                                        ? ''
                                        : '(optional)'
                                }`}
                                multiline
                                maxRows={4}
                                minRows={2}
                                value={data.notes || ''}
                                onChange={(e) =>
                                    handleChange({
                                        type: ItemUpdateActions.NOTES,
                                        value: e.target.value,
                                    })
                                }
                                disabled={!(authData.details?.role === 'ADMIN')}
                            />
                        </LongtextBox>
                    )}

                    {!templateDetails?.itemFields.Internal_notes?.is_hidden && (
                        <LongtextBox>
                            <FieldNameDescription
                                name={
                                    templateDetails.itemFields['Internal_notes']
                                        .name
                                }
                                description={
                                    templateDetails.itemFields['Internal_notes']
                                        .description
                                }
                                required={
                                    templateDetails.itemFields['Internal_notes']
                                        .is_required
                                }
                            />
                            <FWInput
                                // style={{ width: '50%' }}

                                multiline
                                maxRows={4}
                                minRows={2}
                                value={data.internal_notes || ''}
                                onChange={(e) =>
                                    handleChange({
                                        type: ItemUpdateActions.INTERNAL_NOTES,
                                        value: e.target.value,
                                    })
                                }
                                disabled={!(authData.details?.role === 'ADMIN')}
                            />
                        </LongtextBox>
                    )}

                    {!templateDetails?.itemFields?.Specification_name
                        ?.is_hidden && (
                        <InfoBlock item xs={12} sx={{ mb: 0, pr: 0 }}>
                            <FieldNameDescription
                                name={
                                    templateDetails.itemFields[
                                        'Specification_name'
                                    ].name
                                }
                                description={
                                    templateDetails.itemFields[
                                        'Specification_name'
                                    ].description
                                }
                                required={
                                    templateDetails.itemFields[
                                        'Specification_name'
                                    ].is_required
                                }
                            />

                            <Grid container sx={{ width: '100%' }}>
                                <ItemSpecifications
                                    data={data.specifications}
                                    emitSpecificationAction={
                                        handleSpecificationEmitData
                                    }
                                />
                            </Grid>
                        </InfoBlock>
                    )}
                </InfoBlock>

                {data.standardTerms.lead &&
                    data.standardTerms.payment &&
                    data.standardTerms.prePayment && (
                        <InfoBlock item xs={6}>
                            <FWTypography>Standard terms</FWTypography>
                            <ItemStandardTerms
                                data={data.standardTerms}
                                emitSTValuesChange={handleSTValuesChange}
                                anchorEl={menuAnchorEl}
                                onClose={closeMenu}
                                openMenu={openMenu}
                                closeMenu={closeMenu}
                            />
                        </InfoBlock>
                    )}
            </Grid>

            {!templateDetails?.itemFields.Identifier_name?.is_hidden && (
                <Grid item xs={event ? 12 : 6} sx={{ pr: event ? '15px' : 0 }}>
                    <FWTypography
                        sx={{ mb: '5px', fontWeight: 'bold' }}
                        color="text.secondary"
                    >
                        Custom IDs (optional)
                    </FWTypography>
                    <ItemCustomIds
                        data={data.customIds}
                        emitCustomIdsAction={handleCustomIdsEmitData}
                    />
                </Grid>
            )}

            {data?.custom_fields && (
                <Grid item xs={12} sx={{ pr: '15px' }}>
                    <FWTypography sx={{ mb: '5px' }} color="text.secondary">
                        Additional information
                    </FWTypography>

                    <ItemCustomFields
                        customFields={data?.custom_fields}
                        handleChange={handleChange}
                        newItemTemplateSectionList={templateDetails}
                        customFieldTemplateSetting={customFieldSetting}
                    />
                </Grid>
            )}

            {event && <Grid item xs={12} height="30px"></Grid>}

            {authData.details?.role === 'ADMIN' &&
                (SINGLE_ENTITY && type === ItemPageType.EDIT ? (
                    <FWPortal id="item-bottom-buttons">
                        {BottomButtons}
                    </FWPortal>
                ) : (
                    BottomButtons
                ))}
            <UOMMeasurementViewPopup
                open={newMeasurementUnitPopup}
                handleClose={() => {
                    setNewMeasurementUnitPopup(false);
                }}
                onSaved={(measurementUditId: string) => {
                    fetchItemMeasurements();
                    setNewMeasurementUnitSelectedFromPopup(measurementUditId);
                }}
            />
            {/* <AddMeasurementUnitContainer
                open={newMeasurementUnitPopup}
                handleClose={() => {
                    setNewMeasurementUnitPopup(false);
                }}
                onSuccess={onAddNewMeasurementUnit}
            /> */}
        </>
    );
};

export default ItemAddEditContainer;
